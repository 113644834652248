import { Avatar, Button, IconButton, Stack, Typography } from "@mui/material/";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect, useState } from "react";
import { PermissionsC } from "./../../containers/Permissions";
import { FilledAlerts } from "./../Common/AlertsMUI";
import { InputAutocompleteAdapter } from "./../Common/MUIAutoComplete";
import { InputFile } from "./../Common/MUIInputFile";
import { Input } from "./../Common/MUInput";

export const AddUserV = ({
  data: {
    vars: {
      type_user,
      viewUser,
      errors,
      alert,
      control,
      isLoading,
      image,
      rolesEnableds,
    },
    funcs: {
      setPath,
      handleSubmit,
      OnSubmit,
      SetAlert,
      handle_upload_edit_file,
      handleSyncPermissions
    },
  },
}) => {
  const [readOnly, setReadOnly] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (viewUser?.readonly) {
      setReadOnly(true);
    } else if (viewUser?.edit) {
      setEdit(true);
    }
  }, [viewUser?.readonly, viewUser?.edit]);

  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "#f6f7fb",
        pl: 1,
      }}
    >
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <FilledAlerts
        msg={alert?.msg}
        type={alert?.type}
        setOpen={SetAlert}
        open={alert?.active}
      />
      <Box
        height="40px"
        bgcolor="#ffffff"
        p={2}
        pr={8}
        borderRadius="0px 0px 0px 7px"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        boxShadow="0px 3px 4px 0px #2222221A"
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#323338",
          }}
        >
          {edit
            ? "Editar usuario"
            : readOnly
            ? "Información del usuario"
            : "Nuevo usuario"}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          height: "100%",
          width: "95%",
          overflow: "auto",
          borderRadius: "7px",
          bgcolor: "#FFFFFF",
          p: "0px 16px",
          "& .options-className": {
            borderLeft: "3px solid #E2E4E7",
          },
          "& .role-className": {
            borderLeft: "1px solid #E2E4E7",
          },
        }}
      >
        <Grid container columnSpacing={2} rowGap={2} columns={12} pt={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography sx={styles.label}>Nombres*</Typography>
            <Input
              name="names"
              control={control}
              props={{
                required: true,
                size: "small",
                error: errors?.names ? true : false,
                helperText: errors?.names?.message,
                disabled: readOnly,
                autoComplete: "none",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography sx={styles.label}>Apellidos*</Typography>
            <Input
              name="last_names"
              control={control}
              props={{
                required: true,
                size: "small",
                error: errors?.last_names ? true : false,
                helperText: errors?.last_names?.message,
                disabled: readOnly,
                autoComplete: "none",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography sx={styles.label}>Correo electrónico*</Typography>
            <Input
              name="email"
              control={control}
              props={{
                required: true,
                size: "small",
                error: errors?.email ? true : false,
                helperText: errors?.email?.message,
                disabled: readOnly,
                autoComplete: "none",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography sx={styles.label}>Rol*</Typography>
            <InputAutocompleteAdapter
              disabled={readOnly}
              keyLabel="label"
              keyValue="value"
              height={40}
              label=""
              name="rol_id"
              control={control}
              error={errors?.rol_id}
              options={
                rolesEnableds?.map((x) => ({
                  value: x?.id,
                  label: x?.name,
                })) || []
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography sx={styles.label}>Cargo*</Typography>
            <Input
              name="charge"
              control={control}
              props={{
                required: true,
                size: "small",
                error: errors?.charge ? true : false,
                helperText: errors?.charge?.message,
                disabled: readOnly,
                autoComplete: "none",
              }}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} rowGap={2} columns={12} pt={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography sx={styles.label}>Foto de perfil</Typography>
            <Stack flexDirection="row" alignItems="center" gap={2}>
              {(edit || readOnly) && (
                <IconButton sx={{ width: 100, height: 100 }}>
                  <Avatar
                    src={image?.data?.url}
                    sx={{ width: 100, height: 100 }}
                  />
                </IconButton>
              )}
              {!readOnly && (
                <InputFile
                 activateColor={!edit}
                  name="image"
                  label={"Elegir archivo"}
                  control={control}
                  onClick={() => handle_upload_edit_file()}
                  props={{
                    error: errors?.image ? true : false,
                    helperText: errors?.image?.message,
                  }}
                />
              )}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            alignItems="center"
            justifyContent="end"
            gap={2}
          >
            <Button
              type="button"
              variant="text"
              onClick={() => setPath("users-list")}
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "capitalize",
                width: "119px",
                height: "49px",
                color: "#000000",
                ":hover": {
                  background: "#E2E4E7",
                },
              }}
            >
              Cancelar
            </Button>
            {!readOnly && (
              <>

              <Button
                type="button"
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "14px",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  width: "100px",
                  height: "49px",
                  background: "#1AAFD0",
                  color: "#FFFFFF",
                  ":hover": {
                    background: "#1AAFD0",
                  },
                }}
                onClick={handleSubmit(OnSubmit)}
              >
                Guardar
              </Button>
              <Button
                onClick={ ()=>handleSyncPermissions(viewUser?.user?.id)}
              >
                Sincronizar Permisos
              </Button>
              </>
            )}
            
          </Grid>
        </Grid>
        {(viewUser?.readonly || viewUser?.edit) && (
          <Box mt={2}>
            <PermissionsC
              option={2}
              data={{
                label: "Usuario",
                stateView: viewUser,
                isFilterUser: true,
                filters: {
                  user_id: viewUser?.user?.id,
                },
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
