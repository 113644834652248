import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const SelectInput = ({
  rows,
  name,
  label,
  width,
  value_selected,
  handleChange,
  props,
}) => {
  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        {...props}
        name={name}
        id={label}
        value={value_selected || ""}
        onChange={handleChange}
        size={"small"}
        label={label}
        // sx={{ width: width ? width : 300 }}
      >
        <MenuItem value="">
          <em>Seleccionar...</em>
        </MenuItem>
        {rows.sort((actorA, actorB) =>
          actorA.name.localeCompare(actorB.name))?.map((x, i) => (
          <MenuItem key={i} value={x?.id}>
            {x?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
