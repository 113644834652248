import React, { useState, useEffect } from "react";
import { SearchTable } from "../../components/Common/SearchTable";
import { set, useForm } from "react-hook-form";
import { UseStoreCompany } from "../../stores/Company";
import { UseStoreTemplateView } from "../../stores/TemplateView";
import { UseStorePillar } from "../../stores/Pillar";
import { UseStoreSearch } from "../../stores/Search";
import { UseStoreLogin } from "../../stores/Login";
import { SwalFire, ToPayerText } from "../../utils";
import dayjs from "dayjs";
import { readFile } from "../../utils/AWS";

export const SearchTableC = () => {
  const { handleSubmit, control, getValues, reset } = useForm({
    defaultValues: {
      company_id: null,
      pillar_id: 1,
      grant_date: null,
      type_document: null,
      type_power: null,
      type_acts: null,
      tags: null,
      special_power: null,
      attorney: null,
      status: null,
      text: null,
    },
  });
  const userInfo = UseStoreLogin((state) => state.data_user);
  const companies = UseStoreCompany((state) => state.companies);
  const get_permission_user = UseStorePillar(
    (state) => state.get_permission_user
  );
  const pillars = UseStoreTemplateView((state) => state.pillars_companies);
  const attorneys = UseStoreTemplateView((state) => state.attorneys);
  const get_file_by_pillar_code = UseStoreTemplateView(
    (state) => state.get_file_by_pillar_code
  );
  const type_powers = UseStorePillar((state) => state.type_powers);
  const especial_powers = UseStorePillar((state) => state.especial_powers);
  const filter_data = UseStoreSearch((state) => state.filter_data);
  const get_data_by_filter = UseStoreSearch(
    (state) => state.get_data_by_filter
  );
  const [values, setValues] = useState(getValues());
  const [isLoading, setIsLoading] = useState(false);
  const [valueText, setValueText] = useState("");
  const [disabledText, setDisabledText] = useState(false);
  const [pdfPreview, setPdfPreview] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [typeDocument, setTypeDocument] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [modalDocument, setModalDocument] = useState(false);
  const acta = [
    { id: 1, name: "Asamblea ordinaria" },
    { id: 2, name: "Asamblea extraordinaria" },
  ];

  const status = [
    { id: 1, name: "Vigente" },
    { id: 2, name: "Revocado" },
  ];

  const handleNavigate = (url) => {
    window.open(url, "_blank");
  };

  const getTypeDocument = (type) => {
    switch (type) {
      case "act-con":
        return 2;
      case "asa-ord":
      case "asa-ext":
        return 4;
      case "fed-hec":
        return 5;
      case "pwr-ppc":
      case "pwr-paa":
      case "pwr-pad":
      case "pwr-pes":
        return 3;
      default:
        return 0;
    }
  };

  const handleRowAction = async (row, action) => {
    let permissions = {};
    const userPermissions = await get_permission_user(
      userInfo?.id,
      row?.company_id
    );
    if (userPermissions?.length) {
      const found = userPermissions?.find((item) => {
        return action === "company"
          ? item?.section === "Perfil empresarial"
          : item?.section_id === getTypeDocument(row?.pillar_code);
      });
      permissions = found;
    }
    // Realizar acciones según el tipo de acción
    if (action === "company") {
      if (!permissions?.view && userInfo?.rol_id !== 1) {
        SwalFire(
          "Atencion!",
          "Usted no tiene permiso para ver la empresa.",
          "warning"
        );
        return;
      }
      let url = "";
      url = `/view-company/${row?.company_id}`;
      handleNavigate(url);
    } else if (action === "module") {
      if (!permissions?.view && userInfo?.rol_id !== 1) {
        SwalFire(
          "Atencion!",
          "Usted no tiene permiso para ver este modulo.",
          "warning"
        );
        return;
      }
      let url = "";
      if (
        ["pwr-ppc", "pwr-paa", "pwr-pad", "pwr-pes"].includes(row?.pillar_code)
      ) {
        url = `${row?.url_module}${row?.company_name}/poderes/${row?.pillar_code}`;
      }
      if (row?.pillar_code === "act-con") {
        url = `${row?.url_module}${row?.company_name}/act-con`;
      }
      if (["asa-ord", "asa-ext"].includes(row?.pillar_code)) {
        url = `${row?.url_module}${row?.company_name}/act-asm/${row?.pillar_code}`;
      }
      if (row?.pillar_code === "fed-hec") {
        url = `${row?.url_module}${row?.company_name}/${row?.pillar_code}`;
      }

      handleNavigate(url);
    }
  };
  const inputs = [
    {
      label: "Empresa",
      name: "company_id",
      //disabled: Boolean(valueText),
      options:
        companies?.map((x) => ({
          value: x?.id,
          label: x?.name,
        })) || [],
    },
    {
      label: "Pilar",
      name: "pillar_id",
      disabled: false,
      defaultValue: "default_value",
      options:
        pillars
          ?.map((x) => ({
            value: x?.id,
            label: x?.name,
          })) || [],
    },
    {
      label: "Fecha de otorgamiento",
      disabled: Boolean(valueText),
      name: "grant_date",
      options: [],
      getValue: (value) => setDisabledText(Boolean(value)),
    },
    {
      label: "Tipo de documento",
      name: "type_document",
      disabled:  typeDocument,
      //disabled: typeDocument,
      options:
        pillars[0].sub_pillars?.map((x) => ({
          value: x?.code,
          label: x?.name,
        })) || [],
    },
    {
      label: "Tipo de poderes",
      name: "type_power",
      disabled: values?.type_document !== "poderes" || Boolean(valueText) ,
      options:
        type_powers?.map((x) => ({
          value: x?.code,
          label: x?.name,
        })) || [],
    },
    {
      label: "Tipo de actas",
      name: "type_acts",
      disabled: values?.type_document !== "act-asm" || Boolean(valueText),
      options:
        acta?.map((x) => ({
          value: x?.id,
          label: x?.name,
        })) || [],
    },
    {
      label: "Etiquetas",
      name: "tags",
      disabled: values?.type_document !== "act-asm" || Boolean(valueText),
      module: "tags",
    },
    {
      label: "Poderes especiales",
      name: "special_power",
      disabled: values?.type_power !== "pwr-pes",
      options:
        especial_powers?.map((x) => ({
          value: x?.id,
          label: ToPayerText(x?.name),
        })) || [],
    },
    {
      label: "Apoderado",
      name: "attorney",
      disabled: values?.type_document !== "poderes" || Boolean(valueText),
      options:
        attorneys?.map((x) => ({
          value: x?.attorney,
          label: x?.attorney,
        })) || [],
    },
    {
      label: "Estatus",
      name: "status",
      disabled: values?.type_document !== "poderes" || Boolean(valueText),
      options:
        status?.map((x) => ({
          value: x?.id,
          label: x?.name,
        })) || [],
    },
    {
      label: "Buscador de texto",
      name: "text",
      disabled: disabledText,
      size: "small",
      value: valueText,
      getValue: (value) => setValueText(value),
    },
  ];

  useEffect(() => {}, [values]);

  const handleChangeSelect = () => {
    const values = getValues();
    if (values?.type_document || values?.pillar_id === 1) {
      setDisabledText(true);
    } else {
      setDisabledText(false);
    }
    if (!(values?.pillar_id === 1)) {
      console.log('es diferente a 1')
      setTypeDocument(true);
    }else{
      console.log('es igual a 1')
      setTypeDocument(false);
    }
    setValues(values);
  };

  const getFile = async ({ row }) => {
    setLoadingPdf(true);
    const payload = {
      pillar_code: row?.pillar_code,
      url: row?.pdf_link,
      isDownload: false,
      company_id: row?.company_id,
      user: {
        id: userInfo?.id,
        type: userInfo?.rol_id,
      },
    };

    const url_pdf = await get_file_by_pillar_code(payload);
    if (url_pdf) {
      setOpenModal(true);
      setPdfPreview(url_pdf);
    }
    setLoadingPdf(false);
  };
  const fetchShareLink = async (document_url, pilar_id) => {
    console.log(document_url);
    console.log(pilar_id);

    if (document_url !== "") {
      if (parseInt(pilar_id) === 2) {
        const sha_link = await readFile("/Actos_gubernamentales", document_url);
        if (sha_link?.statusCode === 200) {
          setShareLink(sha_link);
          setModalDocument(true);
        } else {
          console.log("Error al obtener el link del archivo 2");
        }
      }
      if (parseInt(pilar_id) === 3) {
        const sha_link = await readFile("/Activos", document_url);
        if (sha_link?.statusCode === 200) {
          setShareLink(sha_link);
          console.log(sha_link);
          setModalDocument(true);
        } else {
          console.log("Error al obtener el link del archivo 3");
        }
      }
      if (parseInt(pilar_id) === 4) {
        const sha_link = await readFile("/Contratos", document_url);
        if (sha_link?.statusCode === 200) {
          setShareLink(sha_link);
          console.log(sha_link);
          setModalDocument(true);
        } else {
          console.log("Error al obtener el link del archivo 4");
        }
      }
    }
  };
  const handleCloseModalDocument = () => {
    setModalDocument(false);
    setShareLink("");
  };

  const OnSubmit = async (data) => {
    setIsLoading(true);
    setShowTable(true);

    //Modificamos los datos para que lleguien como los espera el back
    data.tags =
      typeof data?.tags === typeof []
        ? data?.tags?.map((x) => x?.name || x).join(",")
        : "";
    data.grant_date = data.grant_date
      ? dayjs(data?.grant_date).format("YYYY-MM-DD")
      : null;

    //Enviamos a consultar la data
    await get_data_by_filter(data);
    setIsLoading(false);
  };

  const resetForm = () => {
    const data_form = {
      company_id: null,
      pillar_id: 1,
      grant_date: null,
      type_document: null,
      type_power: null,
      type_acts: null,
      tags: null,
      special_power: null,
      attorney: null,
      status: null,
      text: "",
    };

    setValues(data_form);
    setDisabledText(false);
    setValueText("");
    reset(data_form);
  };

  const handleFilters = () => {
    setShowTable(false);
  };

  return (
    <SearchTable
      vars={{
        control,
        inputs: showTable
          ? inputs?.filter((x) =>
              ["company_id", "pillar_id", "status"]?.includes(x?.name)
            )
          : inputs,
        filter_data,
        isLoading,
        pdfPreview,
        openModal,
        loadingPdf,
        showTable,
        shareLink,
        modalDocument
      }}
      funcs={{
        handleChangeSelect,
        OnSubmit,
        handleSubmit,
        setOpenModal,
        getFile,
        resetForm,
        handleRowAction,
        handleFilters,
        handleNavigate,
        fetchShareLink,
        handleCloseModalDocument,
      }}
    />
  );
};
