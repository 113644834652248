import React, {useState, useEffect} from 'react'
import { RolesV } from '../../components/Roles/'
import { AddRolV } from '../../components/Roles/add-role'
import { UseStoreRoles } from './../../stores/Roles'
import { validateName } from './../../utils'

export const RolesC = ({children}) => {
    const roles = UseStoreRoles(state=> state.roles)
    const FetchRoles = UseStoreRoles(state => state.fetchRoles)
    const AddRol = UseStoreRoles(state => state.addRol)
    const updateRol = UseStoreRoles(state => state.updateRol)
    const updateStateRol = UseStoreRoles(state => state.updateStateRol)
    const DeleteRol = UseStoreRoles(state => state.deleteRol)
    const [alert, SetAlert] = useState({active:false, msg: '', type: ''})
    const [stateView, setStateView] = useState({ readonly: false, edit: false})
    const [path, setPath] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [NameRol, SetNameRol] = useState('')
    const [idRol, setIdRol] = useState('')
    const [Error, SetError] = useState({ error: false, msg: ''})

    useEffect(() => {
        if(!path) setPath('roles-list')
        if(path === 'roles-list'){
            setStateView({readonly: false, edit: false})
            SetNameRol('')
            SetError({ error: false, msg: ''})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, stateView?.edit, stateView?.readonly])
    
    const HandleSubmit = async (e) => {
        e.preventDefault()
        if(!validateName(NameRol))
            SetError({
                error: true,
                msg:'El campo solo debe contener letras.'
            })
        else{
            if(stateView?.edit){
                setIsLoading(true)
                const res = await updateRol({id: idRol, name: NameRol})
                if(res === true){
                    SetAlert({active:true, msg: 'Se ha actualizado el rol', type:'info'})
                    setStateView({edit:false,readonly: true, rol: {id: idRol}})
                }
                else if(res === 2)
                    SetAlert({active:true, msg: 'Debe cambiar el nombre del rol', type:'info'})
                else
                    SetAlert({active:true, msg: 'Ocurrio un error al editar el rol', type:'error'})
                setIsLoading(false)
            }else{
                setIsLoading(true)
                const res = await AddRol({ name: NameRol, state: 1, description: ''})
                if(res?.statusCode === 201){
                    SetAlert({active:true, msg: 'Se ha guardado el rol', type:'success'})
                    setStateView({edit:false,readonly: true, rol: {id: res?.data?.id}})
                }
                else if(res === 2)
                    SetAlert({active:true, msg: 'Debe cambiar el nombre del rol', type:'info'})
                else
                    SetAlert({active:true, msg: 'Ocurrio un error al guardar el rol', type:'error'})
                setIsLoading(false)
            }
        }
    }

    const HandleView = rol => {
        setPath('add-rol')
        setStateView({
            edit:false,
            readonly: true,
            rol
        })
        SetNameRol(rol?.name)
    }

    const HandleUpdate = rol => {
        setPath('add-rol')
        setStateView({
            readonly:false,
            edit: true, 
            rol
        })
        SetNameRol(rol?.name)
        setIdRol(rol?.id)
    }

    if(path === 'roles-list')
        return (
            <RolesV
                data={{
                    vars: {
                        type_user:1,
                        roles,
                        isLoading
                    },
                    funcs: {
                        setPath,
                        HandleView,
                        HandleUpdate,
                        FetchRoles,
                        updateStateRol,
                        DeleteRol,
                        setIsLoading
                    }
                }}
            >
                {children}
            </RolesV>
        )

    if(path === 'add-rol')
        return (
            <AddRolV
                data={{
                    vars: {
                        type_user: 1,
                        NameRol,
                        Error,
                        alert,
                        stateView,
                        isLoading
                    },
                    funcs: {
                        HandleSubmit,
                        SetNameRol,
                        SetError,
                        SetAlert,
                        setStateView,
                        setPath
                    }
                }}
            >
                {children}
            </AddRolV>
        )
                
}