import React from 'react';
import { Avatar, Box, Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import openIcon from "./../../assets/icons/openIcon.svg";

export const ActorGroupV = ({actors, isLoading, handleNavigate}) => {

    //const columnCount = Math.ceil(actors?.length / 10);
    const columnCount = 2
    const sort_actors = actors.sort((a, b) => a.name.localeCompare(b.name))
    return (
      <Box p="0px 20px" bgcolor="#FFFFFF">
        {isLoading && <LinearProgress />}
        <Box width="100%" height="100%">
          <Stack gap={2} p={1} borderBottom="3px solid #DDDDDD">
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                fontWeight: "600",
                color: "#1AAFD0",
              }}
            >
              Actor relacionado
            </Typography>
            <Stack
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#323338",
                }}
              >
                {`${actors?.length} actores relacionados`}
              </Typography>
              {/* <Stack
                flexDirection="row"
                alignItems="center"
                gap={1}
                sx={{ ":hover": { cursor: "pointer" } }}
                onClick={sort_actors}
              >
                <img src={orderIcon} alt="orderIcon" width={15} />
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#323338",
                  }}
                >
                  Ordenar
                </Typography>
              </Stack> */}
            </Stack>
          </Stack>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              p: "20px 0px",
              gridTemplateColumns: `repeat(${columnCount}, 43.3vw)`,
              overflow: "auto",
            }}
          >
            {sort_actors?.map((x, i) => (
              <Box key={i}>
                <Stack
                  onClick={() => handleNavigate(x?.id)}
                  sx={{
                    bgcolor: "#ffffff",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #DDDDDD",
                    borderLeft: "7px solid #1AAFD0",
                    borderRadius: "7px",
                    p: "10px",
                    ".css-jrj3z7-MuiStack-root": {
                      display: "none",
                    },
                    ":hover": {
                      cursor: "pointer",
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                      ".css-jrj3z7-MuiStack-root": {
                        display: "flex",
                      },
                    },
                  }}
                  variant="contained"
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Avatar
                      variant="rounded"
                      alt={x?.name}
                      src={x?.logo}
                      sx={{ width: 56, height: 56 }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#323338",
                      }}
                    >
                      {x?.name}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <img src={openIcon} width={15} alt="openIcon" />
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#323338",
                      }}
                    >
                      Abrir
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
}