import React, { useEffect, useRef, useState, useMemo } from "react";
import { Box, Button, Stack, TextField, FormControlLabel, Checkbox,Paper, TableRow, TableHead, TableContainer,TableCell, TableBody, Table, Grid, LinearProgress, Typography, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/icons/backIcon.svg";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { MUIModal } from "../../Common/MUIModal";
import { Input } from "../../Common/MUInput";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import FolderIcon from "@mui/icons-material/Folder";
import { InputFile } from "../../Common/MUIInputFile";
import { InputAutocompleteAdapter } from "../../Common/MUIAutoComplete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { RenderPdf } from "../../Common/RenderPdf";
import { ImagePreview } from "../../Common/ImagePreview";
import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import dayjs from 'dayjs';

export const ActivosV = ({
  vars: {
    company_id,
    subpillar_id,
    isLoading,
    activos,
    company,
    control,
    register,
    errors,
    setValueForm,
    reset,
    control2, //Crear File
    register2, //Crear File
    errors2, //Crear File
    setValueForm2, //Crear File
    reset2, //Crear File
    styles,
    users,
    actors,
    createFolder,
    editFolder,
    control3, //Editar Folder
    register3, //Editar Folder
    errors3, //Editar Folder
    setValueForm3, //Editar Folder
    reset3,
    createFile,
    editFile,
    modalDocument, //Modal Document
    nameDoc,
    shareLink,
    control4, //Editar File
    register4, //Editar File
    errors4, //Editar File
    setValueForm4, //Editar File
    reset4, //Editar File
    actorsWithNoAplica,
    clientsWithNoAplica,
    permisos,
    subPillar,
    id,
    folderName,
    expandedItems
  },
  funcs: {
    updateGetActivos,
    handleSubmit,
    OnSubmit,
    handleDeleteFolder,
    OnSubmitCreateFile,
    handleSubmit2,
    handleOpenFolder,
    handleCloseFolder,
    handleSubmit3,
    OnSubmitEditFolder,
    handleModalEditNameFolder,
    handleCloseEditNameFolder,
    handleModalEditFile,
    handleModarCloseEditFile,
    handleDeleteFile,
    setCreateFile,
    handleCloseFile,
    handleCloseModalDocument,
    handleOpenModalDocument,
    downloadDocument,
    handleSubmit4,
    OnSubmitEditFile,
    setSubPillar,
    setId,
    setFolderName,
    setExpandedItems
  },
}) => {
  const navigate = useNavigate();

  const [parentId, setParentId] = React.useState("");
  const [hasReadParam, setHasReadParam] = React.useState(false);
  //const [id, setId] = React.useState("");
  //const [folderName, setFolderName] = React.useState("");
  //const [subPillar, setSubPillar] = React.useState("");

  useEffect(() => {
    updateGetActivos();
    setValueForm("parent_id", id);
    setValueForm2("parent_id", id);
    setValueForm2("subpillar_id", subPillar);
    if (id) {
      activos?.filter((item) => {
        if (item.id == id) {
          setSubPillar(item.subpillar_id);
        }
      });
    }
    //leer parametros de la url para abrir el arbol en el nivel correcto
    if (!hasReadParam) {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("id");
      if (myParam && myParam !== null) {
        handleItemSelectionToggle(null, "folder"+myParam, true);
      }
    setHasReadParam(true);
    }
  }, [id, subPillar]);

  const FolderName = () => {
    return (
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
          color: "#323338",
          display: "inline",
        }}
      >
        {folderName}
      </Typography>
    );
  };

  const extractDigits = (str) => {
    const regex = /[0-9]/g;
    const digits = str.match(regex);
    return digits ? digits.join("") : "";
  };

  const fN = (id) => {
    const folder = activos.filter((item) => {
      if (item.id == id) {
        setFolderName(item.file_name);
        setParentId(item.parent_id);
        setSubPillar(item.subpillar_id);
      }
    });
  };
  //const [expandedItems, setExpandedItems] = React.useState([]);
  const [lastSelectedItem, setLastSelectedItem] = React.useState(null);
  const handleItemSelectionToggle = (
    event,
    itemId,
    isSelected,
    label,
    node
  ) => {
    if (isSelected) {
      setLastSelectedItem(itemId);
      setId(extractDigits(itemId));
      fN(extractDigits(itemId));
      activos.filter((item) => {
        if (item.id == id) {
          setSubPillar(item.subpillar_id);
        }
      });
    }
    console.log("itemId", id);
  };
  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const TreeView = ({ govermentActs }) => {
    const buildTree = (subpillarsChild2) => {
      subpillarsChild2 = Array.from(subpillarsChild2);
      const rootItems = [];
      const lookup = {};

      // Crear el lookup con los items
      subpillarsChild2.forEach((item) => {
        lookup[item.id] = { ...item, children: [] };
      });

      // Asignar los items a sus padres o a la raíz
      subpillarsChild2.forEach((item) => {
        if (item.parent_id && item.type !== "file") {
          lookup[item.parent_id].children.push(lookup[item.id]);
        } else if (!item.parent_id && item.type !== "file") {
          rootItems.push(lookup[item.id]);
        }
      });

      // Función para ordenar alfabéticamente por file_name
      const sortAlphabetically = (a, b) => {
        return a.file_name.localeCompare(b.file_name);
      };

      // Ordenar recursivamente los items y sus hijos
      const sortItems = (items) => {
        items.forEach((item) => {
          if (item.children.length > 0) {
            sortItems(item.children);
          }
        });
        items.sort(sortAlphabetically);
      };

      // Ordenar los items raíz y sus hijos
      sortItems(rootItems);

      return rootItems;
    };
    const treeData = buildTree(govermentActs);

    const buildTreeItems = (nodes) => {
      return nodes.map((node) => (
        <TreeItem
          key={node.type + node.id}
          sx={{ verticalAlign: "middle" }}
          itemId={node.type + node.id}
          label={
            <>
              {node.type === "folder" ? (
                <FolderIcon sx={{ verticalAlign: "middle" }} />
              ) : (
                <AttachFileIcon sx={{ verticalAlign: "middle" }} />
              )}
              <span> </span>
              {node.file_name}
            </>
          }
          {...node}
        >
          {node.children && node.children.length > 0
            ? buildTreeItems(node.children)
            : null}
        </TreeItem>
      ));
    };

    return (
      <div>
        <SimpleTreeView
          onItemSelectionToggle={handleItemSelectionToggle}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "14px",
            fontWeight: "400",
            color: "#323338",
          }}
        >
          {buildTreeItems(treeData)}
        </SimpleTreeView>
      </div>
    );
  };
  const handleDownloadFile = async (id) => {
    await downloadDocument(id, false);
  };

  const TableData = ({
    items,
    activos,
    users,
    actors,
    handleDownloadFile,
    downloadDocument,
    handleModalEditFile,
    handleDeleteFile,
    id,
    styles,
  }) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState("");

    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const sortedRows = (rows, comparator) => {
      const stabilizedThis = rows.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    };
    const getComparator = (order, orderBy) => {
      return order === "desc"
        ? (a, b) => -descendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, orderBy);
    };
    const filteredData = activos?.filter((row) => {
      const responsible = users?.find((x) => x.id === row.responsible_id);
      const client = actors?.find((x) => x.id === row.client);
      const fileName = row.file_name ? row.file_name.toLowerCase() : "";
      const responsibleName = responsible
        ? `${responsible.names} ${responsible.last_names}`.toLowerCase()
        : "";
      const clientName = client
        ? `${client.name} - ${client.description}`.toLowerCase()
        : "";
      const location = row.location ? row.location.toLowerCase() : "";
      const register_number = row.register_number ? row.register_number.toLowerCase() : "";
      const country_register = row.country_register ? row.country_register.toLowerCase() : "";
      const description_asset = row.description_asset ? row.description_asset.toLowerCase() : "";
      const location_asset = row.location_asset
        ? row.location_asset.toLowerCase()
        : "";
      return (
        row.type === "file" &&
        row.parent_id == id &&
        (fileName.includes(filter.toLowerCase()) ||
          responsibleName.includes(filter.toLowerCase()) ||
          clientName.includes(filter.toLowerCase()) ||
          location.includes(filter.toLowerCase()) ||
          register_number.includes(filter.toLowerCase()) ||
          country_register.includes(filter.toLowerCase()) ||
          description_asset.includes(filter.toLowerCase()) ||
          location_asset.includes(filter.toLowerCase()))
      );
    });

    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };
    const sortedData = sortedRows(
      filteredData || [],
      getComparator(order, orderBy)
    );

    const renderSortIcon = (column) => {
      if (orderBy !== column) return null;
      return order === "desc" ? (
        <ArrowDownwardIcon fontSize="small" />
      ) : (
        <ArrowUpwardIcon fontSize="small" />
      );
    };
    const columnTitles = {
      location: "Ubicación",
      client: "Actor relacionado",
      general_file_url: "Documento",
      responsible_id: "Responsable",
      description_asset: "Descripción del Bien",
      location_asset: "Ubicación del bien",
      document_property_file_url: "Documento que acredita la propiedad",
      register_number: "Número de registro",
      register_country: "País de registro",
      date_concession: "Fecha de concesión",
      date_due: "Fecha de término de vigencia",
      record_file_url: "Expediente",
    };
    const permisoEditarIntangible = permisos.some(permiso => permiso.section_id === 11 && permiso.edit === true);
    const permisoEditarMueble = permisos.some(permiso => permiso.section_id === 10 && permiso.edit === true);
    const permisoEditarInmueble = permisos.some(permiso => permiso.section_id === 9 && permiso.edit === true);

    const permisoEliminarIntangible = permisos.some(permiso => permiso.section_id === 11 && permiso.delete === true);
    const permisoEliminarMueble = permisos.some(permiso => permiso.section_id === 10 && permiso.delete === true);
    const permisoEliminarInmueble = permisos.some(permiso => permiso.section_id === 9 && permiso.delete === true);

    const permisoDescargarIntangible = permisos.some(permiso => permiso.section_id === 11 && permiso.download === true);
    const permisoDescargarMueble = permisos.some(permiso => permiso.section_id === 10 && permiso.download === true);
    const permisoDescargarInmueble = permisos.some(permiso => permiso.section_id === 9 && permiso.download === true);
    return (
      <>
        <TextField
          label="Buscar..."
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setFilter(e.target.value)}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {subPillar === 8 && (
                  <>
                    {[
                      "location",
                      "client",
                      "general_file_url",
                      "responsible_id",
                    ].map((column) => (
                      <TableCell
                        sx={{
                          fontFamily: "Poppins-SemiBold",
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#323338",
                          ":hover": {
                            cursor: "pointer",
                            color: "#1AAFD0",
                          },
                          ":transition": "all 0.9s",
                        }}
                        key={column}
                        label={column}
                        align="center"
                        sortDirection={orderBy === column ? order : false}
                        onClick={() => handleRequestSort(column)}
                      >
                        {columnTitles[column]}
                        <span>{renderSortIcon(column)}</span>
                      </TableCell>
                    ))}
                  </>
                )}
                {subPillar === 9 && (
                  <>
                    {[
                      "description_asset",
                      "location_asset",
                      "document_property_file_url",
                      "client",
                      "responsible_id",
                    ].map((column) => (
                      <TableCell
                        sx={{
                          fontFamily: "Poppins-SemiBold",
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#323338",
                          ":hover": {
                            cursor: "pointer",
                            color: "#1AAFD0",
                          },
                          ":transition": "all 0.9s",
                        }}
                        key={column}
                        label={column}
                        align="center"
                        sortDirection={orderBy === column ? order : false}
                        onClick={() => handleRequestSort(column)}
                      >
                        {columnTitles[column]}
                        <span>{renderSortIcon(column)}</span>
                      </TableCell>
                    ))}
                  </>
                )}
                {subPillar === 10 && (
                  <>
                    {[
                      "register_number",
                      "register_country",
                      "date_concession",
                      "date_due",
                      "record_file_url",
                      "client",
                      "responsible_id",
                    ].map((column) => (
                      <TableCell
                        sx={{
                          fontFamily: "Poppins-SemiBold",
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#323338",
                          ":hover": {
                            cursor: "pointer",
                            color: "#1AAFD0",
                          },
                          ":transition": "all 0.9s",
                        }}
                        key={column}
                        label={column}
                        align="center"
                        sortDirection={orderBy === column ? order : false}
                        onClick={() => handleRequestSort(column)}
                      >
                        {columnTitles[column]}
                        <span>{renderSortIcon(column)}</span>
                      </TableCell>
                    ))}
                  </>
                )}
                <TableCell
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#323338",
                  }}
                  align="center"
                  colSpan={3}
                >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row) => (
                <TableRow key={row.id}>
                  {subPillar === 8 && (
                    <>
                      <TableCell align="center">{row.location}</TableCell>
                      <TableCell align="center">
                        {actorsWithNoAplica?.map((x) => {
                          if (x.id === row.client) {
                            return x.name + " - " + x.description;
                          }
                        })}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Ver Documento">
                          <Button
                            onClick={() =>
                              handleOpenModalDocument(
                                row.id,
                                row.general_file_url
                              )
                            }
                            variant="text"
                            sx={{
                              color: "#1AAFD0",
                              ":hover": {
                                color: "#323338",
                              },
                            }}
                          >
                            {row.general_file_url.toLowerCase().endsWith('.pdf') ? <PictureAsPdfIcon sx={{ fontSize: 35 }} /> : <ImageIcon sx={{ fontSize: 35 }} />}
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {users?.map((x) => {
                          if (x.id === row.responsible_id) {
                            return x.names + " " + x.last_names;
                          }
                        })}
                      </TableCell>
                    </>
                  )}
                  {subPillar === 9 && (
                    <>
                      <TableCell align="center">
                        {row.description_asset}
                      </TableCell>
                      <TableCell align="center">{row.location_asset}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Ver Documento">
                          <Button
                            onClick={() =>
                              handleOpenModalDocument(
                                row.id,
                                row.general_file_url
                              )
                            }
                            variant="text"
                            sx={{
                              color: "#1AAFD0",
                              ":hover": {
                                color: "#323338",
                              },
                            }}
                          >
                            {row.general_file_url.toLowerCase().endsWith('.pdf') ? <PictureAsPdfIcon sx={{ fontSize: 35 }} /> : <ImageIcon sx={{ fontSize: 35 }} />}
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {actorsWithNoAplica?.map((x) => {
                          if (x.id === row.client) {
                            return x.name + " - " + x.description;
                          }
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {users?.map((x) => {
                          if (x.id === row.responsible_id) {
                            return x.names + " " + x.last_names;
                          }
                        })}
                      </TableCell>
                    </>
                  )}
                  {subPillar === 10 && (
                    <>
                      <TableCell align="center">
                        {row.register_number}
                      </TableCell>
                      <TableCell align="center">
                        {row.register_country}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(row?.date_concession).locale('es').format('dddd, D MMMM YYYY')}
                      </TableCell>
                      <TableCell align="center">{dayjs(row?.date_due).locale('es').format('dddd, D MMMM YYYY')} </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Ver Documento">
                          <Button
                            onClick={() =>
                              handleOpenModalDocument(row.id, row.general)
                            }
                            variant="text"
                            sx={{
                              color: "#1AAFD0",
                              ":hover": {
                                color: "#323338",
                              },
                            }}
                          >
                            {row.general_file_url.toLowerCase().endsWith('.pdf') ? <PictureAsPdfIcon sx={{ fontSize: 35 }} /> : <ImageIcon sx={{ fontSize: 35 }} />}
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {actorsWithNoAplica?.map((x) => {
                          if (x.id === row.client) {
                            return x.name + " - " + x.description;
                          }
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {users?.map((x) => {
                          if (x.id === row.responsible_id) {
                            return x.names + " " + x.last_names;
                          }
                        })}
                      </TableCell>
                    </>
                  )}
                  <TableCell align="center">
                      <Tooltip title="Descargar Documento">
                        <Button
                          onClick={() =>
                            handleDownloadFile(row.general_file_url, false)
                          }
                          variant="text"
                          disabled={
                            !(
                              (subPillar === 8 && permisoDescargarInmueble) ||
                              (subPillar === 9 && permisoDescargarMueble) ||
                              (subPillar === 10 && permisoDescargarIntangible)
                            )
                          }
                        >
                          <DownloadIcon sx={{ fontSize: 35 }} />
                        </Button>
                      </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Editar Documento">
                      <Button
                        onClick={() =>
                          handleModalEditFile(row.subpillar_id, row.id)
                        }
                        disabled={
                            !(
                              (subPillar === 8 && permisoEditarInmueble) ||
                              (subPillar === 9 && permisoEditarMueble) ||
                              (subPillar === 10 && permisoEditarIntangible)
                            )
                          }
                      >
                        <EditIcon sx={{ fontSize: 35 }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Eliminar Documento">
                      <Button
                        onClick={() =>
                          handleDeleteFile(row.subpillar_id, row.id)
                        }
                        disabled={
                            !(
                              (subPillar === 8 && permisoEliminarInmueble) ||
                              (subPillar === 9 && permisoEliminarMueble) ||
                              (subPillar === 10 && permisoEliminarIntangible)
                            )
                          }
                      >
                        <DeleteForeverIcon sx={{ fontSize: 35 }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const handleOpenFile = (id) => {
    setCreateFile(true);
    activos.filter((item) => {
      if (item.id == id) {
        setSubPillar(item.subpillar_id);
      }
    });
  };

  const TopBarButtons = () => {
    const permisoAgregarIntangible = permisos.some(permiso => permiso.section_id === 11 && permiso.add === true);
    const permisoAgregarMueble = permisos.some(permiso => permiso.section_id === 10 && permiso.add === true);
    const permisoAgregarInmueble = permisos.some(permiso => permiso.section_id === 9 && permiso.add === true);

    const permisoEditarIntangible = permisos.some(permiso => permiso.section_id === 11 && permiso.edit === true);
    const permisoEditarMueble = permisos.some(permiso => permiso.section_id === 10 && permiso.edit === true);
    const permisoEditarInmueble = permisos.some(permiso => permiso.section_id === 9 && permiso.edit === true);

    const permisoEliminarIntangible = permisos.some(permiso => permiso.section_id === 11 && permiso.delete === true);
    const permisoEliminarMueble = permisos.some(permiso => permiso.section_id === 10 && permiso.delete === true);
    const permisoEliminarInmueble = permisos.some(permiso => permiso.section_id === 9 && permiso.delete === true);
    return (
      <>
        {(subPillar === 8 ) || (subPillar === 9  ) || (subPillar === 10 ) ? (
          <>
            <Button onClick={handleOpenFolder}
              disabled={
                !(
                  (subPillar === 8 && permisoAgregarInmueble) ||
                  (subPillar === 9 && permisoAgregarMueble) ||
                  (subPillar === 10 && permisoAgregarIntangible)
                )
              }
            >
              Crear Carpeta
              <CreateNewFolderIcon sx={{ marginLeft: 1 }} />{" "}
            </Button>
            <Button
              onClick={() => handleDeleteFolder(subPillar, id, company_id)}
              disabled={
                !(
                  (subPillar === 8 && permisoEliminarInmueble) ||
                  (subPillar === 9 && permisoEliminarMueble) ||
                  (subPillar === 10 && permisoEliminarIntangible)
                )
              }
            >
              Eliminar Carpeta
              <FolderDeleteIcon sx={{ marginLeft: 1 }} />
            </Button>
            <Button onClick={handleOpenFile}
              disabled={
                !(
                  (subPillar === 8 && permisoAgregarInmueble) ||
                  (subPillar === 9 && permisoAgregarMueble) ||
                  (subPillar === 10 && permisoAgregarIntangible)
                )
              }
              >
              Cargar Archivo
              <AttachFileIcon sx={{ marginLeft: 1 }} />{" "}
            </Button>
            <Button onClick={() => handleModalEditNameFolder(id)}
              disabled={
                !(
                  (subPillar === 8 && permisoEditarInmueble) ||
                  (subPillar === 9 && permisoEditarMueble) ||
                  (subPillar === 10 && permisoEditarIntangible)
                )
              }
            >
              Editar Carpeta <EditIcon />
            </Button>
          </>
        ) : (
          <Typography>Seleccione una carpeta</Typography>
        )}
      </>
    );
  };

  const ModalEditFolder = () => {
    return (
      <Stack>
        <MUIModal
          open={editFolder}
          handleClose={handleCloseEditNameFolder}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
          <Box sx={{}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Editar carpeta <FolderName />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography>Nombre de la carpeta</Typography>
              <form onSubmit={handleSubmit3(OnSubmitEditFolder)}>
                <input
                  type="hidden"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  {...register3("id", {})}
                />
                <Input
                  name={`folder_name`}
                  control={control3}
                  props={{
                    value: folderName,
                    size: "small",
                    fullWidth: true,
                    label: "Nombre de la carpeta",
                    autoComplete: "none",
                    required: true,
                  }}
                />
                <Button sx={{}} type="submit"
                  disabled={isLoading}
                >
                  Actualizar
                </Button>
              </form>
            </Typography>
          </Box>
        </MUIModal>
      </Stack>
    );
  };

  const ModalCreateFolder = () => {
    return (
      <Stack>
        <MUIModal
          open={createFolder}
          handleClose={handleCloseFolder}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
          <Box sx={{}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Crear carpeta en <FolderName />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography>Nombre de la carpeta</Typography>
              <form onSubmit={handleSubmit(OnSubmit)}>
                <input
                  type="hidden"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  {...register("parent_id", {})}
                />
                <Input
                  name={`folder_name`}
                  control={control}
                  props={{
                    size: "small",
                    fullWidth: true,
                    label: "Nombre de la carpeta",
                    autoComplete: "none",
                    required: true
                  }}
                />
                {subPillar == 8 && ( //Inmuebles
                  <>
                    <Typography>
                      ¿Deseas generar la estructura de carpetas? (Licencias,
                      Planos, Escrituras, Predial)
                    </Typography>
                    <FormControlLabel
                     control={<Checkbox 
                      {...register("checkbox_construir_estructura")}
                     />} 
                     label="Licencias, Planos, Escrituras, Predial"  
                      name="checkbox_construir_estructura"
                      sx={{ marginTop: "10px", display: "block"}}
                     />

                    {/* <Input
                      name={`checkbox_construir_estructura`}
                      control={control}
                      props={{
                        size: "small",
                        fullWidth: false,
                        label: "Licencias, Planos, Mantenimientos, Predial",
                        autoComplete: "none",
                        type: "checkbox",
                        value: true,
                      }}
                    /> */}
                  </>
                )}
                <Button
                  sx={{
                    marginTop: "20px",
                  }}
                  type="submit"
                  disabled={isLoading}
                >
                  Crear
                </Button>
              </form>
            </Typography>
          </Box>
        </MUIModal>
      </Stack>
    );
  };

  const ModalDocument = () => {
    return (
      <>
        <Stack>
          <MUIModal
            open={modalDocument.open}
            handleClose={handleCloseModalDocument}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            buttonTop={true}
          >
            <Box sx={{}}>
              <Typography id="modal-modal-title2" variant="h6" component="h2">
                Ver Archivo
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* Aqui se renderiza el archivo PDF */}
                {modalDocument?.type === true ? (
                  <ImagePreview url={shareLink?.data?.url} />
                ) : (
                  <RenderPdf url={shareLink?.data?.url} />
                )}
                <Typography>{nameDoc}</Typography>
              </Typography>
            </Box>
          </MUIModal>
        </Stack>
      </>
    );
  };

  const ModalCreateFileInmueble = () => {
    return (
      <Stack>
        <MUIModal
          open={createFile}
          handleClose={handleCloseFile}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
          <Box sx={{}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Cargar archivo en <FolderName />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography>Nombre carpeta</Typography>
              <form onSubmit={handleSubmit2(OnSubmitCreateFile)}>
                <input
                  type="hidden"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  {...register2("parent_id", {})}
                />
                <input
                  type="hidden"
                  value={subPillar}
                  onChange={(e) => setSubPillar(e.target.value)}
                  {...register2("subpillar_id", {})}
                />
                {subPillar == 8 && ( //Inmuebles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Ubicación
                    </Typography>
                    <Input
                      name={`location`}
                      control={control2}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Ubicación",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 9 && ( // muebles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Descripción del Bien
                    </Typography>
                    <Input
                      name={`description_asset`}
                      control={control2}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Descripción del Bien",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 9 && ( // muebles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Ubicación del bien
                    </Typography>
                    <Input
                      name={`location_asset`}
                      control={control2}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Ubicación del bien",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 10 && ( // muebles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Número de registro
                    </Typography>
                    <Input
                      name={`register_number`}
                      control={control2}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Número de registro",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 10 && ( // Intangibles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      País de registro
                    </Typography>
                    <Input
                      name={`register_country`}
                      control={control2}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "País de registro",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 10 && ( // intangibles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Fecha de concesión
                    </Typography>
                    <Input
                      name={`date_concession`}
                      control={control2}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "",
                        autoComplete: "none",
                        required: true,
                        type: "date",
                      }}
                    />
                  </>
                )}
                {subPillar == 10 && ( // intangibles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Fecha de término de vigencia
                    </Typography>
                    <Input
                      name={`date_due`}
                      control={control2}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "",
                        autoComplete: "none",
                        required: true,
                        type: "date",
                      }}
                    />
                  </>
                )}
                <Typography sx={styles.label} pr={1}>
                  Documento
                </Typography>
                <InputFile
                  name="document"
                  label={"Elegir Documento"}
                  control={control2}
                  props={{
                    required: false,
                    error: errors?.document ? true : false,
                    helperText: errors?.document?.message,
                  }}
                />
                <Typography sx={styles.label} pr={1}>
                  Actor relacionado
                </Typography>
                <InputAutocompleteAdapter
                  disabled={false}
                  size={"small"}
                  mb={0}
                  fullWidth={true}
                  height={40}
                  label={""}
                  name="client"
                  control={control2}
                  options={
                    actorsWithNoAplica?.map((x) => ({
                      value: x?.id,
                      label: `${x?.name} - ${x?.description}`,
                    })) || []
                  }
                />
                <Typography sx={styles.label} pr={1}>
                  Responsable
                </Typography>
                <InputAutocompleteAdapter
                  disabled={false}
                  size={"small"}
                  mb={0}
                  fullWidth={true}
                  height={40}
                  label={""}
                  name="responsible_id"
                  control={control2}
                  options={
                    users?.map((x) => ({
                      value: x?.id,
                      label: `${x?.names} ${x?.last_names} - ${x?.charge}`,
                    })) || []
                  }
                />
                <Button sx={styles.buttonSave} type="submit"
                  disabled={isLoading}
                >
                  Guardar
                </Button>
              </form>
            </Typography>
          </Box>
        </MUIModal>
      </Stack>
    );
  };

  const ModalEditFile = () => {
    return (
      <Stack>
        <MUIModal
          open={editFile}
          handleClose={handleModarCloseEditFile}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
          <Box sx={{}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Cargar archivo en <FolderName />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography>Nombre carpeta</Typography>
              <form onSubmit={handleSubmit4(OnSubmitEditFile)}>
                <input
                  type="hidden"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  {...register4("id", {})}
                />
                <input
                  type="hidden"
                  value={subPillar}
                  onChange={(e) => setSubPillar(e.target.value)}
                  {...register4("subpillar_id", {})}
                />
                {subPillar == 8 && ( //Inmuebles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Ubicación
                    </Typography>
                    <Input
                      name={`location`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Ubicación",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 9 && ( // muebles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Descripción del Bien
                    </Typography>
                    <Input
                      name={`description_asset`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Descripción del Bien",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 9 && ( // muebles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Ubicación del bien
                    </Typography>
                    <Input
                      name={`location_asset`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Ubicación del bien",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 10 && ( // muebles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Número de registro
                    </Typography>
                    <Input
                      name={`register_number`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Número de registro",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 10 && ( // Intangibles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      País de registro
                    </Typography>
                    <Input
                      name={`register_country`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "País de registro",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                )}
                {subPillar == 10 && ( // intangibles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Fecha de concesión
                    </Typography>
                    <Input
                      name={`date_concession`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "",
                        autoComplete: "none",
                        required: true,
                        type: "date",
                      }}
                    />
                  </>
                )}
                {subPillar == 10 && ( // intangibles
                  <>
                    <Typography sx={styles.label} pr={1}>
                      Fecha de término de vigencia
                    </Typography>
                    <Input
                      name={`date_due`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "",
                        autoComplete: "none",
                        required: true,
                        type: "date",
                      }}
                    />
                  </>
                )}
                <Typography sx={styles.label} pr={1}>
                  Documento
                </Typography>
                <InputFile
                  name="document"
                  label={"Elegir Documento"}
                  control={control4}
                  props={{
                    required: false,
                    error: errors?.document ? true : false,
                    helperText: errors?.document?.message,
                  }}
                />
                <Typography sx={styles.label} pr={1}>
                  Actor relacionado
                </Typography>
                <InputAutocompleteAdapter
                  disabled={false}
                  size={"small"}
                  mb={0}
                  fullWidth={true}
                  height={40}
                  label={""}
                  name="client"
                  control={control4}
                  options={
                    actorsWithNoAplica?.map((x) => ({
                      value: x?.id,
                      label: `${x?.name} - ${x?.description}`,
                    })) || []
                  }
                />
                <Typography sx={styles.label} pr={1}>
                  Responsable
                </Typography>
                <InputAutocompleteAdapter
                  disabled={false}
                  size={"small"}
                  mb={0}
                  fullWidth={true}
                  height={40}
                  label={""}
                  name="responsible_id"
                  control={control4}
                  options={
                    users?.map((x) => ({
                      value: x?.id,
                      label: `${x?.names} ${x?.last_names} - ${x?.charge}`,
                    })) || []
                  }
                  
                />
                <Button sx={styles.buttonSave} type="submit"
                  disabled={isLoading}
                >
                  Guardar
                </Button>
              </form>
            </Typography>
          </Box>
        </MUIModal>
      </Stack>
    );
  };

  return (
    <div>
      <Box bgcolor="#FFFFFF" p="0px 10px">
        {isLoading && <LinearProgress />}
        <Button
          type="button"
          onClick={() => navigate(`/view-company/${company_id}?tab=2`)}
          variant="text"
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "12px",
            fontWeight: "400",
            color: "#323338",
            width: "100px",
            textTransform: "capitalize",
          }}
          startIcon={<img src={BackIcon} alt="BackIcon" />}
        >
          Regresar
        </Button>
        <Stack
          flexDirection="column"
          gap={1}
          p={2}
          borderBottom="3px solid #DDDDDD"
        >
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "24px",
              fontWeight: "600",
              color: "#1AAFD0",
            }}
          >
            {company?.name}{" "}
            <span
              style={{
                color: "#000",
              }}
            >
              - Activos
            </span>
          </Typography>
          <Typography>
            <TopBarButtons id={id} />
          </Typography>
        </Stack>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#323338",
                }}
              ></Typography>

              <TreeView govermentActs={activos} vars={{}} />
            </Grid>
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#323338",
                }}
              >
                {folderName}
              </Typography>
              {activos?.length > 0 ? (
                <TableContainer component={Paper}>
                  <TableData
                    items={activos}
                    activos={activos}
                    users={users}
                    actors={actorsWithNoAplica}
                    handleDownloadFile={handleDownloadFile}
                    downloadDocument={downloadDocument}
                    handleModalEditFile={handleModalEditFile}
                    handleDeleteFile={handleDeleteFile}
                    id={id}
                    styles={styles}
                  />
                </TableContainer>
              ) : (
                <Typography
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#323338",
                  }}
                >
                  No hay datos
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ModalCreateFolder />
      <ModalCreateFileInmueble />
      <ModalEditFolder />
      <ModalEditFile />
      <ModalDocument />
    </div>
  );
};
