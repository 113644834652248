import React , { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom'
import { Box, Button, IconButton, Fade, CircularProgress, Typography, Stack, TableContainer, TableHead, TableRow, TableCell, Tooltip } from '@mui/material'
import { MUIModal } from '../Common/MUIModal';
import { FormFilters } from './FormFilters'
//import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UploadIcon from '@mui/icons-material/Upload';
import { FormAgenda } from './FormAgenda';
import { HistoryList } from '../Common/HistoryList';
import dayjs from 'dayjs';
import { TableListAdapter } from "../Common/DataGridAdapter";
import ConfigIcon from "./../../assets/icons/configIcon.svg";

import { SwalFire } from '../../utils';
import { Table } from 'react-bootstrap';
import { RenderPdf } from "../Common/RenderPdf";
import { ImagePreview } from "../Common/ImagePreview";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { UseStoreTemplateView } from '../../stores/TemplateView';
import ImageIcon from '@mui/icons-material/Image';
dayjs.locale('es')

export const PanelAgendaV = ({
    vars: { data,dataHistory,areas, typeView, openModal, control, errors, isView, valCheckbox, fields, isLoading, requests, pdf_link, actors, control_status, 
        status_activities, period, isLoadingFilters, LoadingObservations, color_config, openModalSetDocument, infoAgenda, govermentActs, openDocument, shareLink, showFilters, permissions, disabledDownload, userInfo, allAssignments, myAssigments, registerDelete, subPillar, idDocument, nameDocument, custodian, completed, canceled},
    funcs: { handleOpenModal, getValCheckbox, handleSetRequirement, remove, handleSubmit, handleSaveDigitalDocument,
        OnSubmit, hanldeClickRequirements, handleDeleteRow, processRowUpdate, handleCellClick, handleViewDigitalDocument,
        hanldeUpdateRequirements, handleCloseModal, get_pdf_link, handleViewHistory, get_request_filters, refresh_request,
        setTypeView, get_request_filters_my_assignments, handleCloseSetDocument, getPath, assignDocumentToRequirement, handleCloseDocument, handlePreviewDocument, setShowFilters,
        getRequestFilterDeleting, setAllAssignments, setMyAssigments, setRegisterDelete, setIsLoading, setCustodian, getRequestCustodian,getRequestFilterCompleted, setCompleted,
        setCanceled, getRequestFilterCanceled
    }
}) => {
    const get_actors = UseStoreTemplateView(state => state.get_actors);
    const navigate = useNavigate()
    const styles = {
        button: {
          height: "74px",
          textTransform: "inherit",
          width: "170px",
          minWidth: "170px",
          mr: 15,
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#323338",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
        },
        typography: {
          fontFamily: "Poppins-SemiBold",
          fontSize: "30px",
          fontWeight: "600",
          color: "#323338",
          "@media (max-width: 790px)": {
            fontSize: "24px",
          },
          "@media (max-width: 590px)": {
            fontSize: "20px",
          },
        },
    };
    const subpillar_names = {
        5: 'Autoridades federales',
        6: 'Autoridades estatales',
        7: 'Autoridades municipales',
        8: 'Inmuebles',
        9: 'Muebles',
        10: 'Intangibles',
        11: 'Civil',
        12: 'Mercantil',
        13: 'Administrativo',
        14: 'Laboral',
    }
    
    const get_color_status = (code_status, date_end) => {
        let color = 'inherit';
        
        const diffDays = () => {
            const current_date = dayjs()            
            const diff = dayjs(date_end) - current_date;
            return Math.ceil(diff / (1000 * 60 * 60 * 24))
        }

        if(code_status === 'VIG'){
            const daysRes = diffDays()

            if(daysRes <= 5) {
                color = color_config?.status_activity_current2
            }else {
                color = color_config?.status_activity_current
            }

        }else if(code_status === 'VEN') {
            color = color_config?.status_activity_expired
        }else if(code_status === 'CER') {
            color = color_config?.status_activity_closed
        }
        return color
    }

    const get_color_control = (code_control) => {
        let color = ''

        if (code_control === 'PROG') color = color_config?.control_in_progress
        else if (code_control === 'DETE') color = color_config?.control_stopped
        else if (code_control === 'CANC') color = color_config?.control_canceled
        else if (code_control === 'COMP') color = color_config?.control_completed
        else if (code_control === 'ELIM') color = 'GRAY'
        else if (code_control === 'INI') color = 'GRAY'

        return color
    }

    const handleClickFilters = () => {
        setShowFilters(!showFilters)
        if(showFilters) {
            refresh_request()
        }
    }

    const handleClickAllAssignments = () => {
        setMyAssigments(false)
        setCompleted(false)
        setAllAssignments(true)
        setRegisterDelete(false)
        setCustodian(false)
        setCanceled(false)
        refresh_request()
    }

    const handleClickMyAssigments = () => {
        setMyAssigments(true)
        setCompleted(false)
        setAllAssignments(false)
        setRegisterDelete(false)
        setCustodian(false)
        setCanceled(false)
        get_request_filters_my_assignments()
    }

    const handleClickCustodian = () => {
        setMyAssigments(false)
        setCompleted(false)
        setAllAssignments(false)
        setRegisterDelete(false)
        setCustodian(true)
        setCanceled(false)
        getRequestCustodian()
    }

    const handleClickRegisterDeleted = () => {
        setMyAssigments(false)
        setCompleted(false)
        setAllAssignments(false)
        setRegisterDelete(true)
        setCustodian(false)
        setCanceled(false)
        getRequestFilterDeleting()
    }

    const handleClickComplete = () => {
        setMyAssigments(false)
        setCompleted(true)
        setAllAssignments(false)
        setRegisterDelete(false)
        setCustodian(false)
        setCanceled(false)
        getRequestFilterCompleted()
    }

    const handleClickCanceled = () => {
        setMyAssigments(false)
        setCompleted(false)
        setAllAssignments(false)
        setRegisterDelete(false)
        setCustodian(false)
        setCanceled(true)
        getRequestFilterCanceled()
    }

    const handleNavigateActor = (row) => {
        if(row?.actor?.status){
            navigate(`/view-actor/${row?.related_actor_id}`, {replace: true})
        }else{
            SwalFire('Atencion!', 'El actor relacionado no está activo', 'warning')
        }
    }

    const handleClick = async (row) => {
        setIsLoading(true)
        await get_actors()
        handleNavigateActor(row)
        setIsLoading(false)
    }

    const columns = [
        { 
            field: 'date', 
            headerName:'Fecha', 
            width: 120, 
            valueGetter: ({row}) => row.date,
            renderCell: ({row}) => dayjs(row?.date).locale('es').format('dddd, D MMMM YYYY'),
        },
        { 
            field: 'end_date',
            headerName:'Fecha final', 
            width: 150,
            type: 'date',
            valueGetter: ({ row }) => row.end_date, // Mantener el valor original de la fecha en formato ISO
            renderCell: ({row}) => dayjs(row?.end_date).locale('es').format('dddd, D MMMM YYYY'),
            sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix(), // Comparar fechas en formato Unix
        },
        { 
            field: 'status_activity_name', 
            headerName:'Estado de la actividad', 
            width: 200,
            align: 'center',
            renderCell: ({row}) => <Box>
                <Button  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "auto",
                    height: "49px",
                    backgroundColor: get_color_status(row?.status_activity?.code, row?.end_date),
                    color: 'white' 
                }}>
                    {row?.status_activity?.description}
                </Button>
            </Box>
        },
        { 
            field: 'control_name',
            headerName:'Control', 
            width: 150,
            editable: true,
            renderCell: ({row}) => <Box>
                <Button  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "auto",
                    height: "49px",
                    backgroundColor: get_color_control(row?.control?.code), 
                    color: 'white',
                }}>
                    {row?.control?.description}
                </Button>
            </Box>,
            type: 'singleSelect',
            valueOptions: control_status?.map((x) => x?.description) || []
        },
        { field: 'description', headerName:'Descripción', width: 250},
        { field: 'responsible_name', headerName:'Responsable', width: 150},
        { field: 'applicant_company_name', headerName:'Empresa', width: 190},
        { 
            field: 'related_actor_name', 
            headerName:'Actor relacionado', 
            width: 160,
            cellClassName: "cellLink",
            renderCell: ({row}) => <Typography onClick={() => handleClick(row)}>{row?.related_actor_name}</Typography>
        },
        { field: 'internal_applicant_name', headerName:'Solicitante', width: 160},
        { 
            field: 'processing_requirements', 
            headerName:'Requisitos previos', 
            width: 200,
            align: 'center',
            renderCell: ({row}) => 
                <Button 
                    sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "14px",
                        fontWeight: "500",
                        width: "auto",
                        height: "49px",
                    }} 
                    onClick={() => hanldeClickRequirements(row)}
                >
                    {row?.requirements?.length}
                </Button>
        },
        { field: 'pillar_name', headerName:'Pilar', width: 190},
        { 
            field: 'digital_document', 
            headerName:'Documento digital', 
            width: 150,
            align: 'center',
            renderCell: ({row}) => (
                <Box>
                    {row?.digital_document ? 
                        <IconButton onClick={() => handleViewDigitalDocument(row)}>
                            <OpenInNewIcon />
                        </IconButton>
                    :
                        <IconButton onClick={() => handleSaveDigitalDocument(row)}>
                            <UploadIcon />
                        </IconButton>
                    }
                </Box>
            )
        },
        { field: 'area_name', headerName:'Area', width: 150},
        { 
            field: 'physical_document', 
            headerName:'Documento físico', 
            width: 150,
            align: 'center',
            editable: true
        },
        { 
            field: 'safeguarding_name', 
            headerName:'Resguardatorio', 
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: data?.users?.map((x) => `${x?.names} ${x?.last_names} - ${x?.charge}` ) || []
        },
        { 
            field: 'general_comments', 
            headerName:'Comentarios del trámite', 
            width: 220,
            editable: true,

        },
        { field: 'id', headerName:'ID Actividad', width: 110}
    ];

    const handleCloseModalHistory = () => {
        (typeView === 2 && isView) ? setTypeView(1)  : handleCloseModal()
    };

    const ModalDocument = () => {
        const perm = (subpillar_id) => {
            //verificar si permissions tiene datos o está vacío
            if(permissions.length === 0) return [{permisoEditar: false, permisoVer: false, permisoDescargar: false, permisoEliminar: false, permisoSubir: false}]
            return [
                {
                    permisoDescargar : permissions.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.download === true),
                }
            ]
        }
        return (
            <Box>
                <MUIModal
                open={openDocument.open}
                handleClose={handleCloseDocument} 
                closeOnlyButton={Boolean(typeView === 2)}
                buttonTop={true} 
                maxWidth={'md'}
                title={'Vista de documento: '}
                >

                <Tooltip title="Descargar documento">
                    <Button disabled={disabledDownload}
                        onClick={() => window.open(shareLink?.data?.url, '_blank')}
                    >
                        <DownloadIcon/>
                    </Button>
                    
                </Tooltip>
                {idDocument > 0 ? (
                    <Button
                        onClick={() => assignDocumentToRequirement(idDocument, subPillar, infoAgenda, nameDocument )}
                        >
                        Asignar a la agenda operativa este documento
                    </Button>
                    ) : null}
                {openDocument.type === true ? (
                  <ImagePreview url={shareLink?.data?.url} />
                ) : (
                  <RenderPdf url={shareLink?.data?.url} />
                )}
                </MUIModal>
            </Box>
        )
    }

    const styles_table = {
        tablecell: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "600",
            color: "#323338",
            ":hover": {
                cursor: "pointer",
                color: "#1AAFD0",
            },
            ":transition": "all 0.9s",
            ":focus": {
                color: "#1AAFD0",
            },
            ":active": {
                color: "#1AAFD0",
            },
        },
                 
    }

    const ModalSetDocument = () => {
        const route = () => {
            if(infoAgenda?.pillar_id === 2){
                return '/pilares/act-gub/' + infoAgenda?.applicant_company_id
            }
            if(infoAgenda?.pillar_id === 3){
                return '/pilares/activos/' + infoAgenda?.applicant_company_id
            }
            if(infoAgenda?.pillar_id === 4){
                return '/pilares/contratos/' + infoAgenda?.applicant_company_id
            }   
        }
        const perm = (subpillar_id) => {
            //verificar si permissions tiene datos o está vacío
            if(permissions.length === 0) return [{permisoEditar: false, permisoVer: false, permisoDescargar: false, permisoEliminar: false, permisoSubir: false}]
            return [
                {
                    permisoEditar : permissions.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.edit === true),
                    permisoVer : permissions.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.view === true),
                    permisoDescargar : permissions.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.download === true),
                    permisoEliminar : permissions.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.delete === true),
                    permisoSubir : permissions.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.add === true),
                }
            ]
        }
        const uploadDocumentPermiso = (pillar_id) => {
            if(infoAgenda?.pillar_id === 2){
                if(perm(5)[0].permisoSubir || perm(6)[0].permisoSubir || perm(7)[0].permisoSubir){
                    return true
                }
            }
            if(infoAgenda?.pillar_id === 3){
                if(perm(8)[0].permisoSubir || perm(9)[0].permisoSubir || perm(10)[0].permisoSubir){
                    return true
                }
            }
            if(infoAgenda?.pillar_id === 4){
                if(perm(11)[0].permisoSubir || perm(12)[0].permisoSubir || perm(13)[0].permisoSubir || perm(14)[0].permisoSubir){
                    return true
                }
            }
            return false
        };

        return (
            <Box>
                <MUIModal
                open={openModalSetDocument}
                handleClose={handleCloseSetDocument} 
                closeOnlyButton={Boolean(typeView === 2)}
                buttonTop={true} 
                maxWidth={'lg'}
                title={'Selecciona un documento para: ' + infoAgenda?.description}
                >
                {govermentActs.length > 0 
                ? 
                    <>
                        <TableContainer>
                            <Table striped bordered hover style={{width: "100%"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={styles_table.tablecell}>Nombre</TableCell>
                                        <TableCell sx={styles_table.tablecell}>Sub pilar</TableCell>
                                        <TableCell sx={styles_table.tablecell}>Ruta de carpetas</TableCell>
                                        <TableCell sx={styles_table.tablecell} colSpan={2}>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <tbody>
                                    {govermentActs.filter(act => act.type === 'file').map((act, index) => (
                                       perm( act.subpillar_id )[0].permisoVer ?
                                        <TableRow key={index}>
                                            <TableCell>{act.file_name}</TableCell>
                                            <TableCell>{subpillar_names[act.subpillar_id]}</TableCell>
                                            <TableCell>
                                            {getPath(act.id, govermentActs) === '' ? ' /' : getPath(act.id, govermentActs)}
                                            </TableCell>
                                            <TableCell>
                                            <Tooltip title="Asignar este documento a la agenda">
                                                <Button
                                                    type="button"
                                                    onClick={() => assignDocumentToRequirement(act.id,act.subpillar_id, infoAgenda, act.file_name)}
                                                    sx={{
                                                        fontFamily: "Poppins-SemiBold",
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        width: "auto",
                                                        height: "49px",
                                                        background: "#1AAFD0",
                                                        color: "#FFFFFF",
                                                        margin: "0px 5px",
                                                        ":hover": {
                                                            background: "#FFF",
                                                            color: "#1AAFD0",
                                                        },
                                                    }}
                                                >
                                                    <DoneOutlineIcon/>
                                                </Button>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Ver documento">
                                                <Button onClick={() => handlePreviewDocument(act, act.subpillar_id, infoAgenda)} 
                                                     sx={{
                                                        fontFamily: "Poppins-SemiBold",
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        width: "auto",
                                                        height: "49px",
                                                        background: "#1AAFD0",
                                                        color: "#FFFFFF",
                                                        margin: "0px 5px",
                                                        ":hover": {
                                                            background: "#FFF",
                                                            color: "#1AAFD0",
                                                        },
                                                    }}
                                                >
                                                    {act.file_name.toLowerCase().endsWith('.pdf') ? <PictureAsPdfIcon/> : <ImageIcon/>}

                                                </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        : null
                                    ))}
                                </tbody>
                            </Table>    
                        </TableContainer>
                                  
                        <Button 
                            onClick={() => navigate(route())}
                            disabled={!uploadDocumentPermiso()}
                            >
                            Cargar documento
                        </Button>
                    </>
                :
                    <>
                        <Typography>No hay documentos disponibles</Typography>
                        <Button 
                            onClick={() => navigate(route())}
                            disabled={!uploadDocumentPermiso()}
                        >Cargar documento</Button>
                    </>
                    
                }
                </MUIModal>
            </Box>
        )
    }

    return (
        <Box mb={5}>
            <Stack
                display={'flex'}
                bgcolor="#FFFFFF"
                height="80px"
                width="100%"
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
            >
                <Typography sx={styles.typography} textAlign="center">Panel agenda operativa</Typography>
            </Stack>
            <Box 
                width={'100%'} 
                height={'100%'} 
                display={'flex'} 
                flexDirection={'column'} 
                bgcolor={'#fdfdfd'}
                mt={4}
            >
                {!isLoading && 
                    <Box p={1} display={'flex'} flexDirection={'column'}>
                        <Box>
                            {!showFilters && <Button
                                type="button"
                                onClick={() => handleOpenModal(1)}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    width: "auto",
                                    height: "49px",
                                    background: "#1AAFD0",
                                    color: "#FFFFFF",
                                    ":hover": {
                                        background: "#1AAFD0",
                                    },
                                }}
                            >
                                Nueva solicitud
                            </Button>}
                            <Button
                                type="button"
                                onClick={() => handleClickFilters()}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#323338",
                                    textTransform: "inherit",
                                    height: "49px",
                                    width: "131px",
                                    ml:1
                                }}
                                endIcon={
                                    <img
                                        src={ConfigIcon}
                                        width="15px"
                                        height="15px"
                                        alt="ConfigIcon"
                                    />
                                }
                            >
                                Filtros
                            </Button>
                            {!showFilters && <Button
                                type="button"
                                disabled={allAssignments}
                                onClick={() => handleClickAllAssignments()}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    width: "auto",
                                    height: "49px",
                                    background: "#FFF",
                                    color: "#000",
                                    ...allAssignments && {color: '#000 !important'},
                                    ...!allAssignments && {color: '#BBB !important'}
                                }}
                            >
                                Toda la agenda
                            </Button>}
                            {!showFilters && <Button
                                type="button"
                                disabled={myAssigments}
                                onClick={() => handleClickMyAssigments()}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    width: "auto",
                                    height: "49px",
                                    background: "#FFF",
                                    color: "#000",
                                    ...!myAssigments && {color: '#BBB !important'},
                                    ...myAssigments && {color: '#000 !important'}
                                }}
                            >
                                Mis asignaciones
                            </Button>}
                            
                            {!showFilters && <Button
                                type="button"
                                disabled={custodian}
                                onClick={() => handleClickCustodian()}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    width: "auto",
                                    height: "49px",
                                    background: "#FFF",
                                    color: "#000",
                                    ...!custodian && {color: '#BBB !important'},
                                    ...custodian && {color: '#000 !important'}
                                }}
                            >
                                Resguardatorio
                            </Button>}
                            
                            {!showFilters && <Button
                                type="button"
                                disabled={canceled}
                                onClick={() => handleClickCanceled()}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    width: "auto",
                                    height: "49px",
                                    background: "#FFF",
                                    color: "#000",
                                    ...!canceled && {color: '#BBB !important'},
                                    ...canceled && {color: '#000 !important'}
                                }}
                            >
                                Cancelados
                            </Button>}
                            
                            {userInfo?.rol_id === 1 &&
                                <Button
                                type="button"
                                disabled={registerDelete}
                                onClick={() => handleClickRegisterDeleted()}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    width: "auto",
                                    height: "49px",
                                    background: "#FFF",
                                    color: "#000",
                                    ...!registerDelete && {color: '#BBB !important'},
                                    ...registerDelete && {color: 'RED !important'}
                                }}
                            >
                                Eliminados
                            </Button>
                            }
                            {!showFilters && <Button
                                type="button"
                                disabled={completed}
                                onClick={() => handleClickComplete()}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    width: "auto",
                                    height: "49px",
                                    background: "#FFF",
                                    color: "#000",
                                    ...!completed && {color: '#BBB !important'},
                                    ...completed && {color: '#000 !important'}
                                }}
                            >
                                Completados
                            </Button>}
                        </Box>       
                        {showFilters && 
                            <Fade in={showFilters}>
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                        <FormFilters 
                                            control={control} 
                                            status_activities={status_activities}
                                            users={data?.users}
                                            actors={actors}
                                            companies={data?.companies}
                                            pillars={data?.pillars}
                                            control_status={control_status}
                                            period={period}
                                        />
                                        <LoadingButton 
                                            loading={isLoadingFilters} 
                                            loadingIndicator={<CircularProgress color="primary" size={25} />}
                                            variant='contained' 
                                            onClick={() => get_request_filters()} 
                                            sx={{
                                                fontFamily: "Poppins-SemiBold",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                height: "49px",
                                                width: '100%',
                                                background: "#1AAFD0",
                                                color: "#FFFFFF",
                                                ":hover": {
                                                    background: "#1AAFD0",
                                                },
                                                mt:1
                                            }}
                                        >
                                            Filtrar
                                        </LoadingButton>
                                </Box>
                            </Fade>
                        }
                    </Box>
                }
                <Box
                    sx={{
                        "& .cellLink": {
                            color: "#1AAFD0",
                            ":hover": {
                            textDecoration: "underline",
                            },
                        },
                        height: "90vh",
                        overflow: "auto",
                        width: "97%", 
                        borderRadius: "7px 7px 0px 0px",
                        bgcolor: "#FFFFFF",
                        p: "0px 16px",
                        "& .options-className": {
                        borderLeft: "3px solid #E2E4E7",
                        },
                        "& .role-className": {
                        borderLeft: "1px solid #E2E4E7",
                        },
                    }}    
                >
                    <TableListAdapter 
                        height={"100%"} // Altura del 100% del contenedor padre
                        isLoading={Boolean(data?.requests) || isLoading}
                        columns={columns?.map( column => {
                            if(column?.field === 'physical_document' || column?.field === 'control_name' || column?.field === 'safeguarding_name'){
                                return column
                            }else {
                                return {
                                ...column,
                                valueFormatter: (params) => {
                                    const value = params?.value;
                                    return value === null || value === undefined ? 'NO APLICA' : value;
                                }}
                            }
                        })}
                        countRows={10}
                        data={requests?.filter(x => x.status)}
                        handleRowClick={(row) => {}}
                        processRowUpdate={processRowUpdate}
                        handleCellClick={handleCellClick}
                    />
                </Box>
                <MUIModal
                    open={openModal}
                    handleClose={handleCloseModalHistory} 
                    closeOnlyButton={Boolean(typeView === 2)}
                    buttonTop={true} 
                    maxWidth={typeView === 1 ? true : 'sm'}
                    title={(typeView === 1 && !isView) ? 'Formulario actividades agenda operativa' : (typeView === 1 && isView) ? 'Requisitos para atender el trámite' : 'Historial'}
                >
                    {typeView === 1 ?
                        <FormAgenda
                            pdf_link={pdf_link}
                            get_pdf_link={get_pdf_link}
                            data={data}
                            areas={areas}
                            actors={actors}
                            fields={fields}
                            control={control}
                            errors={errors}
                            isView={isView} 
                            valCheckbox={valCheckbox}
                            getValCheckbox={getValCheckbox}
                            remove={remove}
                            OnSubmit={OnSubmit}
                            isLoading={isLoading}
                            handleSubmit={handleSubmit}
                            handleSetRequirement={handleSetRequirement}
                            handleDeleteRowRow={handleDeleteRow}
                            hanldeUpdateRequirements={hanldeUpdateRequirements}
                            handleViewHistory={handleViewHistory}
                        />
                    :
                        (typeView === 2 || typeView === 3) ? 
                            <HistoryList 
                                data={dataHistory} 
                                LoadingObservations={LoadingObservations} 
                                get_pdf_link={get_pdf_link} 
                                pdf_link={pdf_link}
                            /> : null
                    }
                </MUIModal>
                <ModalSetDocument />
                <ModalDocument />
            </Box>
        </Box>
    )
}