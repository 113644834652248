import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchTableC } from "../../containers/Home/SearchTableContainer";
import { UseStoreSearch } from "../../stores/Search";
import { UseStoreTemplateView } from "../../stores/TemplateView";
import { MUIModalAdapter } from "../Common/MUIModalAdapter";
import groupCompaniesIcon from "./../../assets/icons/groupCompaniesIcon.svg";
import myAgendaIcon from "./../../assets/icons/myAgendaIcon.svg";
import searchEngineSmartIcon from "./../../assets/icons/searchEngineSmartIcon.svg";
import PersonIcon from '@mui/icons-material/Person';
import { ButtonOption } from "./style";

export const MenuV = () => {
  const navigate = useNavigate();
  const updateLabelNav = UseStoreTemplateView((state) => state.updateLabelNav);
  const reset_filter_data = UseStoreSearch((state) => state.reset_filter_data);
  const button_back = UseStoreTemplateView((state) => state.button_back);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    reset_filter_data();
    setOpen(false);
  };

  useEffect(() => {
    updateLabelNav("PLATAFORMA DE GESTIÓN DIGITAL");
    button_back(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = {
    typography: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
      color: "#323338",
      textAlign: "center",
      width: "100px",
    },
    flexibleContent: {
      p: 1,
      borderRadius: 2,
      alignItems: "center",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      ":hover": {
        cursor: "pointer",
      },
    },
    card: {
      width: "156px",
      height: "156px",
      borderRadius: 2,
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        width="100%"
        height="90%"
        borderRadius={3}
        bgcolor="#FFFFFF"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        gap={3}
        flexWrap="wrap"
      >
        <ButtonOption
          type="button"
          onClick={() => navigate("/GroupCompanies", { replace: true })}
        >
          <Stack sx={styles.flexibleContent}>
            <Stack bgcolor="#18a5c4" sx={styles.card}>
              <img
                src={groupCompaniesIcon}
                alt="Empresas del grupo"
                width="32.13px"
                height="25px"
              />
            </Stack>
            <Typography sx={styles.typography}>Empresas del grupo</Typography>
          </Stack>
        </ButtonOption>

        <ButtonOption type="button" onClick={handleOpen}>
          <Stack sx={styles.flexibleContent}>
            <Stack bgcolor="#3be8b0" sx={styles.card}>
              <img
                src={searchEngineSmartIcon}
                alt="Buscador inteligente"
                width="31.23px"
                height="25px"
              />
            </Stack>
            <Typography sx={styles.typography}>Buscador inteligente</Typography>
          </Stack>
        </ButtonOption>
        
        <ButtonOption
          type="button"
          onClick={() =>
            navigate("/panel-agenda-operational", { replace: true })
          }
        >
          <Stack sx={styles.flexibleContent}>
            <Stack bgcolor="#6a67ce" sx={styles.card}>
              <img
                src={myAgendaIcon}
                alt="Mi agenda"
                width="21.89px"
                height="25px"
              />
            </Stack>
            <Typography sx={{ ...styles.typography, p: "10px 0px" }}>
              Mi agenda
            </Typography>
          </Stack>
        </ButtonOption>

        <ButtonOption
          type="button"
          onClick={() =>
            navigate("/actors-group", { replace: true })
          }
        >
          <Stack sx={styles.flexibleContent}>
            <Stack bgcolor="#666e87" sx={styles.card}>
              <PersonIcon 
                size="large"
                sx={{
                  color: 'white',
                  height: '35px',
                  width: '35px'
                }}
              />
            </Stack>
            <Typography sx={{ ...styles.typography, p: "10px 0px", width: 'auto'}}>
              Actor relacionado
            </Typography>
          </Stack>
        </ButtonOption>

        <MUIModalAdapter
          open={open}
          maxWidth={"lg"}
          title={"Buscador inteligente"}
          handleClose={handleClose}
          padding={0}
        >
          <SearchTableC />
        </MUIModalAdapter>
      </Stack>
    </Stack>
  );
};
