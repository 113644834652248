import React, { useState, useEffect} from 'react'
import { LoginC } from '../../containers/Login'
import { OTPVerificationC } from '../../containers/OTPVerification'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import { loginRequest } from '../../msal/authConfig';
import { callMsGraph } from '../../msal/graph';

export const Login = props => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    useEffect(() => {
        if (instance && accounts[0]) {
          RequestProfileData();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [instance, accounts])

    function RequestProfileData() {
        instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          return callMsGraph(response.accessToken);
        })
        .then((graphResponse) => {
          setGraphData(graphResponse);
        })
        .catch(error => {
          console.error('Error obteniendo data:', error);
        });
      }

    return (<>
        <AuthenticatedTemplate>
            <OTPVerificationC 
            graphData = { graphData }
            RequestProfileData = { RequestProfileData }
            />            
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <LoginC {...props} />
        </UnauthenticatedTemplate>
    </>
    )
}