import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgress, Alert, Tooltip, IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { UseStoreUsers } from '../../stores/Users';
import { stringAvatar } from '../../utils/JSImplements';
import dayjs from 'dayjs';
import { MUIModal } from './MUIModal';
import { RenderPdf } from './RenderPdf';


export const HistoryList = ({ data, LoadingObservations, get_pdf_link, pdf_link}) => {
    const { comments, load_image, documents} = data
    const get_image_user = UseStoreUsers(state => state.get_image_user)
    const [isLoading, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    
    const get_image = async () => {
        setIsLoading(true)
        for (let index = 0; index < comments?.length; index++) {
            const element = comments[index];
            
            const image_user = await get_image_user(element?.user?.image)
            element.user_image = image_user
            
            comments[index] = element
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if(load_image) get_image()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box display={'flex'} justifyContent={'center'}>
            {isLoading || LoadingObservations ? 
                <CircularProgress color="inherit" />
            :    
                <List sx={{ width: '100%', maxWidth: 500, maxHeight: 400 }}>
                    {(!documents || !documents.length) ?
                        comments?.length ? 
                            comments?.map((x, i) => (
                                <>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            {x?.user?.image ?
                                                <Avatar alt={`${x?.user?.names} ${x?.user?.last_names}`} src={x?.user_image} />
                                            : 
                                                <Avatar
                                                    {...stringAvatar(
                                                        `${x?.user?.names} ${x?.user?.last_names}`
                                                    )}
                                                />
                                            }
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography fontWeight={'Bold'}>{`${x?.user?.names} ${x?.user?.last_names}`}</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography component="span">{x?.comment}</Typography>
                                                    <br />
                                                    {x?.comment_date && `${dayjs(x?.comment_date).locale('es').format('dddd, D MMMM YYYY - h:mm a')}`}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </>
                            ))
                        :
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={ <Alert severity="info">El historial está vacío...</Alert>}
                                />
                            </ListItem>
                    :
                        documents?.length ? 
                            documents?.map((x, i) => (
                                <>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            {x?.user?.image ?
                                                <Avatar alt={`${x?.user?.names} ${x?.user?.last_names}`} src={x?.user_image} />
                                            : 
                                                <Avatar
                                                    {...stringAvatar(
                                                        `${x?.user?.names} ${x?.user?.last_names}`
                                                    )}
                                                />
                                            }
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography fontWeight={'Bold'}>{`${x?.user?.names} ${x?.user?.last_names}`}</Typography>}
                                            secondary={
                                                <>
                                                    <Box display={'flex'}>
                                                        <Typography component="span">{x?.pdf_link}</Typography>
                                                        <Tooltip  title="previsualizar pdf" sx={{ml:1}}>
                                                            <IconButton 
                                                                sx={{ width: 56, height: 56 }} 
                                                                onClick={() => {
                                                                    setOpenModal(true)
                                                                    get_pdf_link(x?.pdf_link)
                                                                }}
                                                            >
                                                                <PictureAsPdfIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <br />
                                                    </Box>
                                                    {x?.created_at && `${dayjs(x?.created_at).locale('es').format('dddd, D MMMM YYYY - h:mm a')}`}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </>
                            ))
                        :
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={ <Alert severity="info">El historial está vacío...</Alert>}
                            />
                        </ListItem>
                    }
                   
                </List>
            }
            <MUIModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
            >
                {openModal && <RenderPdf url={pdf_link}/>}
            </MUIModal>
        </Box>
    );
}
