import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';

export const MUICheckbox = ({label, name, control, props, checked, size, iconClose, sx}) => {
    const inputP = { inputProps: { 'aria-label': label || 'Seleccionar' } };
    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState}) => {
                return (
                    <Checkbox 
                        {...inputP} 
                        {...props}
                        size={size || 'medium'} 
                        checkedIcon={<CheckIcon/>}
                        icon={iconClose && <AddIcon/>}
                        checked={field.value ? field.value : checked ? checked : false}
                        onChange={({target}) => {
                            field.onChange(target.checked ? 1 : 0)

                            if (props?.setValueCheck){
                                props?.setValueCheck(target.checked ? 1 : 0)
                            }
                        }}
                        sx={{...sx, width: sx?.width || 'auto' }}
                    />
                )
            }} 
        />
       
    );
}