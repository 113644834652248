import React, { useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input } from '../Common/MUInput';

export const FromBusinessRelated = (props) => {
    const {handleSetAppend, control, isView, handleRemove, businessRelatedFields } = props;
    const [showSelectType, setShowSelectType] = useState(false)
    const [type, setType] = useState("");

    const styles = {
        label: {
          fontFamily: "Poppins-SemiBold",
          fontSize: "14px",
          fontWeight: "600",
        },
    };

    const handleEventInput = () =>{
        setShowSelectType(true);
    }

    const handleChangeType = (e) => {
        if(e.target.value){
            setType(e.target.value)
            handleSetAppend('related_business', e.target.value)
        }
        setShowSelectType(false);
    }

    const getInputType = (type, i) => {
        return (
            <Input
                name={`related_business.${i}.details`}
                control={control}
                props={{
                    type: type === 'percentage' ? 'number' : type,
                    size: 'small',
                    fullWidth: true,
                    autoComplete:'none',
                    disabled: isView,
                    InputProps:{
                        startAdornment: type === 'number' ? '$' : '',
                        endAdornment:  type === 'percentage' ? '%': '',
                        style: {
                            color: 'black',
                            '-webkit-text-fill-color': 'black',
                        },
                    },
                    sx: {
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: 'black',
                            '-webkit-text-fill-color': 'black',
                        },
                        '& .MuiInputAdornment-root.Mui-disabled': {
                            color: 'black',
                        },
                    },
                }}
            />
        )
    }

    return (
        <Grid container>
            <Grid item xs={11}>
                {showSelectType && <FormControl fullWidth margin="normal" variant="outlined">
                    <Controller 
                        control={control}
                        name='type_details'
                        render={()=> {
                            return (
                                <>
                                    <InputLabel id="select-type-label">Tipo de campo</InputLabel>
                                    <Select
                                        name='type_details__'
                                        labelId="select-type-label"
                                        id="select-type"
                                        value={type}
                                        onChange={handleChangeType}
                                        label="Tipo de campo"
                                    >
                                        <MenuItem value="text">Texto</MenuItem>
                                        <MenuItem value="number">Número</MenuItem>
                                        <MenuItem value="date">Fecha</MenuItem>
                                        <MenuItem value="percentage">Porcentaje</MenuItem>
                                    </Select>
                                </>
                            )
                        }}
                    />
                </FormControl>}
            </Grid>
            <Grid item xs={1}  display={'flex'} justifyContent={'center'}>
                {!isView && <IconButton aria-label="add" size='large' onClick={handleEventInput}>
                    <AddIcon />
                </IconButton>}
            </Grid>
            {businessRelatedFields
            ?.filter(field => field.status)
            ?.map((field, i) => (
                <>
                    <Grid item xs={6} p={1} key={i}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                    >
                            <Typography sx={styles.label}>Descripcíon*</Typography>
                        <Input
                            name={`related_business.${i}.description_bussines`}
                            control={control}
                            props={{
                                size: 'small',
                                fullWidth: true,
                                autoComplete:'none',
                                disabled: isView,
                                inputProps: {
                                    style: {
                                        color: 'black',
                                        '-webkit-text-fill-color': 'black',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={5} p={1}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                    >
                        <Typography sx={styles.label}>Detalles*</Typography>
                        {getInputType(field?.type_details, i)}
                    </Grid>
                    <Grid item xs={1} p={1} display={'flex'} justifyContent={'center'} mt={3}>
                        {!isView && <IconButton aria-label="delete" size='large' onClick={() => handleRemove(i, 'related_business')}>
                            <DeleteIcon />
                        </IconButton>}
                    </Grid>
                </>
            ))}
        </Grid>
    )
}