import React from 'react';
import { Box, Button, Checkbox, Grid, LinearProgress, Paper, Table, TableBody, TableCell,
TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

export const EmailControllerV = ({
    vars: { emails, isLoading},
    funcs: { handleChangeChecked, handleSaveEmailsController}
}) => {
    const styles = {
        header_text: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "600",
            color: "#323338",
        },
        cell_text: {
            fontFamily: "Poppins-Regular",
            fontSize: "14px",
            fontWeight: "400",
            color: "#323338",
        },
        buttonSave: {
            height: "49px",
            width: "100px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            color: "#FFFF",
            textTransform: "capitalize",
            ":hover": {
              background: "#1AAFD0",
            },
        },
        grid_container: {
            display: "flex",
            alignItems: "center",
            flexGrow: 1, mt:1, p:1,
            height: "80vh",
            overflow: "auto",
            width: "100%",
            borderRadius: "7px 7px 0px 0px",
            bgcolor: "#FFFFFF",
        }
    };

    return (
        <Box width={'100%'} height={'100%'} bgcolor={'#fdfdfd'} sx={{overflow: 'scroll'}}>
            {isLoading && <LinearProgress /> }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                    height: "100%",
                    background: "#f6f7fb",
                    pl: 1,
                }}       
            >
                <Box
                    height="40px"
                    bgcolor="#ffffff"
                    p={2}
                    pr={8}
                    borderRadius="0px 0px 0px 7px"
                    justifyContent="space-between"
                    display="flex"
                    alignItems="center"
                    boxShadow="0px 3px 4px 0px #2222221A"
                >
                    <Typography
                    sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#323338",
                    }}
                    >
                        Controlador de correos electrónicos
                    </Typography>
                    <Button
                        startIcon={<SaveIcon />}
                        type="button"
                        onClick={handleSaveEmailsController}
                        sx={{
                            fontFamily: "Poppins-SemiBold",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            width: "119px",
                            height: "49px",
                            background: "#1AAFD0",
                            color: "#FFFFFF",
                            ":hover": {
                                background: "#1AAFD0",
                            },
                        }}
                    >
                        Guardar
                    </Button>
                </Box>
                <Grid container sx={styles.grid_container}>
                    <Grid container >
                        <Grid item xs={12}>
                            <TableContainer sx={{maxHeight: 500}} component={Paper}>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableHead sx={{bgcolor:'#e3e3e3'}}>
                                        <TableRow>
                                            <TableCell sx={styles.header_text}>Correo</TableCell>
                                            <TableCell sx={styles.header_text} align='center'>Solicitante</TableCell>
                                            <TableCell sx={styles.header_text} align='center'>Resguardatorio</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {emails.map((row) => (
                                        <TableRow
                                        key={row?.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={styles.cell_text}>{row?.description}</TableCell>
                                            <TableCell align='center'>
                                                <Checkbox
                                                    checked={row?.applicant}
                                                    onChange={() => handleChangeChecked(row?.id, 'applicant')}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                                {row?.carbs}
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Checkbox
                                                    disabled={['REC-AVT1', 'REC-AVT2', 'REC-AVT3', 'SOL-VEN', 'TRA-VEN'].includes(row.code)}
                                                    checked={row?.safeguarding}
                                                    onChange={() => handleChangeChecked(row?.id, 'safeguarding')}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}