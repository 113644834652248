import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PilaresV } from "../../components/Company/Pilares/Pilares";
import { UseStoreLogin } from "../../stores/Login";
import { UseStoreTemplateView } from "../../stores/TemplateView";
import { UseStoreCompany } from "../../stores/Company";
import { UseStorePillar } from "./../../stores/Pillar";
import { SwalFire } from "./../../utils";
import { uploadFile } from "../../utils/AWS";
import { set, useForm } from "react-hook-form";
import { UseStoreActosGubernamentales } from "../../stores/ActosGubernamentales";
import { UseStoreUsers } from "../../stores/Users";
import { SwalfireConfirm } from "./../../utils";
import {create} from 'zustand';
import { persist } from 'zustand/middleware';
import { UseStoreAgenda } from '../../stores/Agenda'

export const PilaresC = () => {
  const save_digital_document = UseStoreAgenda(state => state.save_digital_document)
  const subPillarsCodes = {
    1: 'act-con',
    2: 'poderes',
    3: 'act-asm',
    4: 'fed-hec',
    5: 'aut-fed',
    6: 'aut-est',
    7: 'aut-mun',
    8: 'act-inm',
    9: 'act-mue',
    10: 'act-int',
    11: 'con-civ',
    12: 'con-mer',
    13: 'con-adm',
    14: 'con-lab',
  }
  const permission_user = UseStorePillar(state=> state.permission_user)
  const [permisos, setPermisos] = useState([]);

  const getPermisos = async () => {
    setPermisos(await permission_user)
    console.log('Permisos', permission_user)
   };

  const users = UseStoreUsers((state) => state.users);
  const navigate = useNavigate();
  const { company_id } = useParams();
  const { pillar } = useParams();
  const userInfo = UseStoreLogin((state) => state.data_user);
  const refreshUserInfo = UseStoreLogin((state) => state.refreshUserInfo);
  const pillars_in_company = UseStorePillar(
    (state) => state.pillars_in_company
  );
  const get_pillar_by_company = UseStorePillar(
    (state) => state.get_pillar_by_company
  );
  const get_permission_user = UseStorePillar(
    (state) => state.get_permission_user
  );
  const updateLabelNav = UseStoreTemplateView((state) => state.updateLabelNav);
  const button_back = UseStoreTemplateView((state) => state.button_back);
  const get_company = UseStoreCompany((state) => state.get_company);
  const [company, setCompany] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const actors = UseStoreTemplateView((state) => state.actors);
  //const get_actors = UseStoreTemplateView((state) => state.get_actors);
  const [subPillar, setSubPillar] = useState(0);
  const [openFolder, setOpenFolder] = React.useState(false);
  const [openFile, setOpenFile] = React.useState(false);
  const [openEditFile, setOpenEditFile] = React.useState(false);
  const [nameDocument, setNameDocument] = React.useState("");
  const [responsible, setResponsible] = React.useState("");
  const [client, setClient] = React.useState("");
  const [validity, setValidity] = React.useState("");
  const [physicalFileLocation, setPhysicalFileLocation] = React.useState("");
  const [fileId, setFileId] = React.useState("");

  const [subPillars, setSubPillars] = useState([5, 6, 7]);

  const noAplicaOption = { id: 999999, name: "No aplica", description: "" };
  const actorsWithNoAplica = [noAplicaOption, ...actors];

  const noAplicaOptionClient = { id: 999999, name: "No aplica" };
  const clientsWithNoAplica = [noAplicaOptionClient, ...actors];

  const [expandedItems, setExpandedItems] = React.useState([]);

  const noAplicaOptionUsers = {
    id: 999999,
    names: "No aplica",
    last_names: "",
    charge: "",
  };
  const usersWithNoAplica = [noAplicaOptionUsers, ...users];

  const [folderName, setFolderName] = React.useState("Sin carpeta");
  const [openEditFolder, setOpenEditFolder] = React.useState(false);
  const [subPillarId, setSubPillarId] = React.useState(null);
  const [id, setId] = React.useState(null);

  const defineSubPillar = async () => {
    if (pillars_in_company?.length > 0) {
      const subPillarsFilter = pillars_in_company.filter(
        (item) => item.pillar_id === 2
      );
      console.log("SubPillars", subPillarsFilter);
      if (subPillarsFilter?.length > 0) {
        setSubPillars(subPillarsFilter.map((item) => item.subpillar_id));
      }
      console.log("SubPillars", subPillars);
    }
  };

  const useStore = create(
    persist(
      (set) => ({
        agenda: [],
        time : 0,
        active : false,
        setAgenda: (newAgenda) => set({ agenda: newAgenda }),
        setTime: (newTime) => set({ time: newTime }),
        setActive: (newActive) => set({ active: newActive }),
      }),
      {
        name: 'agenda-storage', // nombre del almacenamiento en localStorage
        Storage: () => localStorage, // o sessionStorage
      }
    )
  );
  const setAgenda = useStore((state) => state.setAgenda)
  const setActive = useStore((state) => state.setActive)
  const active = useStore((state) => state.active)
  const agenda = useStore((state) => state.agenda)
  const time = useStore((state) => state.time)

  const {
    handleSubmit,
    control,
    register,
    setValue: setValueForm,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    reset: reset2,
    handleSubmit: handleSubmit2,
    setValue,
    control: control2,
    formState: { errors: errors2 },
  } = useForm();

  const {
    //formulario para la edición de nombre de carpeta
    handleSubmit: handleSubmit3,
    control: control3,
    register: register3,
    setValue: setValue3,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm();

  const {
    //formulario para la edición de datos del archivo
    handleSubmit: handleSubmit4,
    control: control4,
    register: register4,
    setValue: setValue4,
    reset: reset4,
    formState: { errors: errors4 },
  } = useForm();

  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
    label_text: {
      height: "40px",
      width: "100px",
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "500",
      textTransform: "capitalize",
    },
    buttonSave: {
      height: "49px",
      width: "100px",
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "500",
      color: "#FFFF",
      textTransform: "capitalize",
      background: "#1AAFD0",
      margin: "10px 0",
      ":hover": {
        color: "#1AAFD0",
        background: "#FFF",
      },
    },
    typography: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "20px",
      fontWeight: "600",
      color: "#323338",
      "@media (max-width: 790px)": {
        fontSize: "16px",
      },
      "@media (max-width: 590px)": {
        fontSize: "18",
      },
    },
    tableCell: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
      color: "#323338",
    },
  };
  
  const deleteFolder = UseStoreActosGubernamentales(
    (state) => state.delete_folder
  );

  const handleDeleteFolder = async (subpillar_id, folder_id, company_id) => {
    const res = await deleteFolder(subpillar_id, folder_id, company_id);
    if (res) {
      SwalFire("¡Exito!", "Carpeta eliminada correctamente", "success");
      const actGob = await Promise.all(
        subPillars.map(async (x) => {
           const cap = await get_goverment_acts(company_id, x);
           return cap.data
        })
      );
      if (actGob) {
        setGovermentActs(actGob.flat());
      }
    setFolderName("");
    setId(null);
    setSubPillarId(0);
    }
    
  };

  const updateFolder = UseStoreActosGubernamentales(
    (state) => state.update_folder
  );
  const updateFile = UseStoreActosGubernamentales((state) => state.update_file);

  const handleModalEditNameFolder = async (folder_id) => {
    govermentActs.map((x) => {
      if (x.id == folder_id) {
        setFolderName(x.file_name);
        setSubPillarId(x.subpillar_id);
        setValue('subpillar_id', x.subpillar_id);
        setId(x.id);
      }
    });
    setId(id);
    setOpenEditFolder(true);
  };

  const handleCloseEditNameFolder = () => {
    setOpenEditFolder(false);
  };

  const OnSubmitUpdateFolder = async (data) => {
    setIsloading(true);
    const schema = {
      file_name: data?.file_name,
      company_id: company_id,
      subpillar_id: data?.subpillar_id,
      folder_id: data?.folder_id,
      type: "folder",
    };
    const s = await updateFolder(schema);
    console.log(s);
    
    updateGetGovermentActs();
    reset3();
    handleCloseEditNameFolder();
    setIsloading(false);
  };

  const handleModalEditFile = (suppillar_id, file_id) => {
    setValue4("id", file_id);
    setValue4("subpillar_id", suppillar_id);
    setOpenEditFile(true);
    const filteredFiles = govermentActs.filter((x) => x.id == file_id);
    if (filteredFiles.length > 0) {
      const x = filteredFiles[0];
      console.log(x);
      setNameDocument(x.file_name);
      setResponsible(x.responsible_id);
      setClient(x.client);
      setValidity(x.validity);
      setPhysicalFileLocation(x.physical_file_location);
      //setFile(x.file_url);
      setFileId(x.file_id);
    }
  };

  const handleCloseEditFile = () => {
    setOpenEditFile(false);
  };

  const OnSubmitUpdateFile = async (data) => {
    setIsloading(true);
    console.log(data, "data");
    if (data?.file) {
      const timestamp = new Date().getTime();
      const name = data?.file?.name.split(".");
      const file_name = name[0] + timestamp + "." + name[1];
      const res = await uploadFile(
        "/Actos_gubernamentales",
        data?.file,
        data?.file_name + timestamp,
        true
      );
      if (res?.statusCode === 200) {
        //SwalFire("Exito!", res?.message, "success");
        const schema = {
          file_name: data?.file_name,
          company_id: company_id,
          subpillar_id: data?.subpillar_id,
          id: data?.id,
          type: "file",
          physical_file_location: data?.physical_file_location,
          validity: data?.validity,
          responsible_id: data?.responsible,
          client: data?.client,
          file_url: data?.file_name + timestamp + "." + name.at(-1).toLowerCase(),
        };
        console.log(schema, "schema file");
        const s = await updateFile(schema);
      } else {
        SwalFire("Error!", res?.message, "error");
        return;
      }
    } else {
      const schema = {
        file_name: data?.file_name,
        company_id: company_id,
        subpillar_id: data?.subpillar_id,
        id: data?.id,
        type: "file",
        physical_file_location: data?.physical_file_location,
        validity: data?.validity,
        responsible_id: data?.responsible,
        client: data?.client,
      };
      console.log(schema, "schema without file");
      const s = await updateFile(schema);
    }
    updateGetGovermentActs();
    reset4();
    //close modal
    setIsloading(false);
    handleCloseEditFile();
  };

  const deleteFile = UseStoreActosGubernamentales((state) => state.delete_file);

  const handleDeleteFile = async (subpillar_id, id) => {
    setIsloading(true);
    const res = await deleteFile(subpillar_id, id);
    if (res) {
      SwalFire("¡Exito!", "Se eliminó el registro correctamente", "success");
      const actGob = await Promise.all(
        subPillars.map(async (x) => {
          const cap = await get_goverment_acts(company_id, x);
          return cap.data;
        })
      );
      if (actGob) {
        setGovermentActs(actGob.flat());
      }
    }
    setIsloading(false);
  };

  const get_goverment_acts = UseStoreActosGubernamentales(
    (state) => state.get_goverment_acts
  );
  const add_goverment_acts = UseStoreActosGubernamentales(
    (state) => state.add_goverment_acts
  );
  const [govermentActs, setGovermentActs] = useState(false);
  const OnSubmit = async (data) => {
    setIsloading(true);
    if (!data?.file) {
      SwalFire("Atención", "No se ha agregado ningún documento", "warning");
      setIsloading(false);
      return;
    }
    govermentActs.filter((x) => {
      if (x?.id === data?.parent_id) {
        setSubPillar(x?.subpillar_id);
      }
    });

    //separar el nombre del archivo y la extension
    const timestamp = new Date().getTime();
    const name = data?.file?.name.split("."); 
    const file = await uploadFile(
      "/Actos_gubernamentales",
      data?.file,
      data?.file_name + timestamp,
      true
    );
    if (file?.statusCode === 200) {
      const payload = {
        subpillar_id: data?.subpillar_id,
        file_name: data?.file_name,
        type: "file",
        file_url: data?.file_name + timestamp + "." + name.at(-1).toLowerCase(),
        company_id: company_id,
        physical_file_location: data?.physical_file_location,
        parent_id: data?.parent_id,
        validity: data?.validity,
        responsible_id: data?.responsible,
        client: data?.client === undefined ? 0 : data?.client,
      };
      console.log(payload);
      const s = await add_goverment_acts(payload);
      if (s.statusCode === 200) {
        const maxTime = 10 * 60 * 1000;
        if(Date.now() - time <= maxTime && active === true){
          const payloads = {
            id : agenda.id,
            type_digital_document: subPillarsCodes[payload.subpillar_id],
            digital_document: file.data.id,
          }
          console.log(payloads)
          try {
            const s = await save_digital_document(payloads)
            if(s){
                console.log('Documento guardado')
            }else{
                console.log('Error al guardar documento')
            }
        } catch (error) {
            console.error('Error al guardar documento:', error)
        }
        }else{
          console.log('No se ha creado un archivo en menos de 10 minutos o no hay agenda')
        }
      }
        setAgenda([])
        setActive(false)
      const actGob = await Promise.all(
        subPillars.map(async (x) => {
          const cap = await get_goverment_acts(company_id, x);
          return cap.data
        })
      );
      if (actGob) {
        setGovermentActs(actGob.flat());
      }
      reset();
      handleCloseFile(); //cerrar modal
      setIsloading(false);
    } else {
      SwalFire("Error!", "Error al cargar el documento, verifique su conexión a internet", "error");
      setIsloading(false);
      return;
    }
  };
  //recibe formulario para la creación de carpeta en actos gubernamentales
  const OnSubmit2 = async (data) => {
    setIsloading(true);
    const payload = {
      subpillar_id: data?.subpillar_id,
      file_name: data?.folder_name,
      type: "folder",
      company_id: company_id,
      parent_id: data?.parent_id,
    };
    const s = await add_goverment_acts(payload);
    const actGob = await Promise.all(
      subPillars.map(async (x) => {
        const cap = await get_goverment_acts(company_id, x);
        return cap.data;
      })
    );
    if (actGob) {
      setGovermentActs(actGob.flat());
    }
    //cerrar el modal de creación de carpeta
    reset2();
    setIsloading(false);
    handleCloseFolder(); //cerrar modal
  };

  const handleOpenFile = () => {
    setOpenFile(true);
  };
  const handleCloseFile = () => {
    setOpenFile(false);
  };

  const handleOpenFolder = () => {
    setOpenFolder(true);
  };
  const handleCloseFolder = () => {
    setOpenFolder(false);
  };
  const OnSubmitEditFolder = async (data) => {
    setIsloading(true);
    govermentActs.filter((x) => {
      if (x?.id === data?.id) {
        setSubPillar(x?.subpillar_id);
      }
    });
    const payload = {
      subpillar_id: subPillar,
      file_name: data?.folder_name,
    };
    const s = await add_goverment_acts(payload);
    
    const actGob = await Promise.all(
      subPillars.map(async (x) => {
        const cap = await get_goverment_acts(company_id, x);
        return cap.data;
      })
    );
    if (actGob) {
      setGovermentActs(actGob.flat());
    }

    //cerrar el modal de creación de carpeta
    reset2();
    setOpenFolder(false);
    setIsloading(false);
  };

  const redirectPillar = (isSubpillar, obj, data) => {
    if (isSubpillar) {
      if (obj?.view || obj?.add || obj?.delete || obj?.download) {
        navigate(
          `/subpillars/${data?.company_name}/${data?.pillar_code}/${data?.subpillar_code}`
        );
      } else {
        SwalFire(
          "Atencion!",
          "Usted no tiene permisos para ver el pillar",
          "warning"
        );
      }
    } else {
      if (obj?.view || obj?.add || obj?.delete || obj?.download) {
        navigate(`/pillars/${data?.company_name}/${data?.pillar_code}`);
      } else {
        SwalFire(
          "Atencion!",
          "Usted no tiene permisos para ver el pillar",
          "warning"
        );
      }
    }
  };

  const handleClickPreviewPillars = async (
    company_name,
    pillar_code,
    subpillar_code = false
  ) => {
    setIsloading(true);
    const type_user = userInfo?.rol_id;
    if (type_user === 1) {
      if (!subpillar_code) {
        navigate(`/pillars/${company_name}/${pillar_code}`);
      } else {
        navigate(
          `/subpillars/${company_name}/${pillar_code}/${subpillar_code}`
        );
      }
    } else {
      const permission = await get_permission_user(userInfo?.id, company_id);
      if (permission?.length) {
        const acts_con = permission.find((x) => x?.section_id === 2);
        const powers = permission.find((x) => x?.section_id === 3);
        const assam = permission.find((x) => x?.section_id === 4);
        const statem = permission.find((x) => x?.section_id === 5);

        if (!subpillar_code) {
          if (pillar_code === "act-con") {
            redirectPillar(subpillar_code, acts_con, {
              company_name,
              pillar_code,
            });
          }
          if (pillar_code === "fed-hec") {
            redirectPillar(subpillar_code, statem, {
              company_name,
              pillar_code,
            });
          }
        } else {
          if (pillar_code === "act-asm") {
            redirectPillar(subpillar_code, assam, {
              company_name,
              pillar_code,
              subpillar_code,
            });
          }
          if (pillar_code === "poderes") {
            redirectPillar(subpillar_code, powers, {
              company_name,
              pillar_code,
              subpillar_code,
            });
          }
        }
      }
    }
    setIsloading(false);
  };

  const getPath = (fileId, data) => {
    // Crear un diccionario de objetos para acceso rápido
    if (data.length > 0) {
      const dataDict = data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      // Función para construir la ruta recursivamente
      const buildPath = (id, path = []) => {
        const item = dataDict[id];
        if (!item) return path;

        // Añadir el ID actual a la ruta
        path.unshift(`folder${item.id}`);

        // Si tiene padre, continuar construyendo la ruta
        if (item.parent_id !== null) {
          return buildPath(item.parent_id, path);
        }

        return path;
      };

      // Construir la ruta completa
      const fullPath = buildPath(fileId);

      // Remover el último elemento si es el archivo final
      if (fullPath.length > 0) {
        const lastItem = dataDict[fileId];
        if (lastItem && lastItem.parent_id === null) {
          fullPath.pop();
        }
      }
      console.log("fullPath", fullPath);
      setExpandedItems(fullPath);
      return fullPath;
    }else{
      console.log("No hay agenda");
      return [];
    }
  };

  const updateGetGovermentActs = async () => {
    defineSubPillar();
    const subPillarsFilter = pillars_in_company.filter(
      (item) => item.pillar_id === 2
    );
    console.log("SubPillars", subPillarsFilter);
    if (subPillarsFilter?.length > 0) {
      setSubPillars(subPillarsFilter.map((item) => item.subpillar_id));
    }
    const actGob = await Promise.all(
      subPillarsFilter.map(async (x) => {
        const cap = await get_goverment_acts(company_id, x.subpillar_id);
        return cap.data;
      })
    );
    if (actGob) {

      const autoridadesFederales = permission_user.find((x) => x.section_id === 6);
      const autoridadesEstatales = permission_user.find((x) => x.section_id === 7);
      const autoridadesMunicipales = permission_user.find((x) => x.section_id === 8);

      const actGobFilter = actGob.flat().filter((x) => {
        return (
          (x.subpillar_id === 5 && autoridadesFederales?.view) ||
          (x.subpillar_id === 6 && autoridadesEstatales?.view) ||
          (x.subpillar_id === 7 && autoridadesMunicipales?.view)
        );
      })
      setGovermentActs(actGobFilter);
      console.log("Activos", actGobFilter);
      if(initFolder() !== 0){
        let folderID = initFolder();
        getPath(folderID, actGobFilter);
        setId(folderID);
        actGobFilter.filter((item) => {
          if (item.id == folderID) {
            setFolderName(item.file_name);
            setSubPillar(item.subpillar_id);
          }
        });
        //eliminar el id de la url
        window.history.replaceState({}, document.title, "/pilares/activos/" + company_id);
      }
    }
  };

  const initFolder = () => {
    //get url id param
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    if (myParam && myParam !== null) {
      return myParam;
    }else{
      return 0;
    }
  };

  const loadingInfo = async () => {
    await getPermisos();
    await defineSubPillar();
    setIsloading(true);
    if (company_id) {
      await get_pillar_by_company(company_id);
      const bussines = await get_company(company_id);
      //await get_actors();
      if (bussines) {
        setCompany(bussines);
        // updateLabelNav(bussines?.name)
        updateLabelNav("Actos corporativos");
        button_back(true, `/view-company/${company_id}`);
      }
      // const subPillarsFilter = pillars_in_company.filter((item) => item.pillar_id === 2);
      // if(subPillarsFilter?.length > 0){
      //   setSubPillars(subPillarsFilter.map((item) => item.subpillar_id));
      // }
      // const result = subPillarsFilter.map(async (x) => {
      //   return await get_goverment_acts(company_id, x.subpillar_id);
      // });
      // const actGob = await Promise.all(result);
      // // const actGob = await get_goverment_acts(company_id);
      // if (actGob) {
      //   setGovermentActs(actGob.flat());
      // }
      updateGetGovermentActs();
    }
    setIsloading(false);
  };

  useEffect(() => {
    loadingInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = async () => {
      await refreshUserInfo();
    };
    handler();
  }, []);

  return (
    <PilaresV
      vars={{
        company,
        pillars_in_company: isLoading ? [] : pillars_in_company,
        isLoading,
        pillar,
        control,
        register,
        govermentActs,
        errors,
        control2,
        register2,
        errors2,
        setValue,
        company_id,
        styles,
        users,
        reset,
        setValueForm,
        register3,
        control3,
        errors3,
        register4,
        setValue3,
        setValue4,
        control4,
        errors4,
        actors,
        reset2,
        reset3,
        reset4,
        openFolder,
        openFile,
        openEditFile,
        nameDocument,
        responsible,
        client,
        validity,
        physicalFileLocation,
        fileId,
        actorsWithNoAplica,
        clientsWithNoAplica,
        usersWithNoAplica,
        folderName,
        openEditFolder,
        subPillarId,
        id,
        permisos,
        expandedItems,
        subPillar
      }}
      funcs={{
        handleClickPreviewPillars,
        OnSubmit,
        handleSubmit,
        OnSubmit2,
        handleSubmit2,
        handleDeleteFolder,
        handleDeleteFile,
        handleSubmit3,
        handleSubmit4,
        OnSubmitEditFolder,
        OnSubmitUpdateFolder,
        OnSubmitUpdateFile,
        setSubPillar,
        handleOpenFolder,
        handleCloseFolder,
        handleOpenFile,
        handleCloseFile,
        handleModalEditFile,
        handleCloseEditFile,
        setResponsible,
        setFileId,
        setFolderName,
        handleModalEditNameFolder,
        handleCloseEditNameFolder,
        setSubPillarId,
        setId,
        setIsloading,
        setExpandedItems
      }}
    />
  );
};
