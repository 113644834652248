import * as React from "react";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { esES } from "@mui/x-date-pickers/locales";

export const InputDate = ({
  label,
  name,
  control,
  required,
  disabled,
  props,
}) => {
  //const maxDate = dayjs(Date())
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-Es"
            localeText={
              esES.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              value={field.value ? dayjs(field.value) : null}
              disabled={disabled}
              inputRef={field.ref}
              onChange={(value) => {
                if (props?.getValue) {
                  props?.getValueFunc(value);
                }
                field.onChange(value);
              }}
              label={label}
              slotProps={{
                textField: {
                  ...props,
                  fullWidth: props?.fullWidth || false,
                  required,
                  size: props?.size || "small",
                  error: Boolean(fieldState.error?.message),
                  helperText: fieldState.error?.message || "",
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};
