import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Input } from "../../../Common/MUInput";
import { InputDate } from "../../../Common/MUIInputDate";
import { CheckboxIcon } from "../../../Common/MUICbxIcon";
import { InputFile } from "../../../Common/MUIInputFile";

export const ConstitutiveAct = ({
  control,
  errors,
  setVerification,
  verification,
  rowSelected,
  setIsUpload,
}) => {
  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
    title: {
      mt: 2,
      fontFamily: "Poppins-SemiBold",
      fontSize: "20px",
      fontWeight: "600",
      color: "#323338",
    },
  };
  return (
    <Box>
      <Grid container columnSpacing={2} rowGap={2} columns={12} pt="5px">
        <Grid
          item
          xs={12}
          sm={7}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Número de escritura</Typography>
          <Input
            name="writing_number"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
              disabled: false,
              isDouble: true,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          lg={4}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Fecha de otorgamiento</Typography>
          <InputDate name="grant_date" control={control} />
        </Grid>
      </Grid>
      <Typography sx={styles.title}>
        Inscripción en el registro público de comercio
      </Typography>
      <Grid
        container
        columnSpacing={2}
        rowGap={2}
        columns={12}
        pt="5px"
        display={verification === 1 ? "flex" : "none"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Partida</Typography>
          <Input
            name="entry_number"
            control={control}
            props={{
              size: "small",
              required: false,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Volumen</Typography>
          <Input
            name="volume"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Libro*</Typography>
          <Input
            name="book"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Fecha de registro</Typography>
          <InputDate
            name={"registry_date"}
            control={control}
            error={Boolean(errors?.registry_date)}
            errMsg={errors?.registry_date?.message}
            required={true}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Folio digital*</Typography>
          <Input
            name="folio"
            control={control}
            props={{
              label: "Folio digital",
              size: "small",
              required: true,
              autoComplete: "none",
            }}
          />
        </Grid>
      </Grid>
      <Button
        type="button"
        sx={{
          mt: 2,
          height: "40px",
          pr: 2,
          background: "#1DB14B",
          color: "#fff",
          textTransform: "capitalize",
          fontFamily: "Poppins-Regular",
          fontSize: "14px",
          fontWeight: "400",
          ":hover": { background: "#1DB14B" },
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
          "& .MuiButtonBase-root-MuiCheckbox-root": {
            color: "#fff",
          },
        }}
        onClick={() => {
          switch (verification) {
            case 0:
              setVerification(1);
              break;
            case 1:
              setVerification(0);
              break;
            default:
              setVerification(0);
              break;
          }
        }}
      >
        <CheckboxIcon
          value={verification}
          name="verification"
          control={control}
          indeterminate={false}
        />
        Verificación
      </Button>
      <Box mt={2}>
        <InputFile
          activateColor
          label="Elegir archivo"
          value={Boolean(rowSelected?.pdf_link)}
          onClick={() => setIsUpload(true)}
          name="pdf_link"
          control={control}
          required={true}
          props={{
            error: errors?.pdf_link ? true : false,
            helperText: errors?.pdf_link?.message
          }}
        />
      </Box>
    </Box>
  );
};
