import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { encrypt_data } from './../utils/JSImplements'
import { GET, POST} from './../utils/AuthService'

export const UseStoreSearch = create(
    persist(
        (set, get) => ({
            prev_data: [],
            current_data: [],
            filter_data: [],
            reset_filter_data: () => {
                set({filter_data: []})
            },
            get_data_by_search: async (text) => {
                const { current_data } = get()
                const res = await GET(`get-rows-key/${text}`, 'buscador')
                if(res?.statusCode === 200){
                    set({current_data: res?.data, prev_data: [...current_data, ...res?.data]})
                }
            },
            get_data_by_filter: async (filters) => {
                const res = await POST(`get-rows-by-filter`, filters, 'buscador')
                if(res?.statusCode === 200){
                    set({filter_data: res?.data})
                }
            },

        }),
        {
            name: encrypt_data('search_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)