import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import React from "react";
import { TableListAdapter } from "./DataGridAdapter";

export const PermissionList = ({
  label,
  assign_user,
  stateView,
  enabledButtons,
  handleChangePermission,
  permissionTemp,
  isLoadingData,
  handleSave,
  handleCancel,
  disabledModule,
}) => {
  const columns = [
    { field: "module", headerName: "Modulo", width: 300 },
    { field: "section", headerName: "Apartado", width: 300 },
    {
      field: "view",
      headerName: "Ver",
      description: "Nombre del permiso",
      cellClassName: "ver-className",
      width: 100,
      renderCell: (params) => (
        <Switch
          disabled={stateView?.readonly}
          checked={Boolean(params?.row?.view)}
          name="view"
          onChange={(e) => handleChangePermission(e, params?.row)}
        />
      ),
    },
    {
      field: "add",
      headerName: "Crear",
      description: "Nombre del permiso",
      width: 100,
      renderCell: (params) => (
        <Switch
          disabled={stateView?.readonly}
          checked={Boolean(params?.row?.add)}
          name="add"
          onChange={(e) => handleChangePermission(e, params?.row)}
        />
      ),
    },
    {
      field: "edit",
      headerName: "Editar",
      description: "Nombre del permiso",
      width: 100,
      renderCell: (params) => (
        <Switch
          disabled={stateView?.readonly}
          checked={Boolean(params?.row?.edit)}
          name="edit"
          onChange={(e) => handleChangePermission(e, params?.row)}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Eliminar",
      description: "Nombre del permiso",
      width: 100,
      renderCell: (params) => (
        <Switch
          disabled={stateView?.readonly}
          checked={Boolean(params?.row?.delete)}
          name="delete"
          onChange={(e) => handleChangePermission(e, params?.row)}
        />
      ),
    },
    {
      field: "download",
      headerName: "Descargar",
      description: "Nombre del permiso",
      width: 100,
      renderCell: (params) => (
        <Switch
          disabled={stateView?.readonly || params?.row?.section_id === 1}
          checked={Boolean(params?.row?.download)}
          name="download"
          onChange={(e) => handleChangePermission(e, params?.row)}
        />
      ),
    },
  ];

  if (
    // (stateView?.rol && label === "Rol") ||
    // (stateView?.user && label === "Usuario")
    true
  )
    columns.unshift({
      field: "companie_name",
      headerName: "Empresa",
      width: 190,
    });

  return (
    <Box
      sx={{
        height: "auto",
        bgcolor: "#FFFFFF",
        flexGrow: 1,
        "& .ver-className": {
          borderLeft: "3px solid #E2E4E7",
        },
      }}
    >
      <Stack>
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
            fontWeight: "600",
            color: "#323338",
          }}
        >
          Permisos del {label}
        </Typography>
        <Grid
          container
          columnSpacing={2}
          rowGap={2}
          columns={12}
          p="0px 16px"
          pt="5px"
        >
          <Grid xs={12} sm={12} md={6} lg={6}>
            {label === "Rol" && (!!stateView?.user || !stateView?.rol) ? (
              <Button
                variant="outlined"
                sx={{
                  marginRight: 2,
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                  color: "#323338",
                  borderColor: "#666F88",
                  ":hover": {
                    background: "#1AAFD0",
                    color: "#FFFFFF",
                  },
                }}
                onClick={() => disabledModule()}
              >
                Desactivar módulos
              </Button>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            display="flex"
            justifyContent="end"
          >
            <Collapse in={enabledButtons}>
              <Button
                variant="outlined"
                sx={{
                  marginRight: 2,
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                  background: "#1AAFD0",
                  color: "#FFFFFF",
                  ":hover": {
                    background: "#1AAFD0",
                  },
                }}
                onClick={() => handleSave(assign_user)}
              >
                Guardar
              </Button>
            </Collapse>
          </Grid>
        </Grid>
      </Stack>
      <Divider sx={{ pt: 2 }} />
      <TableListAdapter
        columns={columns}
        height="60vh"
        countRows={10}
        data={permissionTemp}
        isLoading={isLoadingData}
        handleRowClick={() => {}}
        handleCellClick={() => {}}
      />
    </Box>
  );
};
