import { Box } from "@mui/material";
import React from "react";
import { ConstitutiveAct } from "./formTypes/ConstitutiveAct";
import { ExtraordinaryAssembly } from "./formTypes/ExtraordinaryAssembly";
import { FaithOfFacts } from "./formTypes/FaithOfFacts";
import { GeneralPower } from "./formTypes/GeneralPower";
import { OrdinaryAssembly } from "./formTypes/OrdinaryAssembly";
import { SpecialPower } from "./formTypes/SpecialPower";

export const MultiPillarForm = ({
  control,

  pillar,
  errors,
  type_powers,
  especial_powers,
  set_especial_power,
  especial_power,
  add_especial_powers,
  verification,
  setVerification,
  subpillar,
  rowSelected,
  setIsUpload,

  states,
}) => {
  return (
    <Box>
      {pillar === "act-con" && (
        <ConstitutiveAct
          rowSelected={rowSelected}
          setIsUpload={setIsUpload}
          control={control}
          errors={errors}
          verification={verification}
          setVerification={setVerification}
        />
      )}
      {["pwr-ppc", "pwr-paa", "pwr-pad"]?.includes(pillar) && (
        <GeneralPower
          control={control}
          errors={errors}
          type_powers={type_powers}
          pillar={pillar}
          states={states}
          verification={verification}
          setVerification={setVerification}
          subpillar={subpillar}
          rowSelected={rowSelected}
          setIsUpload={setIsUpload}
        />
      )}
      {pillar === "pwr-pes" && (
        <SpecialPower
          control={control}
          errors={errors}
          pillar={pillar}
          especial_powers={especial_powers}
          set_especial_power={set_especial_power}
          especial_power={especial_power}
          add_especial_powers={add_especial_powers}
          states={states}
          verification={verification}
          setVerification={setVerification}
          subpillar={subpillar}
          rowSelected={rowSelected}
          setIsUpload={setIsUpload}
        />
      )}
      {pillar === "asa-ord" && (
        <OrdinaryAssembly
          control={control}
          errors={errors}
          rowSelected={rowSelected}
          setIsUpload={setIsUpload}
        />
      )}
      {pillar === "asa-ext" && (
        <ExtraordinaryAssembly
          control={control}
          errors={errors}
          verification={verification}
          setVerification={setVerification}
          pillar={pillar}
          subpillar={subpillar}
          rowSelected={rowSelected}
          setIsUpload={setIsUpload}
        />
      )}
      {pillar === "fed-hec" && (
        <FaithOfFacts
          control={control}
          errors={errors}
          rowSelected={rowSelected}
          setIsUpload={setIsUpload}
        />
      )}
    </Box>
  );
};
