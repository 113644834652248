import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

export const MUIModalAdapter = ({
  children,
  open,
  handleClose,
  title,
  maxWidth,
  closeOnlyButton,
  padding = "20px 24px",
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth={maxWidth || "md"}
        open={open}
        onClose={closeOnlyButton ? handleClose : null}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          fontFamily="Poppins-SemiBold"
          fontSize="16px"
        >
          <Grid container>
            <Grid xs={2}>
              <IconButton type="button" onClick={handleClose}>
                <CloseIcon sx={{ color: "#323338" }} />
              </IconButton>
            </Grid>
            <Grid
              xs={8}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {title}
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ padding }}>{children}</DialogContent>
      </Dialog>
    </Box>
  );
};
