import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Backdrop,
  Button,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useState } from "react";
import { ImagePreview } from "../../Common/ImagePreview";
import { MUIModalAdapter } from "../../Common/MUIModalAdapter";
import { RenderPdf } from "../../Common/RenderPdf";
import DeleteIcon from "./../../../assets/icons/deleteIcon.svg";
import { CompanyAdministration } from "./CompanyAdministration";
import { CompanyInformation } from "./CompanyInformation";
import { Pillars } from "./Pillars";
import { useLocation } from 'react-router-dom';
import { set } from "react-hook-form";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: "24px 0px" }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AddCompanyV = ({
  vars: {
    openModal,
    share_link,
    org_chart_link,
    logo_company,
    company_id,
    sector_companies,
    countrys,
    pillars_preview,
    type_user,
    isView,
    control,
    errors,
    isLoading,
    linkGazette,
    isLoadingPillar,
    loadingTownState,
    dataForm,
    permissions,
    tab
  },
  funcs: {
    OnSubmit,
    handleSubmit,
    setOpenModal,
    handleChangePillar,
    handleDelete,
    handleViewGazette,
    handle_upload_edit_file,
    handleChangeZipCode,
    handleClickPreviewPillars,
    handleCancel,
    handleDeleteRow,
    handleAdd,
    handleCopy,
    setTab
  },
}) => {

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setTab(newValue);
  };
  
  const styles = {
    buttonDelete: {
      height: "49px",
      width: "120px",
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "500",
      color: "#323338",
      textTransform: "capitalize",
      ":hover": {
        background: "#EAE4E7",
      },
    },
    buttonCancel: {
      height: "49px",
      width: "105px",
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "500",
      color: "#323338",
      textTransform: "capitalize",
      ":hover": {
        background: "#EAE4E7",
      },
    },
    buttonSave: {
      height: "49px",
      width: "100px",
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "500",
      color: "#FFFF",
      textTransform: "capitalize",
      ":hover": {
        background: "#1AAFD0",
      },
    },
  };
  return (
    <Box
      width="100%"
      bgcolor="#FFFFFF"
      height="100%"
      p="0px 8px"
      overflow="auto"
      sx={
        company_id
          ? {
              width: "100%",
              background: "#FFFFFF",
              height: "100%",
              p: "0px 8px",
              overflow: "auto",
            }
          : {
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
              height: "100%",
              background: "#f6f7fb",
              pl: 1,
            }
      }
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingTownState || isLoadingPillar || isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        height="40px"
        bgcolor="#ffffff"
        p={2}
        pr={8}
        borderRadius="0px 0px 0px 7px"
        justifyContent="space-between"
        display={company_id ? "none" : "flex"}
        alignItems="center"
        boxShadow="0px 3px 4px 0px #2222221A"
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#323338",
          }}
        >
          Nueva empresa
        </Typography>
      </Box>
      <Stack
        display={company_id ? "flex" : "none"}
        flexDirection="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#1AAFD0",
            p: "24px 0px 5px 14px",
          }}
        >
          { dataForm?.name ? dataForm.name + ' - ' : ''}
          <span 
            style={{
              color: "#323338"
            }}
          >
          Perfil empresarial
          </span>
        </Typography>
        {company_id ? (
          <Stack flexDirection="row" gap={3}>
            {permissions?.delete ? (
              <Button
                id="delete"
                type="button"
                onClick={() => handleDelete(company_id)}
                sx={styles?.buttonDelete}
                startIcon={<img src={DeleteIcon} alt="DeleteIcon" />}
              >
                Eliminar
              </Button>
            ) : null}
            <Button
              type="button"
              onClick={handleCancel}
              sx={styles?.buttonCancel}
            >
              Cancelar
            </Button>
            {permissions?.edit ? (
              <Button
                id="edit"
                type="button"
                onClick={handleSubmit(OnSubmit)}
                variant="contained"
                sx={styles?.buttonSave}
              >
                Guardar
              </Button>
            ) : null}
          </Stack>
        ) : null}
      </Stack>
      <Box
        sx={
          company_id
            ? {}
            : {
                width: "95%",
                borderRadius: "7px 7px 0px 0px",
                bgcolor: "#FFFFFF",
                p: "0px 16px",
                "& .options-className": {
                  borderLeft: "3px solid #E2E4E7",
                },
                "& .role-className": {
                  borderLeft: "1px solid #E2E4E7",
                },
              }
        }
      >
        <Box borderBottom="3px solid #DDDDDD">
          <Stack
            display={company_id ? "none" : "flex"}
            flexDirection="row"
            gap={2}
            mt={2}
            justifyContent="end"
          >
            <Button
              type="button"
              onClick={handleCancel}
              sx={styles?.buttonCancel}
            >
              Cancelar
            </Button>
            <Button
              id="save"
              type="button"
              onClick={handleSubmit(OnSubmit)}
              variant="contained"
              sx={styles?.buttonSave}
            >
              Guardar
            </Button>
          </Stack>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Información de la empresa" {...a11yProps(0)} />
            <Tab label="Administración de la sociedad" {...a11yProps(1)} />
            <Tab label="Pilares" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
          <CompanyInformation
            control={control}
            handleCopy={handleCopy}
            isView={isView}
            logo_company={logo_company}
            handle_upload_edit_file={handle_upload_edit_file}
            errors={errors}
            setOpenModal={setOpenModal}
            sector_companies={sector_companies}
            handleViewGazette={handleViewGazette}
            linkGazette={linkGazette}
            handleChangeZipCode={handleChangeZipCode}
            countries={countrys}
            share_link={share_link}
            org_chart_link={org_chart_link}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <CompanyAdministration
            control={control}
            isView={isView}
            company_id={company_id}
            dataForm={dataForm}
            handleDeleteRow={handleDeleteRow}
            handleAdd={handleAdd}
            permissions={permissions}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
          <Pillars
            pillars_preview={pillars_preview}
            isView={isView}
            handleChangePillar={handleChangePillar}
            company_id={company_id}
            handleClickPreviewPillars={handleClickPreviewPillars}
          />
        </CustomTabPanel>
        <MUIModalAdapter
          open={openModal?.open}
          handleClose={() => setOpenModal(false)}
        >
          {openModal?.type === 1 ? (
            <ImagePreview url={openModal?.url} />
          ) : (
            <RenderPdf url={openModal?.url} />
          )}
        </MUIModalAdapter>
        <Stack
          display={company_id ? "none" : "flex"}
          width="100%"
          height="15vh"
          borderTop="3px solid #DDDDDD"
          flexDirection="row"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            type="button"
            variant="text"
            startIcon={<ChevronLeftIcon />}
            onClick={() => {
              if (value > 0) setTab(value - 1);
            }}
            sx={{
              display: value === 0 ? "none" : "flex",
              fontFamily: "Poppins-SemiBold",
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "capitalize",
              width: "123px",
              height: "49px",
              ...(value === 2
                ? {
                    background: "#1AAFD0",
                    color: "#FFFFFF",
                    ":hover": {
                      background: "#1AAFD0",
                    },
                  }
                : {
                    color: "#323338",
                    textDecoration: "underline #323338",
                    ":hover": {
                      textDecoration: "underline #323338",
                    },
                  }),
            }}
          >
            Regresar
          </Button>
          <Button
            endIcon={<ChevronRightIcon />}
            type="button"
            onClick={() => {
              if (value < 2) setTab(value + 1);
            }}
            sx={{
              display: value === 2 ? "none" : "flex",
              fontFamily: "Poppins-SemiBold",
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "capitalize",
              width: "119px",
              height: "49px",
              background: "#1AAFD0",
              color: "#FFFFFF",
              ":hover": {
                background: "#1AAFD0",
              },
            }}
          >
            Siguiente
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
