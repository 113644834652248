import React from 'react';
const ResponsibilityEmail = ({ name_user, id_activity, date_delivery, description, documentation, observation }) => {
  return (
    <div>
        <p>Estimado/a {name_user},</p>
        <p>Es un placer informarte que has sido designado/a como el/a responsable de entregar un requisito en una nueva solicitud dentro de la plataforma DPP</p>
        <p>Detalles clave de la solicitud:</p>
        <ul>
            <li>ID de Actividad: {id_activity}</li>
            <li>Descripción de la solicitud: {description}</li>
        </ul>
        <p>Requisito para el trámite</p>
        <ul>
            <li>Documentación: {documentation}</li>
            <li>Observación: {observation}</li>
            <li>Fecha de entrega de la información: {date_delivery}</li>
        </ul>
        <p>Por favor, ingresa al siguiente enlace <a href={`${process.env.REACT_APP_URL}`}> aquí </a> para revisar la descripción detallada del requisito.</p>
        <p>Si tienes alguna pregunta o necesitas aclaraciones adicionales, no dudes en comunicarte con el solicitante registrado.</p>
        <p>Mantente actualizado/a sobre cualquier desarrollo relacionado con la tarea y asegúrate de cumplir con los plazos establecidos. Apreciamos tu compromiso y dedicación.</p>
        <p>¡Gracias por tu colaboración!</p>
        <p>Saludos cordiales.</p>
    </div>
  );
}

export default ResponsibilityEmail;
