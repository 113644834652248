import React, { useState } from 'react';
import { Box, Grid, Typography, IconButton, Button, Chip} from '@mui/material';
import { Input } from '../Common/MUInput';
import { InputDate } from '../Common/MUIInputDate';
import { MUICheckbox } from '../Common/MUICheckbox'
import { InputAutocompleteAdapter } from '../Common/MUIAutoComplete';
import { InputFile } from '../Common/MUIInputFile'
import { MUIModal } from '../Common/MUIModal'
import { RenderPdf } from '../Common/RenderPdf'
import { TableRequirements } from './TableRequirements'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { MUISkeleton } from '../Common/MUISkeleton';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';

export const FormAgenda = ({
    data, actors, areas, control, errors, isView, getValCheckbox, valCheckbox, handleSetRequirement, fields, remove, OnSubmit, handleSubmit, isLoading,
    hanldeUpdateRequirements, pdf_link, get_pdf_link,handleViewHistory
}) => {
    const [openModal, setOpenModal] = useState(false)   
    const getColorStatusRequirement = (field) => {
        let res = {
            color: '',
            label: '',
        }

        if ((dayjs() < dayjs(field?.delivery_date)) && (!field?.pdf_link)){            
            res.color = '#e6ff28'
            res.label = 'Solicitado'
        }

        if ((dayjs() > dayjs(field?.delivery_date)) && (!field?.pdf_link)){
            res.color = '#f6311b'
            res.label = 'Pendiente'
        }

        if (field?.pdf_link){
            res.color = '#1dfd37'
            res.label = 'Entregado'
        }

        return res
    }

    const styles = {
        label: {
          fontFamily: "Poppins-SemiBold",
          fontSize: "14px",
          fontWeight: "600",
        },
        label_text: {
            height: "40px",
            width: "100px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "capitalize"
        },
        buttonSave: {
            height: "49px",
            width: "100px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            color: "#FFFF",
            textTransform: "capitalize",
            ":hover": {
              background: "#1AAFD0",
            },
        },
        typography: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
            fontWeight: "600",
            color: "#323338",
            "@media (max-width: 790px)": {
              fontSize: "16px",
            },
            "@media (max-width: 590px)": {
              fontSize: "18",
            },
          },
    };

    return (
        <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'}>
            {isLoading ? <Box sx={{ width: '100%' }}>
                <MUISkeleton />
                </Box>
                :
                <Box>
                    {!isView ? 
                        <Box>
                            <Grid container sx={{ flexGrow: 1, mt:1}}>
                                {/* <Grid 
                                    item xs={2} p={1} 
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label}>ID Actividad*</Typography>
                                    <Input 
                                        name="id"
                                        control={control}
                                        props={{
                                            size: 'small',
                                            fullWidth: true,
                                            autoComplete:'none',
                                            disabled: true
                                        }}
                                    />
                                </Grid> */}
                                <Grid 
                                    item xs={3} p={1} 
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label}>Fecha*</Typography>
                                    <InputDate
                                        name={'date'}
                                        control={control}
                                        props={{
                                            fullWidth: true,
                                            size: 'small',
                                        }}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid 
                                    item xs={9} p={1}
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label}>Descripción de la solicitud *</Typography>
                                    <Input 
                                        name="description"
                                        control={control}
                                        props={{
                                            size: 'small',
                                            fullWidth: true,
                                            autoComplete:'none',
                                            disabled: isView
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container sx={{ flexGrow: 1, mt:1}}>
                                <Grid item xs={3} p={1} 
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label}>Actor relacionado *</Typography>
                                    <Box display={'flex'}>
                                        <MUICheckbox
                                            name={'actor_related'}
                                            control={control}
                                            size={'medium'}
                                            label={'Actor relacionado'}
                                            iconClose={true}
                                            props={{
                                                setValueCheck: (value) => getValCheckbox({...valCheckbox, actor: value }),
                                            }}
                                        />
                                        {valCheckbox?.actor === 1 && <InputAutocompleteAdapter
                                            disabled={isView}
                                            size={'small'}
                                            mb={0}
                                            fullWidth={true}
                                            label={''}
                                            keyLabel="label"
                                            keyValue="value"
                                            height={40}
                                            name="related_actor_id"
                                            control={control}
                                            error={errors?.related_actor_id}
                                            options={
                                                actors?.map((x) => ({
                                                    value: x?.id,
                                                    label: x?.name,
                                                })) || []
                                            }
                                        />}
                                    </Box>
                                </Grid>
                                <Grid 
                                    item xs={3} p={1}
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label}>Empresa solicitante*</Typography>
                                    <InputAutocompleteAdapter
                                        disabled={isView}
                                        size={'small'}
                                        mb={0}
                                        fullWidth={true}
                                        keyLabel="label"
                                        keyValue="value"
                                        height={40}
                                        label={''}
                                        name="applicant_company_id"
                                        control={control}
                                        options={
                                            data?.companies?.map((x) => ({
                                                value: x?.id,
                                                label: x?.name,
                                            })) || []
                                        }
                                    />   
                                </Grid>
                                <Grid 
                                    item xs={3} p={1}
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label} pr={1}>Pilar </Typography>
                                    <InputAutocompleteAdapter
                                        disabled={isView}
                                        size={'small'}
                                        mb={0}
                                        fullWidth={true}
                                        keyLabel="label"
                                        keyValue="value"
                                        height={40}
                                        label={''}
                                        name="pillar_id"
                                        control={control}
                                        error={errors?.pillar_id}
                                        options={
                                            data?.pillars?.map((x) => ({
                                                value: x?.id,
                                                label: x?.name,
                                            })) || []
                                        }
                                    />   
                                </Grid>
                                <Grid 
                                    item xs={3} p={1}
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label} pr={1}>Solicitante interno</Typography>
                                    <InputAutocompleteAdapter
                                        disabled={isView}
                                        size={'small'}
                                        mb={0}
                                        fullWidth={true}
                                        keyLabel="label"
                                        keyValue="value"
                                        height={40}
                                        label={''}
                                        name="internal_applicant_id"
                                        control={control}
                                        error={errors?.internal_applicant_id}
                                        options={
                                            data?.users?.map((x) => ({
                                                value: x?.id,
                                                label: `${x?.names} ${x?.last_names} - ${x?.charge}`,
                                            })) || []
                                        }
                                    />   
                                </Grid>
                            </Grid>
                            <Grid container sx={{ flexGrow: 1, mt:1}}>
                                <Grid 
                                    item xs={4} p={1} 
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label} pr={1}>Area</Typography>
                                    <InputAutocompleteAdapter
                                        disabled={isView}
                                        size={'small'}
                                        fullWidth={true}
                                        height={40}
                                        keyLabel="label"
                                        keyValue="value"
                                        label={''}
                                        name="area_id"
                                        control={control}
                                        error={errors?.area_id}
                                        options={
                                            areas?.map((x) => ({
                                                value: x?.id,
                                                label:x?.description,
                                            })) || []
                                        }
                                    />
                                </Grid>
                                <Grid 
                                    item xs={4} p={1}
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label} pr={1}>Responsable</Typography>
                                    <InputAutocompleteAdapter
                                        disabled={isView}
                                        size={'small'}
                                        fullWidth={true}
                                        height={0}
                                        keyLabel="label"
                                        keyValue="value"
                                        label={''}
                                        name="responsible_id"
                                        control={control}
                                        error={errors?.responsible_id}
                                        options={
                                            data?.users?.map((x) => ({
                                                value: x?.id,
                                                label:`${x?.names} ${x?.last_names} - ${x?.charge}`,
                                            })) || []
                                        }
                                    />   
                                </Grid>
                                <Grid item xs={4} p={1} 
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label} pr={1}>Requisitos para el trámite *</Typography>
                                    <Box>
                                        <MUICheckbox
                                            name={'processing_requirements'}
                                            control={control}
                                            size={'medium'}
                                            label={'Requisitos para el trámite'}
                                            iconClose={true}
                                            props={{
                                                setValueCheck: (value) => getValCheckbox({...valCheckbox, requirements: value})
                                            }}
                                        
                                        /> 
                                    </Box>
                                </Grid>
                            </Grid>
                            {valCheckbox?.requirements === 1 && 
                                <Grid container border={1} borderColor={'#dbdbdb'} sx={{ flexGrow: 1, mt:1}} >
                                    <Grid item xs={11} bgcolor={'#e3e3e3'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                                        <Typography sx={styles.typography}>Requisitos</Typography>
                                        {errors?.requirements && <Typography fontSize={11} color={'red'}>{errors?.requirements?.message || errors?.requirements?.root?.message}</Typography>}
                                    </Grid>
                                    <Grid item xs={1} display={'flex'} justifyContent={'center'}>
                                        <IconButton aria-label="add" size='large' onClick={handleSetRequirement}>
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                    {fields?.map((field, i) => (
                                        <Grid container> 
                                            <Grid item xs={2} p={1}
                                                display="flex"
                                                flexDirection="column"
                                                gap={2}
                                            >
                                                <Typography sx={styles.label} pr={1}>Documentación *</Typography>
                                                <Input
                                                    name={`requirements.${i}.documentation`}
                                                    control={control}
                                                    props={{
                                                        size: 'small',
                                                        fullWidth: true,
                                                        autoComplete:'none',
                                                        disabled: isView
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2} p={1}
                                                display="flex"
                                                flexDirection="column"
                                                gap={2}
                                            >
                                                <Typography sx={styles.label} pr={1}>Observación *</Typography>
                                                <Input
                                                    name={`requirements.${i}.observation`}
                                                    control={control}
                                                    props={{
                                                        size: 'small',
                                                        fullWidth: true,
                                                        autoComplete:'none',
                                                        disabled: isView
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2} p={1}
                                                display="flex"
                                                flexDirection="column"
                                                gap={2}
                                            >
                                                <Typography sx={styles.label} pr={1}>Responsable *</Typography>
                                                <InputAutocompleteAdapter
                                                    disabled={isView}
                                                    size={'small'}
                                                    fullWidth={true}
                                                    height={40}
                                                    keyLabel="label"
                                                    keyValue="value"
                                                    label={''}
                                                    name={`requirements.${i}.id_responsible`}
                                                    control={control}
                                                    options={
                                                        data?.users?.map((x) => ({
                                                            value: x?.id,
                                                            label:`${x?.names} ${x?.last_names} - ${x?.charge}`,
                                                        })) || []
                                                    }
                                                />  
                                            </Grid>
                                            <Grid item xs={2} p={1} 
                                                display="flex"
                                                flexDirection="column"
                                                gap={2}
                                            >
                                                <Typography sx={styles.label} pr={1}>Documentación *</Typography>
                                                <InputFile
                                                    activateColor
                                                    name={`requirements.${i}.pdf_link`}
                                                    label={'Documentación'}
                                                    control={control}
                                                    onClick={() =>{}}
                                                    props={{
                                                        height: '40px',
                                                        error: false,
                                                        helperText: ''
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={2} p={1}
                                                display="flex"
                                                flexDirection="column"
                                                gap={2}
                                            >
                                                <Typography sx={styles.label} pr={1}>Fecha de entrega</Typography>
                                                <InputDate
                                                    name={`requirements.${i}.delivery_date`}
                                                    label={''}
                                                    control={control}
                                                    props={{
                                                        fullWidth: true,
                                                        size: 'small',
                                                    }}
                                                    disabled={isView}
                                                />
                                            </Grid>
                                            <Grid item xs={1} p={1} 
                                                display="flex"
                                                flexDirection="column"
                                                gap={2}
                                            >    
                                                <Typography sx={styles.label} pr={1}>Estado</Typography>
                                                <Chip label={getColorStatusRequirement(field)?.label} sx={{bgcolor: getColorStatusRequirement(field)?.color, ...styles.label_text}} />    
                                            </Grid>
                                            <Grid item xs={1} p={1} display={'flex'} justifyContent={'center'}>
                                                <IconButton aria-label="delete" size='large' color='error'  onClick={() => remove(i)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                {isView && 
                                                    <IconButton aria-label="save" size='large' color='success' onClick={() => hanldeUpdateRequirements(field)}>
                                                        <SaveIcon />
                                                    </IconButton>
                                                }
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                            <Grid container sx={{ flexGrow: 1, mt:1}} >
                                <Grid item xs={4} p={1}
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label} pr={1}>Fecha final</Typography>
                                    <InputDate
                                        name={`end_date`}
                                        label={''}
                                        control={control}
                                        props={{
                                            fullWidth: true,
                                            size: 'small',
                                        }}
                                        disabled={isView}
                                    />
                                </Grid>
                                <Grid item xs={8}
                                    p={1}
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label} pr={1}>Comentarios generales</Typography>
                                    <Input
                                        name={`general_comments`}
                                        control={control}
                                        props={{
                                            size: 'small',
                                            fullWidth: true,
                                            label: "Comentarios generales",
                                            autoComplete:'none',
                                            disabled: isView
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Box p={1} display={'flex'} justifyContent={'center'} mt={2}>
                                <Button sx={styles.buttonSave} variant='contained' onClick={handleSubmit(OnSubmit)}>Guardar</Button>
                            </Box>
                        </Box>
                    :
                        <Box>
                            <Grid container border={1} borderColor={'#dbdbdb'} >
                                <Grid item xs={11} bgcolor={'#e3e3e3'} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
                                    {/* <Typography fontSize={24}>Requisitos</Typography>*/}
                                    {errors?.requirements && <Typography fontSize={11} color={'red'}>{errors?.requirements?.message || errors?.requirements?.root?.message}</Typography>}
                                </Grid>
                               {/*  <Grid item xs={1} display={'flex'} justifyContent={'center'}>
                                    <IconButton aria-label="add" size='large' onClick={handleSetRequirement}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid> */} 
                                <TableRequirements 
                                    fields={fields} 
                                    control={control} 
                                    isView={isView}
                                    handleViewHistory={handleViewHistory}
                                    setOpenModal={setOpenModal}
                                    get_pdf_link={get_pdf_link}
                                    hanldeUpdateRequirements={hanldeUpdateRequirements}
                                />
                            </Grid>
                        </Box>
                    }                    
                </Box>
            }
            <MUIModal 
                open={openModal}
                handleClose={() => setOpenModal(false)}
            >   
                <Tooltip title="Descargar documento" arrow>
                    <Button
                        onClick={() => window.open(pdf_link, '_blank')}
                    >
                        <DownloadIcon/>
                    </Button>
                </Tooltip>
                {openModal && <RenderPdf url={pdf_link}/>}
            </MUIModal>
        </Box>
    )
}