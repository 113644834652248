import React, { useEffect, useState } from "react";
import { OTPVerificationV } from "../../components/OTPVerification/";
import { POST } from "../../utils/AuthService";
import { UseStoreLogin } from "./../../stores/Login";
import { UseStoreTemplateView } from "./../../stores/TemplateView";
// import { SwalFire } from "./../../utils";
import { useNavigate } from "react-router-dom";
import { useAlertStore } from "../../components/Common/AlertModal/store";

export const OTPVerificationC = (props) => {
  const alertTool = useAlertStore((state) => state.alertTool);
  const [userMail, setUserMail] = useState("");
  const updateUserInfo = UseStoreLogin((state) => state.updateUserInfo);
  const get_image_user = UseStoreTemplateView((state) => state.get_image_user);
  const navigate = useNavigate();

  useEffect(() => {
    !props.graphData && props.RequestProfileData();
    const email = props?.graphData?.userPrincipalName.split("#")[0];
    process.env.REACT_APP_ENV !== "DEV"
      ? setUserMail(email)
      : setUserMail(email?.replace(/_/g, "@"));
  }, [props, userMail]);

  const verificarOtp = async (otp) => {
    try {
      const response = await POST("verify-2fa", { email: userMail, otp: otp });
      if (response?.statusCode === 200) {
        localStorage.setItem("authToken", response.data.access_token);
        localStorage.setItem("refreshToken", response.data.access_token);
        const user_data = await POST("search-user", { email: userMail });
        if (user_data?.statusCode === 200) {
          updateUserInfo(user_data?.data);
          if (user_data?.data) {
            get_image_user(user_data?.data?.image);
            navigate("/home");
          }
        } else {
          //   SwalFire(
          //     "Advertencia",
          //     "Usuario no encontrado, verifique su OTP",
          //     "warning"
          //   );
          alertTool("Usuario no encontrado, verifique su OTP.", {
            type: "error",
            title: "Advertencia",
          });
        }
      } else {
        // SwalFire("Advertencia", "OTP invalido", "warning");
        alertTool(
          "Lo sentimos, pero no hemos podido verificar este código. Asegúrate de que has ingresado el código correcto.",
          {
            type: "error",
            title: "Error de verificación",
          }
        );
      }
      return true;
    } catch (error) {
      //   SwalFire("Advertencia", "Hubo un error al verificar el OTP", "warning");
      alertTool("Hubo un error al verificar el OTP.", {
        type: "error",
        title: "Advertencia",
      });
      console.error("Hubo un error al verificar el OTP: ", error);
      return false;
    }
  };
  return <OTPVerificationV verificarOtp={verificarOtp} email={userMail} />;
};
