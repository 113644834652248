import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  DataGrid,
  esES,
  gridClasses,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

const escapeRegExp = (value) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

const InputSearch = ({ value, onChange, clearSearch }) => {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Box>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </Box>
      <TextField
        variant="outlined"
        value={value}
        onChange={onChange}
        size="small"
        placeholder="Buscar..."
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Limpiar"
              aria-label="Limpiar"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export const TableList = ({
  columns,
  data,
  isLoading = false,
  height = false,
  handleRowClick,
  processRowUpdate,
  handleCellClick,
  search = true,
}) => {
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = useState(data);
  const Height = height ? height : data?.length ? 700 : 300;
  let account = 1;

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter((row) => {
      // eslint-disable-next-line array-callback-return
      return Object.keys(row).some((field) => {
        if (row[field] !== null) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <Box style={{ height: Height, width: "100%" }}>
      <DataGrid
        fied
        slots={{ toolbar: search ? InputSearch : null }}
        slotProps={{
          toolbar: {
            value: searchText,
            onChange: (e) => requestSearch(e.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [],
            },
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        disableColumnFilter
        disableDensitySelector
        disableRowSelectionOnClick
        disableColumnSelector
        sx={{
          // padding: 1,
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: 16,
          },
          [`& .${gridClasses.cell}`]: {
            cursor: "pointer",
            py: 1.5,
          },
        }}
        getRowId={(row) => (account += 1)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSizeOptions={[10, 25, 50, 100]}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowHeight={() => "auto"}
        onRowClick={(row) => handleRowClick(row)}
        onCellClick={(params) => handleCellClick(params)}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  );
};
