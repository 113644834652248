import {
  Avatar,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { InputAutocompleteAdapter } from "../../Common/MUIAutoComplete";
import { InputDate } from "../../Common/MUIInputDate";
import { InputFile } from "../../Common/MUIInputFile";
import { Input } from "../../Common/MUInput";
import CopyColorIcon from "./../../../assets/icons/copyColorIcon.svg";
import CopyIcon from "./../../../assets/icons/copyIcon.svg";
import openIcon from "./../../../assets/icons/openIcon.svg";
import PdfIcon from "./../../../assets/icons/pdfIcon.svg";
import PhotoIcon from "./../../../assets/icons/photoIcon.svg";

export const CompanyInformation = ({
  isView,
  control,
  logo_company,
  handle_upload_edit_file,
  errors,
  setOpenModal,
  sector_companies,
  handleViewGazette,
  linkGazette,
  handleChangeZipCode,
  countries,
  share_link,
  org_chart_link,
  handleCopy,
}) => {
  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
  };

  return (
    <Stack>
      <Grid container columnSpacing={2} rowGap={2} columns={12} pt="5px">
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Información de la empresa
          </Typography>
          <Typography sx={styles.label}>Nombre de la empresa*</Typography>
          <Input
            name="name"
            control={control}
            props={{
              size: "small",
              fullWidth: true,
              required: true,
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap={2}
        >
          <Typography sx={styles.label}>Logo empresarial</Typography>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            {(logo_company?.data?.file_type === "png" ||
              logo_company?.data?.file_type === "jpg") && (
              <Tooltip title="previsualizar imagen">
                <Avatar
                  onClick={() =>
                    setOpenModal({
                      open: true,
                      type: 1,
                      url: logo_company?.data?.url,
                    })
                  }
                  sx={{
                    width: "70px",
                    height: "70px",
                    border: "1px solid #aaa",
                    ":hover": { cursor: "pointer" },
                  }}
                  variant="rounded"
                  src={logo_company?.data?.url}
                />
              </Tooltip>
            )}
            <InputFile
              activateColor
              name="url_logo"
              label="Elegir archivo"
              control={control}
              onClick={() => handle_upload_edit_file("url_logo")}
              props={{
                error: errors?.url_logo ? true : false,
                helperText: errors?.url_logo?.message,
                disabled: isView,
              }}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Denominación social*</Typography>
          <Input
            name="social_name"
            control={control}
            props={{
              size: "small",
              fullWidth: true,
              required: true,
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>RFC*</Typography>
          <Input
            name="rfc"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Fecha de constitución</Typography>
          <InputDate
            name={"date_of_constitution"}
            control={control}
            error={Boolean(errors?.date_of_constitution)}
            errMsg={errors?.date_of_constitution?.message}
            disabled={isView}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Actividad</Typography>
          <Input
            name="activity"
            control={control}
            props={{
              size: "small",
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Sector*</Typography>
          <InputAutocompleteAdapter
            disabled={isView}
            mb={0}
            keyLabel="label"
            keyValue="value"
            height={40}
            label=""
            name="sector_id"
            control={control}
            error={errors?.sector}
            options={
              sector_companies?.map((x) => ({
                value: x?.id,
                label: x?.name,
              })) || []
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          gap={2}
          alignItems="center"
        >
          <Typography width="200px" sx={styles.label}>
            Gaceta empresarial
          </Typography>
          <Input
            name="business_gazette"
            control={control}
            props={{
              size: "small",
              fullWidth: true,
              autoComplete: "none",
              disabled: isView,
            }}
          />
          <Button
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "14px",
              fontWeight: "400",
              width: "107px",
              minWidth: "107px",
              color: "#323338",
              textTransform: "capitalize",
              ":hover": {
                cursor: linkGazette ? "pointer" : "no-drop",
                background: "#EAE4E7",
              },
            }}
            startIcon={<img src={openIcon} alt="openIcon" />}
            onClick={handleViewGazette}
            disabled={!linkGazette}
          >
            Abrir
          </Button>
          <Button
            onClick={handleCopy}
            disabled={!linkGazette}
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "14px",
              fontWeight: "400",
              width: "142px",
              minWidth: "142px",
              color: "#323338",
              textTransform: "capitalize",
              "& .CopyColorIcon": {
                display: "none",
              },
              ":hover": {
                cursor: linkGazette ? "pointer" : "no-drop",
                background: "#1AAFD0",
                color: "#FFFF",
                "& .CopyIcon": {
                  display: "none",
                },
                "& .CopyColorIcon": {
                  display: "block",
                },
              },
            }}
            startIcon={
              <>
                <img className="CopyIcon" src={CopyIcon} alt="CopyIcon" />
                <img
                  className="CopyColorIcon"
                  src={CopyColorIcon}
                  alt="CopyColorIcon"
                />
              </>
            }
          >
            Copiar link
          </Button>
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
          mt: 3,
        }}
      >
        Domicilio fiscal
      </Typography>
      <Grid container columnSpacing={2} rowGap={2} columns={12} pt="5px">
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Domicilio</Typography>
          <Input
            name="address"
            control={control}
            props={{
              size: "small",
              fullWidth: true,
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Código postal</Typography>
          <Input
            name="zip_code"
            control={control}
            props={{
              size: "small",
              autoComplete: "none",
              disabled: isView,
              customOnchange: true,
              onChange: handleChangeZipCode,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Municipio</Typography>
          <Input
            name="town"
            control={control}
            props={{
              size: "small",
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Estado</Typography>
          <Input
            name="state"
            control={control}
            props={{
              size: "small",
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>País</Typography>
          <InputAutocompleteAdapter
            disabled={isView}
            mb={0}
            keyLabel="label"
            keyValue="value"
            height="1.4375em"
            name="country_id"
            control={control}
            error={errors?.country_id}
            options={
              countries?.map((x) => ({
                value: x?.id,
                label: x?.description,
              })) || []
            }
          />
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
          mt: 3,
        }}
      >
        Estructura accionaria
      </Typography>
      <Grid container columnSpacing={2} rowGap={2} columns={12} pt="10px">
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Stack
            gap={1}
            minHeight="133px"
            sx={{
              "& .see_file": {
                display: "none",
              },
              ":hover": {
                "& .see_file": {
                  display: "block",
                },
              },
            }}
          >
            <Stack gap={2} flexDirection="row" alignItems="center">
              <Avatar
                sx={{
                  width: "95px",
                  height: "95px",
                  background: share_link?.data?.url ? "#1AAFD0" : "#b5bac9",
                }}
              >
                <img src={PdfIcon} alt="PdfIcon" />
              </Avatar>
              <InputFile
                activateColor
                name="shares_link"
                label="Elegir archivo"
                control={control}
                onClick={() => handle_upload_edit_file("shares_link")}
                props={{
                  error: errors?.shares_link ? true : false,
                  helperText: errors?.shares_link?.message,
                  disabled: isView,
                }}
              />
            </Stack>
            {share_link?.data?.url && (
              <Button
                variant="outlined"
                className="see_file"
                type="button"
                onClick={() =>
                  setOpenModal({
                    open: true,
                    type: share_link?.data?.file_type === "pdf" ? 2 : 1,
                    url: share_link?.data?.url,
                  })
                }
                sx={{
                  ml: 4,
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  fontWeight: "400",
                  width: "117px",
                  minWidth: "117px",
                  border: "1px solid #DDDDDD",
                  height: "30px",
                  color: "#323338",
                  textTransform: "capitalize",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                }}
              >
                Ver archivo
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
          mt: 3,
        }}
      >
        Organigrama empresarial
      </Typography>
      <Grid container columnSpacing={2} rowGap={2} columns={12} pt="10px">
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Stack
            gap={1}
            minHeight="133px"
            sx={{
              "& .see_file": {
                display: "none",
              },
              ":hover": {
                "& .see_file": {
                  display: "block",
                },
              },
            }}
          >
            <Stack gap={2} flexDirection="row" alignItems="center">
              <Avatar
                sx={{
                  width: "95px",
                  height: "95px",
                  background: org_chart_link?.data?.url ? "#1AAFD0" : "#b5bac9",
                }}
              >
                <img src={PhotoIcon} alt="PhotoIcon" />
              </Avatar>
              <InputFile
                activateColor
                name="org_chart_link"
                label={"Elegir archivo"}
                control={control}
                onClick={() => handle_upload_edit_file("org_chart_link")}
                props={{
                  error: errors?.org_chart_link ? true : false,
                  helperText: errors?.org_chart_link?.message,
                  disabled: isView,
                }}
              />
            </Stack>
            {org_chart_link?.data?.url && (
              <Button
                className="see_file"
                variant="outlined"
                type="button"
                onClick={() =>
                  setOpenModal({
                    open: true,
                    type: org_chart_link?.data?.file_type === "pdf" ? 2 : 1,
                    url: org_chart_link?.data?.url,
                  })
                }
                sx={{
                  display: org_chart_link?.data?.url ? "block" : "none",
                  ml: 4,
                  fontFamily: "Poppins-Regular",
                  fontSize: "12px",
                  fontWeight: "400",
                  width: "117px",
                  minWidth: "117px",
                  border: "1px solid #DDDDDD",
                  height: "30px",
                  color: "#323338",
                  textTransform: "capitalize",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                }}
              >
                Ver archivo
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
