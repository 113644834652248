import React, { useState } from 'react';
import { Box, Grid, Typography, Button, LinearProgress } from '@mui/material'
import { ColorPalette } from '../Common/ColorPalette';
import SaveIcon from '@mui/icons-material/Save';

export const PaletteV = ({
    vars: {
        isLoading, 
        statusActivityCurrent,  controlInProgress,
        statusActivityCurrent2, controlStopped,
        statusActivityClosed,   controlCanceled,
        statusActivityExpired,  controlCompleted
    },
    funcs: {
        OnSubmit, 
        setStatusActivityCurrent,   setControlInProgress, 
        setStatusActivityCurrent2,  setControlStopped, 
        setStatusActivityClosed,    setControlCanceled,
        setStatusActivityExpired,   setControlCompleted
    }
}) => {
    //Show palette activities
    const [showPaletteCurrent, setShowPaletteCurrent] = useState(false)
    const [showPaletteCurrent2, setShowPaletteCurrent2] = useState(false)
    const [showPaletteClosed, setShowPaletteClosed] = useState(false)
    const [showPaletteExpired, setShowPaletteExpired] = useState(false)
    //show palettes control
    const [showPaletteInProgress, setShowPaletteInProgress] = useState(false)
    const [showPaletteStopped, setShowPaletteStopped] = useState(false)
    const [showPaletteCanceled, setShowPaletteCanceled] = useState(false)
    const [showPaletteCompleted, setShowPaletteCompleted] = useState(false)

    const styles = {
        label: {
          fontFamily: "Poppins-SemiBold",
          fontSize: "14px",
          fontWeight: "600",
        },
        buttonSave: {
            height: "49px",
            width: "100px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            color: "#FFFF",
            textTransform: "capitalize",
            ":hover": {
              background: "#1AAFD0",
            },
        },
        typography: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "30px",
            fontWeight: "600",
            color: "#323338",
            "@media (max-width: 790px)": {
              fontSize: "24px",
            },
            "@media (max-width: 590px)": {
              fontSize: "20px",
            },
        },
        grid_container: {
            display: "flex",
            alignItems: "center",
            flexGrow: 1, mt:1,
            height: "auto",
            overflow: "auto",
            width: "100%",
            borderRadius: "7px 7px 0px 0px",
            bgcolor: "#FFFFFF",
        }
    };

    return (
        <Box width={'100%'} height={'100%'} bgcolor={'#fdfdfd'}>
            {isLoading && <LinearProgress /> }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                    height: "100%",
                    background: "#f6f7fb",
                    pl: 1,
                }}    
            >
                <Box
                    height="40px"
                    bgcolor="#ffffff"
                    p={2}
                    pr={8}
                    borderRadius="0px 0px 0px 7px"
                    justifyContent="space-between"
                    display="flex"
                    alignItems="center"
                    boxShadow="0px 3px 4px 0px #2222221A"
                >
                    <Typography
                    sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#323338",
                    }}
                    >
                        Estado de la actividad
                    </Typography>
                    <Button
                        startIcon={<SaveIcon />}
                        type="button"
                        onClick={OnSubmit}
                        sx={{
                            fontFamily: "Poppins-SemiBold",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            width: "119px",
                            height: "49px",
                            background: "#1AAFD0",
                            color: "#FFFFFF",
                            ":hover": {
                                background: "#1AAFD0",
                            },
                        }}
                    >
                        Guardar
                    </Button>
                </Box>
                <Grid container sx={styles.grid_container}>
                    <Grid container >
                        <Grid item xs={3} p={1} >
                            <ColorPalette 
                                label={'Vigente'}
                                color={statusActivityCurrent} 
                                setColor={setStatusActivityCurrent}
                                showPalette={showPaletteCurrent}
                                setShowPalette={setShowPaletteCurrent}
                            />
                        </Grid>
                        <Grid item xs={4} p={1}>
                            <ColorPalette 
                                label={'Vigente 5 días antes de su fecha de vencimiento.'}
                                color={statusActivityCurrent2} 
                                setColor={setStatusActivityCurrent2}
                                showPalette={showPaletteCurrent2}
                                setShowPalette={setShowPaletteCurrent2}
                            />
                        </Grid>
                        <Grid item xs={2} p={1}>
                            <ColorPalette 
                                label={'Cerrado'}
                                color={statusActivityClosed} 
                                setColor={setStatusActivityClosed}
                                showPalette={showPaletteClosed}
                                setShowPalette={setShowPaletteClosed}
                            />
                        </Grid>
                        <Grid item xs={3} p={1}>
                            <ColorPalette 
                                label={'Vencido'}
                                color={statusActivityExpired} 
                                setColor={setStatusActivityExpired}
                                showPalette={showPaletteExpired}
                                setShowPalette={setShowPaletteExpired}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    height="40px"
                    bgcolor="#ffffff"
                    p={2}
                    pr={8}
                    borderRadius="0px 0px 0px 7px"
                    justifyContent="space-between"
                    display="flex"
                    alignItems="center"
                    boxShadow="0px 3px 4px 0px #2222221A"
                >
                    <Typography
                    sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#323338",
                    }}
                    >
                        Control
                    </Typography>
                </Box>
                <Grid container sx={styles.grid_container}>
                    <Grid container >
                        <Grid item xs={3} p={1}>
                            <ColorPalette 
                                label={'En progreso'}
                                color={controlInProgress} 
                                setColor={setControlInProgress}
                                showPalette={showPaletteInProgress}
                                setShowPalette={setShowPaletteInProgress}
                            />
                        </Grid>
                        <Grid item xs={3} p={1}>
                            <ColorPalette 
                                label={'Detenido'}
                                color={controlStopped} 
                                setColor={setControlStopped}
                                showPalette={showPaletteStopped}
                                setShowPalette={setShowPaletteStopped}
                            />
                        </Grid>
                        <Grid item xs={3} p={1}>
                            <ColorPalette 
                                label={'Cancelado'}
                                color={controlCanceled} 
                                setColor={setControlCanceled}
                                showPalette={showPaletteCanceled}
                                setShowPalette={setShowPaletteCanceled}
                            />
                        </Grid>
                        <Grid item xs={3} p={1}>
                            <ColorPalette 
                                label={'Completado'}
                                color={controlCompleted} 
                                setColor={setControlCompleted}
                                showPalette={showPaletteCompleted}
                                setShowPalette={setShowPaletteCompleted}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}