import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { Input } from '../Common/MUInput';
import { InputFile } from '../Common/MUIInputFile';
import { InputDate } from '../Common/MUIInputDate';
import ChatIcon from '@mui/icons-material/Chat';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';
import HistoryIcon from '@mui/icons-material/History';
import dayjs from 'dayjs';

export const TableRequirements = ({fields, control, isView, handleViewHistory, get_pdf_link, setOpenModal, hanldeUpdateRequirements}) => {

    const getColorStatusRequirement = (field) => {
        let res = {
            color: '',
            label: '',
        }

        if ((dayjs() < dayjs(field?.delivery_date)) && (!field?.pdf_link)){            
            res.color = '#e6ff28'
            res.label = 'Solicitado'
        }

        if ((dayjs() > dayjs(field?.delivery_date)) && (!field?.pdf_link)){
            res.color = '#f6311b'
            res.label = 'Pendiente'
        }

        if (field?.pdf_link){
            res.color = '#1dfd37'
            res.label = 'Entregado'
        }

        return res
    }

    const styles = {
        label: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "600",
        },
        header_text: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "600",
            color: "#323338",
        },
        cell_text: {
            fontFamily: "Poppins-Regular",
            fontSize: "14px",
            fontWeight: "400",
            color: "#323338",
        },
        button_save:{
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            color: "#FFFF",
            background: "#1AAFD0",
            textTransform: "capitalize",
            ":hover": {
                color: "#1AAFD0",
            }
        },
        label_text: {
            height: "40px",
            width: "100px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "capitalize"
        },
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{bgcolor:'#e3e3e3'}}>
                    <TableRow>
                        <TableCell sx={styles.header_text}>Documentación</TableCell>
                        <TableCell sx={styles.header_text}>Observación</TableCell>
                        <TableCell sx={styles.header_text}>Responsable</TableCell>
                        <TableCell sx={styles.header_text}>Documento PDF</TableCell>
                        <TableCell sx={styles.header_text}>Fecha de entrega</TableCell>
                        <TableCell sx={styles.header_text}>Estado</TableCell>
                        <TableCell sx={styles.header_text}>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {fields?.length ? 
                    fields?.map((field, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                {field?.documentation ? 
                                    <Typography sx={styles.cell_text}> {field?.documentation} </Typography>
                                :
                                    <Box
                                        p={1}
                                        display="flex"
                                        flexDirection="column"
                                        gap={2}
                                    >
                                        <Typography sx={styles.label} pr={1}>Documentación *</Typography>
                                        <Input
                                            name={`requirements.${i}.documentation`}
                                            control={control}
                                            props={{
                                                size: 'small',
                                                fullWidth: true,
                                                autoComplete:'none',
                                                disabled: isView
                                            }}
                                        />
                                    </Box>
                                }
                            </TableCell>
                            <TableCell>
                                <Box 
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                >
                                    <Typography sx={styles.label} pr={1}>Nueva observación</Typography>
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        {field?.observation && 
                                            <Box mt={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                <Tooltip title="Ver comentarios">  
                                                    <IconButton aria-label="" onClick={()=> handleViewHistory(field)}>
                                                        <ChatIcon /> 
                                                    </IconButton>
                                                    {/* <Typography fontSize={14}>
                                                        {field?.observation}
                                                    </Typography> */}
                                                </Tooltip>
                                            </Box>
                                        } 
                                        <Input
                                            name={`requirements.${i}.new_observation`}
                                            control={control}
                                            props={{
                                                size: 'small',
                                                fullWidth: true,
                                                autoComplete:'none',
                                                disabled: false
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell>
                                {field?.id_responsible && <Typography sx={styles.cell_text}> {`${field?.responsible?.names} ${field?.responsible?.last_names}`} </Typography> }
                            </TableCell>
                            <TableCell>
                                <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
                                    <InputFile
                                        activateColor
                                        name={`requirements.${i}.pdf_link`}
                                        control={control}
                                        onClick={() =>{}}
                                        props={{
                                            error: false,
                                            helperText: ''
                                        }}
                                    />
                                    {field?.pdf_link &&
                                        <Tooltip  title="previsualizar pdf" sx={{ml:1}}>
                                            <IconButton 
                                                sx={{ width: 56, height: 56 }} 
                                                onClick={() => {
                                                    setOpenModal(true)
                                                    get_pdf_link(field?.pdf_link)
                                                }}
                                            >
                                                <PictureAsPdfIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    <Tooltip title="Ver documentos">  
                                        <IconButton aria-label="" onClick={()=> handleViewHistory(field, 3)}>
                                            <HistoryIcon /> 
                                        </IconButton>
                                        {/* <Typography fontSize={14}>
                                            {field?.observation}
                                        </Typography> */}
                                    </Tooltip>
                                </Box>
                            </TableCell>
                            <TableCell>
                                {field?.delivery_date ? 
                                    <Typography sx={styles.cell_text}> 
                                        {dayjs(field?.delivery_date).locale('es').format('dddd, D MMMM YYYY')}
                                    </Typography>
                                :
                                    <InputDate
                                        name={`requirements.${i}.delivery_date`}
                                        label={'Fecha de entrega'}
                                        control={control}
                                        props={{
                                            fullWidth: true,
                                            size: 'medium',
                                        }}
                                        disabled={isView}
                                    />
                                }
                            </TableCell>
                            <TableCell>
                                <Chip label={getColorStatusRequirement(field)?.label} sx={{bgcolor: getColorStatusRequirement(field)?.color, ...styles.label_text}} />    
                            </TableCell>
                            <TableCell> 
                            {isView && 
                                <IconButton sx={styles.button_save} aria-label="save" size='large' onClick={() => hanldeUpdateRequirements(field, i)}>
                                    <SaveIcon />
                                </IconButton>
                            }
                            </TableCell>
                        </TableRow>
                    ))
                :
                    <TableRow>
                        <TableCell sx={styles.cell_text}>NO APLICA</TableCell>
                        <TableCell sx={styles.cell_text}>NO APLICA</TableCell>
                        <TableCell sx={styles.cell_text}>NO APLICA</TableCell>
                        <TableCell sx={styles.cell_text}>NO APLICA</TableCell>
                        <TableCell sx={styles.cell_text}>NO APLICA</TableCell>
                        <TableCell sx={styles.cell_text}>NO APLICA</TableCell>
                    </TableRow>
                    }
                </TableBody>
          </Table>
        </TableContainer>
      );
}