import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Tab from "@mui/material/Tab";
import React, { useState } from "react";
import { PermissionList } from "./../Common/PermissionList";
import { SelectInput } from "./../Common/Select";

export const PermissionsV = ({
  data: {
    vars: {
      type_user,
      companies,
      users,
      permissionTemp,
      enabledButtons,
      dataSelects,
      isLoading,
      isLoadingData,
      isBaordActive,
      rolesEnableds,
    },
    funcs: {
      handleSave,
      handleOnBlur,
      handleChangePermission,
      handleCancel,
      handleChangeSelect,
      setPermissionsTemp,
      setDataSelects,
      setEnabledButtons,
      disabledModule,
    },
  },
}) => {
  //Cambia los estadaos de los tabs
  const [value, setValue] = useState("1");
  const [label, setLabel] = useState("Rol");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLabel(newValue === "2" ? "Usuario" : "Rol");
    setPermissionsTemp([]);
    setEnabledButtons(false);
    setDataSelects({
      rol_id: 0,
      companie_id: 0,
      user_id: 0,
      companie_user_id: 0,
    });
  };

  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
  };

  return type_user === 1 ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        height: "100vh",
        background: "#f6f7fb",
        pl: 1,
      }}
    >
      <Box
        height="40px"
        bgcolor="#ffffff"
        p={2}
        pr={8}
        borderRadius="0px 0px 0px 7px"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        boxShadow="0px 3px 4px 0px #2222221A"
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#323338",
          }}
        >
          Nuevo usuario
        </Typography>
      </Box>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Box
        sx={{
          height: "100%",
          width: "95%",
          bgcolor: "#FFFFFF",
          borderRadius: "7px 7px 0px 0px",
          p: "0px 16px",
          "& .options-className": {
            borderLeft: "3px solid #E2E4E7",
          },
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="Tabs para controlar permisos"
            >
              <Tab label="Roles" value="1" />
              <Tab label="Usuarios" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "24px 0px" }}>
            <Grid container columnSpacing={2} rowGap={3} columns={12} pt="5px">
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <Typography sx={styles.label}>Rol</Typography>
                <SelectInput
                  placeholder={"jajaja"}
                  width="100%"
                  rows={rolesEnableds}
                  name={"rol_id"}
                  value_selected={dataSelects?.rol_id}
                  handleChange={handleChangeSelect}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <Typography sx={styles.label}>Empresa</Typography>
                <SelectInput
                  rows={companies}
                  name={"companie_id"}
                  value_selected={dataSelects?.companie_id}
                  handleChange={handleChangeSelect}
                  props={{
                    onBlur: () => handleOnBlur(false, 1),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <Typography sx={styles.label}>Módulo</Typography>
                <SelectInput
                  rows={[
                    { id: 1, name: "Modulo Actos Corporativos" },
                    { id: 2, name: "Modulo Actos Gubernamentales" },
                    { id: 3, name: "Modulo Activos" },
                    { id: 4, name: "Modulo Contratos" },
                    ]}
                  name={"module_id"}
                  value_selected={dataSelects?.module_id}
                  handleChange={handleChangeSelect}
                  props={{
                    onBlur: () => handleOnBlur(false, 1),
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "24px 0px" }}>
            <Grid container columnSpacing={2} rowGap={3} columns={12} pt="5px">
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <Typography sx={styles.label}>Usuarios</Typography>
                <SelectInput
                  rows={users?.map((x) => ({ id: x?.id, name: x?.names + " " + x?.last_names}))}
                  name={"user_id"}
                  value_selected={dataSelects?.user_id}
                  handleChange={handleChangeSelect}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <Typography sx={styles.label}>Empresa</Typography>
                <SelectInput
                  rows={companies}
                  name={"companie_user_id"}
                  value_selected={dataSelects?.companie_user_id}
                  handleChange={handleChangeSelect}
                  props={{
                    onBlur: () => handleOnBlur(true, 2),
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
        <PermissionList
          label={label}
          handleSave={handleSave}
          assign_user={label === "Usuario" ? true : false}
          enabledButtons={enabledButtons}
          handleCancel={handleCancel}
          handleChangePermission={handleChangePermission}
          permissionTemp={permissionTemp}
          isLoadingData={isLoadingData}
          disabledModule={disabledModule}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isBaordActive}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  ) : (
    <h1>No eres admin</h1>
  );
};
