import CloseIcon from "@mui/icons-material/Close";
import { Button, Divider, IconButton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import errorIcon from "../../../assets/icons/alert/errorIcon.svg";
import infoIcon from "../../../assets/icons/alert/infoIcon.svg";
import questionIcon from "../../../assets/icons/alert/questionIcon.svg";
import successIcon from "../../../assets/icons/alert/successIcon.svg";
import warningIcon from "../../../assets/icons/alert/warningIcon.svg";
import { useAlertStore } from "./store";

export const AlertTool = () => {
  const { handleClose, open, options, message } = useAlertStore();
  const debounced = useDebouncedCallback(handleClose, 2000);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "480px",
    bgcolor: "background.paper",
    borderRadius: 2,
  };

  const getIcon = (type) => {
    switch (type) {
      case "question":
        return questionIcon;
      case "warning":
        return warningIcon;
      case "success":
        return successIcon;
      case "info":
        return infoIcon;
      case "error":
        return errorIcon;
      default:
        return successIcon;
    }
  };

  useEffect(() => {
    if (open && options?.autoClose) debounced();
  }, [open, debounced, options?.autoClose]);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack alignItems="start">
            <IconButton type="button" onClick={handleClose}>
              <CloseIcon sx={{ color: "#323338", fontSize: 20 }} />
            </IconButton>
          </Stack>
          <Divider />
          <Stack p="24px" alignItems="center" gap={2}>
            <img
              width="55px"
              height="55px"
              src={getIcon(options?.type)}
              alt={options?.type}
            />
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                fontWeight: "600",
                color: "#323338",
                textAlign: "center",
              }}
            >
              {options?.title || ""}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins-Regular",
                fontSize: "16px",
                fontWeight: "400",
                color: "#323338",
                textAlign: "center",
              }}
            >
              {message || ""}
            </Typography>
            {options?.button !== "none" && (
              <Button
                type="button"
                onClick={handleClose}
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "14px",
                  height: "49px",
                  fontWeight: "500",
                  color: "#FFFFFF",
                  width: "100%",
                  background: "#1AAFD0",
                  ":hover": {
                    background: "#1AAFD0",
                  },
                }}
              >
                Aceptar
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};
