import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

export const FilledAlerts = ({msg, type, setOpen, open}) => {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
        <Collapse in={open}>
            <Alert  
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                } 
                variant="filled" 
                severity={type}
            >
                {msg && `${msg}!`}
            </Alert> 
        </Collapse>
    </Stack>
  );
}