import {
  Button,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import TableIcon from "./../../../assets/icons/tableIcon.svg";

export const Pillars = ({
  pillars_preview,
  isView,
  handleChangePillar,
  company_id,
  handleClickPreviewPillars,
}) => {
  const styles = {
    button: {
      display: "flex",
      justifyContent: "start",
      width: "100%",
      color: "#323338",
      borderColor: "#DDDDDD",
      fontFamily: "Poppins-SemiBold",
      fontWeight: "50",
      fontSize: "14px",
      height: "52px",
      textTransform: "capitalize",
      ":hover": {
        borderColor: "#DDDDDD",
        background: "#B5BAC9",
      },
      label: {
        fontFamily: "Poppins-SemiBold",
        fontSize: "14px",
        fontWeight: "600",
        color: "#323338",
      },
    },
  };
  useEffect(() => {
    if (!pillars_preview || pillars_preview.length === 0) {
      // Si no hay información en pillars_preview, recargar la página
      window.location.reload();
    }
  }, [pillars_preview]);
  return (
    <Stack>
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        Pilares
      </Typography>
      <Grid container columnSpacing={2} rowGap={2} columns={12} pt="5px">
        {pillars_preview?.map((item) => (
          <Grid
            key={item?.id}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            display="flex"
            flexDirection="column"
            gap={3}
          >
            <Button
              disabled={!(isView || company_id)}
              startIcon={<img src={TableIcon} alt="TableIcon" />}
              type="button"
              variant="outlined"
              sx={styles?.button}
              onClick={() => handleClickPreviewPillars(company_id, item?.code)}
            >
              {item?.name}
            </Button>
            <FormGroup>
              {item?.sub_pillars?.length
                ? item?.sub_pillars?.map((sub_item) => (
                    <Stack
                      key={sub_item?.id}
                      alignItems="center"
                      flexDirection="row"
                      gap={1}
                    >
                      <Switch
                        checked={sub_item?.active}
                        disabled={isView}
                        name={sub_item?.code}
                        readOnly={isView}
                        onChange={(e) =>
                          handleChangePillar(
                            {
                              pillar_id: item?.id,
                              subpillar_id: sub_item?.id,
                            },
                            e
                          )
                        }
                      />
                      <Typography sx={styles?.label}>
                        {sub_item?.name}
                      </Typography>
                    </Stack>
                  ))
                : [1, 2, 3, 4]?.map((item) => (
                    <Stack
                      key={item}
                      alignItems="center"
                      flexDirection="row"
                      gap={1}
                    >
                      <Switch disabled />
                      <Typography sx={styles?.label}>Por definir</Typography>
                    </Stack>
                  ))}
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
