import React, {useState, useEffect} from 'react'
import { set, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserSchema } from '../../schemas/UserSchema'
import { UsersV } from '../../components/Users'
import { UseStoreUsers } from '../../stores/Users'
import { UseStoreRoles } from '../../stores/Roles'
import { UseStoreTemplateView } from '../../stores/TemplateView'
import { readFile, uploadFile } from './../../utils/AWS'
import { SwalFire, verifyImageDimensions } from './../../utils/'
import { WelcomeUserMailer } from '../../utils/Mailer'

export const UsersC = ({children}) => {
    const users = UseStoreUsers(state=> state.users)
    const TOKEN_MSAL = UseStoreTemplateView(state=> state.TOKEN_MSAL)
    const get_pillars_companies = UseStoreTemplateView(state=> state.get_pillars_companies)
    const getRolesEnableds = UseStoreRoles(state=> state.getRolesEnableds)
    const roles = UseStoreRoles(state=> state.roles)
    const FetchUsers = UseStoreUsers(state => state.fetchUsers)
    const AddUser = UseStoreUsers(state => state.addUser)
    const UpdateUser = UseStoreUsers(state => state.updateUser)
    const DeleteUser = UseStoreUsers(state => state.deleteUser)
    const [path, setPath] = useState('')
    const [alert, SetAlert] = useState({active:false, msg: '', type: ''})
    const [viewUser, setViewUser] = useState({ readonly: false, edit: false})
    const [isLoading, setIsLoading] = useState(false)
    const [image, setImage] = useState(false)
    const [isUpload, setIsUpload] = useState(false)
    const syncPermissions = UseStoreUsers(state => state.syncPermissions)

    // eslint-disable-next-line no-unused-vars
    const { handleSubmit, formState: { errors, isSubmitSuccessful }, reset, control} = useForm({ resolver: yupResolver(UserSchema)})
    useEffect(() => {
        if(!path) setPath('users-list')
        if(path === 'users-list'){
            setViewUser({readonly: false, edit: false})
            reset({})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, viewUser?.edit, viewUser?.readonly])

    const get_data_async = async () => {
        setIsLoading(true)
        await getRolesEnableds()
        await get_pillars_companies()
        setIsLoading(false)
    }
      

    useEffect(() => {
        get_data_async()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    /* useEffect(() => {
        if (isSubmitSuccessful) {
            reset({names: '', last_names: '', email: '', charge: '', rol_id: '', company_id:''})
        }
    }, [reset, isSubmitSuccessful]) */

    const OnSubmit = async data => {
        try {
            let image = ''
            const filename = `${data?.names}-${data?.email}-${Date.now()}`
            
            if(isUpload && data?.image){
                setIsLoading(true)
                const img = data?.image
                //const logo = await verifyImageDimensions(img, 300, 300)
                if(img){
                    const upload = await uploadFile(`/imagen_usuarios`, img, filename)
                    if(upload?.statusCode === 200){
                        image = upload?.data?.urlFile
                        const img = await readFile('/imagen_usuarios', image)
                        if(img?.statusCode === 200){
                            setImage(img)
                        }
                    }else {
                        SwalFire('Atencion!',`Imagen : ${upload?.message}`, 'warning')
                    }
                }else{
                    setIsLoading(false)
                    return false;
                }
                setIsLoading(false)
            }

            if(viewUser?.edit){
                setIsLoading(true)
                const res = await UpdateUser(data?.id, {...data, image})
                if(res === true){
                    SetAlert({active:true, msg: 'Se ha editado el usuario', type:'success'})
                    setViewUser({readonly: true, edit: false})
                }else
                    SetAlert({active:true, msg: 'Ocurrio un error al editar el usuario', type:'error'})    
                setIsLoading(false)
            }else{
                setIsLoading(true)
                const res = await AddUser({...data, image})
                if(res?.statusCode === 201){
                    const token = res?.data?.otp_secret
                    SetAlert({active:true, msg: 'Se ha guardado el usuario', type:'success'})
                    setViewUser({readonly: true, edit: false, user: res?.data?.otp_secret?.user})
                    await WelcomeUserMailer(TOKEN_MSAL, token?.otp_secret, [{email: token?.user?.email}])
                }
                else if(res === 2 || res?.statusCode === 400)
                    SetAlert({active:true, msg: 'Ya existe un usuario con este correo asociado a la empresa.', type:'info'})
                else if(res?.statusCode === 404)
                    SetAlert({active:true, msg: 'El rol que se le ha asignado al usuario aún no tiene permisos.', type:'info'})
                else
                    SetAlert({active:true, msg: 'Ocurrio un error al guardar el usuario', type:'error'})    
                setIsLoading(false)
            }
            setIsUpload(false)
        } catch (e) {
            console.log("Error al hacer submit del formulario: ", e?.message);
        }
    }
    const handleSyncPermissions = async (id) => {
        setIsLoading(true)
        console.log('id', id)
        const res = await syncPermissions(id)
        if(res === true){
            SetAlert({active:true, msg: 'Se han sincronizado los permisos', type:'success'})
        }else
            SetAlert({active:true, msg: 'Ocurrio un error al sincronizar los permisos', type:'error'})    
        setIsLoading(false)
    }

    const HandleView = async user => {
        if(!image && user?.image){
            const image = await readFile('/imagen_usuarios', user?.image)
            if(image?.statusCode === 200) 
                setImage(image)
            else
                SwalFire('Atencion!', image?.message, 'warning')
        }

        setPath('add-user')
        setViewUser({
            edit:false,
            readonly: true,
            user
        })
        reset(user)
    }

    const HandleUpdate =  async user => {
        if(!image && user?.image){
            const image = await readFile('/imagen_usuarios', user?.image)
            if(image?.statusCode === 200) 
                setImage(image)
            else
                SwalFire('Atencion!', image?.message, 'warning')
        }

        setPath('add-user')
        setViewUser({
            readonly:false,
            edit: true,
            user
        })
        reset(user)
    }

    const HandleDelete = async id_user => {
        setIsLoading(true)
        const res = await DeleteUser(id_user)
        if(res || !res) setIsLoading(false)
    }

    const handle_upload_edit_file = () => {
        setIsUpload(true)
    }

    return (
        <UsersV
            data={{
                vars: {
                    type_user: 1,
                    alert,
                    users,
                    path,
                    viewUser,
                    errors,
                    control,
                    isLoading,
                    image,
                    rolesEnableds: roles
                },
                funcs: {
                    handleSubmit,
                    getRolesEnableds,
                    FetchUsers,
                    OnSubmit,
                    HandleView,
                    HandleUpdate,
                    HandleDelete,
                    setViewUser,
                    setPath,
                    SetAlert,
                    handle_upload_edit_file,
                    handleSyncPermissions
                }
            }}
        >
            {children}
        </UsersV>
    )  
}