import * as Yup from 'yup'
export const UserSchema = Yup.object().shape({
    names: Yup.string()
    .matches(/^[a-zA-ZÀ-ÿñ ]*$/, "Solo debe contener letras")
        .required('Nombres es requerido')
        .min(5, "El campo debe contener al minimo 5 caracteres")
        .max(100, "El campo debe contener al maximo 100 caracteres"),
    last_names: Yup.string()
        .required('Apellidos es requerido')
        .matches(/^[a-zA-ZÀ-ÿñ ]*$/, "Solo debe contener letras")
        .min(5, "El campo debe contener minimo 5 caracteres")
        .max(100, "El campo debe contener maximo 100 caracteres"),
    email: Yup.string()
        .required('Correo es requerido')
        .email('Correo invalido')
        .min(10, "El campo debe contener minimo 10 caracteres")
        .max(100, "El campo debe contener maximo 100 caracteres"),
    rol_id: Yup.number()
        .required('Rol es requerido')
        .typeError('El campo no puede quedar vacío'),
    charge: Yup.string()
        .required('Cargo es requerido')
        .matches(/^[a-zA-ZÀ-ÿñ ]*$/, "Solo debe contener letras")
        .min(5, "El campo debe contener minimo 5 caracteres")
        .max(100, "El campo debe contener maximo 100 caracteres"),
    image: Yup.mixed()
        .nullable(true),
});