/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import {HomeV} from '../../components/Home/'
import { UseStoreCompany } from '../../stores/Company'
import { UseStoreTemplateView } from '../../stores/TemplateView'
import { UseStoreUsers } from '../../stores/Users'
import { UseStorePillar } from '../../stores/Pillar'
import { UseStoreConfig } from '../../stores/Config'
import { UseStoreEmailController } from '../../stores/EmailController'

export const HomeC = ({children}) => {
  const get_companies = UseStoreCompany(state=> state.get_companies)
  const get_emails = UseStoreEmailController(state => state.get_emails);
  const fetchUsers = UseStoreUsers(state=> state.fetchUsers)
  const get_pillars_companies = UseStoreTemplateView(state=> state.get_pillars_companies)
  const get_countrys = UseStoreTemplateView(state=> state.get_countrys)
  const get_attorneys = UseStoreTemplateView(state=> state.get_attorneys)
  const get_sector_companies = UseStoreTemplateView(state=> state.get_sector_companies)
  const get_type_powers = UseStorePillar(state=> state.get_type_powers)
  const get_especial_powers = UseStorePillar(state=> state.get_especial_powers)
  const get_color_config = UseStoreConfig(state => state.get_color_config);
  const [isLoading, setIsLoading] = useState(false)

  const getDataselect = async () => {
    setIsLoading(true);
    try {
      // Ejecutar todas las promesas en paralelo
      await Promise.all([
        get_companies(),
        get_emails(),
        fetchUsers(true),
        get_pillars_companies(),
        setIsLoading(false),
        get_type_powers(),
        get_especial_powers(),
        get_countrys(),
        get_sector_companies(),
        get_attorneys(),
        get_color_config()
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      
    }
  };
  useEffect(() =>{
    getDataselect()
  },[])

  return (
    <HomeV isLoading={isLoading}>
          {children}
    </HomeV>
  );
}


HomeC.propTypes = {
  children : PropTypes.node.isRequired
}