import React from 'react';
import { useNavigate } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import {Box, IconButton, Tooltip} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UseStoreTemplateView } from '../../stores/TemplateView'

export const AppBarModuleUsers = ({title, width}) => {
    const labelNav = UseStoreTemplateView(state=> state.labelNav)
    const buttonBack = UseStoreTemplateView(state=> state.buttonBack)
    const navigate = useNavigate()

    return (
        <Box>
            <AppBar position="static" color='default'>
                <Toolbar>
                    <Box sx={{width: width ? width : '100%'}} display={'flex'}  alignItems={'center'} justifyContent={'center'}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                fontSize: 30
                            }}
                            >
                            {title ? title : labelNav}
                        </Typography>
                    </Box>
                    {buttonBack?.state &&
                        <Tooltip title="Volver">
                            <IconButton onClick={() => navigate(buttonBack?.url ? buttonBack?.url : -1)}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Toolbar>
            </AppBar>
        </Box>
    );
}