import styled from "styled-components";
import Fondo from "./../../assets/new-background.svg";
//S -> Section

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url(${Fondo});
  background-size: cover;
  min-width: 380px;
  background-repeat: no-repeat;
`;

export const Image = styled.img`
  width: 145px;
  height: 54px;
`;

export const FormOTP = styled.div`
  color: #67757c;
  font-family: "Montserrat", "Helvetica Neue", "Arial", "sans-serif";
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffffef;
  width: 60%;
  height: 60vh;
  border-radius: 4px;
  box-shadow: 0px 0px 8px #78b0ff61;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
