import React, { useState, useEffect} from 'react';
import { ActorV } from '../../components/Actor';
import { UseStoreLogin } from './../../stores/Login';
import { UseStoreTemplateView } from './../../stores/TemplateView';
import { UseStoreUsers } from './../../stores/Users';
import { UseStoreCompany } from './../../stores/Company';
import { UseStoreAgenda } from './../../stores/Agenda';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ActorSchema } from '../../schemas/ActorSchema';
import { useParams } from 'react-router-dom';
import { filter, remove } from 'jszip';

export const ActorC = () => {
  const { actor_id } = useParams();
  const userInfo = UseStoreLogin(state => state.data_user);
  const requests_agenda = UseStoreAgenda(state => state.requests);
  const get_requests = UseStoreAgenda(state => state.get_requests);
  const users = UseStoreUsers(state => state.users);
  const fetchUsers = UseStoreUsers(state => state.fetchUsers);
  const companies = UseStoreCompany(state => state.companies);
  const get_companies = UseStoreCompany(state => state.get_companies);
  const actors = UseStoreTemplateView(state => state.actors);
  const get_actors = UseStoreTemplateView(state => state.get_actors);
  const get_actor_id = UseStoreTemplateView(state => state.get_actor_id);
  const create_actor = UseStoreTemplateView(state => state.create_actor);
  const delete_actor = UseStoreTemplateView(state => state.delete_actor);
  const updateLabelNav = UseStoreTemplateView((state) => state.updateLabelNav);
  const type_user = userInfo?.rol_id;
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [viewForm, setViewForm] = useState(false)
  const [requestsActor, setRequestsActor] = useState([])
  const { handleSubmit, control, reset, formState: { errors }} = useForm({resolver: yupResolver(ActorSchema)});
  const { fields: contactsFields, append: appendContacts, remove: removeContacts } = useFieldArray({ control, name: 'contacts' });
  const { fields: companiesFields, append: appendCompanies, remove: removeCompanies } = useFieldArray({ control, name: 'companies' });
  const { fields: obligationsFields, append: appendObligations, remove: removeObligations } = useFieldArray({ control, name: 'obligations' });
  const { fields: businessRelatedFields, append: appendBusinessRelated, remove: removeBusinessRelated } = useFieldArray({ control, name: 'related_business' });

  const setRequestActor = async (actor_id) => {
    if(requests_agenda?.length > 0) {
      const requests_actor = requests_agenda.filter(request => request?.related_actor_id === actor_id);
      setRequestsActor(requests_actor)
      return
    }

    const requests = await get_requests()
    const requests_actor = requests.filter(request => request?.related_actor_id === actor_id);
    setRequestsActor(requests_actor)
    return
  }

  const loadData = async () => {
    setIsLoading(true)
    await Promise.all([get_actors(), fetchUsers(), get_companies()])
    setIsLoading(false)
  }

  const handleDeleteActor = async (id) => {
    setIsLoading(true)
    await delete_actor(id)
    await get_actors()
    setIsLoading(false)
  }

  const handleEditActor = async (actor) => {
    setIsEdit(true)
    setViewForm(true)
    setIsView(false)
    setIsLoading(true)

    const related_business = actor?.related_business.filter(r => r?.status).map(x => ({
      ...x,
      details: x.type_details === 'number' ? parseInt(x.details) : x.details,
      description_bussines: x?.description
    }))
    const filteredContacts = actor?.contacts?.filter(r => r?.status).map(x => ({
      ...x
    }))
    const filteredCompanies = actor?.companies?.filter(r => r?.status).map(x => ({
      ...x
    }))

    await reset({
      ...actor,
      contacts: filteredContacts,
      companies: filteredCompanies,
      obligations: actor?.obligations.filter(r => r?.status).map(x => ({
        ...x,
        description_obligation: x?.description
      })),
      related_business
    })
    setIsLoading(false)
    await setRequestActor(actor?.id)
  }

  const hadleViewActor = async (actor, actor_id = null) => {
    setIsView(true)
    setViewForm(true)

    if(actor_id){
      actor = actors?.find(x=>x.id === parseInt(actor_id))
    }

    const related_business = actor?.related_business.filter(r => r?.status).map(x => ({
      ...x,
      details: x.type_details === 'number' ? parseInt(x.details) : x.details,
      description_bussines: x?.description
    }))
    const filteredContacts = actor?.contacts?.filter(r => r?.status).map(x => ({
      ...x
    }))
    const filteredCompanies = actor?.companies?.filter(r => r?.status).map(x => ({
      ...x
    }))

    await reset({
      ...actor,
      contacts: filteredContacts,
      companies: filteredCompanies,
      obligations: actor?.obligations.filter(r => r?.status).map(x => ({
        ...x,
        description_obligation: x?.description
      })),
      related_business
    })
    setIsLoading(false)
    removeContacts()
    filteredContacts.forEach((x) => appendContacts(x))
    await setRequestActor(actor?.id)
  }
  
  const handleViewForm = () => {
    if((isEdit && !viewForm) || (isView && !viewForm)){
      reset({name: '', description: '', relationship_business: '',contacts: [], companies: [], obligations: [], related_business: []})
      setIsEdit(!isEdit)
    }
    setViewForm(!viewForm)
  }

  const handleSetAppend = (field, type = "") => {
    console.log(field)
    if(field === 'contacts'){
      appendContacts({ executive_relation: '', position: '', phone: '', email: '', status: 1 });
    }
    if(field === 'companies'){
      appendCompanies({ company_id: '', executive_id: '', status: 1});
    }
    if(field === 'obligations'){
      appendObligations({ description_obligation: '', status: 1});
    }
    if(field === 'related_business'){
      appendBusinessRelated({ description_bussines: '', type_details: type, details: '', status: 1})
    }
  };

  const handleRemove = (index, field) => {
    console.log(`Removing ${field} at index ${index}`);
    const arrFuncs = {
      contacts: removeContacts,
      companies: removeCompanies,
      obligations: removeObligations,
      related_business: removeBusinessRelated
    }
    
    const exec = arrFuncs[field]
    exec(index)
  };

  const OnSubmit = async (data) => {
    setIsLoading(true)
    const payload = data
    payload.contacts = data?.contacts?.map(x => ({
      ...x,
      related_actor_id: data?.id,
      status: x?.status
    }))

    payload.companies = data?.companies?.map(x => ({
      ...x,
      id_actor_related: data?.id,
      status: x?.status
    }))

    payload.obligations = data?.obligations?.map(x => ({
      id: x.id,
      description: x?.description_obligation,
      id_actor_related: data?.id,
      status: x?.status
    }))

    payload.related_business = data?.related_business?.map(x => ({
      id: x.id,
      description: x?.description_bussines,
      id_actor_related: data?.id,
      details: x?.details,
      type_details: x?.type_details,
      status: x?.status
    }))

    const actor_saved = await create_actor(payload)
    await get_actors()
    setIsLoading(false)

    if (actor_saved && isEdit){

      const actor = await get_actor_id(actor_saved?.id)
      await reset({
        ...actor,
        obligations: actor.obligations.filter(r => r?.status).map((x, i, arr) => ({
          ...x,
          description_obligation: x?.description,
        })),
        related_business: actor.related_business.filter(r => r?.status).map((x, i, arr) => ({
          ...x,
          description_bussines: x?.description
        })),
        contacts: actor?.contacts?.filter(r => r?.status).map(x => ({
          ...x
        })),
        companies: actor?.companies?.filter(r => r?.status).map(x => ({
          ...x
        }))
  
      })
      setViewForm(true)
    }else {
      reset({name: '', description: '', relationship_business: '', contacts: [], companies: [], obligations: [], related_business: []})
      setIsEdit(false)
    }
  };

  useEffect(() => {
    loadData()

    if(actor_id){
      updateLabelNav("Actor relacionado");
      hadleViewActor({}, actor_id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])    
  
  return (
    <ActorV
      vars={{
        actor_id,
        requests_actors: requestsActor,
        users,
        companies,
        contactsFields,
        companiesFields,
        obligationsFields,
        businessRelatedFields,
        isLoading,
        control,
        isView,
        type_user, 
        actors,
        isEdit,
        viewForm
      }}
      funcs={{
        handleSubmit,
        OnSubmit,
        handleSetAppend,
        handleRemove,
        handleDeleteActor,
        handleEditActor,
         setIsView,
        setIsEdit, 
        reset,
        setViewForm,
        hadleViewActor,
        handleViewForm,
      }}
    />
  );
};
