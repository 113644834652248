import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Avatar, Button, IconButton, Typography } from "@mui/material/";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { TableListAdapter } from "../Common/DataGridAdapter";
import { AddUserV } from "./add-user";
import { capitalizeFirstLetter } from "./../../utils"

export const UsersV = ({
  data: {
    vars: {
      type_user,
      users,
      alert,
      path,
      viewUser,
      errors,
      control,
      isLoading,
      image,
      rolesEnableds,
    },
    funcs: {
      handleSubmit,
      OnSubmit,
      getRolesEnableds,
      FetchUsers,
      HandleUpdate,
      HandleDelete,
      setViewUser,
      setPath,
      SetAlert,
      HandleView,
      handle_upload_edit_file,
      handleSyncPermissions
    },
  },
}) => {
  useEffect(() => {
    FetchUsers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const columns = [
    {
      field: "image",
      headerName: "Fotografía",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Avatar
          alt={params?.row?.names}
          src={params?.row?.img}
          sx={{ width: 40, height: 40 }}
        />
      ),
    },
    {
      field: "names",
      headerName: "Nombres",
      minWidth: 180,
      flex: 1.2,
      headerAlign: "center",
    },
    {
      field: "last_names",
      headerName: "Apellidos",
      minWidth: 180,
      flex: 1.2,
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Correo electrónico",
      minWidth: 190,
      flex: 1.5,
      headerAlign: "center",
    },
    {
      field: "rol_name",
      headerName: "Rol",
      minWidth: 160,
      flex: 0.5,
      headerAlign: "center",
      cellClassName: "role-className",
    },
    {
      field: "charge",
      headerName: "Cargo",
      minWidth: 160,
      flex: 0.6,
      headerAlign: "center",
    },

    {
      field: "options",
      headerName: "Opciones",
      description: "Muestra las opciones que se le permiten al usuario",
      sortable: false,
      cellClassName: "options-className",
      width: 180,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="Ver Usuario"
            onClick={() => HandleView(params?.row)}
            size="large"
            sx={{
              color: "#323338",
              ":hover": {
                color: "#1AAFD0",
              },
            }}
          >
            <RemoveRedEyeOutlinedIcon fontSize="medium" />
          </IconButton>
          <IconButton
            aria-label="Editar Usuario"
            onClick={() => HandleUpdate(params?.row)}
            size="large"
            sx={{
              color: "#323338",
              ":hover": {
                color: "#1AAFD0",
              },
            }}
          >
            <EditOutlinedIcon fontSize="medium" />
          </IconButton>
          <IconButton
            aria-label="Eliminar Usuario"
            onClick={() => HandleDelete(params?.row?.id)}
            size="large"
            sx={{
              color: "#323338",
              ":hover": {
                color: "#1AAFD0",
              },
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="medium" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {path === "users-list" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            height: "100%",
            background: "#f6f7fb",
            pl: 1,
          }}
        >
          <Box
            height="40px"
            bgcolor="#ffffff"
            p={2}
            pr={8}
            borderRadius="0px 0px 0px 7px"
            justifyContent="space-between"
            display="flex"
            alignItems="center"
            boxShadow="0px 3px 4px 0px #2222221A"
          >
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "24px",
                fontWeight: "600",
                color: "#323338",
              }}
            >
              Lista de usuarios
            </Typography>
            <Button
              startIcon={<AddIcon />}
              type="button"
              onClick={() => setPath("add-user")}
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "capitalize",
                width: "119px",
                height: "49px",
                background: "#1AAFD0",
                color: "#FFFFFF",
                ":hover": {
                  background: "#1AAFD0",
                },
              }}
            >
              Nuevo
            </Button>
          </Box>
          <Box>
            <Box
              sx={{
                height: "80vh",
                overflow: "auto",
                width: "95%",
                borderRadius: "7px 7px 0px 0px",
                bgcolor: "#FFFFFF",
                p: "0px 16px",
                "& .options-className": {
                  borderLeft: "3px solid #E2E4E7",
                },
                "& .role-className": {
                  borderLeft: "1px solid #E2E4E7",
                },
              }}
            >
              <TableListAdapter
                countRows={7}
                height={"100%"}
                columns={columns}
                data={users?.map((x) => ({
                  ...x,
                  rol_name: x?.rol?.name,
                }))}
                isLoading={!users?.length}
                handleRowClick={() => {}}
                handleCellClick={() => {}}
              />
            </Box>
          </Box>
        </Box>
      )}
      {path === "add-user" && (
        <AddUserV
          data={{
            vars: {
              type_user,
              viewUser,
              alert,
              errors,
              control,
              isLoading,
              image,
              rolesEnableds,
            },
            funcs: {
              setViewUser,
              getRolesEnableds,
              setPath,
              handleSubmit,
              OnSubmit,
              SetAlert,
              handle_upload_edit_file,
              handleSyncPermissions
            },
          }}
        />
      )}
    </>
  );
};
