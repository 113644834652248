import React from "react";
import { GroupCompaniesC } from "../../containers/Company/GroupCompanies";
import { AddCompanyC } from "../../containers/Company/AddCompany";
import { PillarsC } from "../../containers/Company/Pillars";
import { MultiPillarC } from "../../containers/Company/MultiPillar";
export const GroupCompanies = (props) => <GroupCompaniesC {...props} />;
export const AddCompany = (props) => <AddCompanyC {...props} />;
export const Pillars = (props) => <PillarsC {...props} />;
export const MutiPillar = (props) => <MultiPillarC {...props} />;

