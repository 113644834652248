import React, { useState, useEffect } from "react";
import { ActivosV } from "../../components/Company/Activos/Activos";
import { UseStoreActivos } from "../../stores/Activos";
import { UseStoreUsers } from "../../stores/Users";
import { useParams } from "react-router-dom";
import { UseStoreCompany } from "../../stores/Company";
import { get, set, useForm } from "react-hook-form";
import { uploadFile } from "../../utils/AWS";
import { UseStoreTemplateView } from "../../stores/TemplateView";
import Swal from "sweetalert2";
import { SwalFire } from "../../utils";
import { UseStorePillar } from "../../stores/Pillar";
import { readFile } from "../../utils/AWS";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { UseStoreAgenda } from "../../stores/Agenda";

export const ActivosC = (props) => {
  const { company_id } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [activos, setActivos] = useState([]);
  const [company, setCompany] = useState({});
  //const [subPillar, setSubPillar] = useState(0);
  const get_company = UseStoreCompany((state) => state.get_company);
  const [createFolder, setCreateFolder] = useState(false);
  const [editFolder, setEditFolder] = useState(false);
  const [createFile, setCreateFile] = React.useState(false); //Modal
  const [editFile, setEditFile] = React.useState(false); //Modal
  const [subPillars, setSubPillars] = useState([8, 9, 10]);
  const [modalDocument, setModalDocument] = useState({open: false, type: false}); //Modal
  const [nameDoc, setNameDoc] = React.useState("");
  const [shareLink, setShareLink] = React.useState("");
  const save_digital_document = UseStoreAgenda(
    (state) => state.save_digital_document
  );
  const [permisos, setPermisos] = useState([]);
  const permission_user = UseStorePillar((state) => state.permission_user);
  const [subPillar, setSubPillar] = React.useState("");
  const [id, setId] = React.useState("");
  const [folderName, setFolderName] = React.useState("");
  const [expandedItems, setExpandedItems] = React.useState([]);

  const getPermisos = async () => {
    setPermisos(await permission_user);
    console.log("Permisos", permission_user);
  };

  const subPillarsCodes = {
    1: "act-con",
    2: "poderes",
    3: "act-asm",
    4: "fed-hec",
    5: "aut-fed",
    6: "aut-est",
    7: "aut-mun",
    8: "act-inm",
    9: "act-mue",
    10: "act-int",
    11: "con-civ",
    12: "con-mer",
    13: "con-adm",
    14: "con-lab",
  };
  //useStores
  const {
    get_activos,
    add_activos,
    delete_folder,
    update_folder,
    update_file,
    delete_file,
  } = UseStoreActivos();
  const users = UseStoreUsers((state) => state.users);
  const actors = UseStoreTemplateView((state) => state.actors);
  const get_actors = UseStoreTemplateView((state) => state.get_actors);
  const { pillars_in_company } = UseStorePillar();
  const get_pillar_by_company = UseStorePillar(
    (state) => state.get_pillar_by_company
  );

  const noAplicaOption = { id: 999999, name: "No aplica", description: "" };
  const actorsWithNoAplica = [noAplicaOption, ...actors];

  const noAplicaOptionClient = { id: 999999, name: "No aplica" };
  const clientsWithNoAplica = [noAplicaOptionClient, ...actors];

  const useStore = create(
    persist(
      (set) => ({
        agenda: [],
        time: 0,
        active: false,
        setAgenda: (newAgenda) => set({ agenda: newAgenda }),
        setTime: (newTime) => set({ time: newTime }),
        setActive: (newActive) => set({ active: newActive }),
      }),
      {
        name: "agenda-storage", // nombre del almacenamiento en localStorage
        Storage: () => localStorage, // o sessionStorage
      }
    )
  );
  const setAgenda = useStore((state) => state.setAgenda);
  const setActive = useStore((state) => state.setActive);
  const active = useStore((state) => state.active);
  const agenda = useStore((state) => state.agenda);
  const time = useStore((state) => state.time);

  const defineSubPillar = async () => {
    if (pillars_in_company?.length > 0) {
      const subPillarsFilter = pillars_in_company.filter(
        (item) => item.pillar_id === 3
      );
      if (subPillarsFilter?.length > 0) {
        setSubPillars(subPillarsFilter.map((item) => item.subpillar_id));
      }
      console.log("SubPillars", subPillars);
    }
  };

  const getPath = (fileId, data) => {
    // Crear un diccionario de objetos para acceso rápido
    if (data.length > 0) {
      const dataDict = data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      // Función para construir la ruta recursivamente
      const buildPath = (id, path = []) => {
        const item = dataDict[id];
        if (!item) return path;

        // Añadir el ID actual a la ruta
        path.unshift(`folder${item.id}`);

        // Si tiene padre, continuar construyendo la ruta
        if (item.parent_id !== null) {
          return buildPath(item.parent_id, path);
        }

        return path;
      };

      // Construir la ruta completa
      const fullPath = buildPath(fileId);

      // Remover el último elemento si es el archivo final
      if (fullPath.length > 0) {
        const lastItem = dataDict[fileId];
        if (lastItem && lastItem.parent_id === null) {
          fullPath.pop();
        }
      }
      console.log("fullPath", fullPath);
      setExpandedItems(fullPath);
      return fullPath;
    }else{
      console.log("No hay agenda");
      return [];
    }
  };

  const {
    handleSubmit,
    control,
    register,
    setValue: setValueForm,
    reset,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    register: register2,
    setValue: setValueForm2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const {
    handleSubmit: handleSubmit3,
    control: control3,
    register: register3,
    setValue: setValueForm3,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm();
  const {
    handleSubmit: handleSubmit4,
    control: control4,
    register: register4,
    setValue: setValueForm4,
    reset: reset4,
    formState: { errors: errors4 },
  } = useForm();

  const getDocument = (file_id) => {
    activos.map(async (item) => {
      if (item.id === file_id) {
        const sha_link = await readFile("/Activos", item.document_url, +".pdf");
        console.log("SHA_LINK", sha_link);
      }
    });
  };

  const fetchShareLink = async (document_url, modal = true) => {
    if (
      document_url !== "" ||
      document_url !== null ||
      document_url !== undefined
    ) {
      const sha_link = await readFile("/Activos", document_url);
      if (sha_link?.statusCode === 200) {
        setShareLink(sha_link);
        if (!modal) {
          window.open(sha_link.data.url, "_blank");
        }
      } else {
        Swal.fire({
          icon: "error",
          title:
            "Error al obtener el documento, verifique su conexión de internet",
        });
        console.log("Error al obtener el link del archivo");
      }
    }
  };

  const downloadDocument = (file_id, modal = true) => {
    if (modal) {
      setModalDocument({open: true, type: false});
    }
    activos.map((item) => {
      if (item.id === file_id) {
        const isPDF = item.general_file_url.toLowerCase().endsWith('.pdf');
        setModalDocument({open: false, type: !isPDF});
        setNameDoc(item.general_file_url);

      }
    });
    fetchShareLink(file_id, modal);
  };

  const handleOpenModalDocument = async (file_id, document_url) => {
    //fetchShareLink(document_url);
    const isPDF = document_url.toLowerCase().endsWith('.pdf');
    setModalDocument({open: true, type: !isPDF});
    activos.map((item) => {
      if (item.id === file_id) {
        setNameDoc(item.general_file_url);
        fetchShareLink(item.general_file_url);
      }
    });
  };

  const handleCloseModalDocument = () => {
    setShareLink("");
    setModalDocument({
      open: false,
      type: false
    });
  };

  const handleModalEditFile = (subpillar_id, id) => {
    activos.map((item) => {
      if (item.id === id) {
        setValueForm4("subpillar_id", item.subpillar_id);
        setValueForm4("location", item.location);
        setValueForm4("client", item.client);
        setValueForm4("description_asset", item.description_asset);
        setValueForm4("location_asset", item.location_asset);
        setValueForm4("register_number", item.register_number);
        setValueForm4("register_country", item.register_country);
        //format dd/mm/yyyy date_concession
        const date_concession = item.date_concession
          ? new Date(item.date_concession)
          : null;
        const date_concession_format = date_concession
          ? date_concession.toISOString().split("T")[0]
          : null;
        setValueForm4("date_concession", date_concession_format);
        //format dd/mm/yyyy date_due
        const date_due = item.date_due ? new Date(item.date_due) : null;
        const date_due_format = date_due
          ? date_due.toISOString().split("T")[0]
          : null;
        setValueForm4("date_due", date_due_format);
        setValueForm4("responsible_id", item.responsible_id);
        setValueForm4("parent_id", item.parent_id);
        setValueForm4("id", item.id);
      }
    });
    setEditFile(true);
  };

  const handleModarCloseEditFile = () => {
    setEditFile(false);
  };

  const handleOpenFolder = () => {
    setCreateFolder(true);
  };
  const handleCloseFolder = () => {
    setCreateFolder(false);
  };

  const OnSubmit = async (data) => {
    //Crear Folder
    setIsloading(true);
    const estructuraBase = ["Licencias", "Escrituras", "Planos", "Predial"];
    activos?.filter(async (item) => {
      if (item.id == data.parent_id) {
        setSubPillar(item.subpillar_id);
        const schema = {
          company_id: company_id,
          subpillar_id: item.subpillar_id,
          parent_id: data.parent_id,
          file_name: data.folder_name,
          type: "folder",
        };
        const carpeta = await add_activos(schema);
        if (data.checkbox_construir_estructura === true) {
          estructuraBase.map(async (x) => {
            const schema = {
              company_id: company_id,
              subpillar_id: item.subpillar_id,
              parent_id: carpeta.data.id,
              file_name: x,
              type: "folder",
            };
            const c = await add_activos(schema);
          });
        }
        await updateGetActivos();
        SwalFire("Atención", "Carpeta(s) creada correctamente", "success");
      }
    });
    reset();
    handleCloseFolder();
    setIsloading(false);
  };
  const handleCloseFile = () => {
    setCreateFile(false);
  };
  const OnSubmitCreateFile = async (data) => {
    //Crear File
    setIsloading(true);
    
    if (!data?.document) {
      SwalFire("Atención", "No se ha agregado ningún documento", "warning");
      setIsloading(false);
      return;
    }
    
    const timeStamp = new Date().getTime();
    const nameDoc = data?.document.name.split(".");
    const file = await uploadFile(
      "/Activos",
      data?.document,
      nameDoc[0] + timeStamp
    ); //Subir archivo
    if (file.statusCode === 200) {
      const schema = {
        subpillar_id: data.subpillar_id,
        type: "file",
        file_name: data?.document ? data?.document.name : "",
        general_file_url: data?.document
          ? data?.document.name.split(".")[0] +
            timeStamp +
            obtenerExtension(data?.document.name)
          : "",
        location: data.location,
        client: data.client,
        description_asset: data.description_asset ? data.description_asset : "",
        location_asset: data.location_asset ? data.location_asset : "",
        register_number: data.register_number ? data.register_number : "",
        register_country: data.register_country ? data.register_country : "",
        date_concession: data.date_concession ? data.date_concession : null,
        date_due: data.date_due ? data.date_due : null,
        estatus: "activo",
        company_id: company_id,
        responsible_id: data.responsible_id || 0,
        parent_id: data.parent_id,
      };

      const res = await add_activos(schema);
      if (res.statusCode === 200) {
        const maxTime = 10 * 60 * 1000;
        console.log(Date.now() - time);
        if (Date.now() - time <= maxTime && active === true) {
          const payload = {
            id: agenda.id,
            type_digital_document: subPillarsCodes[schema.subpillar_id],
            digital_document: res.data.id,
          };
          console.log(payload);
          try {
            const s = await save_digital_document(payload);
            if (s) {
              console.log("Documento guardado");
            } else {
              console.log("Error al guardar documento");
            }
          } catch (error) {
            console.error("Error al guardar documento:", error);
          }
        } else {
          console.log(
            "No se ha creado un archivo en menos de 10 minutos o no hay agenda"
          );
        }
        setAgenda([]);
        setActive(false);
        SwalFire("Atención", "Archivo creado correctamente", "success");
        updateGetActivos();
      }
      reset2();
      setIsloading(false);
      handleCloseFile();
    } else {
      SwalFire("Atención", "Error al subir el archivo", "error");
      setIsloading(false);
    }
  };

  const obtenerExtension = (nombreArchivo) =>
    nombreArchivo
      .slice(((nombreArchivo.lastIndexOf(".") - 1) >>> 0) + 1)
      .toLowerCase();

      const OnSubmitEditFile = async (data) => {
        setIsloading(true);
        let newFileInfo = {};
      
        if (data?.document) {
          const timeStamp = new Date().getTime();
          const nameDoc = data.document.name.split(".");
          const file = await uploadFile(
            "/Activos",
            data.document,
            nameDoc[0] + timeStamp
          );
          if (file.statusCode !== 200) {
            SwalFire(
              "Atención",
              "Error al cargar el documento, verifique su conexión a internet",
              "error"
            );
            setIsloading(false);
            return false;
          }
          newFileInfo = {
            file_name: data.document.name,
            general_file_url: data.document.name.split(".")[0] +
              timeStamp +
              obtenerExtension(data.document.name)
          };
        }
      
        const schema = {
          id: data.id,
          subpillar_id: data.subpillar_id,
          type: "file",
          location: data.location,
          client: data.client,
          description_asset: data.description_asset || "",
          location_asset: data.location_asset || "",
          register_number: data.register_number || "",
          register_country: data.register_country || "",
          date_concession: data.date_concession || null,
          date_due: data.date_due || null,
          estatus: "activo",
          company_id: company_id,
          responsible_id: data.responsible_id || 0,
          ...newFileInfo // Solo se incluye si se subió un nuevo archivo
        };
      
        const res = await update_file(schema);
        if (res.statusCode === 200) {
          SwalFire("¡Atención!", "Archivo actualizado", "success");
          updateGetActivos();
        }
        reset4();
        setIsloading(false);
        updateGetActivos();
        handleModarCloseEditFile();
      };

  const handleDeleteFolder = async (sp, folder_id, c) => {
    //Eliminar Folder
    setIsloading(true);
    const filter = activos.filter((item) => item.id == folder_id);
    if (filter.length > 0) {
      if (filter[0].type == "folder") {
        const res = await delete_folder(sp, folder_id, c);
        if (res) {
          loadingInfo();
          SwalFire("Atención", "Carpeta eliminada correctamente", "success");
        }
      }
    }
    setFolderName("");
    setId("");
    setSubPillar("");
    setIsloading(false);
  };
  const handleDeleteFile = async (subpillar_id, id) => {
    setIsloading(true);
    const res = await delete_file(subpillar_id, id, company_id);
    if (res) {
      Swal.fire("¡Eliminado!", "El archivo ha sido eliminado.", "success");
      updateGetActivos();
    }
    setIsloading(false);
  };

  const handleModalEditNameFolder = (data) => {
    setValueForm3("folder_name", data.file_name);
    setValueForm3("folder_id", data.id);
    setEditFolder(true);
  };

  const handleCloseEditNameFolder = () => {
    setEditFolder(false);
  };

  const OnSubmitEditFolder = async (data) => {
    //Editar Folder
    setIsloading(true);
    activos?.filter(async (item) => {
      if (item.id == data.id) {
        const schema = {
          company_id: company_id,
          subpillar_id: item.subpillar_id,
          file_name: data.folder_name,
          type: "folder",
          folder_id: data.id,
        };
        await update_folder(schema);
        await updateGetActivos();
      }
    });
    reset3();
    handleCloseEditNameFolder();
    setIsloading(false);
  };

  const getInfoUser = async () => {};

  const updateGetActivos = async () => {
    
    const subPillarsFilter = pillars_in_company.filter(
      (item) => item.pillar_id === 3
    );
    if (subPillarsFilter?.length > 0) {
      setSubPillars(subPillarsFilter.map((item) => item.subpillar_id));
    }
    const result = await Promise.all(
      subPillarsFilter.map(async (subpillar_id) => {
        return await get_activos(company_id, subpillar_id.subpillar_id);
      })
    );
    if (result.length > 0) {
      console.log("Result", result);
      //filtrar si permisos section_id 9 para el subpillar 8, section id 10 para el subpillar 9 y section id 11 para el subpillar 10
      const inmuebles = permission_user.find((x) => x.section_id === 9);
      const muebles = permission_user.find((x) => x.section_id === 10);
      const intangibles = permission_user.find((x) => x.section_id === 11);

      const activos = result.flat().filter((x) => {
        return (
          (x.subpillar_id === 8 && inmuebles?.view) ||
          (x.subpillar_id === 9 && muebles?.view) ||
          (x.subpillar_id === 10 && intangibles?.view)
        );
      });
      setActivos(activos);
      if(initFolder() !== 0){
        let folderID = initFolder();
        getPath(folderID, activos);
        setId(folderID);
        activos.filter((item) => {
          if (item.id == folderID) {
            setFolderName(item.file_name);
            setSubPillar(item.subpillar_id);
          }
        });
        //eliminar el id de la url
        window.history.replaceState({}, document.title, "/pilares/activos/" + company_id);
      }
    }else{
      //espera 5 segundos y vuelve a intentar
      setTimeout(() => {
        get_pillar_by_company(company_id);
        updateGetActivos();
      }, 5000);
             
    }
  };

  const getCompany = async () => {
    const bussines = await get_company(company_id);
    if (bussines) {
      setCompany(bussines);
    }
  };
  const initFolder = () => {
    //get url id param
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    if (myParam && myParam !== null) {
      return myParam;
    }else{
      return 0;
    }
  };

  const loadingInfo = async () => {
    setIsloading(true);
    await getPermisos();
    await get_pillar_by_company(company_id);
    await defineSubPillar();
    await updateGetActivos();
    setIsloading(false);
    await Promise.all([get_actors(), getInfoUser(), getCompany()]);
    
  };

  useEffect(() => {
    loadingInfo();
  }, []);

  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
    label_text: {
      height: "40px",
      width: "100px",
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "500",
      textTransform: "capitalize",
    },
    buttonSave: {
      height: "49px",
      width: "100px",
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "500",
      color: "#FFFF",
      textTransform: "capitalize",
      background: "#1AAFD0",
      margin: "10px 0",
      ":hover": {
        color: "#1AAFD0",
        background: "#FFF",
      },
    },
    typography: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "20px",
      fontWeight: "600",
      color: "#323338",
      "@media (max-width: 790px)": {
        fontSize: "16px",
      },
      "@media (max-width: 590px)": {
        fontSize: "18",
      },
    },
    tableCell: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
      color: "#323338",
    },
  };

  return (
    <div>
      <ActivosV
        vars={{
          isLoading,
          styles,
          users,
          company_id,
          subPillars,
          activos,
          company,
          control, //Crear Folder
          register, //Crear Folder
          errors, //Crear Folder
          setValueForm, //Crear Folder
          reset, //Crear Folder
          control2, //Crear File
          register2, //Crear File
          errors2, //Crear File
          setValueForm2, //Crear File
          reset2, //Crear File
          actors,
          createFolder,
          editFolder,
          control3, //Editar Folder
          register3, //Editar Folder
          errors3, //Editar Folder
          setValueForm3, //Editar Folder
          reset3, //Editar Folder
          createFile, //Crear File
          editFile, //Editar File
          modalDocument, //Modal Document
          nameDoc,
          shareLink,
          control4, //Editar File
          register4, //Editar File
          errors4, //Editar File
          setValueForm4, //Editar File
          reset4, //Editar File
          actorsWithNoAplica,
          clientsWithNoAplica,
          permisos,
          subPillar,
          id,
          folderName,
          expandedItems,
        }}
        funcs={{
          updateGetActivos,
          handleSubmit, //Crear Folder
          OnSubmit, //Crear Folder
          OnSubmitCreateFile, //Crear File
          handleDeleteFolder,
          handleSubmit2, //Crear File
          handleOpenFolder,
          handleCloseFolder,
          handleSubmit3, //Editar Folder
          OnSubmitEditFolder, //Editar Folder
          handleModalEditNameFolder,
          handleCloseEditNameFolder,
          handleModalEditFile,
          handleModarCloseEditFile,
          handleDeleteFile,
          setCreateFile,
          handleCloseFile,
          handleCloseModalDocument,
          handleOpenModalDocument,
          downloadDocument,
          handleSubmit4,
          OnSubmitEditFile,
          setSubPillar,
          setId,
          setFolderName,
          setExpandedItems,
        }}
      />
    </div>
  );
};
