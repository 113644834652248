import React from 'react';
import { Box, Grid, Typography, IconButton, Button, Stack} from '@mui/material';
import { Input } from '../Common/MUInput';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputAutocompleteAdapter } from '../Common/MUIAutoComplete'
import { TabsContent } from './TabsContent';
export const FormActor = ({
    actor_id, users, companies, control, isView,  handleSetAppend, contactsFields,
    companiesFields, obligationsFields, businessRelatedFields, OnSubmit, handleSubmit,
    type_user, isEdit, handleRemove, requests_actors
}) => {

    const styles = {
        label: {
          fontFamily: "Poppins-SemiBold",
          fontSize: "14px",
          fontWeight: "600",
        },
        buttonSave: {
            height: "50px",
            width: "350px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            color: "#FFFF",
            textTransform: "capitalize",
            ":hover": {
              background: "#1AAFD0",
            },
            
        },
        typography: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "30px",
            fontWeight: "600",
            color: "#323338",
            "@media (max-width: 790px)": {
              fontSize: "24px",
            },
            "@media (max-width: 590px)": {
              fontSize: "20px",
            },
          },
    };

    return (
        <Box>
            {actor_id && <Stack
                display={'flex'}
                bgcolor="#FFFFFF"
                height="80px"
                width="100%"
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
            >
                <Typography sx={styles.typography} textAlign="center">Actor relacionado</Typography>
            </Stack>}
            <Grid container border={1} borderColor={'#dbdbdb'} mt={actor_id ? 4 : 0}>
                <Grid container>
                    <Grid item xs={12} p={1.5} bgcolor={'#e3e3e3'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Typography
                            sx={{
                                fontFamily: "Poppins-SemiBold",
                                fontSize: "24px",
                                fontWeight: "600",
                                color: "#323338",
                                textAlign: 'center'
                            }}
                        >
                            Perfil actor relacionado
                        </Typography>
                    </Grid>
                    <Grid container columnSpacing={2} rowGap={1} columns={12} m={1}>
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                        <Typography sx={styles.label}>Nombre*</Typography>
                            <Input
                                name="name"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    autoComplete:'none',
                                    disabled: isView,
                                    inputProps: {
                                        style: {
                                            color: 'black',
                                            '-webkit-text-fill-color': 'black',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                        <Typography sx={styles.label}>Descripción*</Typography>
                            <Input
                                name="description"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    autoComplete:'none',
                                    disabled: isView,
                                    inputProps: {
                                        style: {
                                            color: 'black',
                                            '-webkit-text-fill-color': 'black',
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography sx={styles.label}>Negocios de relación*</Typography>
                            <Input
                                name="relationship_business"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    autoComplete:'none',
                                    disabled: isView,
                                    inputProps: {
                                        style: {
                                            color: 'black',
                                            '-webkit-text-fill-color': 'black',
                                        },
                                    },
                                }}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container mt={1}>
                    <Grid item xs={isView ? 12 : 11} bgcolor={'#e3e3e3'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Typography
                            sx={{
                                fontFamily: "Poppins-SemiBold",
                                fontSize: "24px",
                                fontWeight: "600",
                                color: "#323338"
                            }}
                        >
                            Contactos
                        </Typography>
                    </Grid>
                    {!actor_id && 
                        <Grid item xs={1} p={1} display={'flex'} justifyContent={'center'}>
                            {!isView && <IconButton aria-label="add" size='large' onClick={()=> handleSetAppend('contacts')}>
                                <AddIcon />
                            </IconButton>}
                        </Grid>
                    }
                    {console.log(contactsFields)}
                    {contactsFields
                    .map((field, i) => (
                        <Grid container item  key={field.id || i} columnSpacing={2} columns={12} m={1}>
                            <Grid item xs={3} p={1}
                                display="flex"
                                flexDirection="column"
                                gap={2}
                            >
                                <Typography sx={styles.label}>Ejecutivo relación*</Typography>
                                <Input
                                    name={`contacts.${i}.executive_relation`}
                                    control={control}
                                    props={{
                                        size: 'small',
                                        fullWidth: true,
                                        autoComplete:'none',
                                        disabled: isView,
                                        inputProps: {
                                            style: {
                                                color: 'black',
                                                '-webkit-text-fill-color': 'black',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={isView ? 3 : 2} p={1}
                                display="flex"
                                flexDirection="column"
                                gap={2}
                            >
                             <Typography sx={styles.label}>Puesto*</Typography>
                                <Input
                                    name={`contacts.${i}.position`}
                                    control={control}
                                    props={{
                                        size: 'small',
                                        fullWidth: true,
                                        autoComplete:'none',
                                        disabled: isView,
                                        inputProps: {
                                            style: {
                                                color: 'black',
                                                '-webkit-text-fill-color': 'black',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} p={1}
                                display="flex"
                                flexDirection="column"
                                gap={2}
                            >
                             <Typography sx={styles.label}>Teléfono*</Typography>
                                <Input
                                    name={`contacts.${i}.phone`}
                                    control={control}
                                    props={{
                                        size: 'small',
                                        fullWidth: true,
                                        autoComplete:'none',
                                        disabled: isView,
                                        inputProps: {
                                            style: {
                                                color: 'black',
                                                '-webkit-text-fill-color': 'black',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} p={1} 
                                display="flex"
                                flexDirection="column"
                                gap={2}
                            >
                                <Typography sx={styles.label}>Correo electrónico*</Typography>
                                <Input
                                    name={`contacts.${i}.email`}
                                    control={control}
                                    props={{
                                        size: 'small',
                                        fullWidth: true,
                                        autoComplete:'none',
                                        disabled: isView,
                                        inputProps: {
                                            style: {
                                                color: 'black',
                                                '-webkit-text-fill-color': 'black',
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            {!isView && <Grid item xs={1} p={1} display={'flex'}  mt={4} justifyContent={'center'}>
                                <IconButton aria-label="delete" size='large' onClick={() => handleRemove(i, 'contacts')}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    ))}
                </Grid>
                <Grid container mt={1}>
                    <Grid item xs={isView ? 12 : 11} bgcolor={'#e3e3e3'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Typography
                            sx={{
                                fontFamily: "Poppins-SemiBold",
                                fontSize: "24px",
                                fontWeight: "600",
                                color: "#323338",
                                textAlign: 'center'
                            }}
                        >
                            Empresas relacionadas
                        </Typography>
                    </Grid>
                    {!actor_id && <Grid item xs={1} p={1} display={'flex'} justifyContent={'center'}>
                        {!isView && <IconButton aria-label="add" size='large' onClick={()=> handleSetAppend('companies')}>
                            <AddIcon />
                        </IconButton>}
                    </Grid>}
                    {console.log(companiesFields)}
                    {companiesFields
                        .map((field, i) => (
                        <Grid container item key={field.id || i} columnSpacing={2} rowGap={1} columns={12} m={1}>
                            <Grid item xs={isView ? 6 : 5} p={1}     
                                display="flex"
                                flexDirection="column"
                                gap={2}
                            
                            >
                                <Typography sx={styles.label}>Nombre de la empresa*</Typography>
                                <InputAutocompleteAdapter
                                    size={'small'}
                                    mb={0}
                                    keyLabel="label"
                                    keyValue="value"
                                    height={40}
                                    disabled={isView}
                                    name={`companies.${i}.company_id`}
                                    control={control}
                                    options={
                                        companies?.map((x) => ({
                                            value: x?.id,
                                            label: x?.name
                                        })) || []
                                    }
                                    InputProps={{
                                        style: {
                                        color: 'black',
                                        '-webkit-text-fill-color': 'black',
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input.Mui-disabled': {
                                        color: 'black',
                                        '-webkit-text-fill-color': 'black',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={isView ? 6 : 5} p={1}
                              display="flex"
                              flexDirection="column"
                              gap={2}
                            >
                              <Typography sx={styles.label}>Ejecutivo responsable*</Typography>
                                <InputAutocompleteAdapter
                                    size={'small'}
                                    mb={0}
                                    keyLabel="label"
                                    keyValue="value"
                                    height={40}
                                    disabled={isView}
                                    name={`companies.${i}.executive_id`}
                                    control={control}
                                    options={
                                        users?.map((x) => ({
                                            value: x?.id,
                                            label: `${x?.names} ${x?.last_names}`
                                        })) || []
                                    }
                                    InputProps={{
                                        style: {
                                        color: 'black',
                                        '-webkit-text-fill-color': 'black',
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input.Mui-disabled': {
                                        color: 'black',
                                        '-webkit-text-fill-color': 'black',
                                        },
                                    }}
                                />
                            </Grid>
                            {!isView && <Grid item xs={1} p={1} display={'flex'} mt={4} justifyContent={'center'}>
                                <IconButton aria-label="delete" size='large' onClick={() => handleRemove(i, 'companies')}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>}
                        </Grid>
                    ))}
                </Grid>
                <Grid container mt={1}>
                    <Grid item xs={actor_id || isView ? 12 : 11} p={1}>
                        <TabsContent
                            requests_actors={requests_actors}
                            isEdit={isEdit}
                            isView={isView}
                            control={control} 
                            companies={companies} 
                            actor_id={actor_id}
                            handleSetAppend={handleSetAppend}
                            handleRemove={handleRemove}
                            obligationsFields={obligationsFields}
                            businessRelatedFields={businessRelatedFields}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {type_user === 1 ?
                <Grid item xs={12} display={'flex'} justifyContent={'space-around'} my={3}>
                    {!isView && 
                    <Button sx={styles?.buttonSave} variant='contained' type='button' onClick={handleSubmit(OnSubmit)}>
                        GUARDAR ACTOR RELACIONADO
                    </Button>}
                </Grid>
            : null}
        </Box>
    )
}