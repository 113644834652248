import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseStoreTemplateView } from "../../../stores/TemplateView";
import { MUIModalAdapter } from "../../Common/MUIModalAdapter";
import { RenderPdf } from "../../Common/RenderPdf";
import BackIcon from "./../../../assets/icons/backIcon.svg";
import { MultiPilarList } from "./MultiPilarList";
import { MultiPillarForm } from "./MultiPillarForm";
dayjs.extend(localizedFormat);

export const MultiPillarV = ({
  vars: {
    rows,
    selecteds,
    errors,
    control,
    pillar,
    subpillar,
    isLoading,
    openModal,
    pdfPreview,
    type_user,
    type_powers,
    especial_powers,
    actions,
    verification,
    rowSelected,
    multiple,
    isForm,
    dataForm,
  },
  funcs: {
    OnSubmit,
    handleSubmit,
    setOpenModal,
    get_file,
    set_value_form,
    resetFrom,
    download_or_delete,
    add_especial_powers,
    setIsUpload,
    setVerification,
    handleChangeSelected,
    handleSelectAll,
    HandleDeleteAll,
    HandleDownloadAll,
    setIsForm,
    alertTool,
  },
}) => {
  const navigate = useNavigate();
  const [especial_power, set_especial_power] = useState("");
  const { buttonBack, labelNav } = UseStoreTemplateView((state) => ({
    labelNav: state.labelNav,
    buttonBack: state.buttonBack,
  }));
  const [states] = useState([
    { id: 1, name: "Vigente" },
    { id: 2, name: "Revocado" },
  ]);
  const row_color = "row_color";
  const attorney = subpillar === "poderes" ? "Apoderado" : null;
  const type_power = subpillar === "poderes" ? "Tipo de poder" : null;
  const vigency = subpillar === "poderes" ? "Vigencia" : null;
  const vigency_date =
    subpillar === "poderes" ? "Fecha de término de vigencia" : null;
  const limitation = subpillar === "poderes" ? "Limitación" : null;
  const IRPC =
    pillar === "fed-hec"
      ? null
      : "Inscripción en el registro público de comercio";
  const status = subpillar === "poderes" ? "Estatus" : null;
  const Description =
    pillar === "act-con" || subpillar === "poderes" ? null : "Descripción";
  const Etiqueta =
    pillar === "asa-ext" || pillar === "asa-ord" ? "Etiquetas" : null;
  const columns = [
    attorney,
    type_power,
    "Número de escritura",
    "Fecha de otorgamiento",
    vigency,
    vigency_date,
    limitation,
    Description,
    IRPC,
    Etiqueta,
    "PDF",
    status,
    "Acciones",
  ];
  const [page] = useState(0);
  const [rowsPerPage] = useState(50);

  useEffect(() => {}, [rows, verification]);
  return (
    <Stack bgcolor="#FFFFFF" p={3} gap={2}>
      {isLoading && <LinearProgress />}
      <Stack
        flexDirection="column"
        gap={1}
        borderBottom={isForm ? "3px solid #DDDDDD" : ""}
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#1AAFD0",
          }}
        >
          {labelNav}
        </Typography>
        {isForm ? (
          <Stack
            mb={2}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            rowGap={2}
          >
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "20px",
                fontWeight: "600",
                color: "#323338",
              }}
            >
              {dataForm?.id ? "Actualizar" : "Nuevo registro"}
            </Typography>
            <Stack flexDirection="row" gap={2}>
              <Button
                type="button"
                onClick={resetFrom}
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#000000",
                  width: "117px",
                  height: "49px",
                  textTransform: "capitalize",
                  ":hover": {
                    background: "#E2E4E7",
                  },
                }}
              >
                Limpiar
              </Button>
              <Button
                type="button"
                onClick={() => setIsForm(false)}
                sx={{
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#000000",
                  width: "105px",
                  height: "49px",
                  textTransform: "capitalize",
                  ":hover": {
                    background: "#E2E4E7",
                  },
                }}
              >
                Cancelar
              </Button>
              {(type_user === 1 || actions?.add) && (
                <Button
                  type="button"
                  id="save"
                  onClick={handleSubmit(OnSubmit)}
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#FFFFFF",
                    background: "#1AAFD0",
                    width: "100px",
                    height: "49px",
                    textTransform: "capitalize",
                    ":hover": {
                      background: "#1AAFD0",
                    },
                  }}
                >
                  Guardar
                </Button>
              )}
            </Stack>
          </Stack>
        ) : (
          <Stack flexDirection="revert-layer" justifyContent="space-between">
            <Button
              type="button"
              onClick={() => navigate(buttonBack?.url ? buttonBack?.url : -1)}
              variant="text"
              sx={{
                fontFamily: "Poppins-Regular",
                fontSize: "12px",
                fontWeight: "400",
                color: "#323338",
                width: "100px",
                textTransform: "capitalize",
              }}
              startIcon={<img src={BackIcon} alt="BackIcon" />}
            >
              Regresar
            </Button>
            <Button
              variant="contained"
              type="button"
              sx={{
                fontFamily: "Poppins-Regular",
                fontSize: "14px",
                fontWeight: "400",
                color: "#FFFFFF",
                width: "177px",
                height: "49px",
                textTransform: "capitalize",
                ":hover": {
                  background: "#1AAFD0",
                },
              }}
              startIcon={<AddIcon />}
              onClick={() => {
                resetFrom();
                setIsForm(true);
              }}
            >
              Nuevo registro
            </Button>
          </Stack>
        )}
      </Stack>
      {isForm ? (
        <MultiPillarForm
          attorney={attorney}
          control={control}
          type_power={type_power}
          pillar={pillar}
          errors={errors}
          type_powers={type_powers}
          especial_powers={especial_powers}
          set_especial_power={set_especial_power}
          especial_power={especial_power}
          add_especial_powers={add_especial_powers}
          vigency={vigency}
          vigency_date={vigency_date}
          limitation={limitation}
          verification={verification}
          setVerification={setVerification}
          subpillar={subpillar}
          rowSelected={rowSelected}
          setIsUpload={setIsUpload}
          status={status}
          resetFrom={resetFrom}
          type_user={type_user}
          states={states}
          handleSubmit={handleSubmit}
          OnSubmit={OnSubmit}
          actions={actions}
        />
      ) : (
        <>
          <MultiPilarList
            alertTool={alertTool}
            setIsForm={setIsForm}
            control={control}
            handleSelectAll={handleSelectAll}
            columns={columns}
            isLoading={isLoading}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangeSelected={handleChangeSelected}
            attorney={attorney}
            row_color={row_color}
            type_power={type_power}
            pillar={pillar}
            dayjs={dayjs}
            vigency={vigency}
            vigency_date={vigency_date}
            limitation={limitation}
            actions={actions}
            type_user={type_user}
            setOpenModal={setOpenModal}
            get_file={get_file}
            status={status}
            navigate={navigate}
            set_value_form={set_value_form}
            download_or_delete={download_or_delete}
            selecteds={selecteds}
            multiple={multiple}
            HandleDeleteAll={HandleDeleteAll}
            HandleDownloadAll={HandleDownloadAll}
          />
          {type_user !== 1 && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              p={2}
              width={"100%"}
            >
              {!actions?.add &&
                !actions?.edit &&
                !actions?.delete &&
                !actions?.download && (
                  <Alert
                    variant="outlined"
                    severity="info"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontWeight: "400",
                        color: "#323338",
                      }}
                    >
                      Aún no tiene permisos asignados.
                    </Typography>
                  </Alert>
                )}
            </Box>
          )}
        </>
      )}
      {pdfPreview && (
        <MUIModalAdapter
          open={openModal?.open}
          handleClose={() => setOpenModal(false)}
        >
          <RenderPdf url={pdfPreview} />
        </MUIModalAdapter>
      )}
    </Stack>
  );
};
