import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Input } from "../../Common/MUInput";
import { InputMultiSelect } from "../../Common/MUIMultiSelect";
import { InputTime } from "../../Common/MUIInputTime";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

export const CompanyAdministration = ({
  control,
  isView,
  company_id,
  dataForm,
  handleAdd,
  handleDeleteRow,
  permissions
}) => {
  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
  };

  return (
    <Stack>
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        Consejo de administración
      </Typography>
      <Grid container columnSpacing={2} rowGap={3} columns={12} pt="5px">
        {(dataForm?.company_administrations || [])?.map((_, index) => (
          <Grid
            key={index}
            container
            item
            columnSpacing={2}
            rowGap={2}
            columns={12}
          >
            <Grid
              item
              xs={6}
              sm={5}
              md={4}
              lg={6}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography
                display={index === 0 ? "block" : "none"}
                sx={styles.label}
              >
                Nombre completo
              </Typography>
              <Input
                name={`company_administrations.${index}.name`}
                control={control}
                props={{
                  size: "small",
                  autoComplete: "none",
                  disabled: isView,
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={2}
              md={4}
              lg={3}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography
                display={index === 0 ? "block" : "none"}
                sx={styles.label}
              >
                Función
              </Typography>
              <Input
                name={`company_administrations.${index}.position`}
                control={control}
                props={{
                  size: "small",
                  autoComplete: "none",
                  disabled: isView,
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sm={5}
              md={4}
              lg={3}
              alignItems="end"
              gap={2}
              display="flex"
            >
              {permissions?.add ? (
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    display:
                      index === dataForm?.company_administrations?.length - 1 &&
                      dataForm?.company_administrations?.length < 4
                        ? "flex"
                        : "none",
                    minWidth: "127px",
                    width: "127px",
                    height: "40px",
                    fontFamily: "Poppins-Regular",
                    fontWeight: "400",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    color: "#323338",
                    ":hover": {
                      background: "#1AAFD0",
                      color: "#FFFFFF",
                    },
                  }}
                  type="button"
                  onClick={handleAdd}
                >
                  Agregar
                </Button>
              ) : null}
              {permissions?.delete ? (
                <Button
                  sx={{
                    display:
                      dataForm?.company_administrations?.length === 1
                        ? "none"
                        : "block",
                    width: "53px",
                    height: "40px",
                    fontFamily: "Poppins-Regular",
                    fontWeight: "400",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    color: "#323338",
                    ":hover": {
                      background: "#1AAFD0",
                      color: "#FFFFFF",
                    },
                  }}
                  onClick={() => handleDeleteRow(index)}
                  type="button"
                >
                  <DeleteOutlineRoundedIcon />
                </Button>
              ) : null}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
          mt: 3,
        }}
      >
        Datos de contacto
      </Typography>
      <Grid container columnSpacing={2} rowGap={2} columns={12} pt="5px">
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Nombre completo</Typography>
          <Input
            name="contact_name"
            control={control}
            props={{
              fullWidth: true,
              size: "small",
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Correo electrónico</Typography>
          <Input
            name="contact_email"
            control={control}
            props={{
              size: "small",
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Teléfono</Typography>
          <Input
            name="contact_phone"
            control={control}
            props={{
              size: "small",
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Extensión</Typography>
          <Input
            name="extension"
            control={control}
            props={{
              sx: { width: "69px", minWidth: "69px" },
              size: "small",
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          lg={4}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography sx={styles.label}>Celular</Typography>
          <Input
            name="contact_celular"
            control={control}
            props={{
              size: "small",
              autoComplete: "none",
              disabled: isView,
            }}
          />
        </Grid>
      </Grid>
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
          mt: 3,
        }}
      >
        Horario de atención
      </Typography>
      <Grid
        container
        columnSpacing={2}
        rowGap={2}
        columns={12}
        pt="5px"
        alignItems="end"
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputMultiSelect
            disabled={isView}
            name="ho_days"
            control={control}
            label={"Días"}
            isEdit={Boolean(company_id)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          display="flex"
          gap={2}
          alignItems="center"
        >
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography sx={styles.label}>De</Typography>
            <InputTime
              disabled={isView}
              name="ho_time_start"
              control={control}
              props={{ size: "small" }}
            />
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Typography sx={styles.label}>A:</Typography>
            <InputTime
              disabled={isView}
              name="ho_time_end"
              control={control}
              props={{ size: "small" }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
