import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { React } from "react";
import { millers } from "../../utils";
import CompanyIcon from "./../../assets/icons/company/icon.svg";
import CompanyColorIcon from "./../../assets/icons/company/iconColor.svg";
import ConfigIcon from "./../../assets/icons/configIcon.svg";
import ModuleIcon from "./../../assets/icons/module/icon.svg";
import ModuleColorIcon from "./../../assets/icons/module/iconColor.svg";
import { InputDate } from "./../Common/MUIInputDate";
import { TableList } from "./DataGrid";
import { InputAutocompleteAdapter } from "./MUIAutoComplete";
import { MUIModal } from "./MUIModal";
import { InputMultiSelect } from "./MUIMultiSelect";
import { Input } from "./MUInput";
import { RenderPdf } from "./RenderPdf";
import FolderIcon from "@mui/icons-material/Folder";
import {ImagePreview} from "./ImagePreview";

export const SearchTable = ({
  vars: {
    control,
    inputs,
    filter_data,
    isLoading,
    openModal,
    pdfPreview,
    loadingPdf,
    showTable,
    shareLink,
    modalDocument,
  },
  funcs: {
    handleChangeSelect,
    handleSubmit,
    OnSubmit,
    resetForm,
    setOpenModal,
    getFile,
    handleRowAction,
    handleFilters,
    handleNavigate,
    fetchShareLink,
    handleCloseModalDocument,
  },
}) => {
  const ModalDocument = () => {
    return (
      <>
        <Stack>
          <MUIModal
            open={modalDocument}
            handleClose={handleCloseModalDocument}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            buttonTop={true}
          >
            <Box sx={{}}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* Aqui se renderiza el archivo PDF */}
                {modalDocument?.type === 1 ? (
                  <ImagePreview url={shareLink?.data?.url} />
                ) : (
                  <RenderPdf url={shareLink?.data?.url} />
                )}
              </Typography>
            </Box>
          </MUIModal>
        </Stack>
      </>
    );
  };
  const columnsFiles = [
    {
      headerName: "Acciones",
      align: "left",
      width: 140,
      sortable: false,
      cellClassName: "actions",
      renderCell: ({ row }) => (
        <Box>
          <Tooltip title="Ver empresa">
            <IconButton
              sx={{
                width: 56,
                height: 56,
                "& .CompanyColorIcon": {
                  display: "none",
                },
                ":hover": {
                  "& .CompanyIcon": {
                    display: "none",
                  },
                  "& .CompanyColorIcon": {
                    display: "block",
                  },
                },
              }}
              onClick={async () => await handleRowAction(row, "company")}
            >
              <img
                className="CompanyIcon"
                src={CompanyIcon}
                alt="CompanyIcon"
              />
              <img
                className="CompanyColorIcon"
                src={CompanyColorIcon}
                alt="CompanyColorIcon"
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ver Módulo">
            <IconButton
              sx={{
                width: 56,
                height: 56,
                "& .ModuleColorIcon": {
                  display: "none",
                },
                ":hover": {
                  "& .ModuleIcon": {
                    display: "none",
                  },
                  "& .ModuleColorIcon": {
                    display: "block",
                  },
                },
              }}
              onClick={async () => await handleRowAction(row, "module")}
            >
              <img className="ModuleIcon" src={ModuleIcon} alt="ModuleIcon" />
              <img
                className="ModuleColorIcon"
                src={ModuleColorIcon}
                alt="ModuleColorIcon"
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "company_name",
      headerName: "Empresa",
      minWidth: 200,
      cellClassName: "cellLink",
    },
    {
      field: "pillar_name",
      headerName: "Pilar",
      minWidth: 160,
      cellClassName: "cellLink",
    },
    {
      field: "pdf_link",
      align: "center",
      headerName: "PDF",
      sortable: false,
      width: 60,
      renderCell: (row) => (
        <Tooltip title="Abrir">
          <IconButton
            sx={{
              width: 56,
              height: 56,
              ":hover": {
                color: "#1AAFD0",
              },
            }}
            onClick={() => {
              if(row?.row?.type === "folder"){
                window.location.href = resolverURL(row);
              }else{
              fetchShareLink(resolverURL(row), row?.row?.pillar_id)}
              }}
          >
            {(row?.row.type === "folder") 
            ? <FolderIcon /> 
            : <PictureAsPdfIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    { field: "file_name", headerName: "Nombre", width: 200 },
  ]

  const resolverURL = (row) => {
    if (row?.row.pillar_id === "2") {
      if (row?.row?.type === "folder") {
        return '/pilares/act-gub/' + row.row?.company_id + '?id=' + row.row?.id_folder;
      }
      return row.row?.file_url;
    } else if (row?.row.pillar_id === "3") {
      if (row?.row?.type === "folder") {
        return '/pilares/activos/' + row.row?.company_id + '?id=' + row.row?.id_folder;
      }
      return row?.row.general_file_url;
    }else if (row?.row.pillar_id === "4") {
      if (row?.row?.type === "folder") {
        return '/pilares/contratos/' + row.row?.company_id + '?id=' + row.row?.id_folder;
      }
      return row?.row.document_url;
  }
  }

  const columns = [
    {
      headerName: "Acciones",
      align: "left",
      width: 140,
      sortable: false,
      cellClassName: "actions",
      renderCell: ({ row }) => (
        <Box>
          <Tooltip title="Ver empresa">
            <IconButton
              sx={{
                width: 56,
                height: 56,
                "& .CompanyColorIcon": {
                  display: "none",
                },
                ":hover": {
                  "& .CompanyIcon": {
                    display: "none",
                  },
                  "& .CompanyColorIcon": {
                    display: "block",
                  },
                },
              }}
              onClick={async () => await handleRowAction(row, "company")}
            >
              <img
                className="CompanyIcon"
                src={CompanyIcon}
                alt="CompanyIcon"
              />
              <img
                className="CompanyColorIcon"
                src={CompanyColorIcon}
                alt="CompanyColorIcon"
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ver Módulo">
            <IconButton
              sx={{
                width: 56,
                height: 56,
                "& .ModuleColorIcon": {
                  display: "none",
                },
                ":hover": {
                  "& .ModuleIcon": {
                    display: "none",
                  },
                  "& .ModuleColorIcon": {
                    display: "block",
                  },
                },
              }}
              onClick={async () => await handleRowAction(row, "module")}
            >
              <img className="ModuleIcon" src={ModuleIcon} alt="ModuleIcon" />
              <img
                className="ModuleColorIcon"
                src={ModuleColorIcon}
                alt="ModuleColorIcon"
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "company_name",
      headerName: "Empresa",
      minWidth: 200,
      cellClassName: "cellLink",
    },
    {
      field: "pillar_name",
      headerName: "Pilar",
      minWidth: 160,
      cellClassName: "cellLink",
    },
    {
      field: "pdf_link",
      align: "center",
      headerName: "PDF",
      sortable: false,
      width: 60,
      renderCell: (row) => (
        <Tooltip title="Ver PDF">
          <IconButton
            sx={{
              width: 56,
              height: 56,
              ":hover": {
                color: "#1AAFD0",
              },
            }}
            onClick={() => getFile(row)}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    
    { field: "type_document", headerName: "Tipo de documento", width: 200 },
     {
      field: "grant_date",
      headerName: "Fecha de otorgamiento",
      width: 250,
      renderCell: ({ row }) => (
        <Typography>{`${dayjs(row?.grant_date)
          .locale("es")
          .format("dddd, D MMMM YYYY")}`}</Typography>
      ),
    },
    
    {
      field: "writing_number",
      headerName: "Número de escritura",
      width: 200,
      renderCell: ({ row }) => (
        <Typography>
          {row?.writing_number ? millers(row?.writing_number) : "NA"}
        </Typography>
      ),
    },
    { field: "description", headerName: "Descripción", width: 200 },
    { field: "type_power", headerName: "Tipo de poderes", width: 200 },
    { field: "type_acts", headerName: "Tipo de actas", width: 200 },
    { field: "tags", headerName: "Etiquetas", width: 200 },
    { field: "special_power", headerName: "Poderes especiales", width: 200 },
    { field: "attorney", headerName: "Apoderado", width: 200 },
    { field: "status", headerName: "Estado", width: 100 },
  ];
 ;
  console.log(filter_data);
  const filterByPilar = (columns) => {
    if (filter_data?.length) {
      if (filter_data[0].name_module === "Contratos" || filter_data[0].name_module === "Actos Gubernamentales" || filter_data[0].name_module === "Activos") {
        console.log("dentro de contratos");
        //solo deja las columnas hasya type_document
        const columnsX = columnsFiles
        return columnsX;
      }
    }
    else {
      return columns;
    }
  }
   
  return (
    <Stack gap={2}>
      {loadingPdf && <LinearProgress sx={{ mb: 2 }} />}
      <Grid container columnSpacing={1} rowGap={1} columns={12} p={3}>
        {inputs.map((input, index) => (
          <Grid
            item
            xs={12}
            sm={input?.name === "text" ? 12 : 6}
            md={input?.name === "text" ? 8 : 4}
            lg={input?.name === "text" ? 6 : 3}
            key={index}
          >
            <FormControl fullWidth variant="outlined">
              {input.name === "grant_date" ? (
                <InputDate
                  label={input.label}
                  name={input.name}
                  control={control}
                  disabled={input.disabled}
                  props={{
                    InputLabelProps: { shrink: true },
                    getValue: true,
                    getValueFunc: (value) => input.getValue(value),
                  }}
                />
              ) : input.name === "tags" ? (
                <InputMultiSelect
                  name={input.name}
                  control={control}
                  label={input.label}
                  module={input.module}
                  options={input.options}
                  disabled={input.disabled}
                  props={{ InputLabelProps: { shrink: true } }}
                />
              ) : input.name === "text" ? (
                <Input
                  name={input.name}
                  control={control}
                  props={{
                    label: input.label,
                    autoComplete: "none",
                    disabled: input.disabled,
                    size: input.size,
                    value: input.value,
                    getValue: true,
                    InputLabelProps: { shrink: true },
                    getValueFunc: (value) => input.getValue(value),
                  }}
                />
              ) : (
                <InputAutocompleteAdapter
                  mb={0}
                  propsTextField={{ InputLabelProps: { shrink: true } }}
                  sx={{ width: "100%" }}
                  keyLabel="label"
                  keyValue="value"
                  height={40}
                  label={input.label}
                  name={input.name}
                  control={control}
                  options={input.options}
                  disabled={input.disabled}
                  customOnChange={handleChangeSelect}
                />
              )}
            </FormControl>
          </Grid>
        ))}
        {showTable && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              type="button"
              onClick={handleFilters}
              sx={{
                fontFamily: "Poppins-Regular",
                fontSize: "14px",
                fontWeight: "400",
                color: "#323338",
                textTransform: "inherit",
                height: "100%",
                width: "131px",
              }}
              endIcon={
                <img
                  src={ConfigIcon}
                  width="15px"
                  height="15px"
                  alt="ConfigIcon"
                />
              }
            >
              Filtros
            </Button>
          </Grid>
        )}
      </Grid>
      <Box
        display={showTable ? "none" : "flex"}
        justifyContent="end"
        gap={2}
        p={3}
        borderTop="1px solid #DDDDDD"
      >
        <Button
          variant="text"
          color="primary"
          sx={{
            color: "#000000",
            ":hover": {
              background: "#DDDDDD",
            },
            width: "127px",
            height: "49px",
            textTransform: "inherit",
          }}
          onClick={resetForm}
        >
          Quitar filtros
        </Button>
        <Button
          variant="contained"
          sx={{
            color: "#fff",
            ":hover": {
              background: "#1AAFD0",
            },
            width: "89px",
            height: "49px",
            textTransform: "inherit",
          }}
          onClick={handleSubmit(OnSubmit)}
        >
          Buscar
        </Button>
      </Box>
      {showTable && (
        <Box
          p="0px 24px 24px 24px"
          sx={{
            "& .actions": {
              borderRight: "3px solid #EAE4E7",
            },
            "& .cellLink": {
              color: "#1AAFD0",
              ":hover": {
                textDecoration: "underline",
              },
            },
            "& .MuiDataGrid-columnHeaders": {
              background: "#E2E4E7",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontFamily: "Poppins-SemiBold",
              fontSize: "14px",
              fontWeight: "600",
              color: "#323338",
            },
            "& .MuiDataGrid-root": {
              color: "#323338",
              fontFamily: "Poppins-Regular",
              fontSize: "14px",
              fontWeight: "400",
            },
            "& .MuiDataGrid-row": {
              ":hover": {
                background: "#1AAFD01A",
              },
            },
          }}
        >
          {filter_data?.find(z => z.actors)?.actors && 
          <Box>
            <Box 
              sx={{
                height: '10%',
                background: '#e2e4e7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 1
              }}
            >
              <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "17px",
                    fontWeight: "600",
                    p:1
                  }}
                >
                  Actor Relacionado
              </Typography>
            </Box>
            <Box>
                {filter_data?.find(z => z.actors)?.actors?.map(x => (
                  <Box display={'flex'} p={1}>
                    <Typography
                      onClick={()=> handleNavigate(`/view-actor/${x?.actor_id}`)}
                      sx={{
                        width: "auto",
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "14px",
                        fontWeight: "200",
                        color: "#1AAFD0",
                        cursor: "pointer",
                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {x?.actor_name}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
          }
          <TableList
            search={false}
            handleCellClick={() => {}}
            handleRowClick={() => {}}
            columns={filterByPilar(columns)}
            data={filter_data?.filter(x => !x?.actors)}
            isLoading={isLoading}
            height={470}
            handleRowAction={handleRowAction}
          />
        </Box>
      )}
      <MUIModal open={openModal} handleClose={() => setOpenModal(false)}>
        <RenderPdf url={pdfPreview} />
      </MUIModal>
      <ModalDocument />
    </Stack>
  );
};
