import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export const MUISkeleton = ({height, aditional}) => {
    return (
        <Stack spacing={1} >
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={height || 100} />
            <Skeleton variant="rounded" width={'100%'} height={70} />
            {aditional && <>
                <Skeleton animation="wave" variant="rectangular" width={'100%'} height={20} />
                <Skeleton animation="wave" variant="rectangular" width={'100%'} height={10} />
            </>
            }
        </Stack>
    );
};
