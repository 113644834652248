import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { encrypt_data } from './../utils/JSImplements'
import { SwalFire, SwalfireConfirm } from './../utils/'
import { GET, POST, PUT} from './../utils/AuthService'
import { logAction } from '../utils/logAction'

export const UseStorePillar= create(
    persist(
        (set, get) => ({
            id_document_selected: false,
            especial_powers: [],
            pillars_in_company: [],
            type_powers: [],
            rows: [],
            permission_user: [],
            get_pillar_by_company: async (company_id) => {
                const result = await GET(`get-pillars-by-company/${company_id}`)
                if(result?.statusCode === 200){
                    set({pillars_in_company: result?.data})
                    return true
                }else{
                    SwalFire('Atencion!', result?.message, 'warning')
                    set({pillars_in_company: []})
                }
            },
            get_type_powers: async () => {
                const { type_powers } = get()
                if(!type_powers?.length){
                    const result = await GET('get-type-powers')
                    if(result?.statusCode === 200){
                        set({type_powers: result?.data})
                    }else{
                        SwalFire('Atencion!', result?.message, 'warning')
                        set({type_powers: []})
                    }
                }
            },
            get_especial_powers: async () => {
                const result = await GET('get-special-powers')
                if(result?.statusCode === 200){
                    set({especial_powers: result?.data})
                    return result?.data
                }else{
                    SwalFire('Atencion!', result?.message, 'warning')
                    set({especial_powers: []})
                    return []
                }
            },
            set_document_selected: (id) => {
                set({id_document_selected: id})
            },
            update_rows: (data, selected) => {
                const { rows } = get()
                //Clonamos  para hacer inmutabilidad 
                const new_rows = structuredClone(rows)
                const row = new_rows?.find(x => x?.id === data?.id)
                const rowIndex = new_rows?.findIndex(x => x?.id === data?.id)

                if(rowIndex >= 0){
                    const newPillar = {
                        ...row,
                        selected
                    }
                    new_rows[rowIndex] = newPillar
                    //Actualizamos el estado de Zustand
                    set({rows: new_rows})
                }
            },
            add_especial_powers: async(data) => {
                const acts = await POST('add-special-power', data)
                if(acts.statusCode === 200) {SwalFire('Bien', 'Se registro el poder especial', 'success')
                    await logAction('special_powers', acts?.data?.id, 'create', 'Se registro el poder especial')
                }
                else {SwalFire('Atencion!', acts?.message, 'warning')}
            },
            get_permission_user: async (user_id, company_id) => {
                try {
                    const payload = {
                        companie_id: company_id,
                        search_id: user_id
                    }
                    const permission = await POST('get-user-permissions', payload)
                    if(permission?.statusCode === 200){
                        //await logAction('permissions', user_id, 'read', 'Se consulto los permisos del usuario')
                        set({permission_user: permission?.data})
                        return permission?.data
                    }else{
                        SwalFire('Atencion!', permission?.message, 'warning')
                        return []
                    }
                } catch (error) {
                    console.log('Ha ocurrido un error al consultar el permiso del usuario')
                }
            },
            add_comercial_registry: async (data) => {
                try {
                    const add = await POST('add-comercial-registry', data)
                    //await logAction('comercial_registry', 0, 'create', 'Se registro el registro comercial')
                    return add
                } catch (error) {
                    console.log(`Ha ocurrido un error al agregar el registro comercial`);
                    return {statusCode: 500, message: 'Ha ocurrido un error al agregar el registro comercial'}
                }
            },
            update_comercial_registry: async (id, data) => {
                try {
                    const update = await PUT(`update-comercial-registry/${id}`, data)
                    //await logAction('comercial_registry', id, 'update', 'Se actualizo el registro comercial')
                    return update
                } catch (error) {
                    console.log(`Ha ocurrido un error al actulizar el registro comercial`);
                    return {statusCode: 500, message: 'Ha ocurrido un error al agregar el registro comercial'}
                }
            },
            get: async (pillar_code, company_id) => {
                if(pillar_code === 'act-con'){
                    const fetch = await GET(`get-constitutive-acts/${company_id}`)
                    if(fetch?.statusCode === 200) set({rows: fetch?.data})
                    else {
                        //SwalFire('Atencion!', fetch?.message, 'warning')
                        set({rows: []})
                    }
                }
                if(pillar_code === 'asa-ord'){
                    const fetch = await GET(`get-assembly-acts/${company_id}/ord`)
                    if(fetch?.statusCode === 200) set({rows: fetch?.data})
                    else {
                        //SwalFire('Atencion!', fetch?.message, 'warning')
                        set({rows: []})
                    }
                }

                if(pillar_code === 'asa-ext'){
                    const fetch = await GET(`get-assembly-acts/${company_id}/ext`)
                    if(fetch?.statusCode === 200) set({rows: fetch?.data})
                    else {
                        //SwalFire('Atencion!', fetch?.message, 'warning')
                        set({rows: []})
                    }
                }

                if(pillar_code === 'fed-hec'){
                    const fetch = await GET(`get-statements-facts/${company_id}`)
                    if(fetch?.statusCode === 200) set({rows: fetch?.data})
                    else {
                        //SwalFire('Atencion!', fetch?.message, 'warning')
                        set({rows: []})
                    }
                }

                if(['pwr-ppc', 'pwr-paa', 'pwr-pad', 'pwr-pes'].includes(pillar_code)){
                    const fetch = await GET(`get-powers/${company_id}/${pillar_code}`)
                    if(fetch?.statusCode === 200) set({rows: fetch?.data})
                    else {
                        //SwalFire('Atencion!', fetch?.message, 'warning')
                        set({rows: []})
                    }
                }
            },
            add: async (data) => {
                try {
                    const { add_comercial_registry } = get()
                    if(data?.registry_comercial){
                        const comercial = await add_comercial_registry(data?.registry_comercial)
                        if(comercial?.statusCode === 200){
                            const commercial_registry_id = comercial?.data?.id

                            if(data?.acts_consitutive){
                                const acts = await POST('add-constitutive-acts', {commercial_registry_id, ...data?.acts_consitutive})
                                if(acts.statusCode === 200){
                                    await logAction('constitutive_acts', acts?.data?.id, 'create', 'Se registro el acta constitutiva')
                                    SwalFire('Bien', 'Se registro el acta', 'success')
                                    return acts?.data
                                }else{
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                    return false
                                }  
                            }

                            if(data?.asam_ext){
                                const acts = await POST(`add-assembly-acts`, {commercial_registry_id, ...data?.asam_ext})
                                if(acts.statusCode === 200){
                                    await logAction('assembly_acts', acts?.data?.id, 'create', 'Se registro el acta de asamblea extraordinaria')
                                    SwalFire('Bien', 'Se ha agregado el registro', 'success')
                                    return acts?.data
                                } else {
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                    return false
                                }  
                            }

                            if(data?.power){
                                const acts = await POST(`add-power`, {commercial_registry_id, ...data?.power})
                                if(acts.statusCode === 200){
                                    await logAction('powers', acts?.data?.id, 'create', 'Se registro el poder')
                                    SwalFire('Bien', 'Se ha agregado el registro', 'success')
                                    return acts?.data
                                }else{
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                    return false
                                }  
                            }
                        }else{
                            SwalFire('Atencion!', comercial?.message, 'warning')
                            return false
                        }
                    }else{
                        if(data?.asam_ord){
                            const acts = await POST('add-assembly-acts', data?.asam_ord)
                            if(acts.statusCode === 200){
                                await logAction('assembly_acts', acts?.data?.id, 'create', 'Se registro el acta de asamblea ordinaria')
                                SwalFire('Bien', 'Se registro el acta', 'success')
                                return acts?.data
                            }else{
                                SwalFire('Atencion!', acts?.message, 'warning')
                                return false
                            } 
                        }

                        if(data?.fed_hec){
                            const acts = await POST(`add-statements-facts`, data?.fed_hec)
                            if(acts.statusCode === 200){
                                await logAction('statements_facts', acts?.data?.id, 'create', 'Se registro el acta de hechos')
                                SwalFire('Bien', 'Se ha agregado el registro', 'success')
                                return acts?.data
                            }else{
                                SwalFire('Atencion!', acts?.message, 'warning')
                                return false
                            }  
                        }
                    }

                } catch (error) {
                    console.log(`Ha ocurrido un error al agregar el registro`);
                    return false
                }
            },
            update: async (data) => {
                try {
                    const { update_comercial_registry } = get()
                    const id = data?.id
                    const comercial_id = data?.commercial_registry_id
                    if(data?.registry_comercial){
                        const comercial = await update_comercial_registry(comercial_id, data?.registry_comercial)
                        if(comercial?.statusCode === 200){
                            const commercial_registry_id = comercial?.data?.id

                            if(data?.acts_consitutive){
                                const acts = await PUT(`update-constitutive-acts/${id}`, {commercial_registry_id, ...data?.acts_consitutive})
                                if(acts.statusCode === 200){
                                    await logAction('constitutive_acts', id, 'update', 'Se actualizo el acta constitutiva')
                                    SwalFire('Bien', 'Se ha actualizado el acta', 'success')
                                }
                                else  {
                                    await logAction('constitutive_acts', id, 'update', 'Error al actualizar el acta constitutiva')
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                }
                            }

                            if(data?.asam_ext){
                                const acts = await PUT(`update-assembly-acts/${id}`, {commercial_registry_id, ...data?.asam_ext})
                                if(acts.statusCode === 200) {
                                    await logAction('assembly_acts', id, 'update', 'Se actualizo el acta de asamblea extraordinaria')
                                    SwalFire('Bien', 'Se ha actualizado el registro', 'success')
                                }
                                else  {
                                    await logAction('assembly_acts', id, 'update', 'Error al actualizar el acta de asamblea extraordinaria')
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                }
                            }

                            if(data?.power){
                                const acts = await PUT(`update-powers/${id}`, {commercial_registry_id, ...data?.power})
                                if(acts.statusCode === 200) {
                                    await logAction('powers', id, 'update', 'Se actualizo el poder')
                                    SwalFire('Bien', 'Se ha actualizado el registro', 'success')
                                }
                                else  {
                                    await logAction('powers', id, 'update', 'Error al actualizar el poder')
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                }
                            }
                        }else{
                            await logAction('comercial_registry', comercial_id, 'update', 'Error al actualizar el registro comercial')
                            SwalFire('Atencion!', comercial?.message, 'warning')
                        }
                    }else{
                        if(data?.asam_ord){
                            const acts = await PUT(`update-assembly-acts/${id}`, data?.asam_ord)
                            if(acts.statusCode === 200) {
                                await logAction('assembly_acts', id, 'update', 'Se actualizo el acta de asamblea ordinaria')
                                SwalFire('Bien', 'Se ha actualizado el acta', 'success')
                            }
                            else { 
                                await logAction('assembly_acts', id, 'update', 'Error al actualizar el acta de asamblea ordinaria')
                                SwalFire('Atencion!', acts?.message, 'warning')
                            }
                        }

                        if(data?.fed_hec){
                            const acts = await PUT(`update-statements-facts/${id}`, data?.fed_hec)
                            if(acts.statusCode === 200) {
                                await logAction('statements_facts', id, 'update', 'Se actualizo el acta de hechos')
                                SwalFire('Bien', 'Se ha actualizado el registro', 'success')
                            }
                            else  {
                                await logAction('statements_facts', id, 'update', 'Error al actualizar el acta de hechos')
                                SwalFire('Atencion!', acts?.message, 'warning')
                            }
                        }
                    }
                } catch (error) {
                    await logAction('powers', data?.id, 'update', 'Error al actualizar el poder')
                    console.log(`Ha ocurrido un error al editar el registro`);
                }
            },
            delete: async (id, pillar_code, all = false, ids) => {
                try {
                    if(id && pillar_code){
                        const msg = all ? 'los registros' : 'el registro'
                        const resSwal = await SwalfireConfirm(`¿Está seguro que desea eliminar ${msg}?`)
                        if(resSwal){
                            let acts = {}
                            if(pillar_code === 'act-con'){
                                if(all && ids.length)
                                    acts = await PUT(`delete-constitutive-acts`, {ids})
                                else
                                    acts = await PUT(`delete-constitutive-acts/${id}`)
                                
                                if(acts?.statusCode === 200) {
                                    await logAction('constitutive_acts', id, 'delete', 'Se elimino el acta constitutiva')
                                    SwalFire('Bien', 'Se ha eliminado el acta', 'success')
                                }
                                else  {
                                    await logAction('constitutive_acts', id, 'delete', 'Error al eliminar el acta constitutiva')
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                }
                            }  

                            if(pillar_code === 'asa-ord' || pillar_code === 'asa-ext'){
                                if(all && ids.length)
                                    acts = await PUT(`delete-assembly-acts`, {ids})
                                else
                                    acts = await PUT(`delete-assembly-acts/${id}`)
                                
                                if(acts?.statusCode === 200) {
                                    await logAction('assembly_acts', id, 'delete', 'Se elimino el acta de asamblea')
                                    SwalFire('Bien', 'Se ha eliminado el acta', 'success')
                                }
                                else  {
                                    await logAction('assembly_acts', id, 'delete', 'Error al eliminar el acta')
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                }
                            }  

                            if(pillar_code === 'fed-hec'){
                                if(all && ids.length)
                                    acts = await PUT(`delete-statements-facts`, {ids})
                                else
                                    acts = await PUT(`delete-statements-facts/${id}`)

                                if(acts?.statusCode === 200) {
                                    await logAction('statements_facts', id, 'delete', 'Se elimino el acta de hechos')
                                    SwalFire('Bien', 'Se ha eliminado el registro', 'success')
                                }
                                else { 
                                    await logAction('statements_facts', id, 'delete', 'Error al eliminar el acta de hechos')
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                }
                            } 

                            if(['pwr-ppc', 'pwr-paa', 'pwr-pad', 'pwr-pes'].includes(pillar_code)){
                                if(all && ids.length)
                                    acts = await PUT(`delete-powers`, {ids})
                                else
                                    acts = await PUT(`delete-power/${id}`)

                                if(acts?.statusCode === 200) {
                                    await logAction('powers', id, 'delete', 'Se elimino el poder')
                                    SwalFire('Bien', 'Se ha eliminado el registro', 'success')
                                }
                                else  {
                                    await logAction('powers', id, 'delete', 'Error al eliminar el poder')
                                    SwalFire('Atencion!', acts?.message, 'warning')
                                }
                            }
                        }
                    }
                } catch (error) {
                    await logAction('powers', id, 'delete', 'Error al eliminar el poder')
                    console.log(`Ha ocurrido un error al editar el registro`);
                }
            }
        }),
        {
            name: encrypt_data('pillar_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)