import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { GET, POST, PUT} from './../utils/AuthService'
import { encrypt_data } from './../utils/JSImplements'
import { SwalFire, SwalfireConfirm } from './../utils/index'
import { readFile } from './../utils/AWS'
import { logAction } from '../utils/logAction'
export const UseStoreUsers = create(
    persist(
        (set, get) => ({
            users: [],
            fetchUsers: async (image = false) => {
                const res = await GET('get-users')
                if (res?.data?.length){
                    const arr = res?.data
                    if(image){
                        for (let index = 0; index < arr.length; index++) {
                            const element = arr[index];
                            if(element?.image){
                                const image = await readFile(`/imagen_usuarios`, element?.image)
                                if(image?.statusCode === 200)
                                    arr[index] = {...element, img: image?.data?.url}
                            }else{
                                arr[index] = {...element, img: ''} 
                            }
                        }
                    }

                    set({users: arr})
                }
            },
            get_image_user: async (url) => {
                try{
                    const image = await readFile(`/imagen_usuarios`, url)
                    if(image?.statusCode === 200)
                        return image?.data?.url
                    else
                        return ''
                    
                }catch(e){
                    console.log(`Ha ocurrido un error al cargar la imagen del usuario`);
                }
            },
            addUser: async(payload) => {
                try{
                    const { users, fetchUsers} = get()
                    const issetRol = users?.find(x => x?.email === payload?.email && x?.company_id === payload?.company_id)
            
                    if(issetRol){
                        SwalFire('Advertencia', 'El usuario ya existe en la empresa.', 'warning')
                        return 2
                    } else{
                        //Enviamos a enviar la informacion del usuario para poder tener su id y poder asignar el rol
                        const res = await POST('add-user', payload)

                        //Validamos si se pudo guardar el usuario
                        if(res?.statusCode === 201){
                            await logAction('users', res?.data?.otp_secret?.user_id, 'create', 'Se ha agregado un nuevo usuario')
                            //Asignamos el id del usuario
                            const user_id = res?.data?.otp_secret?.user_id

                            //Consultamos los permisos del rol para asignarlos al usuario
                            const get_permision_role = await POST('get-role-permissions', {search_id: res?.data?.otp_secret?.user?.rol_id})
                            
                            //Validamos que el rol tenga permisos asignados
                            if(get_permision_role?.data?.length > 0){
                                //Preparamos la data para guardar los permisos
                                const data_permission = get_permision_role?.data?.map(x => ({
                                    id: x?.section_id,
                                    user_id,
                                    companie_id: x?.companie_id,
                                    module: x?.module,
                                    section: x?.section,
                                    view: x?.view,
                                    add: x?.add,
                                    edit: x?.edit,
                                    delete: x?.delete,
                                    download: x?.download
                                }))

                                //Asignamos los permisos del rol al usuario
                                const asign_permission = await POST('asign-permissions-user', data_permission)

                                //Validamos si se asignaron los permisos
                                if(asign_permission?.statusCode === 200){
                                    set({
                                        users: [...users, 
                                            {
                                                id: user_id,
                                                ...payload
                                            }
                                        ]
                                    })
                                    fetchUsers(true)
                                    return res
                                }else{
                                    //Eliminamos el usuario sino se pudieron asignar los permisos
                                    await PUT(`deactivate-user/${user_id}`)
                                    return false
                                }
                            }else{
                                SwalFire('Información', 'No hay permisos para asignar al usuario', 'info')
                                return 404
                            }
                        }else{
                            await logAction('users', 0, 'create', 'Error al agregar el usuario')
                            return res
                        }
                    }
                }catch(e){
                    console.log(`Ha ocurrido un erro al agregar el usuario ${e.message}`);
                    return false
                }
            },
            updateUser: async (id, payload) => {
                try{
                    const { users, fetchUsers } = get()
                    //Clonamos los roles para hacer inmutabilidad 
                    const newUsers = structuredClone(users)
                    //Obtenemos el indioce del rol dentro del Array
                    const userIndex = users?.findIndex(row => row?.id === id)
                    const res = await PUT(`update-user/${id}`, payload)
                    const rol_id = users[userIndex]?.rol_id
                    console.log(rol_id, "ROL ID")
                    console.log(payload?.rol_id, "PAYLOAD")
                    if(res?.statusCode === 201){
                        await logAction('users', id, 'update', 'Se ha actualizado un usuario')
                        //Se cambia la informacino en la copia de los roles
                        newUsers[userIndex] = {
                            ...payload
                        }
                        //Actualizamos el estado de Zustand
                        set({users: newUsers})
                        
                        //Consultamos los permisos del rol para asignarlos al usuario
                        if(payload?.rol_id !== rol_id){
                            const get_permision_role = await POST('get-role-permissions', {search_id: res?.data?.rol_id})
                            console.log(res?.data)
                            console.log(get_permision_role)
                                
                            // //Validamos que el rol tenga permisos asignados
                            if(get_permision_role?.data?.length > 0){
                                console.log("Se han encontrado permisos para asignar al usuario")
                                //Preparamos la data para guardar los permisos
                                const data_permission = get_permision_role?.data?.map(x => ({
                                    id: x?.section_id,
                                    user_id: id,
                                    companie_id: x?.companie_id,
                                    module: x?.module,
                                    section: x?.section,
                                    view: x?.view,
                                    add: x?.add,
                                    edit: x?.edit,
                                    delete: x?.delete,
                                    download: x?.download
                                }))
                                console.log(data_permission)
                                //Asignamos los permisos del rol al usuario
                                const asign_permission = await POST('asign-permissions-user', data_permission)

                                //Validamos si se asignaron los permiso
                                console.log(asign_permission)
                                if(asign_permission?.statusCode === 200){
                                    set({
                                        users: [...users, 
                                            {
                                                id: id,
                                                ...payload
                                            }
                                        ]
                                    })
                                    console.log("Se ha actualizado el usuario")
                                    fetchUsers(true)
                                    return true
                                }else{
                                    //Eliminamos el usuario sino se pudieron asignar los permisos
                                    console.log("No se ha podido asignar los permisos")
                                    await PUT(`deactivate-user/${id}`)
                                    return false
                                }
                            }else{
                                console.log("No hay permisos para asignar al usuario")
                                SwalFire('Información', 'No hay permisos para asignar al usuario', 'info')
                                return 404
                            }
                        }
                        return true
                    }else{
                        console.log("No se ha podido guardar el usuario")
                        SwalFire('Advertencia', 'No se ha podido guardar el usuario', 'warning')
                        return false
                    }
                }catch{
                    console.log(`Ha ocurrido un error al actualizar el usuario`);
                    return false
                }
            },
            deleteUser: async (id_user) => {
                try {
                    const { users } = get()
                    const resSwal = await SwalfireConfirm('¿Está seguro que desea eliminar el usuario?')
                    if(resSwal){
                        const res = await PUT(`deactivate-user/${id_user}`)
                        if(res?.statusCode === 200){
                            let newUsers = structuredClone(users)
                            //Obtenemos el indioce del rol dentro del Array
                            const userIndex = users?.findIndex(row => row?.id === id_user)
                            //Obtenemos la informacion del rol
                            const userInfo = newUsers[userIndex]
                            //Actulizamos el estado de ese rol en sustan
                            userInfo.status = 0
                            //Se cambia la informacino en la copia de los roles
                            newUsers[userIndex] = {
                                ...userInfo
                            }
        
                            newUsers = newUsers.filter(x=>x.status !==0) 
                            set({users: newUsers})
                            return true
                        }else
                            return false 
                    }
                }catch (e) {
                    console.log(`Ocurrio un error al actulizar el usuario: ${e.message}`);
                    return false
                }
            },
            syncPermissions: async (user_id) => {
                try {
                    const { users, fetchUsers } = get()
                    //consultamos el rol id del usuario para poder asignar los permisos
                    const user = users?.find(x => x?.id === user_id)
                    //Consultamos los permisos del rol para asignarlos al usuario
                    const get_permision_role = await POST('get-role-permissions', {search_id: user?.rol_id})    
                    // //Validamos que el rol tenga permisos asignados
                    console.log(get_permision_role)
                    console.log(user)
                    if(get_permision_role?.data?.length > 0){
                        console.log("Se han encontrado permisos para asignar al usuario")
                        //Preparamos la data para guardar los permisos
                        const data_permission = get_permision_role?.data?.map(x => ({
                            id: x?.section_id,
                            user_id: user_id,
                            companie_id: x?.companie_id,
                            module: x?.module,
                            section: x?.section,
                            view: x?.view,
                            add: x?.add,
                            edit: x?.edit,
                            delete: x?.delete,
                            download: x?.download
                        }))
                        console.log(data_permission)
                        //Asignamos los permisos del rol al usuario
                        const asign_permission = await POST('asign-permissions-user', data_permission)

                        //Validamos si se asignaron los permiso
                        console.log(asign_permission)
                        if(asign_permission?.statusCode === 200){
                            console.log("Se ha actualizado el usuario")
                            fetchUsers(true)
                            return true
                        }else{
                            //Eliminamos el usuario sino se pudieron asignar los permisos
                            console.log("No se ha podido asignar los permisos")
                            await PUT(`deactivate-user/${user_id}`)
                            return false
                        }
                    }else{
                        console.log("No hay permisos para asignar al usuario")
                        SwalFire('Información', 'No hay permisos para asignar al usuario', 'info')
                        return 404
                    }
                } catch (e) {
                    console.log(`Ocurrio un error al sincronizar los permisos del usuario: ${e.message}`);
                    return false
                }
            },
            log: async (payload) => {
                const result = await POST("/log", payload, "actosgubernamentales");
                console.log("Log",payload);
                console.log("Result",result);
              }
        }),
        {
            name: encrypt_data('users_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)