import React from 'react';
const NewRequest = ({ name_user, type_designation, id_activity, date, descripcion, date_end }) => {
  return (
    <div>
        <p>Estimado/a {name_user},</p>
        <p>Es un placer informarte que has sido designado/a como el/a {type_designation} de un documento en una nueva solicitud dentro de la plataforma DPP</p>
        <p>Detalles clave de la solicitud:</p>
        <ul>
            <li>ID de Actividad: {id_activity}</li>
            {date && <li>Fecha: {date}</li>}
            {descripcion && <li>Descripción de la solicitud: {descripcion}</li>}
            {date_end && <li>Fecha final: {date_end}</li>}
        </ul>
        <p>Por favor, ingresa al siguiente enlace  
            <a href={`${process.env.REACT_APP_URL}`}> aquí </a> 
            para revisar la descripción detallada de la solicitud. Si tienes alguna pregunta o necesitas aclaraciones adicionales, no dudes en comunicarte con el solicitante registrado.</p>
            {type_designation !== 'resguardatario' &&
              <p>Mantente actualizado/a sobre cualquier desarrollo relacionado con la tarea y asegúrate de cumplir con los plazos establecidos. Apreciamos tu compromiso y dedicación.</p>
            }
        <p>¡Gracias por tu colaboración!</p>
        <p>Saludos cordiales.</p>
    </div>
  );
}

export default NewRequest;
