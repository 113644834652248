import React from 'react'
import { Box, Grid, IconButton, Tab, Tabs, Typography, styled, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input } from '../Common/MUInput';
import { TableAgenda } from './TableAgenda';
import { FromBusinessRelated } from './FormBussinessRelated';
import { Controller } from 'react-hook-form';

export const TabsContent = (props) => {
    const {actor_id, control, companies, obligationsFields, businessRelatedFields, handleSetAppend, 
      handleRemove, isView, isEdit, requests_actors } = props;
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const styles = {
      label: {
        fontFamily: "Poppins-SemiBold",
        fontSize: "14px",
        fontWeight: "600",
      },
    };
    
    const CustomTabPanel = (props) => {
        const { children, value, index, ...other } = props;  
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
          fontFamily: "Poppins-SemiBold",
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: 20,
          marginRight: theme.spacing(2),
          textAlign: 'left',
          borderRadius: '15px 15px 0px 0px',
          width: '32%',
          color: '#fff',
          '&.Mui-selected': {
            color: '#fff',
          },
          '&.Mui-focusVisible': {
            backgroundColor: '',
          },
        }),
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  '& .MuiTabs-indicator': {
                    display: 'none',
                  },
                }}
              >
                <StyledTab
                  label="Agenda operativa"
                  {...a11yProps(0)}
                  sx={{
                    backgroundColor: '#2FADCF',
                    '&.Mui-selected': {
                      backgroundColor: '#28A0BF',
                      borderBottom: '4px solid #1E7A8F',
                    },
                  }}
                  // disabled={Boolean(!isView || isEdit)}
                />
                <StyledTab
                  label="Compromisos"
                  {...a11yProps(1)}
                  sx={{
                    backgroundColor: '#3fe7af',
                    '&.Mui-selected': {
                      backgroundColor: '#35D9A0',
                      borderBottom: '4px solid #2AB080',
                    },
                  }}
                />
                <StyledTab
                  label="Negocios relacionados"
                  {...a11yProps(2)}
                  sx={{
                    backgroundColor: '#6966cd',
                    '&.Mui-selected': {
                      backgroundColor: '#5A57BE',
                      borderBottom: '4px solid #4542A0',
                    },
                  }}
                />
              </Tabs>
            </Box>
            
            
              <CustomTabPanel value={value} index={0}>
                <TableAgenda requests={requests_actors} control={control} companies={companies}/>
              </CustomTabPanel>
            


            <CustomTabPanel value={value} index={1}>
              <Grid container>
                <Grid item xs={11}>
                </Grid>
                <Grid item xs={1}  display={'flex'} justifyContent={'center'}>
                    {!isView && <IconButton aria-label="add" size='large' onClick={()=> handleSetAppend('obligations')}>
                        <AddIcon />
                    </IconButton>}
                </Grid>
                    {console.log(obligationsFields)}
                    {obligationsFields
                    ?.filter(field => field.status)
                    ?.map((field, i) => (
                        <>
                            <Grid xs={0.5}>
                              <Typography sx={{textAlign: 'left', fontFamily: "Poppins-SemiBold", fontSize: 18, mt: 6}}>
                                {i+1}.-
                              </Typography>
                            </Grid>
                            <Grid item xs={10.5} p={1}
                              display="flex"
                              flexDirection="column"
                              gap={2}
                            >
                              <Typography sx={styles.label}>Ingresa un compromiso</Typography>
                                {/* <Input
                                  name={`obligations.${i}.description_obligation`}
                                  control={control}
                                  props={{
                                    size: 'small',
                                    fullWidth: true,
                                    autoComplete:'none',
                                    disabled: isView
                                  }}
                                /> */}
                                 <Controller
                                  name={`obligations.${i}.description_obligation`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      variant="outlined"
                                      multiline
                                      fullWidth
                                      autoComplete="none"
                                      disabled={isView}
                                      InputProps={{
                                        style: {
                                          color: 'black',
                                          '-webkit-text-fill-color': 'black',
                                        },
                                      }}
                                      sx={{
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          color: 'black',
                                          '-webkit-text-fill-color': 'black',
                                        },
                                      }}
                                    />
                                  )}
                                  />
                            </Grid>
                            <Grid item xs={1} p={1} display={'flex'} justifyContent={'center'}>
                              {!isView && <IconButton aria-label="delete" size='large' onClick={() => handleRemove(i, 'obligations')}>
                                    <DeleteIcon />
                                </IconButton>}
                            </Grid>
                        </>
                      ))}
              </Grid>
            </CustomTabPanel>


            <CustomTabPanel value={value} index={2}>
              <FromBusinessRelated
                businessRelatedFields={businessRelatedFields}
                handleSetAppend={handleSetAppend}
                handleRemove={handleRemove}
                control={control}
                isView={isView} 
              />
            </CustomTabPanel>
            
        </Box>
    )
}