import React, { useEffect } from "react";
import { UseStoreTemplateView } from "../../stores/TemplateView";
import { Aside } from "./aside";
import { Header } from "./header";
import { AsideStyled, HeaderStyled, LayoutStyled, MainStyled } from "./styled";

export const LayoutAdminV = ({ children, Logout }) => {
  const { type_user } = children?.props;
  const updateLabelNav = UseStoreTemplateView((state) => state.updateLabelNav);

  useEffect(() => {
    updateLabelNav("DPP");
  });

  return (
    // <>
    <LayoutStyled>
      <HeaderStyled>
        <Header Logout={Logout} />
      </HeaderStyled>
      <AsideStyled>{type_user === 1 && <Aside />}</AsideStyled>
      <MainStyled>{children}</MainStyled>
    </LayoutStyled>
  );
};
