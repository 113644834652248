import { SwalFire } from '../utils/'
import { renderToString } from 'react-dom/server'
import  NewRequest from '../components/Emails/NewRequest'
import  ResponsibilityEmail from '../components/Emails/ResponsibleRequirement'
import  CommentNotificationEmail from '../components/Emails/CommentNotification'
import  RequirementCommentNotificationEmail from '../components/Emails/RequirementObservation'
import  WelcomeEmail from '../components/Emails/Welcome'
import  Control from '../components/Emails/Control'
import { logAction } from './logAction'

const AsyncFetch = async (token, template, type_designation) => {
    const extractEmails = (template) => {
        const recipients = template.message.toRecipients;
        const emails = recipients.map(recipient => recipient.emailAddress.address);
        return emails.join(', ');
      };
    const extractTextContent = (template, maxLength = 250) => {
        const htmlContent = template.message.body.content;
        const textContent = htmlContent.replace(/<[^>]*>/g, ' ');
        const cleanedContent = textContent.replace(/\s+/g, ' ').trim();
        const excerpt = cleanedContent.slice(0, maxLength);
        return excerpt.length < cleanedContent.length ? excerpt + '...' : excerpt;
      };
    try {
        
        const response = await fetch('https://graph.microsoft.com/v1.0/me/sendMail', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(template),
        });
        if (response.ok) {
            logAction('email', 0, 'email', 'Correo enviado a '+ extractEmails(template) + " " + template.message.subject + " " + extractTextContent(template))
            SwalFire('Exito!','Correo electrónico enviado con éxito.', 'success');
            return 
        } else {
            logAction('email', 0, 'email', 'Error al enviar el correo electrónico al '+ extractEmails(template) + ' ' + JSON.stringify(response), false)
            SwalFire('Atención!','Error al enviar el correo electrónico al '+ type_designation +': '+ response.statusText, 'info');
            console.error('Error al enviar el correo electrónico: '+ JSON.stringify(response));
            return
        }
    } catch (error) {
        
        let toRecipients = template?.message.toRecipients || 'Destinatario no encontrado'
        logAction('email', 0, 'email', 'Error al enviar el correo electrónico al '+ toRecipients + ' ' + JSON.stringify(error) , false)
        SwalFire('Atención!','Error al enviar el correo electrónico al '+ type_designation, 'info');
        console.log('error', JSON.stringify(error))
        console.error('Error al enviar el correo electrónico:', JSON.stringify(error));
        return 
    }
}

const GetRecipients = (recipients) => {
    return recipients?.map(x => ({
        emailAddress: {
            address: x?.email,
        }
    }))
}

const GetTemplate = (subject, contentHTML, toRecipients) => {
    return {
        message: {
            subject: subject,
            body: {
                contentType: 'HTML',
                content: contentHTML,
            },
            toRecipients
        }
    }
}

export const NewRequestMailer = async (token, data_content, type_designation, subject, Recipients) => {
    console.log('newRequest')
    const toRecipients = GetRecipients(Recipients)
    const contentHTML = renderToString(
        <NewRequest 
            name_user={data_content?.name_user}
            id_activity={data_content?.id_activity}
            date={data_content?.date}
            date_end={data_content?.date_end}
            descripcion={data_content?.descripcion}
            type_designation={type_designation}
        />
    );  

    const TEMPLATE = GetTemplate(subject, contentHTML, toRecipients)
    await AsyncFetch(token, TEMPLATE, type_designation)
}

export const NewRequirementMailer = async (token, data_content, subject, Recipients) => {
    console.log('newRequirement')
    const toRecipients = GetRecipients(Recipients)
    const contentHTML = renderToString(
        <ResponsibilityEmail
            name_user={data_content?.name_user}
            id_activity={data_content?.id_activity}
            description={data_content?.description}
            date_delivery={data_content?.date_delivery}
            documentation={data_content?.documentation}
            observation={data_content?.observation}
        />
    );  

    const TEMPLATE = GetTemplate(subject, contentHTML, toRecipients)
    await AsyncFetch(token, TEMPLATE, 'Requisito')
}

export const NewCommentMailer = async (token, data_content, type_designation, subject, Recipients) => {
    console.log('newComment')
    const toRecipients = GetRecipients(Recipients)
    const contentHTML = renderToString(
        <CommentNotificationEmail 
            name_user={data_content?.name_user}
            id_activity={data_content?.id_activity}
            date={data_content?.date}
            date_end={data_content?.date_end}
            descripcion={data_content?.descripcion}
            type_designation={type_designation}
            applicant={data_content?.applicant}
            responsible={data_content?.responsible}
            comment={data_content?.comment}
            user_comment={data_content?.user_comment}
            status={data_content?.status}
        />
    );  

    const TEMPLATE = GetTemplate(subject, contentHTML, toRecipients)
    await AsyncFetch(token, TEMPLATE, type_designation)
}

export const NewObservationRequirementMailer = async (token, data_content, subject, Recipients) =>{
    console.log('newObservation')
    const toRecipients = GetRecipients(Recipients)
    const contentHTML = renderToString(
        <RequirementCommentNotificationEmail 
            userName={data_content?.name_user}
            activityId={data_content?.id_activity}
            activityDescription={data_content?.description}
            activityState={data_content?.state}
            commenterName={data_content?.user_comment}
            deliveryDate={data_content?.delivery_date}
            documentation={data_content?.documentation}
            dueDate={data_content?.date_end}
            observation={data_content?.observation}
        />
    );  

    const TEMPLATE = GetTemplate(subject, contentHTML, toRecipients)
    await AsyncFetch(token, TEMPLATE, 'Observacion del requisito')
}

export const WelcomeUserMailer = async (token, token_otp, Recipients) =>{
    console.log('welcome')
    const toRecipients = GetRecipients(Recipients)
    const contentHTML = renderToString(
        <WelcomeEmail 
            token_auth={token_otp}
        />
    );  

    const TEMPLATE = GetTemplate('¡Bienvenido/a! Registro exitoso - Plataforma DPP', contentHTML, toRecipients)
    await AsyncFetch(token, TEMPLATE, 'Observacion del requisito')
}

export const ControlMailer = async (token, data_content, subject, Recipients) => {
    const toRecipients = GetRecipients(Recipients)
    const contentHTML = renderToString(
        <Control
            name_user={data_content?.name_user}
            control={data_content?.control_status}
            id_activity={data_content?.id_activity}
            date={data_content?.date}
            date_end={data_content?.date_end}
            descripcion={data_content?.descripcion}
            type_designation={data_content?.type_designation}
        />
    )

    const TEMPLATE = GetTemplate(subject, contentHTML, toRecipients)
    await AsyncFetch(token, TEMPLATE, data_content?.type_designation)
}