import styled from "styled-components";

export const Image = styled.img`
  width: 100px;
  height: 100px;
`;

export const Main = styled.div`
  width: 100%;
  height: 91vh;
`;
export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #e3e3e3;
`;
export const Container = styled.div`
  margin: 0px;
  padding: 0px;
  width: 85%;
  padding: 15px;
  overflow: scroll;
`;
export const SideBar = styled.div`
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SiderBarAdmin = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
`;

// new styles

export const LayoutStyled = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 70px 1fr;
  grid-template-areas:
    "header header"
    "aside main";
  height: 100vh;
  width: 100%;
  background: #f6f7fb;
`;

export const HeaderStyled = styled.header`
  grid-area: header;
  background-color: #fff;
  color: #171717;
  display: flex;
  box-shadow: none;
  height: 70px;
  justify-content: center;
  align-items: center;
  background: #666f87;
`;

export const AsideStyled = styled.aside`
  grid-area: aside;
  color: #fff;
  display: flex;
  width: 300px;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  transform: translateX(0);
  transition: transform 0.1s ease-in-out, width 0.3s ease-in-out; /* Agrega una transición suave al transform y la anchura */
  background: #fff;
`;

export const MainStyled = styled.main`
  grid-area: main;
  display: flex;
  background-color: #dcdcdc;
  color: #000;
  overflow: auto;
  background: #f6f7fb;
`;
