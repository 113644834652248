import { LicenseInfo } from "@mui/x-license-pro";
import React, { useState, useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  esES,
  gridClasses,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { DataGridFooter } from "./DataGridFooter";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_KEY);

const escapeRegExp = (value) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

const InputSearch = ({ value, onChange, clearSearch }) => {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={2}>
      <Box>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </Box>
      <TextField
        variant="outlined"
        value={value}
        onChange={onChange}
        size="small"
        placeholder="Buscar..."
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Limpiar"
              aria-label="Limpiar"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export const TableListAdapter = ({
  columns,
  data,
  isLoading = false,
  height = false,
  handleRowClick,
  processRowUpdate,
  handleCellClick,
  search = true,
  props,
  countRows = 7,
}) => {
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = useState(data);
  const [showFooter, setShowFooter] = useState(true);
  const Height = height ? height : data?.length ? 700 : 300;
  let account = 1;

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter((row) => {
      // eslint-disable-next-line array-callback-return
      return Object.keys(row).some((field) => {
        if (row[field] !== null) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <Box style={{ height: Height, width: "100%" }}>
      <DataGridPro
        {...props}
        slots={{
          ...props?.slots,
          toolbar: search ? InputSearch : null,
          footer: !!props?.slots?.footer
            ? props?.slots?.footer
            : () => (
                <DataGridFooter
                  active={showFooter && rows?.length > countRows && !searchText}
                  handleClick={() => {
                    setShowFooter(false);
                  }}
                />
              ),
        }}
        slotProps={{
          toolbar: {
            value: searchText,
            onChange: (e) => requestSearch(e.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
        initialState={{
          ...props?.initialState,
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [],
            },
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        disableColumnFilter
        disableDensitySelector
        disableRowSelectionOnClick
        disableColumnSelector
        sx={{
          "& .MuiDataGrid-row": {
            ":hover": {
              background: "#1AAFD01A",
            },
          },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            background: "#E2E4E7",
            borderLeft: "3px solid #d7d9dc",
            boxShadow: "none",
          },
          "& .MuiDataGrid-pinnedColumns": {
            borderLeft: "3px solid #E2E4E7",
            boxShadow: "none",
          },
          background: "#FFFFFF",
          border: "none",
          "& .MuiDataGrid-columnHeaders": {
            background: "#E2E4E7",
          },
          // padding: 1,
          "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "600",
            color: "#323338",
          },
          "& .MuiDataGrid-cellContent": {
            fontFamily: "Poppins-Regular",
            fontSize: "14px",
            fontWeight: "400",
            color: "#323338",
          },
          [`& .${gridClasses.cell}`]: {
            cursor: "pointer",
            py: 1.5,
          },
        }}
        getRowId={(row) => (account += 1)}
        rows={
          showFooter && rows?.length > countRows && !props?.slots?.footer && !searchText
            ? rows?.filter((_, index) => index < countRows)
            : rows
        }
        columns={columns}
        loading={isLoading}
        pageSizeOptions={[10, 25, 50, 100]}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowHeight={() => "auto"}
        onRowClick={(row) => handleRowClick(row)}
        onCellClick={(params) => handleCellClick(params)}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  );
};
