import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
// import Button from "react-bootstrap/Button";
import Button from "@mui/material/Button";
const baseButtonStyle = {
  // fontSize: '24px',
  // paddingLeft: '20px',
  // paddingRight: '20px',
  border: "none",
  color: "black",
  textTransform: "inherit",
  fontFamily: "Poppins-SemiBold",
  textAlign: "right",
  fontSize: "14px",
  width: "135px",
  height: "49px",
};

export const SignOutButton = (props) => {
  const { instance } = useMsal();
  const [buttonStyle, setButtonStyle] = useState(baseButtonStyle);

  useEffect(() => {
    if (props.buttonStyle) {
      setButtonStyle(props.buttonStyle);
    }
  }, [props.buttonStyle]);

  const handleLogout = () => {
    localStorage.setItem("authToken", null);
    localStorage.setItem("refreshToken", null);
    instance.logoutPopup({
      postLogoutRedirectUri: "/login",
      mainWindowRedirectUri: "/login",
    });
  };

  return (
    <Button variant="outlined" onClick={handleLogout} style={buttonStyle}>
      Cerrar sesión
    </Button>
  );
};
