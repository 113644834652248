import React from 'react';

const RequirementCommentNotificationEmail = ({ userName, activityId, activityDescription, activityState, 
    commenterName, dueDate, documentation, observation, deliveryDate 
}) => {
  return (
    <div>
        <p>Estimado/a {userName},</p>
        <p>Te informamos que se ha agregado un nuevo comentario sobre un requisito para atender la solicitud con ID {activityId} en la que estás registrado como Responsable. Este comentario puede contener información relevante, actualizaciones o aclaraciones sobre la tarea.</p>
        <p>Detalles de la solicitud:</p>
        <ul>
            <li>Estado de la actividad: {activityState}</li>
            <li>Nuevo comentario: {`${observation} - ${commenterName}`}</li>
            <li>ID de Actividad: {activityId}</li>
            <li>Descripción de la solicitud: {activityDescription}</li>
            <li>Fecha de Vencimiento: {dueDate}</li>
        </ul>
        <p>Requisito para el trámite: </p>
        <ul>
            <li>Documentación: {documentation}</li>
            <li>Nueva observación: {observation}</li>
            <li>Fecha de entrega de la información: {deliveryDate}</li>
        </ul>
        <p>Para revisar el historial de las observaciones y el detalle de la solicitud, por favor sigue el siguiente enlace <a href={`${process.env.REACT_APP_URL}`}> aquí </a>.</p>
        <p>Si tienes alguna pregunta, necesitas más información o deseas discutir algún aspecto de la tarea, no dudes en agregar tus propios comentarios en la plataforma.</p>
        <p>Gracias por tu colaboración.</p>
        <p>Saludos cordiales.</p>
    </div>
  );
}

export default RequirementCommentNotificationEmail;
