import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { TableListAdapter } from "../Common/DataGridAdapter";

export const RolesV = ({
  data: {
    vars: { type_user, roles, isLoading },
    funcs: {
      setPath,
      HandleView,
      HandleUpdate,
      FetchRoles,
      updateStateRol,
      DeleteRol,
      setIsLoading,
    },
  },
}) => {
  useEffect(() => {
    FetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (event, id) => {
    const { checked } = event?.target;
    setIsLoading(true);
    const res = await updateStateRol(id, checked);
    if (res || !res) setIsLoading(false);
  };

  const HandleDelete = async (id) => {
    setIsLoading(true);
    const res = await DeleteRol(id);
    if (res || !res) setIsLoading(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 4,
      headerAlign: "center",
    },
    {
      field: "state",
      headerName: "Habilitado",
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 4,
      renderCell: (params) => (
        <Switch
          checked={Boolean(params?.row?.state)}
          onChange={(e) => handleChange(e, params?.row?.id)}
        />
      ),
    },
    {
      field: "options",
      headerName: "Opciones",
      cellClassName: "options-className",
      description: "Muestra las opciones que se le permiten al usuario",
      sortable: false,
      flex: 2,
      align: "center",
      headerAlign: "center",
      minWidth: 200,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="Ver Rol"
            onClick={() => HandleView(params?.row)}
            size="large"
            sx={{
              color: "#323338",
              ":hover": {
                color: "#1AAFD0",
              },
            }}
          >
            <RemoveRedEyeOutlinedIcon fontSize="medium" />
          </IconButton>
          <IconButton
            aria-label="Editar Rol"
            onClick={() => HandleUpdate(params?.row)}
            size="large"
            sx={{
              color: "#323338",
              ":hover": {
                color: "#1AAFD0",
              },
            }}
          >
            <EditOutlinedIcon fontSize="medium" />
          </IconButton>
          <IconButton
            aria-label="Eliminar Rol"
            onClick={() => HandleDelete(params?.row?.id)}
            size="large"
            sx={{
              color: "#323338",
              ":hover": {
                color: "#1AAFD0",
              },
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="medium" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    // <div>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        height: "100%",
        background: "#f6f7fb",
        pl: 1,
      }}
    >
      <Box
        height="40px"
        bgcolor="#ffffff"
        p={2}
        pr={8}
        borderRadius="0px 0px 0px 7px"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        boxShadow="0px 3px 4px 0px #2222221A"
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#323338",
          }}
        >
          Lista de Roles
        </Typography>
        <Button
          startIcon={<AddIcon />}
          type="button"
          onClick={() => setPath("add-rol")}
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "capitalize",
            width: "119px",
            height: "49px",
            background: "#1AAFD0",
            color: "#FFFFFF",
            ":hover": {
              background: "#1AAFD0",
            },
          }}
        >
          Nuevo
        </Button>
      </Box>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Box
        sx={{
          height: "100%",
          width: "95%",
          bgcolor: "#FFFFFF",
          borderRadius: "7px 7px 0px 0px",
          p: "0px 16px",
          "& .options-className": {
            borderLeft: "3px solid #E2E4E7",
          },
        }}
      >
        <TableListAdapter
          columns={columns}
          data={roles}
          height="80vh"
          isLoading={!roles?.length}
          handleRowClick={() => {}}
          handleCellClick={() => {}}
          countRows={10}
        />
      </Box>
    </Box>
  );
};

RolesV.propTypes = {
  data: PropTypes.object,
};
