import React from 'react';

const CommentNotificationEmail = ({ name_user, type_designation, status, applicant, responsible, id_activity, date, descripcion, date_end, comment, user_comment }) => {
  return (
    <div>
        <p>Estimado/a {name_user},</p>
        <p>Te informamos que se ha agregado un nuevo comentario a la solicitud con ID <strong>{id_activity}</strong> en la que estás registrado como {type_designation}. Este comentario puede contener información relevante, actualizaciones o aclaraciones sobre la tarea.</p>
        <p>Detalles de la solicitud:</p>
        <ul>
            <li>Fecha: {date}</li>
            <li>Descripción de la solicitud: {descripcion}</li>
            {applicant && <li>Solicitante: {applicant}</li>}
            {responsible && <li>Responsable: {responsible}</li>}
            <li>Fecha de Vencimiento: {date_end}</li>
            <li>Estado de la actividad: {status}</li>
            <li>Nuevo comentario: {comment} - {user_comment}</li>
        </ul>
        <p>Para revisar el historial de los comentarios y el detalle de la solicitud, por favor sigue el siguiente enlace <a href={`${process.env.REACT_APP_URL}`}> aquí </a>.</p>
        <p>Si tienes alguna pregunta, necesitas más información o deseas discutir algún aspecto de la tarea, no dudes en agregar tus propios comentarios en la plataforma.</p>
        <p>Gracias por tu colaboración.</p>
        <p>Saludos cordiales.</p>
    </div>
  );
}

export default CommentNotificationEmail;
