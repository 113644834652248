import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { TextField, Autocomplete } from "@mui/material/";

const days = [
  { id: 1, name: "Lunes" },
  { id: 2, name: "Martes" },
  { id: 3, name: "Miercoles" },
  { id: 4, name: "Jueves" },
  { id: 5, name: "Viernes" },
  { id: 6, name: "Sabado" },
  { id: 7, name: "Domingo" },
];

//selectTag
const tags = [
  { id: 1, name: "Fusión" },
  { id: 2, name: "Aumento de capital" },
  { id: 3, name: "Cambio de denominación" },
  { id: 4, name: "Reforma de estatutos" },
  { id: 5, name: "Modificación objeto social" },
  { id: 6, name: "Acciones" },
  { id: 7, name: "Administrador único" },
  { id: 8, name: "Nombramiento de Director general" },
  { id: 9, name: "Revocación de poder" },
  { id: 10, name: "Otorgamiento Poderes" },
  { id: 11, name: "Compraventa" },
  { id: 12, name: "Inmuebles" },
  { id: 13, name: "Disolución" },
  { id: 14, name: "Órgano de administración" },
  { id: 15, name: "Consejo de administración" },
  { id: 16, name: "Comisario" },
  { id: 17, name: "Informe del consejo de administración" },
  { id: 18, name: "Estados financieros" },
];

export const InputMultiSelect = ({
  label,
  control,
  name,
  disabled,
  isEdit,
  module,
  props,
}) => {
  const autocompleteRef = useRef(null);
  let data = days;

  if (module === "tags") {
    data = tags.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          if (field.value === null) {
            const clearButton = autocompleteRef.current?.querySelector(
              ".MuiAutocomplete-clearIndicator"
            );

            if (clearButton && clearButton.disabled !== true) {
              clearButton.click();
            }
          }

          return (
            <Autocomplete
              ref={autocompleteRef}
              fullWidth
              multiple
              disableCloseOnSelect
              disabled={disabled}
              id="tags-filled"
              defaultValue={
                disabled || isEdit
                  ? field?.value?.map((element, index) => ({
                      id: index + 1,
                      name: element,
                    }))
                  : []
              }
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              options={data?.map((option) => option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...props}
                  fullWidth
                  value={field.value ? field.value : data[0]}
                  label={label}
                  size="small"
                  variant="outlined"
                  inputRef={field.ref}
                  onChange={field.onChange}
                  error={Boolean(fieldState.error?.message)}
                  helperText={fieldState.error?.message || ""}
                />
              )}
            ></Autocomplete>
          );
        }}
      />
    </FormControl>
  );
};
