import { UseStoreUsers } from "../stores/Users";
import { UseStoreLogin } from "../stores/Login";

export const logAction = async (tableName, recordId, action, description, success = true) => {
  const userInfo = UseStoreLogin.getState().data_user;
  const log = UseStoreUsers.getState().log;

  if (!userInfo || !userInfo.id) {
    console.error('UserInfo no válido');
    return;
  }

  const logData = {
    user_id: userInfo.id,
    table_name: tableName,
    record_id: recordId,
    action: action,
    description: description,
    success: success,
  };

  try {
    await log(logData);
  } catch (error) {
    console.error("Error al registrar la acción:", error);
  }
};