import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { gridClasses } from "@mui/x-data-grid";
import React, { useState } from "react";
import { TableListAdapter } from "../../Common/DataGridAdapter";
import { CheckboxIcon } from "../../Common/MUICbxIcon";
import { MUICheckbox } from "../../Common/MUICheckbox";
import { MUIChip } from "../../Common/MUIChip";
import DeleteColorIcon from "./../../../assets/icons/deleteColorIcon.svg";
import DiaryIcon from "./../../../assets/icons/diaryIcon.svg";
import DownloadColorIcon from "./../../../assets/icons/downloadColorIcon.svg";
import DownloadIcon from "./../../../assets/icons/downloadIcon.svg";
import EditColorIcon from "./../../../assets/icons/editColorIcon.svg";
import EditIcon from "./../../../assets/icons/editIcon.svg";
import DeleteIcon from "./../../../assets/icons/iconDelete.svg";
import PdfColorIcon from "./../../../assets/icons/pdfColorIcon.svg";
import SettingsIcon from "./../../../assets/icons/settingsIcon.svg";
import { DataGridFooter } from "../../Common/DataGridFooter";

export const MultiPilarList = ({
  control,
  // handleSelectAll,
  // columns,
  // isLoading,
  rows,
  // page,
  // rowsPerPage,
  handleChangeSelected,
  // attorney,
  row_color,
  // type_power,
  pillar,
  dayjs,
  // vigency,
  // vigency_date,
  // limitation,
  actions,
  type_user,
  setOpenModal,
  get_file,
  // status,
  navigate,
  set_value_form,
  download_or_delete,
  selecteds,
  multiple,
  HandleDeleteAll,
  HandleDownloadAll,
  setIsForm,
  alertTool,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [countRows, setCountRows] = useState(7);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const styles = {
    typography: {
      fontFamily: "Poppins-Regular",
      fontSize: "14px",
      fontWeight: "400",
      color: "#323338",
    },
  };

  // Columna PDF
  const getPDF = (row) => (
    <Tooltip title="Ver PDF">
      <IconButton
        sx={{
          width: 45,
          height: 45,
          color: { row_color },
        }}
        onClick={() => {
          if (actions?.view || type_user === 1) {
            setOpenModal({
              open: true,
              type: 2,
              url: row?.pdf_link,
            });
            get_file(row?.pdf_link);
          } else {
            alertTool("Usted no tiene este permiso.", {
              type: "warning",
              title: "Atención",
              autoClose: true,
            });
          }
        }}
      >
        <img src={PdfColorIcon} alt="pdfColorIcon" />
      </IconButton>
    </Tooltip>
  );
  // Columna de acciones
  const getActions = (row) => (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Tooltip title="Ver agenda">
        <IconButton
          color="warning"
          id="agenda"
          onClick={() =>
            navigate("/panel-agenda-operational", {
              replace: true,
            })
          }
        >
          <img src={DiaryIcon} alt="DiaryIcon" />
        </IconButton>
      </Tooltip>
      {(type_user === 1 || actions?.edit) && (
        <Tooltip title="Editar">
          <IconButton
            id="edit"
            onClick={() => {
              set_value_form(row);
              setIsForm(true);
            }}
            sx={{
              ".EditColorIcon": { display: "none" },
              ":hover": {
                ".EditColorIcon": { display: "block" },
                ".EditIcon": { display: "none" },
              },
            }}
          >
            <img className="EditIcon" src={EditIcon} alt="EditIcon" />
            <img
              className="EditColorIcon"
              src={EditColorIcon}
              alt="EditColorIcon"
            />
          </IconButton>
        </Tooltip>
      )}
      {(type_user === 1 || actions?.delete) && (
        <Tooltip title="Eliminar">
          <IconButton
            id="delete"
            color="error"
            onClick={() => download_or_delete(row, "delete")}
            sx={{
              ".DeleteColorIcon": { display: "none" },
              ":hover": {
                ".DeleteColorIcon": { display: "block" },
                ".DeleteIcon": { display: "none" },
              },
            }}
          >
            <img className="DeleteIcon" src={DeleteIcon} alt="DeleteIcon" />
            <img
              className="DeleteColorIcon"
              src={DeleteColorIcon}
              alt="DeleteColorIcon"
            />
          </IconButton>
        </Tooltip>
      )}
      {(type_user === 1 || actions?.download) && (
        <Tooltip title="Descargar">
          <IconButton
            id="download"
            color="secondary"
            onClick={() => download_or_delete(row, "download")}
            sx={{
              ".DownloadColorIcon": { display: "none" },
              ":hover": {
                ".DownloadColorIcon": { display: "block" },
                ".DownloadIcon": { display: "none" },
              },
            }}
          >
            <img
              className="DownloadIcon"
              src={DownloadIcon}
              alt="DownloadIcon"
            />
            <img
              className="DownloadColorIcon"
              src={DownloadColorIcon}
              alt="DownloadColorIcon"
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  // Columna de configuración
  const getConfig = () => {
    return selecteds?.filter((x) => x?.selected)?.length && multiple ? (
      <IconButton type="button" aria-describedby={id} onClick={handleClick}>
        <img src={SettingsIcon} alt="ConfigIcon" />
      </IconButton>
    ) : (
      <Tooltip title="Seleccionar una fila para ver opciones">
        <IconButton type="button">
          <img src={SettingsIcon} alt="ConfigIcon" />
        </IconButton>
      </Tooltip>
    );
  };

  // Columna de checks
  const getCheckBox = (row) => (
    <MUICheckbox
      name={`selected_${row?.id}`}
      control={control}
      props={{
        onClick: (e) => handleChangeSelected(e, row),
      }}
      checked={row?.selected}
    />
  );

  // Columna de inscripción en el registro público de comercio
  const getIRPC = (row) => (
    <Stack flexDirection="row">
      {row?.irpc?.verification === 1 && (
        <Typography sx={styles.typography}>
          {`  Partida: ${row?.irpc?.entry_number || ""},
                                                  Volumen: ${
                                                    row?.irpc?.volume || ""
                                                  },
                                                  Libro: ${
                                                    row?.irpc?.book || ""
                                                  },
                                                  ${
                                                    pillar === "act-con" ||
                                                    pillar === "asa-ext"
                                                      ? `Folio digital: ${
                                                          row?.irpc?.folio || ""
                                                        }, `
                                                      : ""
                                                  }
                                                  Fecha: ${
                                                    row?.irpc?.registry_date
                                                      ? dayjs(
                                                          row?.irpc
                                                            ?.registry_date
                                                        )
                                                          .locale("es")
                                                          .format(
                                                            "dddd, D MMMM YYYY"
                                                          )
                                                      : ""
                                                  }
                                              `}
        </Typography>
      )}
      <Tooltip
        title={
          row?.irpc?.verification === 1
            ? "SI"
            : row?.irpc?.verification === 2
            ? "NO APLICA"
            : "NO"
        }
      >
        <CheckboxIcon
          name={"verification_db"}
          control={control}
          disabled={true}
          value={row?.irpc?.verification}
        />
      </Tooltip>
    </Stack>
  );

  // Acta constitutiva
  const columnsConstitutiveAct = [
    {
      field: "check",
      width: 60,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "check-className",
      renderHeader: () => getConfig(),
      renderCell: ({ row }) => getCheckBox(row),
    },
    {
      field: "writing_number",
      headerName: "Número de escritura",
      minWidth: 280,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "grant_date",
      headerName: "Fecha de otorgamiento",
      minWidth: 290,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) =>
        dayjs(row?.grant_date).locale("es").format("dddd, D MMMM YYYY"),
    },
    {
      field: "irpc",
      headerName: "Inscripción en el registro público de comercio",
      minWidth: 460,
      flex: 2,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getIRPC(row),
    },
    {
      field: "PDF",
      headerName: "PDF",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getPDF(row),
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 152,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getActions(row),
    },
  ];

  // Poder general
  const columnsGeneralPower = [
    {
      field: "check",
      width: 60,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "check-className",
      renderHeader: () => getConfig(),
      renderCell: ({ row }) => getCheckBox(row),
    },
    {
      field: "attorney",
      headerName: "Apoderado",
      width: 180,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "type_power",
      headerName: "Tipo de poder",
      width: 180,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) =>
        pillar === "pwr-pes"
          ? row?.power_especial?.name
          : row?.type_power?.name,
    },
    {
      field: "writing_number",
      headerName: "Número de escritura",
      width: 170,
      headerAlign: "left",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "grant_date",
      headerName: "Fecha de otorgamiento",
      width: 200,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) =>
        dayjs(row?.grant_date).locale("es").format("dddd, D MMMM YYYY"),
    },
    {
      field: "vigency",
      headerName: "Vigencia",
      width: 100,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "date_vigency",
      headerName: "Fecha de término de vigencia",
      width: 240,
      headerAlign: "left",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) =>
        dayjs(row?.date_vigency).locale("es").format("dddd, D MMMM YYYY"),
    },
    {
      field: "limitation",
      headerName: "Limitación",
      width: 100,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "irpc",
      headerName: "Inscripción en el registro público de comercio",
      width: 360,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getIRPC(row),
    },
    {
      field: "PDF",
      headerName: "PDF",
      width: 60,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getPDF(row),
    },
    {
      field: "state",
      headerName: "Estatus",
      width: 80,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) =>
        row?.state === 1 ? "Vigente" : row?.state === 2 ? "Revocado" : "",
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 152,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getActions(row),
    },
  ];

  // Asamblea ordinaria - Asamblea extraordinaria
  const columnsOrdinaryAssembly = [
    {
      field: "check",
      width: 60,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "check-className",
      renderHeader: () => getConfig(),
      renderCell: ({ row }) => getCheckBox(row),
    },
    {
      field: "writing_number",
      headerName: "Número de escritura",
      width: 170,
      headerAlign: "left",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "grant_date",
      headerName: "Fecha de otorgamiento",
      width: 200,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) =>
        dayjs(row?.grant_date).locale("es").format("dddd, D MMMM YYYY"),
    },
    {
      field: "description",
      headerName: "Descripción",
      width: 200,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "irpc",
      headerName: "Inscripción en el registro público de comercio",
      width: 360,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (pillar === "asa-ord" ? "N/A" : getIRPC(row)),
    },
    {
      field: "tags",
      headerName: "Etiquetas",
      width: 240,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Stack>
          <MUIChip tags={row?.tags} />
        </Stack>
      ),
    },
    {
      field: "PDF",
      headerName: "PDF",
      width: 60,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getPDF(row),
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 152,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getActions(row),
    },
  ];

  // Fe de hechos
  const columnsFaithOfFacts = [
    {
      field: "check",
      width: 60,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "check-className",
      renderHeader: () => getConfig(),
      renderCell: ({ row }) => getCheckBox(row),
    },
    {
      field: "writing_number",
      headerName: "Número de escritura",
      width: 170,
      headerAlign: "left",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "grant_date",
      headerName: "Fecha de otorgamiento",
      width: 220,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) =>
        dayjs(row?.grant_date).locale("es").format("dddd, D MMMM YYYY"),
    },
    {
      field: "description",
      headerName: "Descripción",
      width: 280,
      headerAlign: "center",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "PDF",
      headerName: "PDF",
      width: 460,
      headerAlign: "left",
      align: "left",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getPDF(row),
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 152,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => getActions(row),
    },
  ];

  // obtener las columnas dependiendo del pillar
  const getColumns = () => {
    switch (pillar) {
      case "act-con":
        // Acta constitutiva
        return columnsConstitutiveAct;
      case "pwr-ppc":
      case "pwr-paa":
      case "pwr-pad":
      case "pwr-pes":
        // Acta constitutiva - Poder general para actos de administración - Poder general para poder actos de dominio - Poder especial
        return columnsGeneralPower;
      case "asa-ord":
      case "asa-ext":
        // Asamblea ordinaria - Asamblea extraordinaria
        return columnsOrdinaryAssembly;
      case "fed-hec":
        // Asamblea ordinaria - Asamblea extraordinaria
        return columnsFaithOfFacts;

      default:
        return [];
    }
  };

  return (
    <Box
      sx={{
        "& .check-className": {
          borderRight: "3px solid #E2E4E7",
        },
        [`& .${gridClasses.row}.even`]: {
          background: "#1AAFD01A",
        },
      }}
    >
      <TableListAdapter
        props={{
          slots: {
            footer: () => (
              <DataGridFooter
                active={countRows === 7 && rows?.length > 7}
                handleClick={() => {
                  setCountRows(rows?.length);
                }}
              />
            ),
          },
          getRowClassName: ({ row }) => {
            return row?.selected === true ? "even" : "";
          },
          initialState: {
            pinnedColumns: { right: ["actions"] },
          },
        }}
        columns={getColumns()}
        search={false}
        data={rows?.filter((_, index) => index < countRows)}
        height="70vh"
        handleRowClick={() => {}}
        handleCellClick={() => {}}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack p="5px">
          {(type_user === 1 || actions?.download) && (
            <Button
              sx={{
                display: "flex",
                justifyContent: "start",
                background: "#FFFFFF",
                textTransform: "capitalize",
                color: "#323338",
                fontFamily: "Poppins-Regular",
                fontWeight: "400",
                fontSize: "12px",
                ".DownloadColorIcon": { display: "none" },
                ":hover": {
                  ".DownloadColorIcon": { display: "block" },
                  ".DownloadIcon": { display: "none" },
                  color: "#1AAFD0",
                  background: "#CBDAE54D",
                },
              }}
              startIcon={
                <>
                  <img
                    className="DownloadIcon"
                    src={DownloadIcon}
                    alt="DownloadIcon"
                  />
                  <img
                    className="DownloadColorIcon"
                    src={DownloadColorIcon}
                    alt="DownloadColorIcon"
                  />
                </>
              }
              onClick={() => {
                setAnchorEl(null);
                HandleDownloadAll();
              }}
            >
              Descargar
            </Button>
          )}
          {(type_user === 1 || actions?.delete) && (
            <Button
              sx={{
                display: "flex",
                justifyContent: "start",
                background: "#FFFFFF",
                textTransform: "capitalize",
                color: "#323338",
                fontFamily: "Poppins-Regular",
                fontWeight: "400",
                fontSize: "12px",
                ".DeleteColorIcon": { display: "none" },
                ":hover": {
                  ".DeleteColorIcon": { display: "block" },
                  ".DeleteIcon": { display: "none" },
                  color: "#1AAFD0",
                  background: "#CBDAE54D",
                },
              }}
              startIcon={
                <>
                  <img
                    className="DeleteIcon"
                    src={DeleteIcon}
                    alt="DeleteIcon"
                  />
                  <img
                    className="DeleteColorIcon"
                    src={DeleteColorIcon}
                    alt="DeleteColorIcon"
                  />
                </>
              }
              onClick={() => {
                setAnchorEl(null);
                HandleDeleteAll();
              }}
            >
              Eliminar
            </Button>
          )}
        </Stack>
      </Popover>
    </Box>
  );
};
