import React, { useEffect, useState} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { PillarsV } from '../../components/Company/Pillars'
import { UseStoreLogin } from '../../stores/Login'
import { UseStoreTemplateView } from '../../stores/TemplateView'
import { UseStoreCompany } from '../../stores/Company'
import { UseStorePillar } from './../../stores/Pillar'
import { SwalFire } from './../../utils'

export const PillarsC =  () => {
    const navigate = useNavigate()
    const { company_id } = useParams()
    const userInfo = UseStoreLogin(state => state.data_user)
    const refreshUserInfo = UseStoreLogin((state) => state.refreshUserInfo);
    const pillars_in_company = UseStorePillar(state => state.pillars_in_company)
    const get_pillar_by_company = UseStorePillar(state => state.get_pillar_by_company)
    const get_permission_user = UseStorePillar(state=> state.get_permission_user)
    const updateLabelNav = UseStoreTemplateView(state=> state.updateLabelNav)
    const button_back = UseStoreTemplateView(state=> state.button_back)
    const get_company = UseStoreCompany(state=> state.get_company)
    const [company, setCompany] = useState({})
    const [isLoading, setIsloading] = useState(false)

    const redirectPillar = (isSubpillar, obj, data) => {
        if(isSubpillar){
            if(obj?.view || obj?.add || obj?.delete || obj?.download) {
                navigate(`/subpillars/${data?.company_name}/${data?.pillar_code}/${data?.subpillar_code}`)
            }else {
                SwalFire('Atencion!', 'Usted no tiene permisos para ver el pillar', 'warning')
            }
        }else {
            if(obj?.view || obj?.add || obj?.delete || obj?.download) {
                navigate(`/pillars/${data?.company_name}/${data?.pillar_code}`)
            }else {
                SwalFire('Atencion!', 'Usted no tiene permisos para ver el pillar', 'warning')
            }
        }
    }

    const handleClickPreviewPillars = async (company_name, pillar_code, subpillar_code = false) => {
        setIsloading(true)
        const type_user = userInfo?.rol_id
        if(type_user === 1){
            if(!subpillar_code){
                navigate(`/pillars/${company_name}/${pillar_code}`)
            }else{
                navigate(`/subpillars/${company_name}/${pillar_code}/${subpillar_code}`)
            }
        }else{
            const permission = await get_permission_user(userInfo?.id, company_id)
            if(permission?.length){
                const acts_con = permission.find(x => x?.section_id === 2)
                const powers = permission.find(x => x?.section_id === 3)
                const assam = permission.find(x => x?.section_id === 4)
                const statem = permission.find(x => x?.section_id === 5)
                
                if(!subpillar_code){  
                    if(pillar_code === 'act-con'){
                        redirectPillar(subpillar_code, acts_con, {company_name, pillar_code})
                    }
                    if(pillar_code === 'fed-hec'){
                        redirectPillar(subpillar_code, statem, {company_name, pillar_code})
                    }
                }else{
                    if(pillar_code === 'act-asm'){
                        redirectPillar(subpillar_code, assam, {company_name, pillar_code, subpillar_code})
                    }
                    if(pillar_code === 'poderes'){
                        redirectPillar(subpillar_code, powers, {company_name, pillar_code, subpillar_code})
                    }
                }
            }
        }
        setIsloading(false)
    }

    const loadingInfo = async () => {
        setIsloading(true)
        if(company_id){
            await get_pillar_by_company(company_id)
            const bussines = await get_company(company_id)
            if(bussines){
                setCompany(bussines)
                // updateLabelNav(bussines?.name)
                updateLabelNav('Actos corporativos')
                button_back(true,`/view-company/${company_id}` )
            }
        }
        setIsloading(false)
    }
    
    useEffect(() => {
        loadingInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company_id])

    useEffect(() => {
        const handler = async () => {
          await refreshUserInfo()
        }
        handler()
      }, [ refreshUserInfo ])
    
    return (
        <PillarsV
            vars={{
                company,
                pillars_in_company: isLoading ? [] : pillars_in_company,
                isLoading
            }}
            funcs={{
                handleClickPreviewPillars
            }}
        />
    )
}
