import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,

} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useRef } from "react";
import openIcon from "./../../assets/icons/openIcon.svg";
import {MUIModal} from "./MUIModal";
import {ImagePreview} from "./ImagePreview";
import {RenderPdf} from "./RenderPdf";

const SearchList = ({
  data,
  handleChange,
  value,
  isLoading,
  handleNavigate,
  getLabelText,
  get_data,
  modalDocument,
  handleCloseModalDocument,
  shareLink,
  fullPath
}) => {
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current]);

  const setTextInputRef = (element) => {
    inputRef.current = element;
  };
  const ModalDocument = () => {
    return (
      <>
        <Stack>
          <MUIModal
            open={modalDocument}
            handleClose={handleCloseModalDocument}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            buttonTop={true}
          >
            <Box sx={{}}>
              <Typography id="modal-modal-title2" variant="h6" component="h2">
                {fullPath}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* Aqui se renderiza el archivo PDF */}
                {modalDocument?.type === 1 ? (
                  <ImagePreview url={shareLink?.data?.url} />
                ) : (
                  <RenderPdf url={shareLink?.data?.url} />
                )}
              </Typography>
            </Box>
          </MUIModal>
        </Stack>
      </>
    );
  };

  return (
    <Box p={1}>
      <Grid
        container
        spacing={1}
        style={{ marginBottom: 16, width: "100%", paddingRight: 0 }}
      >
        <Grid item xs={12}>
          <TextField
            focused
            inputRef={setTextInputRef}
            variant="outlined"
            placeholder="Buscar"
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "30px",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 1px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                p: 0,
              },
              "& fieldset": {
                border: "none",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Button
                    type="button"
                    onClick={get_data}
                    sx={{
                      background: "#1AAFD0",
                      color: "#FFFFFF",
                      borderRadius: "20px",
                      textTransform: "inherit",
                      "&:hover": {
                        background: "#FFF",
                        color: "#1AAFD0",
                      },
                    }}
                    startIcon={<SearchIcon />}
                  >
                    Buscar
                  </Button>
                </InputAdornment>
              ),
            }}
            fullWidth
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            style={{ marginBottom: 16 }}
          />
        </Grid>
      </Grid>
      {data?.length ? (
        <List sx={{ maxHeight: 350, overflow: "scroll" }} disablePadding>
          {data?.map((x, i) => {
            return (
              <ListItem
                key={i}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    fontWeight: "600",
                    pb: 1,
                  }}
                >
                  {x?.table_name}
                </Typography>
                <List
                  component="div"
                  sx={{
                    // bgcolor: "#fafafa",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                  }}
                  disablePadding
                >
                  {x?.data?.map((y, j) => {
                    const { primary, secondary } = getLabelText(y);
                    return (
                      <ListItemButton
                        sx={{
                          pl: 4,
                          borderRadius: "5px 5px 0px 0px",
                          borderBottom: "1px solid #B0B0B0",
                          background: "#F7F7F7",
                          ".css-jrj3z7-MuiStack-root": {
                            display: "none",
                          },
                          ":hover": {
                            ".css-jrj3z7-MuiStack-root": {
                              display: "flex",
                            },
                          },
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <ListItemText
                            primary={primary}
                            secondary={secondary}
                            sx={{
                              width: "100%",
                              ".css-83ijpv-MuiTypography-root": {
                                fontFamily: "Poppins-Regular",
                                fontWeight: "400",
                                fontSize: "12px",
                              },
                              ".css-10hburv-MuiTypography-root": {
                                fontFamily: "Poppins-Regular",
                                fontWeight: "400",
                                fontSize: "14px",
                              },
                              whiteSpace: "nowrap",
                            }}
                          />
                        </div>
                        <ListItem
                          key={j}
                          secondaryAction={
                            <Stack
                              onClick={() => handleNavigate(y)}
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                                ":hover": {
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <img src={openIcon} width={15} alt="openIcon" />
                              <Typography
                                sx={{
                                  fontFamily: "Poppins-Regular",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#323338",
                                }}
                              >
                                Abrir
                              </Typography>
                            </Stack>
                          }
                        ></ListItem>
                      </ListItemButton>
                    );
                  })}
                </List>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Alert
          icon={false}
          sx={{
            background: "#FFB900",
            color: "#323338",
            fontFamily: "Poppins-Regular",
            fontSize: "12px",
            fontWeight: "400",
          }}
        >
          ¡No se encontraron registros!
        </Alert>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ModalDocument />
    </Box>
    
  );
};

export default SearchList;
