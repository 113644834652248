import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Input } from "../../../Common/MUInput";
import { InputDate } from "../../../Common/MUIInputDate";
import { InputFile } from "../../../Common/MUIInputFile";

export const FaithOfFacts = ({ control, rowSelected, setIsUpload, errors }) => {
  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
    title: {
      mt: 2,
      fontFamily: "Poppins-SemiBold",
      fontSize: "20px",
      fontWeight: "600",
      color: "#323338",
    },
  };

  return (
    <Box>
      <Grid container columnSpacing={2} rowGap={2} columns={12} mt="5px">
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Número de escritura</Typography>
          <Input
            name="writing_number"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
              disabled: false,
              isDouble: true,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Fecha de otorgamiento</Typography>
          <InputDate name={"grant_date"} control={control} />
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Typography sx={styles.label}>Descripción</Typography>
          <Input
            name="description"
            control={control}
            props={{
              multiline: true,
              rows: 3,
              size: "small",
              fullWidth: true,
              required: true,
              autoComplete: "none",
            }}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <InputFile
          activateColor
          label="Elegir archivo"
          value={Boolean(rowSelected?.pdf_link)}
          onClick={() => setIsUpload(true)}
          name="pdf_link"
          control={control}
          props={{
            error: errors?.pdf_link ? true : false,
            helperText: errors?.pdf_link?.message,
          }}
        />
      </Box>
    </Box>
  );
};
