import React, { useEffect, useState } from 'react'
import { useParams, useNavigate} from 'react-router-dom'
import { MultiPillarV } from '../../components/Company/MultiPillarView'
import { UseStoreTemplateView } from '../../stores/TemplateView'
import { UseStorePillar } from '../../stores/Pillar'
import { UseStoreCompany } from '../../stores/Company'
import { UseStoreLogin } from '../../stores/Login'
import { UseStoreAgenda } from '../../stores/Agenda'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { uploadFile, readFile, downloadAll} from './../../utils/AWS'
import { SwalFire, millers } from './../../utils'
import { noDescriptionSchema, withDescriptionSchema, noIRPCSchema, powerSchema } from '../../schemas/PillarsSchema'
import dayjs from 'dayjs'
import { useAlertStore } from '../../components/Common/AlertModal/store'

export const MultiPillarC =  () => {
    const navigate = useNavigate()
    const { pillar, subpillar, company_name } = useParams()
    const userInfo = UseStoreLogin(state => state.data_user)
    const refreshUserInfo = UseStoreLogin((state) => state.refreshUserInfo);
    const type_user = userInfo?.rol_id
    const alertTool = useAlertStore((state) => state.alertTool);
    const id_operational_agenda = UseStoreAgenda(state => state.id_operational_agenda)
    const save_digital_document = UseStoreAgenda(state => state.save_digital_document)
    const updateLabelNav = UseStoreTemplateView(state=> state.updateLabelNav)
    const button_back = UseStoreTemplateView(state=> state.button_back)
    const companies = UseStoreCompany(state=> state.companies)
    const id_document_selected = UseStorePillar(state=> state.id_document_selected)
    const set_document_selected = UseStorePillar(state=> state.set_document_selected)
    const rows = UseStorePillar(state=> state.rows)
    const update_rows = UseStorePillar(state=> state.update_rows)
    const get = UseStorePillar(state=> state.get)
    const add = UseStorePillar(state=> state.add)
    const update = UseStorePillar(state=> state.update)
    const desactivate = UseStorePillar(state=> state.delete)
    const get_permission_user = UseStorePillar(state=> state.get_permission_user)
    const type_powers = UseStorePillar(state=> state.type_powers)
    const get_type_powers = UseStorePillar(state=> state.get_type_powers)
    const get_especial_powers = UseStorePillar(state=> state.get_especial_powers)
    const add_especial_power = UseStorePillar(state=> state.add_especial_powers)
    const [isLoading, setIsloading] = useState(false)
    const [company, setCompany] = useState({})
    const [openModal, setOpenModal] = useState({open: false, type: false, url: ''})
    const [rowSelected, setRowSelected] = useState(false)
    const [pdfPreview, setPDFPreview] = useState(false)
    const [especial_powers, setEspecialPower] = useState([])
    const [actions, setUserActions] = useState([])
    const [isUpload, setIsUpload] = useState()
    const [verification, setVerification] = useState(0)
    const [selecteds, setSelecteds] = useState([])
    const [multiple, setMultiple] = useState(false)
    const [isForm, setIsForm] = useState(false);

    //Asigamaos el nombre el schema dependiendo del pillar para cargarlo dinamicamente
    let pillarSchema = {}
    const pillar_code = pillar && subpillar ? subpillar : pillar
    let pillarConfig = {
        'act-con': {
            name: 'Acta constitutiva',
            section_id: 2,
            folderS3: '/Actas_constitutivas',
        },
        'asa-ord': {
            name: 'Asamblea ordinaria',
            section_id: 4,
            folderS3: '/Actas_asamblea'
        },
        'asa-ext': {
            name: 'Asamblea extraordinaria',
            section_id: 4,
            folderS3: '/Actas_asamblea'
        },
        'fed-hec': {
            name: 'Fe de hechos',
            section_id: 5,
            folderS3: '/Fe_hechos'
        },
        'pwr-ppc':{
            name: 'Poder general para pleitos y cobranza',
            section_id: 3,
            folderS3: '/Poderes'
        },
        'pwr-paa':{
            name: 'Poder general para actos de administración',
            section_id: 3,
            folderS3: '/Poderes'
        },
        'pwr-pad':{
            name: 'Poder general para poder actos de dominio',
            section_id: 3,
            folderS3: '/Poderes'
        },
        'pwr-pes':{
            name: 'Poder especial',
            section_id: 3,
            folderS3: '/Poderes'
        }
    }

    if(pillar_code === 'act-con') pillarSchema = noDescriptionSchema
    else if(pillar_code === 'asa-ord') pillarSchema = noIRPCSchema
    else if(pillar_code === 'fed-hec') pillarSchema = noIRPCSchema
    else if(pillar === 'poderes') pillarSchema = powerSchema
    else pillarSchema = withDescriptionSchema

    const { handleSubmit, formState: { errors }, reset, control, watch } = useForm({ resolver: yupResolver(pillarSchema)})
    const dataForm = watch();

    const add_especial_powers = async (value) => {
        setIsloading(true)
        await add_especial_power({name: value})
        await get_acts()
        setIsloading(false)
    }

    const get_acts = async () => {
        setIsloading(true)
        updateLabelNav(pillarConfig[pillar_code]?.name)
        
        if(type_user !== 1){
            const comp = companies?.find(x => x.name === company_name)
            const permission = await get_permission_user(userInfo?.id, comp?.id)
            if(permission?.length){
                const action_section = permission?.find(x => x?.section_id === pillarConfig[pillar_code]?.section_id)
                setUserActions(action_section)
            }
        }
        
        if(pillar === 'poderes'){
            reset({type_power_code: pillar_code})
            await get_type_powers()
            const power = await get_especial_powers()
            setEspecialPower(power)
        }
        
        const comp = companies?.find(x => x.name === company_name)
        await get(pillar_code, comp?.id)


        if(id_document_selected){
            update_rows({id: id_document_selected}, true)
        }
        setIsloading(false)
    }

    const set_value_form = async (data) => {
        setIsloading(true)
        if(data){
            delete data?.irpc?.id
            setVerification(data?.irpc?.verification)
            setRowSelected(data)

            const set_data_form = {
                id: data?.id, 
                commercial_registry_id: data?.commercial_registry_id,
                writing_number: millers(data?.writing_number),
                grant_date: data?.grant_date,
                entry_number: data?.irpc?.entry_number,
                volume: data?.irpc?.volume,
                book: data?.irpc?.book,
                description: data?.description,
                folio: data?.irpc?.folio,
                registry_date: data?.irpc?.registry_date,
                verification: data?.irpc?.verification,
                pdf_link: data?.pdf_link,
                tags: data?.tags
            }
    
            if(pillar === 'poderes'){
                reset({...data, ...data?.irpc, })
            }else{
                reset(set_data_form)
            }
        }

        setTimeout(() => {
            setIsloading(false)
        }, 1000)
    }

    const get_file = async (url, isDownload = false) => {
        const file = await readFile(pillarConfig[pillar_code]?.folderS3, url, isDownload)
        if(file?.statusCode === 200){
            setPDFPreview(file?.data?.url)
            return file?.data?.url
        }
        else {
            alertTool(file?.message, {
                type: "warning",
                title: "Atención",
                autoClose: true,
            });
        }
    }

    const download_or_delete = async (data , id) => {
        setIsloading(true)  
        if(data){
            if(id === 'download'){
                const file = await get_file(data?.pdf_link)
                if(file){
                    const link = document.createElement("a");
                    link.href = file
                    link.download = `${company?.name}-${company?.rfc}-${Date.now()}.pdf`;
                    link.click();
                }
            }else if(id === 'delete'){
                await desactivate(data?.id, pillar_code)
                await get_acts()
                resetFrom()
            }
        }else
            SwalFire('Atencion!','Debe seleccionar un registro', 'warning')
        setIsloading(false)
    }
    
    useEffect(() => {
        const isset_code_pillar = Object.keys(pillarConfig).concat(['pwr-ppc', 'pwr-paa', 'pwr-pad', 'pwr-pes']).includes(pillar_code)
        const comp = companies?.find(x => x.name === company_name)
        button_back(true, `/pillars/${comp?.id}`)
        setSelecteds(rows)
       
        if(!isset_code_pillar){
            SwalFire('Atencion!','Codigo del pillar incorrecto', 'warning')
            setTimeout(() => {
                navigate(`/pillars/${comp?.id}`, {replace: true})
            }, 1000)
        }else{
            setCompany(comp)
            get_acts()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const OnSubmit = async(data) => {
        setIsloading(true)
        //Validamos la informacion que estamos precargando para saber si podemos editar un registro
        let action = ''
        if(data && data?.id) action = 'edit'
        else if(data && !data?.id) action = 'save'
        // eslint-disable-next-line no-extend-native
        String.prototype.toInt = function (){    
            return parseInt(this.split(' ').join('').split(',').join('') || 0);
        }
     
        const bussines = companies?.find(x => x?.name === company_name)
        const filename = `${bussines?.name}-${bussines?.rfc}-${Date.now()}`;
        const payload = {}
        let pdf_link = data?.pdf_link ? data?.pdf_link : ''
        if (action === 'save') {
            if (data?.pdf_link === '') {
              SwalFire('Atención', 'El archivo PDF es requerido, por favor seleccione un archivo', 'warning');
              setIsloading(false);
              return;
            }
        }

        if((data?.id && data?.commercial_registry_id) ||(data?.id)){
            payload.id = data?.id
            payload.commercial_registry_id = data?.commercial_registry_id
        }else{
            if(action === 'edit'){
                SwalFire('Atencion!','Debe seleccionar un registro', 'warning')
                setIsloading(false)
                return false
            }
        }

        if(!['asa-ord', 'fed-hec'].includes(pillar_code)) {
            payload.registry_comercial = {
                entry_number: data?.entry_number,
                volume: data?.volume,
                book: data?.book,
                folio: data?.folio,
                registry_date: data?.registry_date ? dayjs(data?.registry_date).format('YYYY-MM-DD') : null,
                verification: data?.verification
            }
        }

        if((action === 'save' || action === 'edit') && (isUpload && data?.pdf_link)){
            const pdf = await uploadFile(pillarConfig[pillar_code]?.folderS3, data?.pdf_link, filename, true)
            if(pdf?.statusCode === 200) 
                pdf_link = pdf?.data?.urlFile
            else{
                SwalFire('Atencion!', pdf?.message, 'warning')
                setIsloading(false)
                return false
            }
        }

        if(pillar_code === 'act-con'){
            payload.acts_consitutive = {
                company_id: bussines?.id,
                writing_number: data?.writing_number.toInt(),
                grant_date: data?.grant_date ? dayjs(data?.grant_date).format('YYYY-MM-DD') : null,
                pdf_link
            }
        }else if (pillar_code === 'asa-ord'){
            payload.asam_ord = {
                company_id: bussines?.id,
                extraordinary: 0,
                writing_number: data?.writing_number.toInt(),
                grant_date: data?.grant_date ? dayjs(data?.grant_date).format('YYYY-MM-DD') : null,
                description: data?.description,
                commercial_registry_id: null,
                pdf_link,
                tags: typeof data?.tags === typeof([]) ? data?.tags?.map(x => x?.name || x).join(',') : null
            }
        }else if(pillar_code === 'asa-ext'){
            payload.asam_ext = {
                company_id: bussines?.id,
                extraordinary: 1,
                writing_number: data?.writing_number.toInt(),
                grant_date: data?.grant_date ? dayjs(data?.grant_date).format('YYYY-MM-DD') : null,
                description: data?.description,
                pdf_link,
                tags: typeof data?.tags === typeof([]) ? data?.tags?.map(x => x?.name || x).join(',') : null
            }
        }else if(pillar_code === 'fed-hec'){
            payload.fed_hec = {
                company_id: bussines?.id,
                writing_number: data?.writing_number.toInt(),
                grant_date: data?.grant_date ? dayjs(data?.grant_date).format('YYYY-MM-DD') : null,
                description: data?.description,
                pdf_link
            }
        }else if((['pwr-ppc', 'pwr-paa', 'pwr-pad', 'pwr-pes']).includes(pillar_code)){
            payload.power = {
                company_id: bussines?.id,
                attorney: data?.attorney,
                type_power_code: data?.type_power_code,
                special_power_id: data?.special_power_id ? data?.special_power_id : null,
                writing_number: data?.writing_number.toInt(),
                grant_date: data?.grant_date ? dayjs(data?.grant_date).format('YYYY-MM-DD') : null,
                vigency: data?.vigency,
                date_vigency: data?.date_vigency ? dayjs(data?.date_vigency).format('YYYY-MM-DD') : null,
                limitation: data?.limitation,
                state: data?.state,
                pdf_link
            }
        }

        if(action === 'save') {
            const saved = await add(payload)
            if(saved && id_operational_agenda){
                await save_digital_document({
                    id: id_operational_agenda, 
                    type_digital_document: pillar_code, 
                    digital_document: saved?.id 
                })
            }
        }else if(action === 'edit') await update(payload)

        resetFrom()
        await get_acts()
        set_document_selected(false)
        setIsUpload(false)
        setIsloading(false)
        setIsForm(false)
    }

    const resetFrom = () => {
        let set_data_form = {}

        if((['pwr-ppc', 'pwr-paa', 'pwr-pad', 'pwr-pes']).includes(pillar_code)){
            set_data_form = {
                type_power_code: pillar_code,
                attorney: '',
                writing_number: '',
                grant_date: null,
                vigency: '',
                date_vigency: '',
                entry_number: '',
                volume: '',
                book: '',
                registry_date: null,
                verification: 0,
                limitation: '',
                state: '',
                pdf_link: ''
            }
        } else {
            set_data_form = {
                writing_number: '',
                grant_date: null,
                entry_number: '',
                volume: '',
                book: '',
                folio: '',
                registry_date: null,
                verification: 0,
                description: '',
                pdf_link: ''
            }
        }
        setVerification(0)
        setIsUpload(false)
        setRowSelected(false)
        reset(set_data_form)
    }

    const handleChangeSelected = (e, row) => {
        setIsloading(true)
        setMultiple(true)
        const { checked } = e?.target

        //Consultamos si ya hemos modificado una fila para actualizar el valor y no agregar uno nuevo a la lista de asignaciones
        const permissionTempIndex = selecteds?.findIndex(x => x?.id === row?.id)

        const per = {
            ...row,
            selected: checked
        }

        //Validamos que se ha encontrado un indice del valor para modificarlo o agreagarlo nuevo
        if(permissionTempIndex >= 0) {
            const newSelected = selecteds?.map((x) => {
                return x?.id === row?.id ? per : x
            })

            // selecteds[permissionTempIndex] = {
            //     ...row,
            //     selected: checked
            // }
            setSelecteds(newSelected)
        }else{
            setSelecteds([
                ...selecteds,
                per
            ])
        }

        update_rows(row, checked)
        setIsloading(false)
    }

    const handleSelectAll = (e) => {
        setIsloading(true)
        const { checked } = e?.target
        setMultiple(checked)
        for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            element.selected = checked
            rows[index] = element
            setSelecteds(rows)
            update_rows(element, checked)
        }
        setIsloading(false)
    }

    const HandleDownloadAll = async () => {
        setIsloading(true)
        const files = selecteds?.map(x => (x?.selected && x?.pdf_link ? x?.pdf_link : null))?.filter(Boolean);
        await downloadAll(files, pillarConfig[pillar_code]?.folderS3)
        setIsloading(false)
        setMultiple(false)
    }

    const HandleDeleteAll = async () => {
        setIsloading(true)
        const ids = selecteds?.map(x => (x?.selected && x?.id ? x?.id : null))?.filter(Boolean)
        await desactivate(true, pillar_code, true, ids)
        await get_acts()
        resetFrom()
        setIsloading(false)
        setMultiple(false)
    }

    useEffect(() => {
        const handler = async () => {
          await refreshUserInfo()
        }
        handler()
      }, [ refreshUserInfo ])

    return (
        <MultiPillarV
            vars={{
                multiple,
                pillar: pillar_code,
                subpillar: pillar,
                control,
                errors,
                isLoading,
                rows,
                selecteds,
                openModal,
                pdfPreview,
                type_user,
                type_powers,
                especial_powers,
                actions,
                verification,
                rowSelected,
                isForm,
                dataForm
            }}
            funcs={{
                OnSubmit,
                alertTool,
                handleSubmit,
                setOpenModal,
                get_file,
                set_value_form,
                resetFrom,
                download_or_delete,
                add_especial_powers,
                setIsUpload,
                setVerification,
                handleChangeSelected,
                handleSelectAll,
                HandleDeleteAll,
                HandleDownloadAll,
                setIsForm
            }}
        />
    )
}
