import * as Yup from 'yup'
export const ActorSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nombre es requerido')
        .min(5, "El campo debe contener al minimo 5 caracteres")
        .max(100, "El campo debe contener al maximo 100 caracteres"),
    description: Yup.string()
        .required('Descripcion es requerido')
        .min(5, "El campo debe contener minimo 5 caracteres")
        .max(150, "El campo debe contener maximo 150 caracteres"),
    relationship_business: Yup.string()
        .required('Negocios de relación es requerido')
        .min(10, "El campo debe contener minimo 10 caracteres")
        .max(300, "El campo debe contener maximo 300 caracteres"),
    contacts: Yup.array().of(
        Yup.object().shape({
            executive_relation: Yup.string()
                .min(1, "El campo no puede estar vacío"),
            position: Yup.string()
                .min(1, "El campo no puede estar vacío"),
            phone: Yup.number()
                .typeError("No se permiten letras")
                .min(1, "El campo no puede estar vacío"),
            email: Yup.string().email('Formato de correo electrónico no válido')
                .min(1, "El campo no puede estar vacío")
        })),
    companies: Yup.array().of(
        Yup.object().shape({
            company_id: Yup.number().required("El campo es requerido")
                .min(1, "El campo no puede estar vacío"),
            executive_id: Yup.number().required("El campo es requerido")
                .min(1, "El campo no puede estar vacío")
        })),
    obligations: Yup.array().of(
        Yup.object().shape({
            description_obligation: Yup.string()
                .min(1, "El campo no puede estar vacío"),
        })),
    related_business: Yup.array().of(
        Yup.object().shape({
            description_bussines: Yup.string()
                .min(1, "El campo no puede estar vacío"),
            type_details: Yup.string(),
            details: Yup.string()
                .min(1, "El campo no puede estar vacío"),
        }))
});