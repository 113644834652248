import React from "react";
import { Button, Typography, Stack } from "@mui/material";

export const DataGridFooter = ({ active = false, handleClick }) => {
  return active ? (
    <Stack height={100} justifyContent="center" alignItems="center">
      <Button
        type="button"
        onClick={handleClick}
        sx={{
          width: "124px",
          height: "49px",
          ":hover": {
            background: "#E2E4E7",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            color: "#323338",
            textDecoration: "underline",
          }}
        >
          Mostrar más
        </Typography>
      </Button>
    </Stack>
  ) : null;
};
