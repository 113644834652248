import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Input } from "../../../Common/MUInput";
import { InputAutocompleteAdapter } from "../../../Common/MUIAutoComplete";
import { SwalFire, ToPayerText } from "../../../../utils";
import AddIcon from "@mui/icons-material/Add";
import { InputDate } from "../../../Common/MUIInputDate";
import { CheckboxIcon } from "../../../Common/MUICbxIcon";
import { InputFile } from "../../../Common/MUIInputFile";

export const SpecialPower = ({
  control,
  pillar,
  especial_powers,
  errors,
  set_especial_power,
  especial_power,
  add_especial_powers,
  states,
  verification,
  setVerification,
  subpillar,
  rowSelected,
  setIsUpload,
}) => {
  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
    title: {
      mt: 2,
      fontFamily: "Poppins-SemiBold",
      fontSize: "20px",
      fontWeight: "600",
      color: "#323338",
    },
  };
  return (
    <Box>
      <Grid container columnSpacing={2} rowGap={2} columns={12} mt="5px">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Nombre del apoderado</Typography>
          <Input
            name="attorney"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
              disabled: false,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Poder especial</Typography>
          <InputAutocompleteAdapter
            disabled={["pwr-ppc", "pwr-paa", "pwr-pad"].includes(pillar)}
            mb={0}
            height="1.4375em"
            keyLabel="label"
            keyValue="value"
            label=""
            name="special_power_id"
            control={control}
            error={errors?.special_power_id}
            options={
              especial_powers?.map((x) => ({
                value: x?.id,
                label: ToPayerText(x?.name),
              })) || []
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Agrega un poder no elistado</Typography>
          <TextField
            onChange={(e) => set_especial_power(e?.target?.value)}
            name="especial_power"
            control={control}
            size="small"
            autoComplete="none"
            error={errors?.especial_power}
            helperText={errors?.especial_power?.message}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          lg={3}
          display="flex"
          alignItems="end"
          gap={1}
        >
          <Button
            type="button"
            onClick={() => {
              if (especial_power === "")
                SwalFire(
                  "Atención",
                  "El campo no puede estar vació",
                  "warning"
                );
              else {
                add_especial_powers(especial_power);
                set_especial_power("");
              }
            }}
            sx={{
              fontFamily: "Poppins-Regular",
              fontSize: "14px",
              fontWeight: "400",
              color: "#323338",
              textTransform: "capitalize",
              height: "40px",
              width: "127px",
              ":hover": {
                color: "#fff",
                background: "#1AAFD0",
              },
            }}
            startIcon={<AddIcon />}
          >
            Agregar
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={6}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Número de escritura</Typography>
          <Input
            name="writing_number"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
              disabled: false,
              isDouble: true,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Fecha de otorgamiento</Typography>
          <InputDate name={"grant_date"} control={control} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Vigencia</Typography>
          <Input
            name="vigency"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
              disabled: false,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>
            Fecha de término de vigencia
          </Typography>
          <InputDate name={"date_vigency"} control={control} required={true} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Estatus</Typography>
          <InputAutocompleteAdapter
            mb={0}
            height="1.4375em"
            keyLabel="label"
            label=""
            keyValue="value"
            name="state"
            control={control}
            error={errors?.state}
            options={
              states?.map((x) => ({
                value: x?.id,
                label: x?.name,
              })) || []
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Limitación</Typography>
          <Input
            name="limitation"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
            }}
          />
        </Grid>
      </Grid>
      <Typography sx={styles.title}>
        Inscripción en el registro público de comercio
      </Typography>
      <Grid
        container
        columnSpacing={2}
        rowGap={2}
        columns={12}
        pt="5px"
        display={verification === 1 ? "flex" : "none"}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Partida</Typography>
          <Input
            name="entry_number"
            control={control}
            props={{
              size: "small",
              required: false,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Volumen</Typography>
          <Input
            name="volume"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Libro*</Typography>
          <Input
            name="book"
            control={control}
            props={{
              size: "small",
              required: true,
              autoComplete: "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Typography sx={styles.label}>Fecha de registro</Typography>
          <InputDate
            name={"registry_date"}
            control={control}
            error={Boolean(errors?.registry_date)}
            errMsg={errors?.registry_date?.message}
            required={true}
          />
        </Grid>
      </Grid>
      <Button
        type="button"
        sx={{
          mt: 2,
          height: "40px",
          pr: 2,
          background: "#1DB14B",
          color: "#fff",
          textTransform: "capitalize",
          fontFamily: "Poppins-Regular",
          fontSize: "14px",
          fontWeight: "400",
          ":hover": { background: "#1DB14B" },
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
          "& .MuiButtonBase-root-MuiCheckbox-root": {
            color: "#fff",
          },
        }}
        onClick={() => {
          setVerification(
            pillar === "asa-ext" || subpillar === "poderes"
              ? verification === 2
                ? 1
                : verification === 1
                ? 0
                : verification === 0 || !verification
                ? 2
                : 1
              : verification === 0 || !verification
              ? 1
              : verification === 1 && 0
          );
        }}
      >
        <CheckboxIcon
          value={verification}
          name="verification"
          control={control}
          indeterminate={
            pillar === "asa-ext" ? true : subpillar === "poderes" ? true : false
          }
        />
        Verificación
      </Button>
      <Box mt={2}>
        <InputFile
          activateColor
          label="Elegir archivo"
          value={Boolean(rowSelected?.pdf_link)}
          onClick={() => setIsUpload(true)}
          name="pdf_link"
          control={control}
          props={{
            error: errors?.pdf_link ? true : false,
            helperText: errors?.pdf_link?.message,
          }}
        />
      </Box>
    </Box>
  );
};
