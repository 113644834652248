import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanySchema } from "../../schemas/CompanySchema";
import { AddCompanyV } from "../../components/Company/SaveCompany";
// import { AddCompanyV } from '../../components/Company/AddCompany'
import { UseStoreTemplateView } from "../../stores/TemplateView";
import { UseStoreCompany } from "../../stores/Company";
import { UseStorePillar } from "../../stores/Pillar";
import { uploadFile, readFile } from "./../../utils/AWS";
import { SwalFire, verifyImageDimensions } from "./../../utils";
import { UseStoreLogin } from "./../../stores/Login";
import copy from "clipboard-copy";
import dayjs from "dayjs";
import { useAlertStore } from "../../components/Common/AlertModal/store";

export const AddCompanyC = () => {
  const navigate = useNavigate();
  const { company_id } = useParams();
  const refreshUserInfo = UseStoreLogin((state) => state.refreshUserInfo);
  const alertTool = useAlertStore((state) => state.alertTool);
  const userInfo = UseStoreLogin((state) => state.data_user);
  const type_user = userInfo?.rol_id;
  const updateLabelNav = UseStoreTemplateView((state) => state.updateLabelNav);
  const button_back = UseStoreTemplateView((state) => state.button_back);
  const sector_companies = UseStoreTemplateView(
    (state) => state.sector_companies
  );
  const countrys = UseStoreTemplateView((state) => state.countrys);
  const pillars_companies = UseStoreTemplateView(
    (state) => state.pillars_companies
  );
  const get_countrys = UseStoreTemplateView((state) => state.get_countrys);
  const get_sector_companies = UseStoreTemplateView(
    (state) => state.get_sector_companies
  );
  const get_pillars_companies = UseStoreTemplateView(
    (state) => state.get_pillars_companies
  );
  const get_permission_user = UseStorePillar(
    (state) => state.get_permission_user
  );
  const get_actors = UseStoreTemplateView((state) => state.get_actors_simplified);
  const get_town_state = UseStoreTemplateView((state) => state.get_town_state);
  const get_company = UseStoreCompany((state) => state.get_company);
  const add_company = UseStoreCompany((state) => state.add_company);
  const pillars_preview = UseStoreCompany((state) => state.pillars_preview);
  const update_pillars_preview = UseStoreCompany(
    (state) => state.update_pillars_preview
  );
  const delete_company = UseStoreCompany((state) => state.delete_company);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPillar, setIsLoadingPillar] = useState(false);
  const [pillarSelecteds, setPillarSelected] = useState([]);
  const [isView, setIsView] = useState(false);
  const [linkGazette, setLinkGazete] = useState(false);
  const [share_link, setShareLink] = useState(false);
  const [org_chart_link, setOrgChartLink] = useState(false);
  const [logo_company, setLogoCompany] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    type: false,
    url: "",
  });
  const [isUpload, setIsUpload] = useState({
    shares_link: false,
    org_chart_link: false,
    url_logo: false,
  });
  const [loadingTownState, setLoadingTownState] = useState(false);
  const [permissions, setPermissions] = useState({});
  const get_pillar_by_company = UseStorePillar(state => state.get_pillar_by_company);
  const [tab, setTab] = useState(0);
  

  // eslint-disable-next-line no-unused-vars
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
  } = useForm({ resolver: yupResolver(CompanySchema) });
  const dataForm = watch();

  const handleClickPreviewPillars = async (company_id, pillar_code) => {
    setIsLoading(true);
    if (pillar_code === "act-cor") {
      const type_user = userInfo?.rol_id;
      if (type_user === 1) {
        navigate(`/pillars/${company_id}`, { replace: true });
      } else {
        const permission = await get_permission_user(userInfo?.id, company_id);
        if (permission?.length) {
          //Sacamos los permisos por cada modulo
          const acts_con = permission.find((x) => x?.section_id === 2);
          const powers = permission.find((x) => x?.section_id === 3);
          const assam = permission.find((x) => x?.section_id === 4);
          const statem = permission.find((x) => x?.section_id === 5);

          //Validamos si todos los pillares estan desactivada la opcion de ver no permitira la redireccion
          if (
            !acts_con?.view &&
            !powers?.view &&
            !assam?.view &&
            !statem?.view
          ) {
            SwalFire(
              "Atencion!",
              "Usted no tiene permisos para ver los pillares",
              "warning"
            );
          } else {
            navigate(`/pillars/${company_id}`, { replace: true });
          }
        }
      }
    }
    if (pillar_code === "act-gub") {
      navigate(`/pilares/${pillar_code}/${company_id}`, { replace: true });
    }
    if (pillar_code === "activos") {
      navigate(`/pilares/${pillar_code}/${company_id}`, { replace: true });
    }
    if (pillar_code === "contratos") {
      navigate(`/pilares/${pillar_code}/${company_id}`, { replace: true });
    } else {
      //alertTool("", { title: "En construcción", type: "question" });
      // SwalFire("Información!", "En construcción", "info");
    }
    setIsLoading(false);
  };

  const get_company_id = async () => {
    setIsLoading(true);
    //Actualizamos la lista de los pillares porque inicialmente esta vacio para luego cambiar su valor por los del los que estan en mi compañia
    await update_pillars_preview(null, false, pillars_companies);
    const comp = await get_company(company_id);

    if (comp) {
      comp.company_administrations = comp?.company_administrations?.length
        ? comp?.company_administrations?.filter((x) => !!x?.status)
        : [];
      // const company_admins = {};
      //Sacamos la informacion de los administradores para precargarlos
      // if (comp?.company_administrations?.length) {
      //   comp?.company_administrations?.forEach((element, i) => {
      //     i++;
      //     company_admins[`bd_id_${i}`] = element?.id;
      //     company_admins[`bd_names_${i}`] = element?.name;
      //     company_admins[`bd_function_${i}`] = element?.position;
      //   });
      // } else if (typeof comp?.company_administrations === typeof {}) {
      //   if (comp?.company_administrations) {
      //     company_admins[`bd_id_1`] = comp?.company_administrations.id;
      //     company_admins[`bd_names_1`] = comp?.company_administrations?.name;
      //     company_admins[`bd_function_1`] =
      //       comp?.company_administrations?.position;
      //   }
      // }

      if (comp?.company_pillars?.length) {
        comp?.company_pillars?.forEach(async (element) => {
          await update_pillars_preview(element, element?.active);
        });
      } else if (typeof comp?.company_pillars === typeof {}) {
        if (Object.keys(comp?.company_pillars).length) {
          await update_pillars_preview(
            comp?.company_pillars,
            comp?.company_pillars?.active,
            pillars_companies
          );
        } else {
          await update_pillars_preview(null, false, pillars_companies);
        }
      }

      if (comp?.business_gazette) setLinkGazete(comp?.business_gazette);

      const data_preview = {
        name: comp?.name,
        social_name: comp?.social_name,
        url_logo: comp?.url_logo,
        date_of_constitution: comp?.date_of_constitution,
        rfc: comp?.rfc,
        country_id: comp?.country_id,
        activity: comp?.activity,
        sector_id: comp?.sector_id,
        shares_link: comp?.shares_link,
        org_chart_link: comp?.org_chart_link,
        business_gazette: comp?.business_gazette,
        contact_name: comp?.company_contacts?.name,
        contact_email: comp?.company_contacts?.email,
        extension: comp?.company_contacts?.extension,
        contact_celular: comp?.company_contacts?.telephone,
        contact_phone: comp?.company_contacts?.phone,
        ho_days: comp?.company_contacts?.ho_days
          ? comp?.company_contacts?.ho_days?.split(",").map((key) => key)
          : [],
        ho_time_start: comp?.company_contacts?.ho_time_start
          ? dayjs(
              `${comp?.date_of_constitution}T${comp?.company_contacts?.ho_time_start}`
            )
          : "",
        ho_time_end: comp?.company_contacts?.ho_time_end
          ? dayjs(
              `${comp?.date_of_constitution}T${comp?.company_contacts?.ho_time_end}`
            )
          : "",
        company_pillars: comp?.company_pillars,
        company_administrations: comp?.company_administrations?.length
          ? comp?.company_administrations
          : [
              { id: undefined, name: "", position: "" },
              { id: undefined, name: "", position: "" },
            ],
        ...comp?.domicile_fiscal,
        // ...company_admins,
      };

      if (!share_link && comp?.shares_link) {
        const sha_link = await readFile(
          "/Estructura_accionaria",
          comp?.shares_link
        );
        if (sha_link?.statusCode === 200) setShareLink(sha_link);
        else SwalFire("Atencion!", sha_link?.message, "warning");
      }

      if (!org_chart_link && comp?.org_chart_link) {
        const or_link = await readFile(
          "/Organigrama_empresarial",
          comp?.org_chart_link
        );
        if (or_link?.statusCode === 200) setOrgChartLink(or_link);
        else SwalFire("Atencion!", or_link?.message, "warning");
      }

      if (!logo_company && comp?.url_logo) {
        const logo = await readFile("/logos_company", comp?.url_logo);
        if (logo?.statusCode === 200) setLogoCompany(logo);
        else SwalFire("Atencion!", logo?.message, "warning");
      }

      const userPermissions = await get_permission_user(userInfo?.id, comp?.id);
      if (userPermissions?.length) {
        const found = userPermissions?.find(
          (item) => item?.section === "Perfil empresarial"
        );
        if (!found?.edit) setIsView(true);
        setPermissions(found);
      }

      reset(data_preview);
    } else navigate("/GroupCompanies", { replace: true });

    setIsLoading(false);
  };

  const load_pillars = async () => {
    setIsLoadingPillar(true);
    const pillars = await get_pillars_companies();
    await update_pillars_preview(null, false, pillars);
    setIsLoadingPillar(false);
  };

  useEffect(() => {
    updateLabelNav("Perfil empresarial");
    button_back(true, "/GroupCompanies");
    get_sector_companies();
    get_countrys();
    get_actors();

    //recuperamos la informacion de la empresa si es que se ha pasado un id por parametro en el URL para editar el tab de la vista
    const tabValue =  new URLSearchParams(window.location.search).get("tab");
    if (tabValue !== null) {
      setTab(parseInt(tabValue));
      //eliminar el parametro del URL 
      const url = window.location.href.split('?')[0];
      window.history.pushState({}, document.title, url);

    }
    if (company_id) {
      get_company_id();
      get_pillar_by_company(company_id);
      // if (type_user !== 1) setIsView(true);
    } else {
      load_pillars();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  const OnSubmit = async (data, e) => {
    try {
      //Obtenemos el id del boton para saber que acccion se va a realizar
      const { id } = e?.target;

      //Creamos una validacion para crear la accion correspiende segun el id del boton
      let isEdit = false;
      if (id === "edit") isEdit = true;

      setIsLoading(true);
      const filename = `${data?.name}-${data?.rfc}-${Date.now()}`;
      let ea = false;
      let oe = false;
      let logo = false;

      if (isUpload.shares_link && data?.shares_link) {
        const shares_link = data?.shares_link;
        const upload_shares_link = await uploadFile(
          `/Estructura_accionaria`,
          shares_link,
          filename
        );
        if (upload_shares_link?.statusCode === 200)
          ea = upload_shares_link?.data?.urlFile;
        else {
          SwalFire(
            "Atencion!",
            `Estructura Accionaria: ${upload_shares_link?.message}`,
            "warning"
          );
          setIsLoading(false);
          return false;
        }
      }

      if (isUpload.org_chart_link && data?.org_chart_link) {
        const oc_link = data?.org_chart_link;
        const upload_org_chart_link = await uploadFile(
          "/Organigrama_empresarial",
          oc_link,
          filename
        );
        if (upload_org_chart_link?.statusCode === 200)
          oe = upload_org_chart_link?.data?.urlFile;
        else {
          SwalFire(
            "Atencion!",
            ` Organigrama Empresarial: ${upload_org_chart_link?.message}`,
            "warning"
          );
          setIsLoading(false);
          return false;
        }
      }

      if (isUpload.url_logo && data?.url_logo) {
        const url_logo = data?.url_logo;
        const emp_logo = await verifyImageDimensions(url_logo, 300, 300);
        if (emp_logo) {
          const upload_url_logo = await uploadFile(
            "/logos_company",
            url_logo,
            filename
          );
          if (upload_url_logo?.statusCode === 200)
            logo = upload_url_logo?.data?.urlFile;
          else {
            SwalFire(
              "Atencion!",
              ` Logo Empresarial: ${upload_url_logo?.message}`,
              "warning"
            );
            setIsLoading(false);
            return false;
          }
        } else {
          setIsLoading(false);
          return false;
        }
      }

      const company = {
        name: data?.name,
        social_name: data?.social_name,
        url_logo: logo || data?.url_logo,
        date_of_constitution: data?.date_of_constitution
          ? dayjs(data?.date_of_constitution).format("YYYY-MM-DD")
          : null,
        rfc: data?.rfc,
        country_id: data?.country_id || null,
        activity: data?.activity || "",
        sector_id: data?.sector_id || "",
        shares_link: ea || data?.shares_link,
        org_chart_link: oe || data?.org_chart_link,
        business_gazette: data?.business_gazette || "",
      };

      const domicile_fiscal = {
        address: data?.address || "",
        zip_code: data?.zip_code || null,
        town: data?.town || "",
        state: data?.state || "",
      };
      let company_administrations = (data?.company_administrations || [])
        ?.filter((item) => !!item?.name && !!item?.position)
        ?.map((x) => ({ ...x, status: x?.status ? 1 : 0 }));
      // if (data?.bd_names_1 && data?.bd_function_1)
      //   company_administrations.push({
      //     id: data?.bd_id_1,
      //     name: data?.bd_names_1,
      //     position: data?.bd_function_1,
      //   });
      // if (data?.bd_names_2 && data?.bd_function_2)
      //   company_administrations.push({
      //     id: data?.bd_id_2,
      //     name: data?.bd_names_2,
      //     position: data?.bd_function_2,
      //   });
      // if (data?.bd_names_3 && data?.bd_function_3)
      //   company_administrations.push({
      //     id: data?.bd_id_3,
      //     name: data?.bd_names_3,
      //     position: data?.bd_function_3,
      //   });
      // if (data?.bd_names_4 && data?.bd_function_4)
      //   company_administrations.push({
      //     id: data?.bd_id_4,
      //     name: data?.bd_names_4,
      //     position: data?.bd_function_4,
      //   });

      const company_contacts = {
        name: data?.contact_name,
        email: data?.contact_email,
        telephone: data?.contact_celular ? `${data?.contact_celular}` : "",
        phone: data?.contact_phone ? `${data?.contact_phone}` : "",
        extension: data?.extension || null,
        ho_days:
          typeof data?.ho_days === typeof []
            ? data?.ho_days?.map((x) => x?.name || x).join(",")
            : null,
        ho_time_start: data?.ho_time_start
          ? dayjs(data?.ho_time_start).format("HH:mm:ss")
          : null,
        ho_time_end: data?.ho_time_end
          ? dayjs(data?.ho_time_end).format("HH:mm:ss")
          : null,
      };

      if (isEdit) {
        const findCompany = await get_company(company_id);

        const company_administrations_remove = findCompany
          ?.company_administrations?.length
          ? findCompany?.company_administrations
              ?.filter((x) => x?.status)
              ?.reduce((prev, curr) => {
                const exist = company_administrations?.find(
                  (item) => item?.id === curr?.id
                );
                return !!exist?.id ? prev : [...prev, { ...curr, status: 0 }];
              }, [])
          : [];
        company_administrations = [
          ...company_administrations,
          ...company_administrations_remove,
        ];
      }
      await add_company(
        {
          company,
          domicile_fiscal,
          company_administrations,
          company_contacts,
          pillar_selecteds: pillarSelecteds?.length ? pillarSelecteds : [],
        },
        isEdit
      );

      setIsUpload({
        shares_link: false,
        org_chart_link: false,
      });
      setIsLoading(false);
      navigate("/GroupCompanies", { replace: true });
    } catch (error) {
      console.log(`Ha ocurrido un error al hacer submit: ${error?.message}`);
    }
  };

  const handleChangePillar = (data, e) => {
    const { checked } = e?.target;

    //Consultamos si ya hemos modificado una fila para actualizar el valor y no agregar uno nuevo a la lista de asignaciones
    const pillarIndex = pillarSelecteds?.findIndex(
      (row) =>
        row?.pillar_id === data?.pillar_id &&
        row?.subpillar_id === data?.subpillar_id
    );
    const per = {
      ...data,
      active: checked,
    };

    //Validamos que se ha encontrado un indice del valor para modificarlo o agreagarlo nuevo
    if (pillarIndex >= 0) {
      pillarSelecteds[pillarIndex] = {
        ...data,
        active: checked,
      };

      setPillarSelected(pillarSelecteds);
    } else {
      setPillarSelected([...pillarSelecteds, per]);
    }

    if (company_id) {
      update_pillars_preview(data, checked);
    } else {
      update_pillars_preview(data, checked);
    }
  };

  const handleDelete = async (company_id) => {
    setIsLoading(true);
    const res = await delete_company(company_id);
    if (res) {
      SwalFire("Bien!", "Se ha eliminado la empresa", "success");
      navigate("/GroupCompanies", { replace: true });
    } else SwalFire('Atencion"', "No se pudo eliminar la empresa", "warning");
    setIsLoading(false);
  };

  const handleViewGazette = () => {
    window.open(linkGazette, "_blank");
  };

  const handle_upload_edit_file = (file) => {
    console.log(file);
    setIsUpload({ ...isUpload, [file]: true });
  };

  const handleChangeZipCode = async (value) => {
    setLoadingTownState(true);
    const zip = await get_town_state(value);
    if (zip) {
      setValue("zip_code", value, { shouldValidate: true });
      setValue("town", zip?.d_town, { shouldValidate: true });
      setValue("state", zip?.d_state, { shouldValidate: true });
    } else {
      setValue("zip_code", value);
      setValue("town", "");
      setValue("state", "");
    }
    setLoadingTownState(false);
  };

  const handleCancel = () => {
    navigate("/GroupCompanies", { replace: true });
  };

  const handleDeleteRow = (rowIndex) => {
    const newRows = dataForm?.company_administrations?.filter(
      (_, index) => index !== rowIndex
    );
    setValue("company_administrations", newRows);
  };

  const handleAdd = () => {
    const newRow = { id: null, name: "", position: "" };
    setValue("company_administrations", [
      ...dataForm?.company_administrations,
      newRow,
    ]);
  };

  const handleCopy = () => {
    copy(linkGazette);
  };

  useEffect(() => {
    if (!company_id) {
      setValue("company_administrations", [
        { id: undefined, name: "", position: "" },
        { id: undefined, name: "", position: "" },
      ]);
    }
  }, [setValue, company_id]);

  useEffect(() => {
    const handler = async () => {
      await refreshUserInfo();
    };
    handler();
  }, [refreshUserInfo]);

  return (
    <AddCompanyV
      vars={{
        openModal,
        share_link,
        org_chart_link,
        company_id,
        sector_companies,
        countrys,
        pillars_companies,
        pillars_preview,
        type_user,
        isView,
        errors,
        control,
        isLoading,
        linkGazette,
        isLoadingPillar,
        loadingTownState,
        logo_company,
        dataForm,
        permissions,
        tab
      }}
      funcs={{
        OnSubmit,
        handleSubmit,
        setOpenModal,
        handleChangePillar,
        handleDelete,
        handleViewGazette,
        handle_upload_edit_file,
        handleChangeZipCode,
        handleClickPreviewPillars,
        handleCancel,
        handleAdd,
        handleDeleteRow,
        handleCopy,
        setTab
      }}
    />
  );
};
