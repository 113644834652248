import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { POST } from './../utils/AuthService'
import { encrypt_data } from './../utils/JSImplements'
import { SwalFire } from './../utils/index'
import { logAction } from './../utils/logAction'
export const UseStorePermission = create(
    persist(
        (set, get) => ({
            permissions: [],
            permissionTemp: [],
            getPermissions: async (path, payload) => {
                const res = await POST(path, payload)
                if (res?.data?.length){
                    set({permissions: res?.data})
                    return res?.data?.map((x, i) => ({...x, id: i+1}))
                }else{
                    SwalFire('Informacion', 'No se encontraron permisos.', 'info')
                    return []
                }
            },
            setPermissionsTemp: (data) =>{
                set({permissionTemp: data})
            },
            resetPermissions: () => {
                const { permissions } = get()
                set({permissionTemp: permissions})
            },
            disabledModuleZ: async (data) => {
                try {
                    const { permissionTemp } = get()
                    //Clonamos  para hacer inmutabilidad 
                    const newPermissionTemp = structuredClone(permissionTemp)
                    //recorremos la data para deshabilitar todos los permisos
                    const payload = newPermissionTemp?.map(x => ({
                        id: x?.section_id,
                        role_id: data?.role_id,
                        companie_id: data?.companie_id,
                        module: x?.module,
                        section: x?.section,
                        view: false,
                        add: false,
                        edit: false,
                        delete:false,
                        download: false,
                    }))
                    
                    const res = await POST('asign-permissions-role', payload)
                    if(res?.statusCode === 200) {
                        set({
                            permissions: permissionTemp,
                            permissionTemp: payload
                        })
                        SwalFire('Exito', 'Se han desactivado los permisos para el modulo', 'success')
                        return true
                    }else
                        SwalFire('Advertencia', 'No se pudieron asignar los permisos', 'warning')
                        return false
                } catch (error) {
                    SwalFire('Error', 'Ha ocurrido un error al desactivar el modulo los permisos.', 'error')
                    console.log(`ha ocurrido un error al desactivar el modulo los permisos ${error.message}`);
                }
            },
            updatePermissionsTemp: (data) => {
                const { permissionTemp } = get()
                //Clonamos  para hacer inmutabilidad 
                const newPermissionTemp = structuredClone(permissionTemp)
                //Obtenemos el indioce  dentro del Array
                const permissionTempIndex = permissionTemp?.findIndex(row => row?.id === data?.id)
                //Se cambia la informacino en la copia
                newPermissionTemp[permissionTempIndex] = {
                    ...data
                }
                //Actualizamos el estado de Zustand
                set({permissionTemp: newPermissionTemp})
            },
            asignPermission: async (path ,data, user=false) => {
                try {
                    const { permissionTemp } = get()
                    //asignamos el nombre de la llave dinamicamente
                    const labelKey = user ? 'user_id' : 'role_id'
                    //Preparamos la data que se va a mandar
                    const payload = data?.map(x => {
                        //asignamos el id de la secion 
                        const id = x?.section_id
                        //asigamos el valor dinamico de la llave
                        const valueKey = user ? x?.user_id : x?.role_id
                        //asignamos el valor de la compañia
                        const companie_id = x?.companie_id ? x?.companie_id : x?.companie_user_id 

                        const body = {
                            id,
                            [labelKey]: valueKey,
                            companie_id,
                            module: x?.module,
                            section: x?.section,
                            view: x?.view,
                            add: x?.add,
                            edit: x?.edit,
                            delete: x?.delete,
                            download: x?.download
                        }

                        //Devolvemos el nuevo array
                        return body
                    })

                    const res = await POST(path, payload)
                    console.log("payload permissions", payload)
                    console.log("response permissions", res)
                    if(res?.statusCode === 200) {
                        set({permissions: permissionTemp})
                        SwalFire('Exito', 'Se han asignado los permisos', 'success')
                        return true
                    }else
                        SwalFire('Advertencia', 'No se pudieron asignar los permisos', 'warning')
                        return false
                } catch (error) {
                    SwalFire('Error', 'Ha ocurrido un error al asignar los permisos.', 'error')
                    console.log(`ha ocurrido un error al asignar los permisos ${error.message}`);
                }
            }
        }),
        {
            name: encrypt_data('permission_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)