import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { SignInButton } from "../../utils/SignInButton";
import Logo from "./../../assets/logo.svg";
import { Container, Image } from "./styled";

export const LoginV = () => {
  return (
    <Container>
      <Box
        sx={{
          width: "420px",
          height: "322px",
          background: "#0000008a",
          p: "10px",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            height: "28%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#6d707830",
          }}
        >
          <Image src={Logo} alt="logo" />
        </Box>
        <Box
          sx={{
            height: "72%",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: " 24px",
              fontFamily: "Poppins-SemiBold",
              color: "#323338",
            }}
          >
            Iniciar sesión
          </Typography>
          <SignInButton />
        </Box>
      </Box>
    </Container>
  );
};

LoginV.propTypes = {
  SignIn: PropTypes.func,
};
