import React, { useState } from "react";
import {Box, Typography, IconButton} from '@mui/material';
import { Document, Page, pdfjs } from "react-pdf";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import * as PDFJSWorker from "pdfjs-dist/build/pdf.worker";


export const RenderPdf = ({url}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    pdfjs.GlobalWorkerOptions.workerSrc = PDFJSWorker;

    return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems={'center'}>
            <Box height={1250} sx={{overflow: 'scroll'}}>
                <Document
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        
                        className="pdf-page"
                        pageNumber={pageNumber}
                        width={850}
                    />
                </Document>
            </Box>
            <Box>
                <Typography>
                    Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}
                </Typography>
                <Box>
                    <IconButton aria-label="Anterior" disabled={pageNumber <= 1} onClick={previousPage}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton
                        aria-label="Siguiente"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}