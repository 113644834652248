import React, { useEffect, useState } from "react";
import { GroupCompaniesV } from "../../components/Company/GroupCompanies";
import { UseStoreTemplateView } from "../../stores/TemplateView";
import { UseStoreCompany } from "../../stores/Company";
import { UseStoreLogin } from "../../stores/Login";
import { useNavigate } from "react-router-dom";

export const GroupCompaniesC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = UseStoreLogin((state) => state.data_user);
  const refreshUserInfo = UseStoreLogin((state) => state.refreshUserInfo);
  const updateLabelNav = UseStoreTemplateView((state) => state.updateLabelNav);
  const button_back = UseStoreTemplateView((state) => state.button_back);
  const validate_user_in_companies = UseStoreTemplateView(
    (state) => state.validate_user_in_companies
  );
  const get_pillars_companies = UseStoreTemplateView(
    (state) => state.get_pillars_companies
  );
  const companies = UseStoreCompany((state) => state.companies);
  const getCompanies = UseStoreCompany((state) => state.get_companies);
  const sort_companies = UseStoreCompany((state) => state.sort_companies);

  const get_companies = async () => {
    setIsLoading(true);
    await getCompanies(true);
    await sort_companies()
    await get_pillars_companies();
    setIsLoading(false);
  };

  useEffect(() => {
    button_back(true, "/home");
    updateLabelNav("EMPRESAS DEL GRUPO");
    get_companies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = async (company_id) => {
    setIsLoading(true);
    const type_user = userInfo?.rol_id;
    if (type_user === 1) {
      navigate(`/view-company/${company_id}`, { replace: true });
    } else {
      const result = await validate_user_in_companies({
        user_id: userInfo?.id,
        company_id,
      });
      if (result) navigate(`/view-company/${company_id}`, { replace: true });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handler = async () => {
      await refreshUserInfo()
    }
    handler()
  }, [ refreshUserInfo ])

  return (
    <GroupCompaniesV
      isLoading={isLoading}
      companies={companies}
      validate_permission_user={handleClick}
    />
  );
};
