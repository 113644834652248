import React from "react";
import { Controller } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { esES } from "@mui/x-date-pickers/locales";

export const InputTime = ({ label, control, name, disabled, props }) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        esES.components?.MuiLocalizationProvider?.defaultProps?.localeText
      }
    >
      <DemoContainer components={["MobileTimePicker"]}>
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => {
            return (
              <MobileTimePicker
                ampm={false}
                defaultValue={field.value}
                disabled={disabled}
                inputRef={field.ref}
                onChange={field.onChange}
                label={label}
                slotProps={{
                  textField: {
                    ...props,
                    error: Boolean(fieldState.error?.message),
                    helperText: fieldState.error?.message || "",
                  },
                }}
              />
            );
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};
