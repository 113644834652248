import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { encrypt_data } from './../utils/JSImplements'
import { GET, POST} from './../utils/AuthService'
import { SwalFire } from '../utils'

export const UseStoreEmailController = create(
    persist(
        (set, get) => ({
            emails: [],
            current_data: [],
            filter_data: [],
            get_emails: async () => {
                const { emails } = get()
                if(!emails?.length){
                    const res = await GET(`get-emails-controller`, 'agenda')
                    if(res?.statusCode === 200){
                        set({emails: res?.data})
                    }
                }
                return emails
            },
            update_emails_checked: async (emailId, field) => {
                const { emails } = get()
                const updatedEmails = emails.map(email => {
                    if (email.id === emailId) {
                      return {
                        ...email,
                        [field]: !email[field] ? 1 : 0 // Cambia el valor del campo entre 0 y 1
                      };
                    }
                    return email;
                  });
                  set({emails: updatedEmails });
            },
            save_emails_controller: async () => {
                const { emails } = get()
                const res = await POST(`update-email-controller`, emails, 'agenda')
                if(res?.statusCode === 200){
                    SwalFire('Exito!', 'Se ha actualizado la configuración', 'success')
                    return res?.data
                }
                return emails
            }
        }),
        {
            name: encrypt_data('email_controller'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)