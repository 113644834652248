import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { GET, POST, PUT} from './../utils/AuthService'
import { encrypt_data } from './../utils/JSImplements'
import { SwalFire, SwalfireConfirm } from './../utils/index'
import { logAction } from './../utils/logAction'
export const UseStoreRoles = create(
    persist(
        (set, get) => ({
            roles: [],
            rolesEnableds: [],
            fetchRoles: async () => {
                const res = await GET('get-roles')
                if (res?.data?.length) set({roles: res?.data?.filter(x => !!x?.state)})
            },
            getRolesEnableds: async() => {
                const { roles, fetchRoles } = get()
                if(!roles?.length) {
                    await fetchRoles()
                }
                set({rolesEnableds: roles?.filter(x => !!x?.state)})
            },
            addRol: async (payload) => {
                try{
                    const { roles } = get()
                    const issetRol = roles.find(x => x?.name === payload?.name)
            
                    if(issetRol){
                        SwalFire('Advertencia', 'El rol ya existe.', 'warning')
                        return 2
                    } else{
                        const res = await POST('add-role', payload)
                        if(res?.statusCode === 201){
                            set({roles: [...roles, {...payload, id: res?.data?.id}]})
                            return res
                        }else
                            return false 
                    }
                }catch (e){
                    console.log(`Ocurrio un error al agregar el rol: ${e.message}`);
                    return false 
                }
            },
            updateStateRol: async (id, checked) => {
                try {
                    const { roles } = get()
                    //Clonamos los roles para hacer inmutabilidad 
                    const newRoles = structuredClone(roles)
                    //Obtenemos el indioce del rol dentro del Array
                    const rolIndex = roles?.findIndex(row => row?.id === id)
                    //Obtenemos la informacion del rol
                    const rolInfo = newRoles[rolIndex]
                    //Actulizamos el estado de ese rol en sustan
                    rolInfo.state = checked ? 1 : 0
                    //Enviamos la peticion al servidor para actualizar el registo en la base de datos
                    const res = await PUT(`update-role/${id}`, {name: rolInfo?.name, description: rolInfo?.description, state: rolInfo?.state})
                    //Validamos que se haya actualizado el estado
                    if(res?.statusCode === 200){
                        await logAction('roles', id, 'update', `Se actualizó el estado del rol: ${rolInfo?.name}`)
                        //Se cambia la informacino en la copia de los roles
                        newRoles[rolIndex] = {
                            ...rolInfo
                        }
                        //Actualizamos el estado de Zustand
                        set({roles: newRoles})
                        return true
                    }else{
                        SwalFire('Advertencia', 'No se ha podido actualizar el estado', 'warning')
                        return false
                    }
                } catch (error) {
                    console.log(`Ha ocurrido un error al actualizar el estado del role: ${error.message}`);
                    return false
                }
            },
            updateRol: async (rol) => {
                try {
                    const { roles } = get()
                    //Valiamos si existe el rol
                    const issetRol = roles.find(x => x?.name === rol?.name)
                    if(issetRol){
                        SwalFire('Advertencia', 'El rol ya existe.', 'warning')
                        return 2
                    }else{
                        //Clonamos los roles para hacer inmutabilidad 
                        const newRoles = structuredClone(roles)
                        //Obtenemos el indioce del rol dentro del Array
                        const rolIndex = roles?.findIndex(row => row?.id === rol?.id)
                        //Obtenemos la informacion del rol
                        const rolInfo = newRoles[rolIndex]
                        //Actulizamos el estado de ese rol en sustan
                        rolInfo.name = rol?.name
                        //Enviamos la peticion al servidor para actualizar el registo en la base de datos
                        const res = await PUT(`update-role/${rol?.id}`, {name: rolInfo?.name, description: rolInfo?.description, state: rolInfo?.state})
                        //Validamos que se haya actualizado el estado
                        if(res?.statusCode === 200){
                            await logAction('roles', rol?.id, 'update', `Se actualizó el rol: ${rolInfo?.name}`)
                            //Se cambia la informacino en la copia de los roles
                            newRoles[rolIndex] = {
                                ...rolInfo
                            }
                            //Actualizamos el estado de Zustand
                            set({roles: newRoles})
                            return true
                        }else{
                            SwalFire('Advertencia', 'No se ha podido actualizar el rol', 'warning')
                            return false
                        }
                    }
                } catch (e) {
                    console.log(`Ha ocurrido un error al actualizar el role: ${e.message}`);
                    return false
                }
            },
            deleteRol: async (id_rol) => {
                const { roles } = get()
                const resSwal = await SwalfireConfirm('¿Está seguro que desea eliminar el rol?')
                if(resSwal){
                    const res = await PUT(`deactivate-role/${id_rol}`)
                    if(res?.statusCode === 200){
                        let newRoles = structuredClone(roles)
                        //Obtenemos el indioce del rol dentro del Array
                        const rolIndex = roles?.findIndex(row => row?.id === id_rol)
                        //Obtenemos la informacion del rol
                        const rolInfo = newRoles[rolIndex]
                        //Actulizamos el estado de ese rol en sustan
                        rolInfo.status = 0
                        //Se cambia la informacino en la copia de los roles
                        newRoles[rolIndex] = {
                            ...rolInfo
                        }
                        
                        newRoles = newRoles.filter(x=>x.status !==0) 
                        set({roles: newRoles})
                        await logAction('roles', id_rol, 'delete', 'Se eliminó un rol')
                        return true
                    }else
                        return false
                }
            }
        }),
        {
            name: encrypt_data('roles_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)