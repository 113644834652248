import * as Yup from 'yup'
export const AgendaSchema = Yup.object().shape({
    id: Yup.number(),
    date: Yup.date()
        .required('Fecha es requerido'),
    description: Yup.string()
        .required('Descripcion es requerido')
        .min(5, "El campo debe contener minimo 5 caracteres")
        .max(100, "El campo debe contener maximo 100 caracteres"),
    actor_related: Yup.number().transform((value) => Number.isNaN(value) ? null : value ),
    related_actor_id: Yup.number()
        .when("actor_related", (actor_related, schema) => {
            if(actor_related.includes(1))
                return schema.required('Debe seleccionar un actor').typeError('El campo es obligatorio')
            return schema.nullable(true).transform((value) => Number.isNaN(value) ? null : value )
        }),
    applicant_company_id: Yup.number().required('Empresa solicitante es requido').typeError('El campo no puede estar vacío'),
    pillar_id: Yup.number().required('Pilar es requido').typeError('El campo no puede estar vacío'),
    internal_applicant_id: Yup.number().required('Solicitante es requido').typeError('El campo no puede estar vacío'),
    area_id: Yup.number().required('Debe seleccionar un area').typeError('El campo es obligatorio'),
    responsible_id: Yup.number().required('Responsable es requido').typeError('El campo no puede estar vacío'),
    processing_requirements: Yup.number().nullable(true).transform((value) => Number.isNaN(value) ? null : value ),
    requirements: Yup.array().of(
        Yup.object().shape({
            id_requirement: Yup.number().nullable(true).transform((value) => Number.isNaN(value) ? null : value ),
            documentation: Yup.string()
                .min(1, "El campo no puede estar vacío"),
            observation: Yup.string()
                .min(1, "El campo no puede estar vacío")
                .max(300, "El campo debe contener maximo 300 caracteres"),
            observation_date: Yup.string(),
            id_responsible: Yup.number().required('Responsable es requido').typeError('El campo no puede estar vacío'),
            new_observation: Yup.string()
                .min(1, "El campo no puede estar vacío")
                .max(300, "El campo debe contener maximo 300 caracteres"),
            pdf_link: Yup.mixed().nullable(),
            delivery_date: Yup.date()
                .required('Fecha de entrega es requerido') 
                .typeError("El campo no puede estar vacío")
        })
    ).test(
        'is-required',
        'Debe agregar los requisitos del tramite',
        function (value) {
            const processingRequirements = this.parent.processing_requirements;
            if (processingRequirements === 1) {
                return Array.isArray(value) && value.length > 0;
            }
            return true;
        }
    ),
    end_date: Yup.date()
        .required('Fecha final es requerido')
        .typeError('El campo no puede estar vacío'),
    general_comments: Yup.string()
        .max(300, "El campo debe contener maximo 300 caracteres"),
    digital_document: Yup.string()
        .nullable(),
    physical_document: Yup.string()
        .nullable()
});