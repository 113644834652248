import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import { List, ListItemButton } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    fontSize: "1em",
  },
  "& .MuiInputBase-input": {
    fontSize: "1em",
  },
  "& .MuiInputBase-root": {
    fontSize: "1em",
    height: "50px",
  },
}));

export const InputAutocompleteAdapter = (props) => {
  const {
    name,
    options,
    control,
    label = "Selecciona una opción",
    schema,
    customOnChange,
    disabled,
    fullWidth,
    sx,
    height,
    mb,
    size,
    propsTextField,
    ...rest
  } = props;
  const theme = useTheme();
  const [inputOptions, setInputOptions] = useState([]);

  useEffect(() => {
    if (options?.length) {
      setInputOptions([...options]);
    }
  }, [options]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          {...rest}
          sx={{ ...sx, margin: 0, padding: 0 }}
          size={size || "small"}
          disabled={disabled}
          fullWidth={fullWidth || false}
          onOpen={() => {
            setInputOptions(options);
          }}
          isOptionEqualToValue={(option, value) => {
            return option?.value === value?.value;
          }}
          value={
            field?.value
              ? options?.find((x) => x?.value === field?.value)
              : null
          }
          options={inputOptions.sort((actorA, actorB) =>
            actorA.label.localeCompare(actorB.label))}
          getOptionLabel={(option) => option?.label}
          // eslint-disable-next-line react/no-unstable-nested-components
          ListboxComponent={(value) => {
            return (
              <List
                {...value}
                sx={{
                  padding: 0,
                  margin: 0,
                  height: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  bgcolor: "background.paper",
                  position: "relative",
                  overflow: "auto",
                  maxHeight: "300px",
                  "& ul": { padding: 0, margin: 0 },
                }}
              >
                {value?.children}
              </List>
            );
          }}
          renderOption={(prop, option, state) => (
            <ListItemButton {...prop}>{option?.label}</ListItemButton>
          )}
          onInputChange={(e, value) => {
            setInputOptions(
              options?.filter((x) =>
                x?.label?.toString().toLowerCase().includes(value.toLowerCase())
              )
            );
          }}
          onChange={(_, value) => {
            field.onChange(value?.value);
            if (customOnChange) customOnChange();
          }}
          onBlur={field.onBlur}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              {...propsTextField}
              variant="outlined"
              label={label}
              error={Boolean(fieldState.error?.message)}
              helperText={fieldState.error?.message || ""}
              sx={{
                height,
                margin: 0,
                padding: 0,
                mb: mb === 0 ? mb : theme.spacing(1),
                fontSize: "1em",
              }}
            />
          )}
        />
      )}
    />
  );
};
