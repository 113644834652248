/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { PaletteV } from '../../components/Palette';
import { UseStoreConfig } from './../../stores/Config';

export const PaletteC = () => {
    const color_config = UseStoreConfig(state => state.color_config);
    const get_color_config = UseStoreConfig(state => state.get_color_config);
    const update_color_config = UseStoreConfig(state => state.update_color_config);
    const [isLoading, setIsLoading] = useState(false)
    //Colors estados actividad
    const [statusActivityCurrent, setStatusActivityCurrent] = useState('')
    const [statusActivityCurrent2, setStatusActivityCurrent2] = useState('')
    const [statusActivityClosed, setStatusActivityClosed] = useState('')
    const [statusActivityExpired, setStatusActivityExpired] = useState('')
    //Color estados
    const [controlInProgress, setControlInProgress] = useState('')
    const [controlStopped, setControlStopped] = useState('')
    const [controlCanceled, setControlCanceled] = useState('')
    const [controlCompleted, setControlCompleted] = useState('')
    
    const get_data = async () => {
        setIsLoading(true)
        const result = await get_color_config()
        setStatusActivityCurrent(result?.status_activity_current)
        setStatusActivityCurrent2(result?.status_activity_current2)
        setStatusActivityClosed(result?.status_activity_closed)
        setStatusActivityExpired(result?.status_activity_expired)
        setControlInProgress(result?.control_in_progress)
        setControlStopped(result?.control_stopped)
        setControlCanceled(result?.control_canceled)
        setControlCompleted(result?.control_completed)
        setIsLoading(false)
    }

    useEffect(() => {
        get_data()
    }, [])    

    const OnSubmit = async () => {
        const payload = {
            id: color_config?.id,
            status_activity_current: statusActivityCurrent,
            status_activity_current2: statusActivityCurrent2,
            status_activity_closed: statusActivityClosed,
            status_activity_expired: statusActivityExpired,
            control_in_progress: controlInProgress,
            control_stopped: controlStopped,
            control_canceled: controlCanceled,
            control_completed: controlCompleted,
        }

        setIsLoading(true)
        await update_color_config(payload)
        setIsLoading(false)
    }

    return ( 
        <PaletteV  
            vars={{ 
                isLoading,
                statusActivityCurrent,
                statusActivityCurrent2,
                statusActivityClosed,
                statusActivityExpired,
                controlInProgress,
                controlStopped,
                controlCanceled,
                controlCompleted
            }}
            funcs={{
                OnSubmit,
                setStatusActivityCurrent,
                setStatusActivityCurrent2,
                setStatusActivityClosed,
                setStatusActivityExpired,
                setControlInProgress,
                setControlStopped,
                setControlCanceled,
                setControlCompleted
            }}
        />
    )
}