import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { encrypt_data } from './../utils/JSImplements'
import { GET, POST } from './../utils/AuthService'
import { SwalFire } from './../utils'

export const UseStoreNotifications = create(
    persist(
        (set, get) => ({
            notification: false,
            get_notification: async () => {
                const notification_ = await GET('get-time-notification', 'agenda')
                if(notification_?.data) set({notification: notification_?.data})
                return notification_?.data
            },
            save_time_notification:  async (payload) => {
                try {
                    const notification = await POST(`save-time-notification`, payload ,'agenda')
                    if(notification?.statusCode === 200){
                        SwalFire('Exito!', 'Se ha guardado la información.', 'success')
                        return notification; 
                    }else {
                        SwalFire('Atención!', 'No se pudo guardar la información.', 'warning')
                        return false; 
                    }
                }catch (e) {
                    console.log('Ha ocurrido un error al guardar la notificacion')
                }
            }
        }),
        {
            name: encrypt_data('notification_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)