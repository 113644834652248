import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { PermissionsC } from "./../../containers/Permissions";
import { FilledAlerts } from "./../Common/AlertsMUI";

export const AddRolV = ({
  data: {
    vars: { type_user, NameRol, Error, alert, stateView, isLoading },
    funcs: { HandleSubmit, SetNameRol, SetAlert, setPath },
  },
}) => {
  const [readOnly, setReadOnly] = useState(false);
  const [edit, setEdit] = useState(false);

  /* const companies = [
        {id: 1, name: '4040apps'},
        {id: 2, name: 'jabare soft'}
    ] */

  /* const [viewPermission, setViewPermission] = useState(false)
    const [dataSelects, setDataSelects] = useState({
        rol_id: 0,
        companie_id: 0,
        user_id: 0,
        companie_user_id: 0
    }); */

  /* //Esta pendiente de los cambios en los select para la pantalla de permisos
    const handleChangeSelect = (event) => {
        const { name, value} = event?.target
        setDataSelects({
            ...dataSelects,
            [name]: value
        });
    };

    const permission = useCallback(() => (<PermissionsC
        option={2}
        data={{
            label: 'Rol',
            stateView,
            filters:{
                role_id: stateView?.rol?.id,
                companie_id: dataSelects?.companie_id,
                module_id: dataSelects?.module_id,
            }
        }}
    />)) */
  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
  };

  useEffect(() => {
    if (stateView?.readonly) {
      setReadOnly(true);
    } else if (stateView?.edit) {
      setEdit(true);
    }
  }, [stateView?.readonly, stateView?.edit]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "#f6f7fb",
        pl: 1,
      }}
    >
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <FilledAlerts
        msg={alert?.msg}
        type={alert?.type}
        setOpen={SetAlert}
        open={alert?.active}
      />
      <Box
        height="40px"
        bgcolor="#ffffff"
        p={2}
        pr={8}
        borderRadius="0px 0px 0px 7px"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        boxShadow="0px 3px 4px 0px #2222221A"
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#323338",
          }}
        >
          {edit
            ? "Editar rol"
            : readOnly
            ? "Información del Rol"
            : "Agregar rol"}
        </Typography>
      </Box>
      <Box
        mt={2}
        component="form"
        onSubmit={HandleSubmit}
        sx={{
          height: "100%",
          overflow: "auto",
          width: "95%",
          borderRadius: "7px",
          bgcolor: "#FFFFFF",
          p: "0px 16px",
        }}
      >
        <Grid container columnSpacing={2} rowGap={2} columns={12} pt={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={8}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography sx={styles.label}>Nombre de nuevo rol</Typography>
            <TextField
              required
              disabled={readOnly}
              id="name_rol"
              size="small"
              value={NameRol}
              onChange={(e) => SetNameRol(e?.target?.value)}
              error={Error?.error}
              helperText={Error?.msg}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            display="flex"
            alignItems="end"
            justifyContent="end"
            gap={2}
          >
            <Button
              type="button"
              variant="text"
              onClick={() => setPath("roles-list")}
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "capitalize",
                width: "119px",
                height: "40px",
                color: "#000000",
                ":hover": {
                  background: "#E2E4E7",
                },
              }}
            >
              Cancelar
            </Button>
            {!readOnly && (
              <Button
                type="submit"
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "14px",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  width: "100px",
                  height: "40px",
                  background: "#1AAFD0",
                  color: "#FFFFFF",
                  ":hover": {
                    background: "#1AAFD0",
                  },
                }}
              >
                Guardar
              </Button>
            )}
          </Grid>
        </Grid>
        {(readOnly || edit) && (
          <Box mt={2}>
            <PermissionsC
              option={2}
              data={{
                label: "Rol",
                stateView,
                filters: {
                  role_id: stateView?.rol?.id,
                },
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
