import * as Yup from 'yup'
export const noDescriptionSchema = Yup.object().shape({
    writing_number: Yup.string()
        .matches(/^[0-9,]+$/, 'Numero de escrita invalido')
        .required('Numero de Escritura es requerido'),
    grant_date: Yup.date()
        .required('Fecha otorgamiento es requerido')
        .typeError('El campo no puede quedar vacío'),
    entry_number: Yup.number()
        .when("verification", (verification, schema) => {
            if(verification.includes(1))
                return schema.required('Entrada es requerido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        }),
    volume: Yup.number()
        .when("verification", (verification, schema) => {
            if(verification.includes(1))
                return schema.required('Volumen es requeido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        }),
    book: Yup.string()
        .when("verification", (verification, schema) => {
            if(verification.includes(1))
                return schema.required('Libro es requerido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        }),
    folio: Yup.string()
        .nullable(),
    registry_date: Yup.date()
        .when("verification", (verification, schema) => {
            if(verification.includes(1))
                return schema.required('Fecha registro es requerido').typeError('El campo no puede quedar vacío')
            return schema.nullable()
        })
        .nullable(),   
    verification: Yup.number(),
    pdf_link: Yup.mixed()
        .required('El documento es requerido')
       
});

export const withDescriptionSchema = Yup.object().shape({
    writing_number: Yup.string()
        .matches(/^[0-9,]+$/, 'Numero de escrita invalido')
        .required('Numero de Escritura es requerido'),
    description: Yup.string()
        .required('Descripcion es requerido')
        .typeError('El campo solo permite numeros'),
    grant_date: Yup.date()
        .required('Fecha otorgamiento es requerido')
        .typeError('El campo no puede quedar vacío'),
    entry_number: Yup.number()
        .when("verification", (verification, schema) => {
            if(verification.includes(1))
                return schema.required('Entrada es requerido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        })
        .nullable(true),
    volume: Yup.number()
        .when("verification", (verification, schema) => {
            if(verification.includes(1))
                return schema.required('Volumen es requeido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        }),
    book: Yup.string()
        .when("verification", (verification, schema) => {
            if(verification.includes(1))
                return schema.required('Libro es requerido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        }),
    folio: Yup.string()
        .nullable(),
    registry_date: Yup.date()
        .when("verification", (verification, schema) => {
            if(verification.includes(1))
                return schema.required('Fecha registro es requerido').typeError('El campo no puede quedar vacío')
            return schema.nullable()
        })
        .nullable(), 
    verification: Yup.number(),
    pdf_link: Yup.mixed()
        .required('El documento es requerido'),
    tags: Yup.array().nullable()
});

export const noIRPCSchema = Yup.object().shape({
    writing_number: Yup.string()
        .matches(/^[0-9,]+$/, 'Numero de escrita invalido')
        .required('Numero de Escritura es requerido'),
    description: Yup.string()
        .required('Descripcion es requerido')
        .typeError('El campo solo permite numeros'),
    grant_date: Yup.date()
        .required('Fecha otorgamiento es requerido')
        .typeError('El campo no puede quedar vacío'),
    pdf_link: Yup.mixed()
        .required('El documento es requerido'),
    tags: Yup.array().nullable()

});

export const powerSchema = Yup.object().shape({
    attorney: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿñ ]*$/, "Solo debe contener letras")
        .required('Apoderado es requerido')
        .min(5, "El campo debe contener al minimo 5 caracteres")
        .max(50, "El campo debe contener al maximo 50 caracteres"),
    type_power_code: Yup.string()
        .nullable(true),
    special_power_id: Yup.number()
        .when("type_power_code", (type_power_code, schema) => {
            if(type_power_code.includes('pwr-pes'))
                return schema.required('Tipo de poder especial es requerido')
            return schema
        })
        .nullable(true),
    writing_number: Yup.string()
        .matches(/^[0-9,]+$/, 'Numero de escrita invalido')
        .required('Numero de Escritura es requerido'),
    grant_date: Yup.date()
        .required('Fecha otorgamiento es requerido')
        .typeError('El campo no puede quedar vacío'),
    vigency: Yup.string()
        .required('Vigencia es requerido'),
    date_vigency: Yup.date()
        .required('Fecha limite de vigencia es requerido')
        .typeError('El campo no puede quedar vacío'),
    limitation: Yup.string()
        .required('Limitacion es requerido'),
    entry_number: Yup.number()
        .when("verification", (verification, schema) => {
            console.log(verification)
            if(verification.includes(1))
                return schema.required('Entrada es requerido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        }),
    volume: Yup.number()
        .when("verification", (verification, schema) => {
            console.log(verification)
            if(verification.includes(1))
                return schema.required('Volumen es requeido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        }),
    book: Yup.string()
        .when("verification", (verification, schema) => {
            console.log(verification)
            if(verification.includes(1))
                return schema.required('Libro es requerido').typeError('El campo solo permite numeros')
            return schema.transform((value) => Number.isNaN(value) ? null : value ).nullable(true)
        }),
    registry_date: Yup.date()
        .when("verification", (verification, schema) => {
            console.log(verification)
            if(verification.includes(1))
                return schema.required('Fecha registro es requerido').typeError('El campo no puede quedar vacío')
            return schema.nullable()
        })
        .nullable(), 
    verification: Yup.number(),
    pdf_link: Yup.mixed()
        .required('El documento es requerido'),
    state: Yup.number()
        .required('Estatus es requerido'),
    especial_power: Yup.string()
        .matches(/^[a-zA-ZÀ-ÿñ ]*$/, "Solo debe contener letras")
        .min(5, "El campo debe contener al minimo 5 caracteres")
        .max(50, "El campo debe contener al maximo 50 caracteres")
});