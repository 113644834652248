import React from 'react';

const WelcomeEmail = ({ token_auth }) => {
 /*  return (
    <div>
      <p>¡Bienvenido/a a Digital Phase Platform DPP! Tu registro ha sido procesado con éxito, y ahora tienes acceso a la plataforma.</p>
      <p>A continuación, te proporcionamos algunos detalles clave para comenzar:</p>
      <ul>
        <li><strong>Inicia Sesión:</strong> Dirígete a nuestra página de inicio de sesión <a href={`${process.env.REACT_APP_URL}`}>aquí</a> e 
        ingresa con las credenciales de Outlook. Deberás dar de alta tu cuenta en la aplicación de Google Authenticator son el siguiente Token: <strong>{token_auth} </strong> 
        En cada inicio sesión deberás hacer la verificación de dos pasos.</li>
        <li>Anexo a este correo encontrarás el Manual de Usuario.</li>
      </ul>
      <p>Si tienes alguna pregunta o necesitas asistencia, comunícate con el administrador de la plataforma.</p>
      <p>Te agradecemos nuevamente por unirte a Digital Phase Platform.</p>
      <p>Saludos cordiales.</p>
    </div>
  ); */
  return (
    <div>
      <p>Estimado Colaborador:</p>
      <p>¡Te damos la Bienvenida a Digital Phase Platform, DPP!</p>
      <p>Confirmamos que tu registro y acceso ha sido procesado con éxito.</p>
      <p>A continuación, te proporcionamos algunos detalles clave para comenzar:</p>
      <ul>
        <li><strong>Paso 1:</strong> Para iniciar sesión, dirígete a nuestra <a href={`${process.env.REACT_APP_URL}`}>página de inicio de sesión</a> e ingresa con tus credenciales de Outlook.</li>
        <li><strong>Paso 2:</strong> Deberás descargar en tu teléfono celular la aplicación “Google Authenticator” y crear una cuenta en la aplicación.</li>
        <li><strong>Paso 3:</strong> Una vez creada, inserta en “Ingresar un código” el siguiente token: <strong>{token_auth}</strong> con el nombre de identificación DPP.</li>
      </ul>
      <p>Recuerda que en cada inicio de sesión deberás hacer la verificación de dos pasos.</p>
      <p>Puedes encontrar el Manual de Usuario en este <a href="https://drive.google.com/file/d/10bPGTNH05xvruUC8E8CoU9Ot4sxn_qGU/view?usp=share_link">link</a>.</p>
      <p>Si tienes alguna pregunta o necesitas asistencia, comunícate con el administrador de la plataforma.</p>
      <p>Saludos cordiales.</p>
      <p>DPP</p>
    </div>
  );
}

export default WelcomeEmail;
