import React, { useState } from "react";
import { Container, Image } from "./styled";
import { Box, LinearProgress, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { POST } from "../../utils/AuthService";
import { SignOutButton } from "../../utils/SignOutButton";
import Logo from "./../../assets/logo.svg";

const NotRegisteredMessage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: 3,
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: " 24px",
          fontFamily: "Poppins-SemiBold",
        }}
      >
        Tu cuenta no está registrada
      </Typography>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: " 16px",
          fontFamily: "Poppins-SemiBold",
        }}
      >
        Ponte en contacto con un administrador para solicitar acceso a la
        plataforma
      </Typography>
      <SignOutButton />
    </Box>
  );
};

export const OTPVerificationV = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const isInDatabase = async () => {
    try {
      const response = await POST("/search-user", { email: props.email });
      if (response.statusCode === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Hubo un error al verificar el OTP:", error);
      return false;
    }
  };

  return (
    <Container>
      <Box
        sx={{
          width: "601px",
          height: "420px",
          background: "#0000008a",
          p: "10px",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            height: "20%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#323338",
          }}
        >
          <Image src={Logo} alt="logo" />
        </Box>
        {loading && <LinearProgress />}
        <Box
          sx={{
            height: "80%",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            gap: 1.5,
            padding: "0px 40px 0px 20px",
          }}
        >
          {isInDatabase ? (
            <>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: " 24px",
                  fontFamily: "Poppins-SemiBold",
                }}
              >
                Verificación de seguridad
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: " 16px",
                  fontFamily: "Poppins-SemiBold",
                }}
              >
                Autenticación de 2 pasos
              </Typography>
              <TextField
                id="OTP"
                type="number"
                value={otp}
                sx={{ width: "100%" }}
                onChange={(e) => setOtp(e.target.value)}
              />
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: " 12px",
                  fontFamily: "Poppins-Regular",
                }}
              >
                Escribe la contraseña de 6 dígitos generada por tu app de
                autenticación.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  gap: 3,
                  mt: 1,
                  width: "100%",
                }}
              >
                <SignOutButton />
                <Button
                  variant="contained"
                  className="otp-button"
                  sx={{
                    background: "#1AAFD0",
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    width: "100px",
                    height: "49px",
                    color: "#fff",
                    textTransform: "inherit",
                    "&: hover": {
                      background: "#1AAFD0",
                    },
                  }}
                  onClick={async () => {
                    setLoading(true);
                    const result = await props.verificarOtp(otp);
                    if (result) setLoading(false);
                    setOtp("");
                  }}
                >
                  Verificar
                </Button>
              </Box>
            </>
          ) : (
            <NotRegisteredMessage />
          )}
        </Box>
      </Box>
    </Container>
  );
};
