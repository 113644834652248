import React from "react";
import { PermissionsC } from '../../containers/Permissions'
export const Permissions = ({props}) => <PermissionsC 
    {...props} 
    children 
    option={1} 
    data={{
        label:'',
        pathGetPermission: '',
        pathAssignPermission: '',
        filters: {}
    }}/>
