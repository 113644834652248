import { Box, Button, Collapse, Typography } from '@mui/material';
import React from 'react';
import { HexColorPicker } from "react-colorful";

export const ColorPalette = ({color, setColor, showPalette, setShowPalette, label}) => {

  const styles = {
    label: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "600",
    },
    buttonSave: {
      height: "49px",
      width: "100px",
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      fontWeight: "500",
      bgcolor: color, 
      color: 'inherit',
    }
};
  return (
    <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
      >
        {label &&<Typography sx={styles.label}>{label}</Typography>}
        <Button
          sx={styles.buttonSave}
          onClick={() => setShowPalette(!showPalette)} 
        />
      </Box>
      <Collapse in={showPalette} sx={{m:1}}>
        <HexColorPicker color={color} onChange={setColor} />
      </Collapse>
    </Box>
    
  ) 
};