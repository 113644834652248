import * as Yup from "yup";
export const CompanySchema = Yup.object().shape({
  name: Yup.string()
    .required("Nombre de la empresa es requerido")
    .min(5, "El campo debe contener al minimo 5 caracteres")
    .max(50, "El campo debe contener al maximo 50 caracteres"),
  social_name: Yup.string()
    .required("Denominacion social es requerido")
    .min(5, "El campo debe contener minimo 5 caracteres")
    .max(50, "El campo debe contener maximo 50 caracteres"),
  url_logo: Yup.mixed().nullable(true),
  rfc: Yup.string()
    .required("RFC es requerido")
    .min(5, "El campo debe contener minimo 5 caracteres")
    .max(50, "El campo debe contener maximo 50 caracteres"),
  date_of_constitution: Yup.date().nullable(),
  address: Yup.string().nullable(true),
  zip_code: Yup.number().typeError("No se permiten letras").nullable(true),
  town: Yup.string().nullable(true),
  state: Yup.string().nullable(true),
  country_id: Yup.number().nullable(true),
  activity: Yup.string()
    .nullable(true)
    .max(255, "El campo debe contener maximo 255 caracteres"),
  sector_id: Yup.number().required("Sector es requerido"),
  shares_link: Yup.mixed().nullable(true),
  org_chart_link: Yup.mixed().nullable(true),
  // bd_names_1: Yup.string().nullable(true),
  // bd_function_1: Yup.string().nullable(true),
  // bd_names_2: Yup.string().nullable(true),
  // bd_function_2: Yup.string().nullable(true),
  // bd_names_3: Yup.string().nullable(true),
  // bd_function_3: Yup.string().nullable(true),
  // bd_names_4: Yup.string().nullable(true),
  // bd_function_4: Yup.string().nullable(true),
  company_administrations: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().nullable(true),
      name: Yup.string().nullable(true),
      position: Yup.string().nullable(true),
    })
  ),
  contact_name: Yup.string().nullable(true),
  contact_email: Yup.string().nullable(true),
  contact_celular: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(true),
  contact_phone: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(true),
  extension: Yup.number().nullable(true),
  ho_days: Yup.array().nullable(true),
  ho_time_start: Yup.string().nullable(true),
  ho_time_end: Yup.string().nullable(true),
  business_gazette: Yup.string()
    .nullable()
    .test("is-valid-url", "URL invalida", function (value) {
      // Perform URL validation only if the field is not null or empty
      if (value && value.trim() !== "") {
        return /^(?:https?):\/\/(\w+:? \w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=~|]*))?$/.test(
          value
        );
      }
      return true; // Allow null or empty values
    }),
});
