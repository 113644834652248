import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PanelAgendaV } from '../../components/Agenda'
import { useForm, useFieldArray, set, get } from 'react-hook-form';
import { AgendaSchema } from '../../schemas/AgendaSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseStoreTemplateView } from '../../stores/TemplateView'
import { UseStoreCompany } from '../../stores/Company'
import { UseStoreUsers } from '../../stores/Users'
import { UseStoreAgenda } from '../../stores/Agenda'
import { UseStoreLogin } from '../../stores/Login'
import { UseStorePillar } from '../../stores/Pillar'
import { UseStoreConfig } from './../../stores/Config';
import { UseStoreEmailController } from '../../stores/EmailController'
import { GridRowModes } from '@mui/x-data-grid';
import { uploadFile } from '../../utils/AWS'
import { NewRequestMailer, NewRequirementMailer, NewCommentMailer, NewObservationRequirementMailer, ControlMailer } from '../../utils/Mailer'
import dayjs from 'dayjs';
import { UseStoreActosGubernamentales } from "../../stores/ActosGubernamentales";
import { UseStoreActivos } from "../../stores/Activos";
import { UseStoreContratos } from "../../stores/Contratos";
import {create} from 'zustand';
import { persist } from 'zustand/middleware';
import { readFile } from "../../utils/AWS";
import { act } from 'react';
import Swal from 'sweetalert2';
import { SwalfireConfirmUnbold } from '../../utils';


export const PanelAgendaC = () => {
    const navigate = useNavigate()
    const userInfo = UseStoreLogin(state => state.data_user)
    const emails = UseStoreEmailController(state => state.emails);
    const areas = UseStoreAgenda(state=> state.areas)
    const requests = UseStoreAgenda(state=> state.requests)
    const pdf_link = UseStoreAgenda(state=> state.pdf_link)
    const get_areas = UseStoreAgenda(state=> state.get_areas)
    const get_requests = UseStoreAgenda(state=> state.get_requests)
    const get_history_observations_by_requirement = UseStoreAgenda(state=> state.get_history_observations_by_requirement)
    const get_history_document_by_requirement = UseStoreAgenda(state=> state.get_history_document_by_requirement)
    const get_requests_filter = UseStoreAgenda(state=> state.get_requests_filter)
    const get_requests_filter_deleting = UseStoreAgenda(state=> state.get_requests_filter_deleting)
    const get_requests_filter_custodian = UseStoreAgenda(state=> state.get_requests_filter_custodian)
    const get_pdf_link = UseStoreAgenda(state=> state.get_pdf_link)
    const add_request = UseStoreAgenda(state=> state.add_request)
    const update_status_requirements = UseStoreAgenda(state=> state.update_status_requirements)
    const update_id_operational_agenda = UseStoreAgenda(state=> state.update_id_operational_agenda)
    const update_processing_requirement = UseStoreAgenda(state=> state.update_processing_requirement)
    const add_general_comment = UseStoreAgenda(state=> state.add_general_comment)
    const add_observation_requirement = UseStoreAgenda(state=> state.add_observation_requirement)
    const add_document_requirement = UseStoreAgenda(state=> state.add_document_requirement)
    const update_request = UseStoreAgenda(state=> state.update_request)
    const update_requests = UseStoreAgenda(state=> state.update_requests)
    const delete_request = UseStoreAgenda(state=> state.delete_request)
    const TOKEN_MSAL = UseStoreTemplateView(state=> state.TOKEN_MSAL)
    const status_activities = UseStoreTemplateView(state=> state.status_activities)
    const get_status_activities = UseStoreTemplateView(state=> state.get_status_activities)
    const updateLabelNav = UseStoreTemplateView(state=> state.updateLabelNav)
    const pillars = UseStoreTemplateView(state=> state.pillars_companies)
    const actors = UseStoreTemplateView(state=> state.actors)
    const get_actors = UseStoreTemplateView(state=> state.get_actors_simplified)
    const control_status = UseStoreTemplateView(state=> state.control_status)
    const get_controls_status = UseStoreTemplateView(state=> state.get_controls_status)
    const refresh_token_msal = UseStoreTemplateView(state=> state.refresh_token_msal)
    const set_document_selected = UseStorePillar(state=> state.set_document_selected)
    const companies = UseStoreCompany(state=> state.companies)
    const users = UseStoreUsers(state=> state.users)
    const color_config = UseStoreConfig(state => state.color_config);
    const [openModal, setOpenModal] = useState(false)
    const [typeView, setTypeView] = useState(0)
    const [isView, setIsView] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingFilters, setIsLoadingFilters] = useState(false)
    const [idRow, setIDRow] = useState(false)
    const [LoadingObservations, SetLoadingObservations] = useState(false)
    const [rowModesModel, setRowModesModel] = useState({});
    const [dataHistory, setDataHistory] = useState([])
    const [valCheckbox, getValCheckbox] = useState({actor: 0, requirements: 0})
    const [data, setData] = useState({})
    const { handleSubmit, control, reset, formState: { errors }, setValue, watch} = useForm({resolver: yupResolver(AgendaSchema)});
    const { fields, append, remove } = useFieldArray({ control, name: 'requirements' });
    const period = watch('period')
    const [myAssigments, setMyAssigments] = useState(false)
    const [openModalSetDocument, setOpenSetDocumento] = useState(false)
    const [infoAgenda, setInfoAgenda] = useState({});
    const get_goverment_acts = UseStoreActosGubernamentales((state) => state.get_goverment_acts);
    const get_activos = UseStoreActivos((state) => state.get_activos);
    const get_contratos = UseStoreContratos((state) => state.get_contratos);
    const [govermentActs, setGovermentActs] = useState([]);
    const save_digital_document = UseStoreAgenda(state => state.save_digital_document)
    const [openDocument, setOpenDocument] = useState({open: false, type: false})
    const [shareLink, setShareLink] = useState(null)
    const get_pillar_by_company = UseStorePillar(state => state.get_pillar_by_company)
    const [showFilters, setShowFilters] = useState(false)
    const get_permission_user = UseStorePillar(state => state.get_permission_user)
    const [permissions, setPermissions] = useState([])
    const [disabledDownload, setDisabledDownload] = useState(false)
    const [registerDelete, setRegisterDelete] = useState(false)
    const [allAssignments, setAllAssignments] = useState(true)
    const [subPillar, setSubPillar] = useState(0)
    const [idDocument, setIdDocument] = useState(0)
    const [nameDocument, setNameDocument] = useState('')
    const [custodian, setCustodian] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [canceled, setCanceled] = useState(false)
    
    //console.log(errors);
    
    const handleSetRequirement = () => {
        append({documentation: '', observation: '', observation_date: dayjs().format('YYYY-MM-DD HH:mm:ss'), id_responsible: null,  pdf_link: null, delivery_date: null});
    };

    const subPillarsCodes = {
        1: 'act-con',
        2: 'poderes',
        3: 'act-asm',
        4: 'fed-hec',
        5: 'aut-fed',
        6: 'aut-est',
        7: 'aut-mun',
        8: 'act-inm',
        9: 'act-mue',
        10: 'act-int',
        11: 'con-civ',
        12: 'con-mer',
        13: 'con-adm',
        14: 'con-lab',
    }

    const loadData = async () => {
        await refresh_token_msal()
        setIsLoading(true);

        
        //const reqs = await get_requests()
        const payload = {
            control: [1,2,100]
        }
        const [reqs, areas, actors, statusActivities, ControlsStatus] =  await Promise.all([
            await get_requests_filter(payload),
            get_areas(),
            get_actors(),
            get_status_activities(),
            get_controls_status()
        ])
        resetForm( reqs?.length + 1)
        setData({
            companies, 
            users: users.map(x => ({...x, full_name: `${x?.names} ${x?.last_names} - ${x?.charge}`})), 
            pillars,
        })
        setIsLoading(false)
    }

    const refresh_request = async () => {
        setMyAssigments(false)
        setIsLoading(true)
        const payload = {
            control: [1,2,100]
        }
        await get_requests_filter(payload)
        setData({
            companies,
            users: users.map(x => ({...x, full_name: `${x?.names} ${x?.last_names} - ${x?.charge}`})),
            pillars,
        })
        setIsLoading(false)
    }

    const get_request_filters = async () => {
        setIsLoadingFilters(true)
        const payload = {
            period: watch('period'),
            date_start: watch('date_start')? dayjs(watch('date_start')).format('YYYY-MM-DD') : null, 
            date_end:  watch('date_end') ? dayjs(watch('date_end')).format('YYYY-MM-DD') : null, 
            status: watch('status'),
            responsible: watch('responsible'),
            responsible_requirement: watch('responsible_requirement'),
            internal_applicant: watch('internal_applicant'),
            resguard: watch('resguard'),
            actor: watch('actor'),
            company: watch('company'),
            pillar: watch('pillar'),
            control: watch('control') ? [watch('control')] : [1, 2, 3, 4, 5, 100]
        }
        await get_requests_filter(payload)
        console.log(payload)
        setIsLoadingFilters(false)
    }
    const get_request_filters_my_assignments = async () => {
        setIsLoading(true)
        setIsLoadingFilters(true)
        setMyAssigments(true);
        const payload = {
            date_start: null, 
            date_end:  null, 
            responsible: userInfo?.id,
            control : [1,2,3,100]
        }
        await get_requests_filter(payload)
        setIsLoadingFilters(false)
        setIsLoading(false)
    }
    const getRequestCustodian = async () => {

        setIsLoading(true)
        setIsLoadingFilters(true)
        setCustodian(true) 
        const payload1 = {
            date_start: null,
            date_end: null,
            resguard: userInfo?.id,
            //responsible: userInfo?.id,
            control : [1,2,3,100]
        }
        /* const payload2 = {
            date_start: null,
            date_end: null,
            //resguard: userInfo?.id,
            responsible: userInfo?.id,
            control : [1,2,3,4]
        } */
        await get_requests_filter(payload1)
        setIsLoading(false)
        setIsLoadingFilters(false)
    }
    const getRequestFilterDeleting = async () => {
        setIsLoading(true)
        const payload = {
            date_start: null, 
            date_end:  null, 
            control: [5]
        }
        await get_requests_filter_deleting(payload)
        console.log(payload)
        setIsLoading(false)
    }
    const getRequestFilterCompleted = async () => {
        setIsLoading(true)
        const payload = {
            date_start: null,
            date_end: null,
            control: [4]
        }
        await get_requests_filter(payload)
        setIsLoading(false)
    }
    const getRequestFilterCanceled = async () => {
        setIsLoading(true)
        const payload = {
            control: [3]
        }
        await get_requests_filter(payload)
        setIsLoading(false)
    }


    const resetForm = (id_act) => {
        reset({
            id: 1,
            date: dayjs(),
            description: '',
            related_actor_id: '',   
            applicant_company_id: '',
            pillar_id: '',
            area_id: '',
            internal_applicant_id: '',
            responsible_id: '',
            processing_requirements: '',
            end_date:  '',
            general_comments: '',
            status_activity_id: '',
            type_digital_document: '',
            digital_document: null,
            physical_document: '',
            safeguarding_id: '',
            control_id: '',
            requirements: []
        })
    }

    const handleNavigatePillar = (type_doc, data) => {
        let url = ''
    
        if (data?.pillar?.code === 'act-cor') {
            if (['act-con', 'fed-hec'].includes(type_doc)){
                url = `/pillars/${data?.company}/${type_doc}`
            }

            if (['pwr-ppc', 'pwr-paa', 'pwr-pad', 'pwr-pes'].includes(type_doc)){
                url = `/subpillars/${data?.company}/poderes/${type_doc}`;
            }
            
            if (['asa-ext', 'asa-ord'].includes(type_doc)){
                url = `/subpillars/${data?.company}/act-asm/${type_doc}`;
            }
        }
        
        navigate(url, {replace: true})   
    }

    const redirectToPillarByCode = (code, company_id) => { 
        if(code === 'act-cor'){
            navigate(`/pillars/${company_id}`, {replace: true})
        }
    }

    const useStore = create(
        persist(
          (set) => ({
            agenda: [],
            time : 0,
            active : false,
            setAgenda: (newAgenda) => set({ agenda: newAgenda }),
            setTime: (newTime) => set({ time: newTime }),
            setActive: (newActive) => set({ active: newActive }),
          }),
          {
            name: 'agenda-storage', // nombre del almacenamiento en localStorage
            Storage: () => localStorage, // o sessionStorage
          }
        )
      );
    
    const setAgenda = useStore((state) => state.setAgenda)
    const setTime = useStore((state) => state.setTime)
    const setActive = useStore((state) => state.setActive)
    

    const handleOpenSetDocument = (row) => {
        setOpenSetDocumento(true)
    }

    const handleCloseSetDocument = () => {
        refresh_request()
        setAgenda([])
        setActive(false)
        setOpenSetDocumento(false)
    }

    const getPath = (fileId, data) => {
        // Crear un diccionario de objetos para acceso rápido
        const dataDict = data.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});
        // Función para construir la ruta recursivamente
        const buildPath = (id, path = []) => {
            const item = dataDict[id];
            if (!item) return path;
            
            path.unshift(item.file_name);
            
            if (item.parent_id !== null) {
                return buildPath(item.parent_id, path);
            }
            
            return path;
        };
        const fullPath = buildPath(fileId);
        // Excluir la última ruta (nombre del archivo) y la primera ruta (raíz)
        if (fullPath.length > 1) {
            fullPath.pop();
            fullPath.shift();
        }
        return fullPath.join(' / ');
    }

    const assignDocumentToRequirement = async (document_id, sp, a, file_name = '') => {
        setIsLoading(true)
        console.log(a)
        const confirm = await SwalfireConfirmUnbold('¿Está seguro de asignar el documento: <br><b>'+ file_name +'</b> <br>a la actividad?')
        if(confirm){
            const payload = {
                id : a.id,
                type_digital_document: subPillarsCodes[sp], // code subpillars
                digital_document : document_id
            }
            try {
                const s = await save_digital_document(payload)
                if(s){
                    console.log('Documento guardado')
                }else{
                    console.log('Error al guardar documento')
                }
            } catch (error) {
                console.error('Error al guardar documento:', error)
            }
            setAgenda([])
            get_request_filters_my_assignments()
            setIsLoading(false)
            handleCloseSetDocument()
        }
        setIdDocument(0)
        setNameDocument('')
    }

    const handleSaveDigitalDocument = async (row) => {
        setIsLoading(true)
        if(row?.pillar_id === 2){
            const subPillars=[5,6,7]
            try {
            const actGob = await Promise.all(
                subPillars.map(async (x) => {   
                  const cap = await get_goverment_acts(row.applicant_company_id, x);
                  return cap.data
                }),
                await get_pillar_by_company(row.applicant_company_id)
              );
              if (actGob) {
                setGovermentActs(actGob.flat());
              }
            const p = await get_permission_user(userInfo?.id, row.applicant_company_id)
            console.log(p)
            setPermissions(p)
            setInfoAgenda(row)
            handleOpenSetDocument(row)
            
            } catch (error) {
                console.error('Error fetching government acts:', error);
            }
        }
        if(row?.pillar_id === 3){
            const subPillars=[8,9,10]
            try {
            const actGob = await Promise.all(
                subPillars.map(async (x) => {
                  return await get_activos(row.applicant_company_id, x);
                }),
                await get_pillar_by_company(row.applicant_company_id)
              );
              if (actGob) {
                setGovermentActs(actGob.flat());
              }
            const p = await get_permission_user(userInfo?.id, row.applicant_company_id)
            console.log(p)
            setPermissions(p)
            setInfoAgenda(row)
            handleOpenSetDocument(row)

            } catch (error) {
                console.error('Error fetching government acts:', error);
            }
        }
        if(row?.pillar_id === 4){
            const subPillars=[11,12,13,14]
            try {
            const actGob = await Promise.all(
                subPillars.map(async (x) => {
                  return await get_contratos(row.applicant_company_id, x);
                }),
                await get_pillar_by_company(row.applicant_company_id)
              );
              if (actGob) {
                setGovermentActs(actGob.flat());
              }
            const p = await get_permission_user(userInfo?.id, row.applicant_company_id)
            console.log(p)
            setPermissions(p)
            setInfoAgenda(row)
            
            handleOpenSetDocument(row)

            } catch (error) {
                console.error('Error fetching government acts:', error);
            }
        }
        setAgenda(row)
        setActive(true)
        setTime(Date.now())
        update_id_operational_agenda(row?.id)
        redirectToPillarByCode(row?.pillar?.code, row?.applicant_company_id)
        setIsLoading(false)
    }

    const fetchShareLink = async (document_url, pilar_id) => {
        console.log(document_url)
        let isPDF = document_url.toLowerCase().endsWith('.pdf');
        setOpenDocument({type: !isPDF})
        console.log('isPDF', isPDF)
        console.log(pilar_id)
        if (document_url !== "") {
            if (pilar_id === 2) {
                const sha_link = await readFile("/Actos_gubernamentales", document_url);
                if (sha_link?.statusCode === 200) {
                    setShareLink(sha_link);
                } else {
                    console.log("Error al obtener el link del archivo 2");
                }
            }
            if (pilar_id === 3) {
                const sha_link = await readFile("/Activos", document_url);
                if (sha_link?.statusCode === 200) {
                    setShareLink(sha_link);
                } else {
                    console.log("Error al obtener el link del archivo 3");
                }
            }
            if (pilar_id === 4) {
                const sha_link = await readFile("/Contratos", document_url);
                if (sha_link?.statusCode === 200) {
                    setShareLink(sha_link)
                } else {
                    console.log("Error al obtener el link del archivo 4");
                }
            }
        }
      };
    
    const handlePreviewDocument = async (row, sp, infoAgenda) => {
        setIsLoading(true)
        const p = await get_permission_user(userInfo?.id, row.applicant_company_id)
        let subpillar_id = row?.subpillar_id
        let pillar = 0
        let document_url = ''
        if(row?.subpillar_id === 5 || row?.subpillar_id === 6 || row?.subpillar_id === 7){
            pillar = 2
            document_url = row?.file_url
        }if(row?.subpillar_id === 8 || row?.subpillar_id === 9 || row?.subpillar_id === 10){
            pillar = 3
            document_url = row?.general_file_url
        }if(row?.subpillar_id === 11 || row?.subpillar_id === 12 || row?.subpillar_id === 13 || row?.subpillar_id === 14){
            pillar = 4
            document_url = row?.file_url
        }
        fetchShareLink(document_url, pillar)
        setSubPillar(sp)
        setIdDocument(row?.id)
        setNameDocument(row?.file_name)


        if (p) {
            const permisoVer = p.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.view === true)
            const permisoDescargar = p.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.download === true)
            setDisabledDownload(!permisoDescargar)
            const isPDF = document_url.toLowerCase().endsWith('.pdf');
            if(permisoVer){
                setOpenDocument({open: true, type: !isPDF})
            }else{
                Swal.fire({ icon: 'error', title: 'Sin Permiso', text: 'No tiene permisos para ver el documento' })
            }
        }
        setIsLoading(false)
    }
    
    const handleViewDigitalDocument = async (row) => {
        // row.pillar_id aqui se decide que hacer con el documento si es 2, 3 o 4 se visualiza en un modal
        setIsLoading(true)
        const p = await get_permission_user(userInfo?.id, row.applicant_company_id)
        let subpillar_id = 0
        let isPDF = false
        if(row?.pillar_id === 2 || row?.pillar_id === 3 || row?.pillar_id === 4){
            if(row?.pillar_id === 2){
                console.log(row?.pillar_id)
                const subPillars=[5,6,7]
                try {
                const actGob = await Promise.all(
                    subPillars.map(async (x) => {
                      const cap = await get_goverment_acts(row.applicant_company_id, x);
                      return cap.data
                    })
                  );
                  if (actGob) {
                    setGovermentActs(actGob.flat());
                    const matchAct = actGob.flat()?.filter(x => x.id === row?.digital_document)
                    subpillar_id = matchAct[0]?.subpillar_id
                    if(matchAct.length > 0){
                        const metchedAct = matchAct[0]
                        fetchShareLink(metchedAct.file_url, row?.pillar_id)
                        isPDF = metchedAct.file_url.toLowerCase().endsWith('.pdf');
                    }
                  }
                } catch (error) {
                    console.error('Error fetching government acts:', error);
                }
            }
            if(row?.pillar_id === 3){
                console.log(row?.pillar_id)
                const subPillars=[8,9,10]
                try {
                const actGob = await Promise.all(
                    subPillars.map(async (x) => {
                        return await get_activos(row.applicant_company_id, x);
                        })
                    );
                    if (actGob) {
                        setGovermentActs(actGob.flat());
                        const matchAct = actGob.flat()?.filter(x => x.id === row?.digital_document)
                        subpillar_id = matchAct[0]?.subpillar_id
                        if(matchAct.length > 0){
                            const metchedAct = matchAct[0]
                            fetchShareLink(metchedAct.general_file_url, row?.pillar_id)
                            isPDF = metchedAct.general_file_url.toLowerCase().endsWith('.pdf');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching government acts:', error);
                }
            }
            if(row?.pillar_id === 4){
                console.log(row?.pillar_id)
                const subPillars=[11,12,13,14]
                try {
                const actGob = await Promise.all(
                    subPillars.map(async (x) => {
                        return await get_contratos(row.applicant_company_id, x);
                        }
                    )
                    );
                    if (actGob) {
                        setGovermentActs(actGob.flat());
                        const matchAct = actGob.flat()?.filter(x => x.id === row?.digital_document)
                        subpillar_id = matchAct[0]?.subpillar_id
                        if(matchAct.length > 0){
                            const metchedAct = matchAct[0]
                            fetchShareLink(metchedAct.file_url, row?.pillar_id)
                            isPDF = metchedAct.file_url.toLowerCase().endsWith('.pdf');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching government acts:', error);
                }
            }
            if (p) {
                const permisoVer = p.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.view === true)
                const permisoDescargar = p.some(permiso => permiso.section_id === (subpillar_id + 1) && permiso.download === true)
                setDisabledDownload(!permisoDescargar)
                if(permisoVer){
                    setOpenDocument({open: true, type: !isPDF})
                }else{
                    Swal.fire({ icon: 'error', title: 'Sin Permiso', text: 'No tiene permisos para ver el documento' })
                }
            }
        }else{
            const { applicant_company_name, pillar, type_digital_document, digital_document} = row
            set_document_selected(digital_document)
            handleNavigatePillar(type_digital_document, { company: applicant_company_name, pillar})
        }
        setIsLoading(false)
    }

    const handleCloseDocument = () => {
        setOpenDocument({open: false, type: false})
        setShareLink(null)
    }

    const hanldeClickRequirements = (row) => {
        setOpenModal(true)
        setIsView(true)
        setValue('requirements', row.requirements?.map(r =>({...r, id_requirement: r?.id })))
        setTypeView(1)
        setIDRow(row?.id)
    }

    const hanldeUpdateRequirements = async (field, index) => {
        const requiremenst = watch('requirements')
        const requirement = requiremenst[index]
        let observation = {}
        let document = {}

        if(requirement?.new_observation){
            requirement.observation = requirement?.new_observation
            requirement.observation_date = dayjs().format('YYYY-MM-DD HH:mm:ss')

            //Armammos los datos para insertar la trazabilidad de las observaciones
            observation.id_processing_requirements = requirement?.id
            observation.comment = requirement?.new_observation
            observation.comment_date = dayjs().format('YYYY-MM-DD HH:mm:ss')
            observation.user_id = userInfo?.id
        }

        if(field?.pdf_link !== requirement?.pdf_link){
            const filename = `ID ACTIVIDAD: ${requirement?.operational_id} FECHA SUBIDA: ${dayjs().locale('es').format('dddd, D MMMM YYYY')}`
            const upload_doc = await uploadFile(`/requisitos_tramite`, requirement?.pdf_link, filename, true)
            if(upload_doc?.statusCode === 200){
                requirement.pdf_link = upload_doc?.data?.urlFile

                //Armammos los datos para insertar la trazabilidad del documento
                document.id_processing_requirements = requirement?.id
                document.pdf_link = field?.pdf_link
                document.user_id = userInfo?.id
            }
        }

        setIsLoading(true)
        const updated = await update_processing_requirement(requirement)

        if(updated){
            if(observation.comment){
               const obs = await add_observation_requirement(observation)

               //Validamos si se agrego la observacion del requisito
               if(obs){
                    //Buscamos la solicitud para tener la informacion que se necesita
                    const request = requests?.find(x => x.id === requirement?.operational_id)
                    //Validamos que haya un responsable para enviar el correo
                    if(field?.id_responsible && request){
                        await NewObservationRequirementMailer(TOKEN_MSAL, {
                                id_activity: requirement?.operational_id,
                                name_user: `${field?.responsible?.names} ${field?.responsible?.last_names}`,
                                description: request?.description,
                                state: request?.status_activity_id ? request?.status_activity?.description : '',
                                user_comment: `${userInfo?.names} ${userInfo?.last_names}`,
                                delivery_date: requirement?.delivery_date ? `${dayjs(requirement?.delivery_date ).locale('es').format('dddd, D MMMM YYYY')}` : 'No se tiene fecha de entrega.',
                                documentation: requirement?.documentation,
                                date_end: `${dayjs(request?.end_date).locale('es').format('dddd, D MMMM YYYY')}`,
                                observation: requirement?.observation
                            },
                            `Nuevo Comentario sobre requisito del tramite de la solicitud con ID ${requirement?.operational_id}`,
                            [{email: field?.responsible?.email}]
                        )
                    }
               }

            }

            if(document.pdf_link){
                await add_document_requirement(document)
            }
            const update_request = await update_status_requirements(field?.operational_id, updated?.data)
            setValue('requirements', update_request?.requirements)
        }
        await get_requests()
        setIsLoading(false)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setTypeView(false)
        setIsView(false)
        resetForm(requests?.length + 1 )
    }

    const handleCellClick = ({row, field}) => {
        if(field === 'general_comments'){
            handleOpenModal(2)
            setDataHistory({main_comment: row?.general_comments, main_comment_date: row?.general_comments_date  , comments: row?.history_comments, load_image: true})
        }
    }

    const handleViewHistory = async (row, type_view = 2) => {
        handleOpenModal(type_view)
        if(type_view === 2){
            SetLoadingObservations(true)
            const comments = await get_history_observations_by_requirement(row?.id_requirement)
            SetLoadingObservations(false)
            setDataHistory({main_comment: row?.observation, main_comment_date: row?.observation_date, comments, load_image: false, documents: null})
        }else{
            SetLoadingObservations(true)
            const documents = await get_history_document_by_requirement(row?.id_requirement)
            SetLoadingObservations(false)
            setDataHistory({pdf_link, comments: [], load_image: false, documents })
        }
    }

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const processRowUpdate = async (newRow, prevRow) => {
        //Obtenemos el id de la actvidad para poder cambiar los valores dentro de la tabla
        const activity = status_activities?.find(activity => activity.description === newRow.status_activity_name)

        //Obtenemos el usuario para actualizar la visualizcion en el datagrid
        const user = data?.users?.find(user => user?.full_name === newRow?.safeguarding_name)
        
        //Obtenemos el control para cambiar el estado
        const control = control_status?.find(control => control?.description === newRow?.control_name) 

        const updatedRow = { 
            ...newRow, 
            status_activity_id:  activity?.id, 
            status_activity: activity, 
            safeguarding_id: user?.id, 
            control_id: control?.id,
            user_id: userInfo?.id,
            isNew: false
        };

        //Validamos si viene un nuevo comentario
        if(newRow?.general_comments !== prevRow?.general_comments){
            updatedRow.general_comments_date = dayjs().format('YYYY-MM-DD HH:mm:ss') 
            const subject = `Nuevo Comentario en solicitud con ID ${updatedRow?.id}`
            const responsible = updatedRow?.responsible
            const applicant = updatedRow?.internal_applicant
            const safeguarding = updatedRow?.safeguarding
            const email_controller_control_comment = emails?.find(x => x?.code === 'NUE-COM')

            await add_general_comment({
                request_id: updatedRow?.id,
                comment: prevRow?.general_comments,
                comment_date: prevRow?.general_comments_date,
                user_id: userInfo?.id
            }) 

            //Validacion para enviar el correo de nuevo comentario a los colaboradores
            if(updatedRow?.responsible_id){
                await NewCommentMailer(TOKEN_MSAL, {
                        name_user: `${responsible?.names} ${responsible?.last_names}`,
                        id_activity: updatedRow?.id,
                        date: `${dayjs(updatedRow?.date).locale('es').format('dddd, D MMMM YYYY')}`,
                        date_end:`${dayjs(updatedRow?.end_date).locale('es').format('dddd, D MMMM YYYY')}`,
                        descripcion: updatedRow?.description,
                        applicant: `${applicant?.names} ${applicant?.last_names}`,
                        responsible: null,
                        comment: updatedRow?.general_comments,
                        user_comment: `${userInfo?.names} ${userInfo?.last_names}`,
                        status: updatedRow?.status_activity_id ? updatedRow?.status_activity?.description : ''
                    }, 
                    'Responsable', 
                    subject, 
                    [{email: responsible?.email}]
                )
            }
            if(email_controller_control_comment?.applicant){
                if(updatedRow?.internal_applicant_id){
                    await NewCommentMailer(TOKEN_MSAL, {
                            name_user: `${applicant?.names} ${applicant?.last_names}`,
                            id_activity: updatedRow?.id,
                            date: `${dayjs(updatedRow?.date).locale('es').format('dddd, D MMMM YYYY')}`,
                            date_end:`${dayjs(updatedRow?.end_date).locale('es').format('dddd, D MMMM YYYY')}`,
                            descripcion: updatedRow?.description,
                            applicant: null,
                            responsible: `${responsible?.names} ${responsible?.last_names}`,
                            comment: updatedRow?.general_comments,
                            user_comment: `${userInfo?.names} ${userInfo?.last_names}`,
                            status: updatedRow?.status_activity_id ? updatedRow?.status_activity?.description : ''
                        }, 
                        'Solicitante', 
                        subject, 
                        [{email: applicant.email}]
                    )
                }
            }
            if(email_controller_control_comment?.safeguarding){
                if(updatedRow?.safeguarding_id){
                    await NewCommentMailer(TOKEN_MSAL, {
                            name_user: `${safeguarding?.names} ${safeguarding?.last_names}`,
                            id_activity: updatedRow?.id,
                            date: `${dayjs(updatedRow?.date).locale('es').format('dddd, D MMMM YYYY')}`,
                            date_end:`${dayjs(updatedRow?.end_date).locale('es').format('dddd, D MMMM YYYY')}`,
                            descripcion: updatedRow?.description,
                            applicant: `${applicant?.names} ${applicant?.last_names}`,
                            responsible: `${responsible?.names} ${responsible?.last_names}`,
                            comment: updatedRow?.general_comments,
                            user_comment: `${userInfo?.names} ${userInfo?.last_names}`,
                            status: updatedRow?.status_activity_id ? updatedRow?.status_activity?.description : ''
                        }, 
                        'Resguardatario', 
                        subject, 
                        [{email: safeguarding?.email}]
                    )
                }
            }
        }

        //Validamos si cambio el resguardatoio
        if(newRow?.safeguarding_name !== prevRow?.safeguarding_name){
            const email_controller_safeguarding = emails?.find(x => x?.code === 'REG-NS')
            if(email_controller_safeguarding?.safeguarding){
                //Enviar solicitud como resguardatoriO
                const toRecipients = []
                if(user){
                    toRecipients.push({email: user?.email})
                }
                const safeguarding = data?.users?.find(user => user?.id === updatedRow?.safeguarding_id)
    
                await NewRequestMailer(TOKEN_MSAL, {
                        name_user: `${safeguarding?.names} ${safeguarding?.last_names}`,
                        id_activity: updatedRow?.id, 
                        descripcion: updatedRow?.description
                    }, 
                    'resguardatario', 
                    'Designación como Resguardatario de un documento - DPP', 
                    toRecipients
                )   
            }
        }

        // Validamos si bviene un control nuevo
        if(newRow?.control_name !== prevRow.control_name){
            const recipients = [
                {name_user:  `${newRow?.responsible?.names} ${newRow?.responsible?.last_names}`, type_designation: 'Responsable', email: newRow?.responsible?.email}
            ]
            
            const addApplicantRecipients = () => {
                recipients.push({name_user: `${newRow?.internal_applicant?.names} ${newRow?.internal_applicant?.last_names}`, type_designation: 'Solicitante', email: newRow?.internal_applicant?.email})
            }

            const addSafeguardingRecipients = () => {
                if(updatedRow?.safeguarding_id){
                    recipients.push({name_user:  `${newRow?.safeguarding?.names} ${newRow?.safeguarding?.last_names}`, type_designation: 'Resguardatorio', email: newRow?.safeguarding?.email})
                }
            }

            const email_controller_control_prog = emails?.find(x => x?.code === 'CES-EPR')
            if(control?.code === 'PROG'){
                if(email_controller_control_prog?.applicant){
                    addApplicantRecipients()
                }
                if(email_controller_control_prog?.safeguarding){
                    addSafeguardingRecipients()
                }
            }
            
            const email_controller_control_det = emails?.find(x => x?.code === 'CES-DET')
            if(control?.code === 'DETE'){
                if(email_controller_control_det?.applicant){
                    addApplicantRecipients()
                }
                if(email_controller_control_det?.safeguarding){
                    addSafeguardingRecipients()
                }
            }
            
            const email_controller_control_canc = emails?.find(x => x?.code === 'CES-CAN')
            if(control?.code === 'CANC'){
                if(email_controller_control_canc?.applicant){
                    addApplicantRecipients()
                }
                if(email_controller_control_canc?.safeguarding){
                    addSafeguardingRecipients()
                }
            }

            const email_controller_control_comp = emails?.find(x => x?.code === 'CES-COM')
            if(control?.code === 'COMP'){
                if(email_controller_control_comp?.applicant){
                    addApplicantRecipients()
                }
                if(email_controller_control_comp?.safeguarding){
                    addSafeguardingRecipients()
                }
            }
            
            for (let index = 0; index < recipients.length; index++) {
                const recipient = recipients[index];

                await ControlMailer(TOKEN_MSAL, {
                        name_user: recipient?.name_user,
                        control_status: control?.description,
                        id_activity: updatedRow?.id,
                        date: `${dayjs(updatedRow?.date).locale('es').format('dddd, D MMMM YYYY')}`,
                        date_end:`${dayjs(updatedRow?.end_date).locale('es').format('dddd, D MMMM YYYY')}`,
                        descripcion: updatedRow?.description,
                        type_designation: recipient?.type_designation
                    },
                    `${updatedRow?.id} en estatus ${control?.description} - DPP`,
                    [{email: recipient?.email}]
                )
            }
        }
        //Actualizamos los registros que se muestran en la lista
        update_request(requests.map((row) => (row.id === newRow.id ? updatedRow : row)));

        //Mandamos a ejecutar el endpoint para guardar la informacion en la Base de Datos
        setIsLoading(true)
        await update_requests(updatedRow)
        // await get_requests()
        /* if(myAssigments){
            await get_request_filters_my_assignments()
        }else if(showFilters){
            await get_request_filters()
            
        }else{
            await get_requests()
        } */
       if(myAssigments){
            await get_request_filters_my_assignments()
        }else if(showFilters){
            await get_request_filters()
        }else if(custodian){
            await getRequestCustodian()
        }else if(registerDelete){
            await getRequestFilterDeleting()
        }else if(completed){
            await getRequestFilterCompleted()
        }else if(canceled){
            await getRequestFilterCanceled()
        }else{
            await get_requests()
        }

        
        setIsLoading(false)
        
        handleSaveClick(newRow?.id);
        return updatedRow;
    };

    const handleDeleteRow = async () => {
        setIsLoading(true)
        const del = await delete_request(idRow)

        if(del){
            await get_requests()
        }
        setIsLoading(false)
        setOpenModal(false)
    }

    useEffect(() => {
        updateLabelNav('PANEL AGENDA OPERATIVA')
        loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenModal = (typeView) => {
        setOpenModal(true)
        setTypeView(typeView)
    }

    const OnSubmit = async (data) => {
        const payload = {}

        payload.request = {
            date:  data?.date ? dayjs(data?.date).format('YYYY-MM-DD') : null,
            description: data?.description ? data?.description : null,
            related_actor_id: data?.related_actor_id ? data?.related_actor_id : null,
            applicant_company_id: data?.applicant_company_id ? data?.applicant_company_id : null,
            pillar_id: data?.pillar_id ? data?.pillar_id : null,
            area_id: data?.area_id ? data?.area_id : null,
            internal_applicant_id: data?.internal_applicant_id || null,
            responsible_id: data?.responsible_id || null,
            processing_requirements: data?.processing_requirements || null,
            end_date:  data?.end_date ? dayjs(data?.end_date).format('YYYY-MM-DD') : null,
            general_comments: data?.general_comments || null,
            status_activity_id: data?.status_activity_id || null,
            type_digital_document: null,
            digital_document: data?.digital_document || null,
            physical_document: data?.physical_document || null,
            safeguarding_id: data?.safeguarding_id || null,
            control_id: data?.control_id || null,
            user_id: userInfo?.id,
        }

        const diffDays = () => {
            const current_date = dayjs()            
            const diff = dayjs(data?.end_date) - current_date;
            return Math.ceil(diff / (1000 * 60 * 60 * 24))
        }

        const daysRes = diffDays()
        if(daysRes > 0){
            payload.request.status_activity_id = 1
        }else if(daysRes <= 0){
            payload.request.status_activity_id = 2
        }else {
            payload.request.status_activity_id = 3
        }

        payload.requirements = data?.requirements || []
        payload.user = userInfo

        setIsLoading(true)

        const added = await add_request(payload)

        if (added) {
            const email_controller_applicant = emails?.find(x => x?.code === 'REG-NS')

            //Enviar correo como responsable
            if(data?.responsible_id){
                const toRecipients = []
                const responsible = users?.find(x => x?.id === data?.responsible_id)
                if(responsible){
                    toRecipients.push({email: responsible?.email})
                }
                await NewRequestMailer(TOKEN_MSAL, {
                        id_activity: added?.id, 
                        name_user: `${responsible?.names} ${responsible?.last_names}`, 
                        date: `${dayjs(data?.date).locale('es').format('dddd, D MMMM YYYY')}`, 
                        descripcion: data?.description, 
                        date_end: data?.end_date ? `${dayjs(data?.end_date).locale('es').format('dddd, D MMMM YYYY')}` : ''
                    }, 
                    'responsable', 
                    'Designación como Responsable de una Nueva Solicitud - DPP', 
                    toRecipients
                )
            }
            
            //Enviar correo como solicitante interno
            if(email_controller_applicant?.applicant){
                if(data?.internal_applicant_id){
                    const toRecipients = []
                    const applicant = users?.find(x => x?.id === data?.internal_applicant_id)
                    if(applicant){
                        toRecipients.push({email: applicant?.email})
                    }
                    await NewRequestMailer(TOKEN_MSAL, {
                            id_activity: added?.id, 
                            name_user: `${applicant?.names} ${applicant?.last_names}`, 
                            date: `${dayjs(data?.date).locale('es').format('dddd, D MMMM YYYY')}`, 
                            descripcion: data?.description, 
                            date_end: data?.end_date ? `${dayjs(data?.end_date).locale('es').format('dddd, D MMMM YYYY')}` : ''
                        }, 
                        'solicitante inteno', 
                        'Designación como Solicitante Interno en una Nueva Solicitud - DPP', 
                        toRecipients
                    )
                }
            }

            //Enviar correo para los responsable de los requisitos del tramite
            if(data?.requirements?.length > 0){
                for (let index = 0; index < data?.requirements.length; index++) {
                    const toRecipients = []
                    const element = data?.requirements[index];
                    const responsible = users?.find(x => x?.id === element?.id_responsible)
                    if(responsible){
                        toRecipients.push({email: responsible?.email})
                    }

                    await NewRequirementMailer(TOKEN_MSAL, {
                            name_user: `${responsible?.names} ${responsible?.last_names}`,
                            id_activity: added?.id,
                            description: data?.description,
                            date_delivery: element?.delivery_date ? `${dayjs(element?.delivery_date).locale('es').format('dddd, D MMMM YYYY')}` : '',
                            documentation: element?.documentation,
                            observation: element?.observation
                        }, 
                        'Designación como Responsable de un requisito para el trámite - DPP', 
                        toRecipients
                    )
        
                }
            }
        }

        //const reqs = await get_requests()
        const reqs = get_request_filters_my_assignments()
        setValue('id', reqs?.length + 1)

        setIsLoading(false)
        setIDRow(false)
        setOpenModal(false)
        setIsView(false)
        resetForm(reqs?.length + 1)
    }

    return <PanelAgendaV
        vars={{
            data,
            actors,
            areas,
            dataHistory,
            openModal,
            typeView,
            control,
            errors,
            isView,
            valCheckbox,
            fields,
            isLoading,
            requests,
            pdf_link,
            control_status, 
            status_activities,
            period,
            isLoadingFilters,
            LoadingObservations,
            color_config,
            openModalSetDocument,
            infoAgenda,
            govermentActs,
            openDocument,
            shareLink,
            showFilters,
            permissions,
            disabledDownload,
            userInfo,
            myAssigments,
            allAssignments,
            registerDelete,
            subPillar,
            idDocument,
            nameDocument,
            custodian,
            completed,
            canceled
        }}
        funcs={{
            handleOpenModal,
            handleSubmit, 
            OnSubmit,
            getValCheckbox,
            handleSetRequirement,
            remove,
            hanldeClickRequirements,
            hanldeUpdateRequirements,
            handleDeleteRow,
            processRowUpdate,
            handleCellClick,
            get_pdf_link,
            handleViewHistory,
            handleCloseModal,
            handleSaveDigitalDocument,
            handleViewDigitalDocument,
            get_request_filters,
            refresh_request,
            setTypeView,
            get_request_filters_my_assignments,
            handleCloseSetDocument,
            getPath,
            assignDocumentToRequirement,
            handleCloseDocument,
            handlePreviewDocument,
            setShowFilters,
            getRequestFilterDeleting,
            setRegisterDelete,
            setAllAssignments,
            setMyAssigments,
            setIsLoading,
            setCustodian,
            getRequestCustodian,
            getRequestFilterCompleted,
            setCompleted,
            setCanceled,
            getRequestFilterCanceled
        }}
    />
}