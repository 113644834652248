import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { encrypt_data } from './../utils/JSImplements'
import { SwalFire } from './../utils/'
import { readFile } from './../utils/AWS'
import { GET, POST, PUT, setupTokenRefresh, refreshToken } from './../utils/AuthService'
import { UseStorePillar } from './Pillar'

export const UseStoreTemplateView = create(
    persist(
        (set, get) => ({
            TOKEN_MSAL: '',
            labelNav: '',
            buttonBack: {state: false, url: ''},
            img_user: false,
            sector_companies: [],
            countrys: [],
            attorneys: [],
            actors: [],
            control_status: [],
            status_activities: [],
            pillars_companies: [],
            isLogged: false,
            isLoading: false,
            set_token_msal: (token) => {
                set({TOKEN_MSAL: token})
                // Configuramos el refresh del token pasando la función para actualizar el estado
                setupTokenRefresh((newToken) => set({TOKEN_MSAL: newToken}))
            },
            refresh_token_msal: async () => {
                const newToken = await refreshToken();
                if (newToken) {
                    set({TOKEN_MSAL: newToken});
                }
            },
            updateLabelNav: (label) => {
                if (label){
                    set({labelNav: label})
                }else{
                    set({labelNav: 'PLATAFORMA DE GESTIÓN DIGITAL'})
                }
            },
            button_back: (state, url) => {
                set({buttonBack: {state, url}})
            },
            updateMultipillar: (pillar) => {
                const { controlMultipillar } = get()
                set({controlMultipillar: { ...controlMultipillar, ...pillar}})
            },
            get_file_by_pillar_code: async (data) => {
                const { pillar_code, url, isDownload, company_id, user} = data
                const folders = {
                    'act-con': {
                        section_id: 2,
                        folderS3: '/Actas_constitutivas',
                    },
                    'asa-ord': {
                        section_id: 4,
                        folderS3: '/Actas_asamblea'
                    },
                    'asa-ext': {
                        section_id: 4,
                        folderS3: '/Actas_asamblea'
                    },
                    'fed-hec': {
                        section_id: 5,
                        folderS3: '/Fe_hechos'
                    },
                    'pwr-ppc':{
                        section_id: 3,
                        folderS3: '/Poderes'
                    },
                    'pwr-paa':{
                        section_id: 3,
                        folderS3: '/Poderes'
                    },
                    'pwr-pad':{
                        section_id: 3,
                        folderS3: '/Poderes'
                    },
                    'pwr-pes':{
                        section_id: 3,
                        folderS3: '/Poderes'
                    }           
                }
                
                //Validamos el tipo de usuario para saber si es administrador
                if(user?.type === 1){
                    if(folders[pillar_code]?.folderS3){
                        const file = await readFile(folders[pillar_code]?.folderS3, url, isDownload)
                        if(file?.statusCode === 200){
                            return file?.data?.url
                        }else{
                            SwalFire('Atencion!', file?.message, 'warning')
                            return false
                        }
                    }else{
                        SwalFire('Atencion!', 'No se encontro el folder del documento.', 'warning')
                        return false
                    }
                }else{
                    const getPermissionUser = UseStorePillar.getState().get_permission_user
                    const permission = await getPermissionUser(user?.id, company_id)
                    if(permission?.length){
                        const action_section = permission?.find(x => x?.section_id === folders[pillar_code]?.section_id)
                        if (action_section?.view){
                            if(folders[pillar_code]?.folderS3){
                                const file = await readFile(folders[pillar_code]?.folderS3, url, isDownload)
                                if(file?.statusCode === 200){
                                    return file?.data?.url
                                }else{
                                    SwalFire('Atencion!', file?.message, 'warning')
                                    return false
                                }
                            }else{
                                SwalFire('Atencion!', 'No se encontro el folder del documento.', 'warning')
                                return false
                            }
                        }else{
                            SwalFire('Atencion!', 'Usted no tiene permiso para ver el archivo.', 'warning')
                            return false
                        }
                    }
                }
            },
            get_image_user: async (url) => {
                const img = await readFile('/imagen_usuarios', url)
                if(img?.statusCode === 200) set({img_user: img?.data?.url})
            },
            get_sector_companies: async () => {
                const { sector_companies } = get()
                if(!sector_companies?.length){
                    const sector = await GET('get-sector-companies')
                    if(sector?.data.length) set({sector_companies: sector?.data})
                }
            },
            get_town_state: async (d_code) => {
                const zip_code = await POST('get-town-state', { d_code } )
                if(zip_code?.statusCode === 200){
                    return zip_code?.data
                }else {
                    SwalFire('Información', zip_code?.message, 'info')
                    return false
                }
            },
            get_countrys: async () => {
                const { countrys } = get()
                if(!countrys.length){
                    const countrys = await GET('get-countrys')
                    if(countrys?.data.length) set({countrys: countrys?.data})
                }
            },
            get_attorneys: async () => {
                const { attorneys } = get()
                if(!attorneys.length){
                    const attorneys = await GET('get-attorneys')
                    if(attorneys?.data.length) set({attorneys: attorneys?.data})
                }
            },
            get_pillars_companies: async () => {
                let pillarC = []
                
                const pillars = await GET('get-pillars-companies')
                if(pillars?.data.length) {
                    pillarC = pillars?.data
                    set({pillars_companies: pillars?.data})
                }
                
                return pillarC
            },
            setIsLoading: val => {
                set({isLoading: val})
            },
            validate_user_in_companies: async (payload) => {
                const result = await POST('user-permission-companies', payload)
                if(result?.statusCode === 200){
                    return true
                }else{
                    SwalFire('Atencion!', 'Usted no tiene permiso para ver la empresa.', 'warning')
                    return false
                }
            },
            get_controls_status: async () => {
                const { control_status } = get()
                if(!control_status.length){
                    const control_status = await GET('get-control-status')
                    if(control_status?.data.length) set({control_status: control_status?.data})
                }
            },
            get_status_activities: async () => {
                const { status_activities } = get()
                if(!status_activities.length){
                    const status_activities = await GET('get-status-activities')
                    if(status_activities?.data.length) set({status_activities: status_activities?.data})
                }
            },
            get_actors: async () => {
                const actors = await GET('get-actors-related')
                if(actors?.data.length) set({actors: actors?.data})
            },
            get_actors_simplified: async () => {
                const actors = await GET('get-simplified-actors')
                if(actors?.data.length) set({actors: actors?.data})
            },
            get_actor_id: async (actor_id) => {
                const actor = await GET(`get-actor/${actor_id}`)
                if(actor?.statusCode === 200){
                    return actor?.data
                }else {
                    return false
                }
            },
            sort_actors: () => {
                set((state) => ({
                  actors: [
                    ...state.actors.sort((actorA, actorB) =>
                    actorA.name.localeCompare(actorB.name)
                    ),
                  ],
                }));
            },
            create_actor: async (payload) => {
                const result = await POST('actor-related', payload)
                if(result?.statusCode === 200){
                    SwalFire('Exito!', 'Se ha guardado la información', 'success')
                    return result?.data
                }else{
                    SwalFire('Atencion!', 'No se pudo agregar el actor', 'warning')
                    return false
                }
            },
            delete_actor: async (id) => {
                const result = await PUT(`desactivate-actor-related/${id}`)
                if(result?.statusCode === 200){
                    SwalFire('Exito!', 'Se ha eliminado el actor.', 'success')
                    return true
                }else{
                    SwalFire('Atencion!', 'No se pudo eliminar el actor', 'warning')
                    return false
                }
            }
        }),
        {
            name: encrypt_data('template_view'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)