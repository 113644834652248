import {
  Backdrop,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { UseStoreTemplateView } from "../../stores/TemplateView";
import { Header } from "../Layout/header";
import { AppBarModuleUsers } from "./../Common/AppBarModuleUsers";

export const HomeV = ({ children, isLoading }) => {
  const labelNav = UseStoreTemplateView((state) => state.labelNav);
  const [ignore] = useState([
    "PLATAFORMA DE GESTIÓN DIGITAL",
    "PANEL AGENDA OPERATIVA",
    "EMPRESAS DEL GRUPO",
    "ACTORES",
    "Actor relacionado",
    "Perfil empresarial",
    "Actos corporativos",
    "Acta constitutiva",
    "Poder general para pleitos y cobranza",
    "Poder general para actos de administración",
    "Poder general para poder actos de dominio",
    "Poder especial",
    "Asamblea ordinaria",
    "Asamblea extraordinaria",
    "Fe de hechos",
  ]);

  const Logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload(true);
  };

  const styles = {
    button: {
      height: "74px",
      textTransform: "inherit",
      width: "170px",
      minWidth: "170px",
      mr: 15,
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#323338",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
    },
    typography: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "30px",
      fontWeight: "600",
      color: "#323338",
      "@media (max-width: 790px)": {
        fontSize: "24px",
      },
      "@media (max-width: 590px)": {
        fontSize: "20px",
      },
    },
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows:
          labelNav === "PLATAFORMA DE GESTIÓN DIGITAL"
            ? "70px 80px 1fr"
            : "70px 1fr",
        height: "100vh",
        width: "100%",
        background: "#F6F7FB",
      }}
    >
      <Header Logout={Logout} />
      <Stack
        display={labelNav === "PLATAFORMA DE GESTIÓN DIGITAL" ? "flex" : "none"}
        bgcolor="#FFFFFF"
        height="80px"
        width="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
      >
        <Typography sx={styles.typography} textAlign="center">
          Plataforma de gestión digital
        </Typography>
      </Stack>
      <Box sx={{ overflow: "auto", padding: "0px 70px" }}>
        <Backdrop open={isLoading} sx={{ zIndex: 999999 }}>
          <CircularProgress color="primary" />
        </Backdrop>
        {!ignore?.includes(labelNav) && <AppBarModuleUsers />}
        {children}
      </Box>
    </Box>
  );
};
