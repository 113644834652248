import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Divider, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "./../../assets/icons/backIcon.svg";
import OpenIcon from "./../../assets/icons/openIcon.svg";
import TableIcon from "./../../assets/icons/tableIcon.svg";

export const PillarsV = ({
  vars: { company, pillars_in_company, isLoading },
  funcs: { handleClickPreviewPillars },
}) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box bgcolor="#FFFFFF" p="0px 10px">
      <Stack
        flexDirection="column"
        gap={1}
        p={2}
        borderBottom="3px solid #DDDDDD"
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#1AAFD0",
          }}
        >
          {company?.name}
        </Typography>
        <Button
          type="button"
          onClick={() => navigate(`/view-company/${company?.id}?tab=2`)}
          variant="text"
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "12px",
            fontWeight: "400",
            color: "#323338",
            width: "100px",
            textTransform: "capitalize",
          }}
          startIcon={<img src={BackIcon} alt="BackIcon" />}
        >
          Regresar
        </Button>
      </Stack>
      <Stack
        p="20px 70px"
        gap={2}
        sx={{
          ".css-s4i5bw-MuiPaper-root-MuiAccordion-root.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
            fontWeight: "600",
            color: "#323338",
          }}
        >
          Actos corporativos
        </Typography>
        {isLoading && <LinearProgress />}
        {pillars_in_company
            ?.filter((pillar) => pillar.pillar_id === 1)
            .sort((a, b) => {
              const nameA = a?.subpillar?.name?.toUpperCase(); // Ignorar mayúsculas y minúsculas
              const nameB = b?.subpillar?.name?.toUpperCase(); // Ignorar mayúsculas y minúsculas
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              // Los nombres son iguales
              return 0;
            })
            .map((pillar, i) => (
          <Accordion
            key={i}
            sx={{
              boxShadow: "none",
            }}
            expanded={
              pillar?.items_subpillars?.length
                ? expanded === pillar?.subpillar?.name
                : null
            }
            onChange={handleChange(pillar?.subpillar?.name)}
          >
            <AccordionSummary
              onClick={() => {
                if (!pillar?.items_subpillars?.length) {
                  handleClickPreviewPillars(
                    company?.name,
                    pillar?.subpillar?.code
                  );
                }
              }}
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "7px",
                ":hover": {
                  background: "#B5BAC9",
                },
              }}
              expandIcon={
                Boolean(pillar?.items_subpillars?.length) && <ExpandMoreIcon />
              }
              aria-controls={`panel_content_${i}`}
              id={`panel_header_${i}`}
            >
              <Stack flexDirection="row" gap={1}>
                <img src={TableIcon} alt="TableIcon" />
                <Typography
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#323338",
                  }}
                >
                  {pillar?.subpillar?.name}
                </Typography>
              </Stack>
              {/* <Typography sx={{ color: "text.secondary" }}>
                Click para desplegar los subpilares
                </Typography> */}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                border: "1px solid #DDDDDD",
              }}
            >
              {pillar?.items_subpillars?.length &&
                pillar?.items_subpillars?.map((sp, i) => (
                  <>
                    <Stack
                      sx={{
                        height: "43px",
                        flexDirection: "row",
                        p: "0px 10px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: "7px",
                        ".css-jrj3z7-MuiStack-root": {
                          display: "none",
                        },
                        ":hover": {
                          cursor: "pointer",
                          ".css-jrj3z7-MuiStack-root": {
                            display: "flex",
                          },
                          boxShadow: "0px 2px 4px 0px #2222224D;",
                          background: "#EAE4E7",
                        },
                      }}
                      key={i}
                      onClick={() =>
                        handleClickPreviewPillars(
                          company?.name,
                          pillar?.subpillar?.code,
                          sp?.code
                        )
                      }
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#323338",
                        }}
                      >
                        {sp?.name}
                      </Typography>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 1,
                          ":hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        <img src={OpenIcon} width={15} alt="openIcon" />
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#323338",
                          }}
                        >
                          Abrir
                        </Typography>
                      </Stack>
                      {/* <Button
                      variant="outlined"
                      color="inherit"
                      style={{ textTransform: "none" }}
                      onClick={() =>
                        handleClickPreviewPillars(
                          company?.name,
                          pillar?.subpillar?.code,
                          sp?.code
                        )
                      }
                    >
                      {sp?.name}
                    </Button> */}
                    </Stack>
                    {pillar?.items_subpillars?.length - 1 !== i && <Divider />}
                  </>
                ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Box>
  );
};
