import React from 'react'
import PropTypes from 'prop-types'
import {LayoutAdminV} from '../../components/Layout/'

export const LayoutAdminC = ({children}) => {
    const { type_user } = children?.props
    const Logout = () => {
        localStorage.clear()
        sessionStorage.clear()
        window.location.reload(true);
    }

    return (
        <LayoutAdminV
            Logout={Logout}
        >
            {type_user && children}
        </LayoutAdminV>
    )
}

LayoutAdminC.propTypes = {
    children : PropTypes.node.isRequired
}