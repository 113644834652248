import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { InputAutocompleteAdapter } from '../Common/MUIAutoComplete';
import { InputDate } from '../Common/MUIInputDate';

export const FormFilters = ({ control, status_activities, users, actors, companies, pillars, control_status, period}) => {
    const types_date = [
        {id: 1, description: 'Semanal'},
        {id: 2, description: 'Mensual'},
        {id: 3, description: 'Entre fechas'}
    ]
    const styles = {
        label: {
          fontFamily: "Poppins-SemiBold",
          fontSize: "14px",
          fontWeight: "600",
        },
        label_text: {
            height: "40px",
            width: "100px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "capitalize"
        },
        buttonSave: {
            height: "49px",
            width: "100px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            color: "#FFFF",
            textTransform: "capitalize",
            ":hover": {
              background: "#1AAFD0",
            },
        },
        typography: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "20px",
            fontWeight: "600",
            color: "#323338",
            "@media (max-width: 790px)": {
              fontSize: "16px",
            },
            "@media (max-width: 590px)": {
              fontSize: "18",
            },
          },
    };

    return (
        <Box width={'100%'} height={'100%'} p={1} display={'flex'}>
            <Grid container spacing={2}>
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label}>Periodo</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="period"
                        control={control}
                        options={
                            types_date?.map((x) => ({
                                value: x?.id,
                                label: x?.description,
                            })) || []
                        }
                    />
                </Grid>
                {period === 3 && 
                    <Grid item xs={3}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                    >
                        <Typography sx={styles.label} pr={1}>Fecha incial</Typography>
                        <InputDate
                            name={'date_start'}
                            label={''}
                            control={control}
                            props={{
                                fullWidth: true,
                                size: 'small',
                            }}
                        />
                    </Grid>
                }
                {period === 3 && 
                    <Grid item xs={3}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                    >
                        <Typography sx={styles.label} pr={1}>Fecha final</Typography>
                        <InputDate
                            name={'date_end'}
                            label={''}
                            control={control}
                            props={{
                                fullWidth: true,
                                size: 'small',
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label} pr={1}>Estado</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="status"
                        control={control}
                        options={
                            status_activities?.map((x) => ({
                                value: x?.id,
                                label: x?.description,
                            })) || []
                        }
                    />
                </Grid>        
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label} pr={1}>Responsable</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="responsible"
                        control={control}
                        options={
                            users?.map((x) => ({
                                value: x?.id,
                                label: x?.full_name
                            })) || []
                        }
                    />
                </Grid>        
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label} pr={1}>Responsable del requisito</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="responsible_requirement"
                        control={control}
                        options={
                            users?.map((x) => ({
                                value: x?.id,
                                label: x?.full_name
                            })) || []
                        }
                    />
                </Grid>        
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label} pr={1}>Solicitante</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="internal_applicant"
                        control={control}
                        options={
                            users?.map((x) => ({
                                value: x?.id,
                                label: x?.full_name
                            })) || []
                        }
                    />
                </Grid>        
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label}>Resguardatorio</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="resguard"
                        control={control}
                        options={
                            users?.map((x) => ({
                                value: x?.id,
                                label: x?.full_name
                            })) || []
                        }
                    />
                </Grid>        
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label} pr={1}>Actor</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="actor"
                        control={control}
                        options={
                            actors?.map((x) => ({
                                value: x?.id,
                                label: x?.name
                            })) || []
                        }
                    />
                </Grid>        
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label} pr={1}>Empresa solicitante</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="company"
                        control={control}
                        options={
                            companies?.map((x) => ({
                                value: x?.id,
                                label: x?.name
                            })) || []
                        }
                    />
                </Grid>        
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label} pr={1}>Pilar</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="pillar"
                        control={control}
                        options={
                            pillars?.map((x) => ({
                                value: x?.id,
                                label: x?.name
                            })) || []
                        }
                    />
                </Grid>        
                <Grid item xs={3}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Typography sx={styles.label} pr={1}>Control</Typography>
                    <InputAutocompleteAdapter
                        size={'small'}
                        mb={0}
                        label={''}
                        keyLabel="label"
                        keyValue="value"
                        height={40}
                        name="control"
                        control={control}
                        options={
                            control_status?.map((x) => ({
                                value: x?.id,
                                label: x?.description
                            })) || []
                        }
                    />
                </Grid>        
            </Grid>
        </Box>
    )
}