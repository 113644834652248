import aws  from 'aws-sdk'
import { getFileExtension } from './index'
import JSZip from 'jszip'

aws.config.update({
    region: 'us-east-1',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

const BUCKET_S3 = process.env.REACT_APP_AWS_BUCKET
const s3 = new aws.S3()

export const uploadFile = async (folder, file, filename, onlyPdf = false) => {
    try {
        const file_type = getFileExtension(file?.name)
        let formats = ['pdf', 'png', 'jpg']
        if(onlyPdf) formats = ['pdf', 'png', 'jpg']

        const isValid = formats.includes(file_type);
        if (!isValid){
            return {
                statusCode: 400,
                message: `Formato de archivo no permitido: .${file_type}`,
                data: {}
            }
        }

        const params = {
            Bucket: `${BUCKET_S3}${folder}`,
            Key: `${filename}.${file_type}`,
            Body: file
        }
        
        const res = (await s3.putObject(params).promise()).$response
        return {
            statusCode: 200,
            message: 'Se ha subido el archivo exitosamente',
            data: {
                urlFile: `${filename}.${file_type}`,
                ...res
            }
        }

    } catch (error) {
        console.log('Ha ocurrido un error al cargar el archivo', error?.message)
        return { statusCode: 500, message: error.message }
    }
}

export const readFile = async (folder, filename, isDownload = false) => {
    try {

        const file_type = getFileExtension(filename).toLowerCase()
        const params = {
          Bucket: `${BUCKET_S3}${folder}`,
          Key: filename,
        };
      
        const data = await s3.getObject(params).promise();
        const body = data?.Body
        
        if(body.length > 0){
            const blob = new Blob([body.buffer], { type:  file_type !== 'pdf' ? 'image/png' : 'application/pdf'})
            const url = URL.createObjectURL(blob)
            if(isDownload){ 
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(url);
                link.download = filename;
                link.click();
            }else{
                return {
                    statusCode: 200, 
                    message: 'Se ha leido el archivo correctamente',
                    data: {
                        url,
                        file_type
                    }
                }
            }
        }else{
            return {
                statusCode: 404,
                message: 'No se encontro el archivo',
                data: {}
            }
        }
    } catch (error) {
        console.log('Ha ocurrido un error al leer el archivo', error?.message)
        return { statusCode: 500, message: error.message }
    }
};

export const downloadAll = async (data, folder) => {
    try{
        //Creamos una instancia de jszip para poder crear el zip con los archivos que se van a descargar
        const zip = new JSZip()

        //Iteramos aca uno de loa archivos que queremos descargar
        const downloads = data?.map(async (filename) => {
            //Configuramos los parametros para conectarnos al bucket
            const params = { Bucket: `${BUCKET_S3}${folder}`, Key: filename };

            try {
                //Obetenemos el archivo desde S3
                const response = await s3.getObject(params).promise();
                //Agregamos el archivo al zip
                zip.file(filename, response?.Body)
            } catch (error) {
                console.log(`Error al descargar el archivo ${filename}: ${error.message}`);
                return { statusCode: 500, message: `Error al descargar el archivo ${filename}` }
            }
        })

        //Esperamos que se completen todas las descargas
        await Promise.all(downloads)

        //Creamos el objeto y luego generamos la descarga
        const zipContent = await zip.generateAsync({type: 'blob'})
        const url = URL.createObjectURL(zipContent)
        const link = document.createElement('a')
        link.href = url
        link.download = 'documents.zip'
        document.body.appendChild(link)
        
        // Simula el clic en el enlace para iniciar la descarga
        link.click()
        // Limpia el enlace después de la descarga
        document.body.removeChild(link)
    }catch(e){
        console.log('Ha ocurrido un error al descargar los archivos.', e?.message)
        return { statusCode: 500, message: e.message }
    }
}