import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { encrypt_data } from '../utils/JSImplements'
import { GET, PUT} from '../utils/AuthService'
import { SwalFire } from '../utils'
import { logAction } from '../utils/logAction'
export const UseStoreConfig = create(
    persist(
        (set, get) => ({
            color_config: null,
            get_color_config: async () => {
                const { color_config } = get();
                if(!color_config){
                    const res = await GET(`get-color-config`, 'agenda')
                    if(res?.statusCode === 200){
                        set({color_config: res?.data})
                        return res?.data
                    }
                }
                return color_config
            },
            update_color_config: async (payload) => {
                const res = await PUT(`update-color-config/${payload?.id}`, payload, 'agenda')
                if(res?.statusCode === 200){
                    await logAction('colors_config', payload?.id, 'update', 'Se actualizó la configuración de colores')
                    SwalFire('Exito!', 'Se ha actualizado la configuración', 'success')
                    set({color_config: res?.data})
                }
            },

        }),
        {
            name: encrypt_data('config_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)