import React, {useEffect} from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UseStoreLogin } from './../stores/Login'
import { LayoutAdmin } from "./../pages/Layout"
import { Home } from "./../pages/Home"

export const PrivateRoute = () => {
    const userInfo = UseStoreLogin(state => state.data_user)
    const isLogged = UseStoreLogin(state => state.isLogged)
    const verifyToken = UseStoreLogin(state => state.verifyToken)

    useEffect(() => {
        verifyToken()
    })

    return isLogged ? <Home>
        <Outlet type_user={userInfo?.rol_id}/>
    </Home>
        : <Navigate to="/login/" />
}

export const RoutingConfig = () => {
    const userInfo = UseStoreLogin(state => state.data_user)
    const isLogged = UseStoreLogin(state => state.isLogged)
    const verifyToken = UseStoreLogin(state => state.verifyToken)

    useEffect(() => {
        verifyToken()
    })

    return isLogged ? 
        <LayoutAdmin>
            <Outlet type_user={userInfo?.rol_id}/>
        </LayoutAdmin>
    : <Navigate to="/login/" />
}
