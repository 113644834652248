import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./../msal/authConfig";
import { UseStoreTemplateView } from "../stores/TemplateView";
// import Button from "react-bootstrap/Button";
import Button from "@mui/material/Button";

const buttonStyle = {
  fontSize: "14px",
  textTransform: "inherit",
  width: "351px",
  height: "49px",
  background: "#666F88",
  color: "#fff",
};

export const SignInButton = (props) => {
  const { instance } = useMsal();
  const set_token_msal = UseStoreTemplateView((state) => state.set_token_msal);

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        const accessToken = response.accessToken;
        set_token_msal(accessToken);
      })
      .catch((e) => {
        if (props.onPopupClose) {
          props.onPopupClose();
        }
        console.log(e);
      });
    if (props.onButtonClick) {
      props.onButtonClick();
    }
  };

  return (
    <Button
      variant="contained"
      className="ml-auto"
      onClick={handleLogin}
      sx={buttonStyle}
    >
      Usar Outlook
    </Button>
  );
};
