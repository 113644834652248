import React from "react";
import Chip from "@mui/material/Chip";

export const MUIChip = (props) => {
  const { tags } = props;
  const stringValida = String(tags);
  const arrayDeElementos = stringValida.split(",");

  return (
    <>
      {arrayDeElementos.map((elemento, index) => (
        elemento !== 'null' && (
        <Chip
            key={index}
            label={elemento || ""}
            size="small"
            color="primary"
            sx={{ margin: 0.3 }}
        />
        )
      ))}
    </>
  );
};
