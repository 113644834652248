import React, { useState } from "react";
import SearchList from "../../components/Common/SearchList";
import { UseStoreSearch } from "./../../stores/Search";
// import { useDebounce } from "./../../utils";
import { useNavigate } from "react-router-dom";
import { UseStoreCompany } from "../../stores/Company";
import { UseStorePillar } from "../../stores/Pillar";
import { SwalFire } from "../../utils";
import { UseStoreLogin } from "../../stores/Login";
import { readFile } from "../../utils/AWS";
import FolderIcon from "@mui/icons-material/Folder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Typography } from "@mui/material";
import { UseStoreActivos } from "../../stores/Activos";
import { UseStoreContratos } from "../../stores/Contratos";
import { UseStoreActosGubernamentales } from "../../stores/ActosGubernamentales";

export const SearchListC = ({ onClose }) => {
  const get_activos = UseStoreActivos((state) => state.get_activos);
  const get_contratos = UseStoreContratos((state) => state.get_contratos);
  const get_goverment_acts = UseStoreActosGubernamentales(
    (state) => state.get_goverment_acts
  );

  const current_data = UseStoreSearch((state) => state.current_data);
  const userInfo = UseStoreLogin((state) => state.data_user);
  const get_data_by_search = UseStoreSearch(
    (state) => state.get_data_by_search
  );
  const get_permission_user = UseStorePillar(
    (state) => state.get_permission_user
  );
  const companies = UseStoreCompany((state) => state.companies);
  const [key, setKey] = useState("");
  //   const textDebounce = useDebounce(key, 2000);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [shareLink, setShareLink] = useState("");
  const [openDocument, setOpenDocument] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [fullPath, setFullPath] = useState("");
  const get_pillar_by_company = UseStorePillar(
    (state) => state.get_pillar_by_company
  );

  
  const getPath = async (fileId, companyId, subPillar) => {
    setFullPath("Resolviendo ruta...");

    // Función para obtener los datos del pilar seleccionado
    const fetchData = async (subPillar, companyId) => {
        if (subPillar >= 5 && subPillar <= 7) {
            return await get_goverment_acts(companyId, subPillar);
        }
        if (subPillar >= 8 && subPillar <= 10) {
            return await get_activos(companyId, subPillar);
        }
        if (subPillar >= 11 && subPillar <= 14) {
            return await get_contratos(companyId, subPillar);
        }
        return null; // En caso de que subPillar no esté en el rango esperado
    };

    // Trae la data del pilar seleccionado
    const data = await fetchData(subPillar, companyId);
    console.log("data", data);

    if (!data || data.length === 0) {
        setFullPath("Ruta no encontrada");
        return;
    }

    // Crear un diccionario de objetos para acceso rápido
    const dataDict = data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
    }, {});

    // Función para construir la ruta recursivamente
    const buildPath = (id, path = []) => {
        const item = dataDict[id];
        if (!item) return path;

        path.unshift(item.file_name);

        // Continuar el recorrido si hay un `parent_id` y este no es `null`
        if (item.parent_id !== null && dataDict[item.parent_id]) {
            return buildPath(item.parent_id, path);
        }

        return path;
    };

    const fullPath = buildPath(fileId);

    // Excluir la última ruta (nombre del archivo) y la primera ruta (raíz)
    if (fullPath.length > 1) {
        fullPath.pop();
        fullPath.shift();
    }

    const fullPathString = fullPath.join(" / ");
    setFullPath(fullPathString);
    console.log(fullPathString);
    
    return fullPathString;
};

  const handleCloseModalDocument = () => {
    setModalDocument(false);
    setShareLink("");
  };

  const get_data = async () => {
    setIsLoading(true);
    await get_data_by_search(key);
    setKey("");
    setIsLoading(false);
  };

  const getTypeDocument = (type) => {
    switch (type) {
      case "act-con":
        return 2;
      case "asa-ord":
      case "asa-ext":
        return 4;
      case "fed-hec":
        return 5;
      case "pwr-ppc":
      case "pwr-paa":
      case "pwr-pad":
      case "pwr-pes":
        return 3;
      default:
        return 0;
    }
  };

  const subPillarName = (type) => {
    switch (type) {
      case 5:
        return "Autoridades federales";
      case 6:
        return "Autoridades estatales";
      case 7:
        return "Autoridades municipales";
      case 8:
        return "Inmuebles";
      case 9:
        return "Muebles";
      case 10:
        return "Intangibles";
      case 11:
        return "Civil";
      case 12:
        return "Mercantil";
      case 13:
        return "Administrativo";
      case 14:
        return "Laboral";
      default:
        return "";
    }
  };
  const nameCompanie = (id) => {
    const company = companies.find((x) => x?.id === id);
    return company?.name || "";
  };

  const fetchShareLink = async (document_url, pilar_id) => {
    console.log(document_url);
    console.log(pilar_id);
    if (document_url !== "") {
      if (pilar_id === 2) {
        const sha_link = await readFile("/Actos_gubernamentales", document_url);
        if (sha_link?.statusCode === 200) {
          setShareLink(sha_link);
        } else {
          console.log("Error al obtener el link del archivo 2");
        }
      }
      if (pilar_id === 3) {
        const sha_link = await readFile("/Activos", document_url);
        if (sha_link?.statusCode === 200) {
          setShareLink(sha_link);
        } else {
          console.log("Error al obtener el link del archivo 3");
        }
      }
      if (pilar_id === 4) {
        const sha_link = await readFile("/Contratos", document_url);
        if (sha_link?.statusCode === 200) {
          setShareLink(sha_link);
        } else {
          console.log("Error al obtener el link del archivo 4");
        }
      }
    }
  };

  const handleNavigate = async (row) => {
    let url = "";
    if (row?.name_module === "Actor relacionado") {
      url = `${row?.url_module}${row?.id}`;
    } else {
      const getPermission = async (action = "", pillar_code = "") => {
        const company_id = action === "company" ? row?.id : row?.company_id;
        const userPermissions = await get_permission_user(
          userInfo?.id,
          company_id
        );

        const permissions = userPermissions?.find((item) => {
          return action === "company"
            ? item?.section === "Perfil empresarial"
            : item?.section_id === getTypeDocument(pillar_code);
        });

        console.log("Permissions", permissions);
        if (!permissions?.view) {
          SwalFire(
            "Atencion!",
            action === "company"
              ? "Usted no tiene permiso para ver la empresa."
              : "Usted no tiene permiso para ver este modulo.",
            "warning"
          );
          return false;
        }
        return true;
      };

      if (row?.name_module === "Perfil empresarial") {
        const permissions = await getPermission("company");
        if (!permissions) return;
        url = `${row?.url_module}${row?.id}`;
        navigate(url, { replace: true });
        onClose();
      }
      if (row?.name_module === "Poderes") {
        const company = companies.find((x) => x?.id === row?.company_id);
        const permissions = await getPermission("module", row?.type_power_code);
        if (!permissions) return;
        url = `${row?.url_module}${company?.name}/poderes/${row?.type_power_code}`;
        navigate(url, { replace: true });
        onClose();
      }
      if (row?.name_module === "Actas constitutiva") {
        const company = companies.find((x) => x?.id === row.company_id);
        const permissions = await getPermission("module", "act-con");
        if (!permissions) return;
        url = `${row?.url_module}${company?.name}/act-con`;
        navigate(url, { replace: true });
        onClose();
      }
      if (row?.name_module === "Actas de asambleas") {
        const company = companies.find((x) => x?.id === row.company_id);
        const typeAssemnly = row?.extraordinary === 0 ? "asa-ord" : "asa-ext";
        const permissions = await getPermission("module", typeAssemnly);
        if (!permissions) return;
        url = `${row?.url_module}${company?.name}/act-asm/${typeAssemnly}`;
        navigate(url, { replace: true });
        onClose();
      }
      if (row?.name_module === "Fe de hechos") {
        const company = companies.find((x) => x?.id === row.company_id);
        const permissions = await getPermission("module", "fed-hec");
        if (!permissions) return;
        url = `${row?.url_module}${company?.name}/fed-hec`;
        navigate(url, { replace: true });
        onClose();
      }
      if (row?.name_module === "Activos") {
        if (row?.type === "file") {
          const permissions = await getPermission("module", "act-con");
          if (!permissions) return;
          
          getPath(row?.id, row?.company_id, row?.subpillar_id);
          fetchShareLink(row?.general_file_url, 3);
          setModalDocument(true);
        } else {
          await get_pillar_by_company(row?.company_id);
          const permissions = await getPermission("module", "act-con");
          if (!permissions) return;
          url = `/pilares/activos/${row?.company_id}?id=${row?.id}`;
          window.location.href = url;
          onClose();
        }
      }
      if (row?.name_module === "Actos Gubernamentales") {
        if (row?.type === "file") {
          const permissions = await getPermission("module", "act-con");
          if (!permissions) return;
          getPath(row?.id, row?.company_id, row?.subpillar_id);
          fetchShareLink(row?.file_url, 2);
          setModalDocument(true);
        } else {
          await get_pillar_by_company(row?.company_id);
          const permissions = await getPermission("module", "act-con");
          if (!permissions) return;
          url = `/pilares/act-gub/${row?.company_id}?id=${row?.id}`;
          window.location.href = url;
          onClose();
        }
      }
      if (row?.name_module === "Contratos") {
        if (row?.type === "file") {
          const permissions = await getPermission("module", "act-con");
          if (!permissions) return;
          getPath(row?.id, row?.company_id, row?.subpillar_id);
          fetchShareLink(row?.document_url, 4);
          setModalDocument(true);
        } else {
          await get_pillar_by_company(row?.company_id);
          const permissions = await getPermission("module", "act-con");
          if (!permissions) return;
          url = `/pilares/contratos/${row?.company_id}?id=${row?.id}`;
          window.location.href = url;
          onClose();
        }
      }
    }

    //navigate(url, { replace: true });
    //onClose();
  };
  const typePower = (typePowers) => {
    const resultTypePower = {
      "pwr-ppc": "Poder general para pleitos y cobranza",
      "pwr-paa": "Poder general para actos de administración",
      "pwr-pad": "Poder general para actos de dominio",
      "pwr-pes": "Poder especial",
      0: "Asamblea ordinaria",
      1: "Asamblea extraordinaria",
    };
    return resultTypePower[typePowers] || "";
  };
  const getLabelText = (row) => {
    let primary = "";
    let secondary = "";

    if (row?.name_module === "Perfil empresarial") {
      primary = `${row?.name}`;
      secondary = `Empresas del grupo | Perfil empresarial`;
    }
    if (row?.name_module === "Actor relacionado") {
      primary = `${row?.name}`;
      secondary = `Actor | Perfil actor relacionado`;
    }
    if (row?.name_module === "Poderes") {
      const company = companies.find((x) => x?.id === row.company_id);
      primary = `${company?.name}, Apoderado: ${row?.attorney}`;
      secondary = `Perfil empresarial | Actos corporativos | Poderes | ${typePower(
        row?.type_power_code
      )}`;
    }

    if (row?.name_module === "Actas constitutiva") {
      const company = companies.find((x) => x?.id === row.company_id);
      primary = `${company?.name}, Acta constitutiva: ${company?.name}`;
      secondary = `Perfil empresarial | Actos corporativos | Acta constitutiva | ${company?.name}`;
    }
    if (row?.name_module === "Actas de asambleas") {
      const company = companies.find((x) => x?.id === row.company_id);
      primary = `${company?.name}, Actas de asambleas: ${company?.name}`;
      secondary = `Perfil empresarial | Actos corporativos | ${
        row?.name_module
      } | ${typePower(row?.extraordinary)} | ${company?.name}`;
    }
    if (row?.name_module === "Fe de hechos") {
      const company = companies.find((x) => x?.id === row.company_id);
      primary = `${company?.name}, Fe de hechos: ${company?.name}`;
      secondary = `Perfil empresarial | Actos corporativos | ${row?.name_module}`;
    }
    if (row?.name_module === "Activos") {
      if (row?.type === "file") {
        primary = (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PictureAsPdfIcon
              fontSize="small"
              sx={{ marginRight: 1, color: "action.active" }}
            />
            <Typography variant="body1" component="span">
              {row?.file_name}
            </Typography>
          </Box>
        );
        secondary = `Activos | ${subPillarName(
          row?.subpillar_id
        )} | ${nameCompanie(row?.company_id)}`;
      }
      if (row?.type === "folder") {
        primary = (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FolderIcon
              fontSize="small"
              sx={{ marginRight: 1, color: "action.active" }}
            />
            <Typography variant="body1" component="span">
              {row?.file_name}
            </Typography>
          </Box>
        );
        secondary = `Activos | ${subPillarName(
          row?.subpillar_id
        )} | ${nameCompanie(row?.company_id)}`;
      }
    }
    if (row?.name_module === "Actos Gubernamentales") {
      if (row?.type === "file") {
        primary = (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PictureAsPdfIcon
              fontSize="small"
              sx={{ marginRight: 1, color: "action.active" }}
            />
            <Typography variant="body1" component="span">
              {row?.file_name}
            </Typography>
          </Box>
        );
        secondary = `Actos gubernamentales | ${subPillarName(
          row?.subpillar_id
        )} | ${nameCompanie(row?.company_id)}`;
      }
      if (row?.type === "folder") {
        primary = (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FolderIcon
              fontSize="small"
              sx={{ marginRight: 1, color: "action.active" }}
            />
            <Typography>{row?.file_name}</Typography>
          </Box>
        );
        secondary = `Actos gubernamentales | ${subPillarName(
          row?.subpillar_id
        )} | ${nameCompanie(row?.company_id)}`;
      }
    }
    if (row?.name_module === "Contratos") {
      if (row?.type === "file") {
        primary = (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PictureAsPdfIcon
              fontSize="small"
              sx={{ marginRight: 1, color: "action.active" }}
            />
            <Typography variant="body1" component="span">
              {row?.file_name}
            </Typography>
          </Box>
        );
        secondary = `Contratos | ${subPillarName(
          row?.subpillar_id
        )} | ${nameCompanie(row?.company_id)} | ${row?.affair} `;
      }
      if (row?.type === "folder") {
        primary = (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FolderIcon
              fontSize="small"
              sx={{ marginRight: 1, color: "action.active" }}
            />
            <Typography variant="body1" component="span">
              {row?.file_name}
            </Typography>
          </Box>
        );
        secondary = `Contratos | ${subPillarName(
          row?.subpillar_id
        )} | ${nameCompanie(row?.company_id)}`;
      }
    }

    return { primary, secondary };
  };

  //   useEffect(() => {
  //     if (key) {
  //       get_data();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [textDebounce]);

  return (
    <SearchList
      data={current_data}
      value={key}
      handleChange={setKey}
      isLoading={isLoading}
      handleNavigate={handleNavigate}
      getLabelText={getLabelText}
      get_data={get_data}
      modalDocument={modalDocument}
      handleCloseModalDocument={handleCloseModalDocument}
      shareLink={shareLink}
      fullPath={fullPath}
    />
  );
};
