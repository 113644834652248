export const colors = {
  primary: {
    // main: "#666F88",
    main: "#1AAFD0",
    dark: "#666F88",
    // dark: "#323338",
    light: "#8990a2",
    // light2: "#B5BAC9",
    snow: "#FFFFFF",
  },
  secondary: {
    main: "#1AAFD0",
    dark: "#fff",
    light: "#fff",
  },
  // secondary: {
  //   main: "#fff",
  //   dark: "#fff",
  //   light: "#fff",
  //   green: "#3BE8B0",
  //   blue: "#1AAFD0",
  //   purple: "#6A67CE",
  //   yellow: "#FFB900",
  //   red: "#EF5255",
  // },
  //   tertiary: {
  //     color1: "#717171",
  //     color2: "#BOBOBO",
  //     color3: "#DDDDDD",
  //     color4: "#F7F7F7",
  //     color5: "#E2E4E7",
  //   },
};
