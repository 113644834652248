import React, { useState, useEffect }from 'react';
import dayjs from 'dayjs';
import { Box, Button, Fade, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { UseStoreConfig } from '../../stores/Config';
import ConfigIcon from "./../../assets/icons/configIcon.svg";
import { TableListAdapter } from "../Common/DataGridAdapter";
import { DataGridFooter } from "../Common/DataGridFooter";


export const TableAgenda = ({requests, control, companies}) => {
    const [showFilters, setshowFilters] = useState(false)
    const [requestsSelected, setRequestsSelected] = useState([])
    const [selectedEmpresas, setSelectedEmpresas] = useState([]);
    const [countRows, setCountRows] = useState(10);

    const color_config = UseStoreConfig(state => state.color_config);

    const handleClickFilters = () => {
        setshowFilters(!showFilters)
    }
 
    const get_color_status = (code_status, date_end) => {
        let color = 'inherit';
        
        const diffDays = () => {
            const current_date = dayjs()            
            const diff = dayjs(date_end) - current_date;
            return Math.ceil(diff / (1000 * 60 * 60 * 24))
        }

        if(code_status === 'VIG'){
            const daysRes = diffDays()

            if(daysRes <= 5) {
                color = color_config?.status_activity_current2
            }else {
                color = color_config?.status_activity_current
            }

        }else if(code_status === 'VEN') {
            color = color_config?.status_activity_expired
        }else if(code_status === 'CER') {
            color = color_config?.status_activity_closed
        }
        return color
    }

    const columns = [
        { field: 'id', headerName:'ID Actividad', width: 110},
        { field: 'date', headerName:'Fecha', width: 120, valueGetter: ({row}) => `${dayjs(row?.date).locale('es').format('dddd, D MMMM YYYY')}`},
        { field: 'description', headerName:'Descripción', width: 250},
        { field: 'related_actor_name', headerName:'Actor relacionado', width: 160},
        { field: 'applicant_company_name', headerName:'Empresa', width: 190},
        { field: 'pillar_name', headerName:'Pilar', width: 190},
        { field: 'internal_applicant_name', headerName:'Solicitante', width: 160},
        { field: 'area_name', headerName:'Area', width: 150},
        { field: 'responsible_name', headerName:'Responsable', width: 150},
        { 
            field: 'processing_requirements', 
            headerName:'Requisitos previos', 
            width: 200,
            align: 'center',
            renderCell: ({row}) => <Button>{row?.requirements?.length}</Button>
        },
        { 
            field: 'end_date',
            headerName:'Fecha final', 
            width: 150,
            valueGetter: ({row}) => `${dayjs(row?.end_date).locale('es').format('dddd, D MMMM YYYY')}`
        },
        { 
            field: 'status_activity_name', 
            headerName:'Estado de la actividad', 
            width: 200,
            align: 'center',
            renderCell: ({row}) => <Box>
                <Button  sx={{backgroundColor: get_color_status(row?.status_activity?.code, row?.end_date), color: 'white', fontWeight: 'bold' }}>
                    {row?.status_activity?.description}
                </Button>
            </Box>
        },
        { 
            field: 'digital_document', 
            headerName:'Documento digital', 
            width: 150,
            align: 'center',
        },
        { 
            field: 'physical_document', 
            headerName:'Documento físico', 
            width: 150,
            align: 'center',
        },
        { 
            field: 'safeguarding_name', 
            headerName:'Resguardatorio', 
            width: 150,
        },
        { 
            field: 'control_name',
            headerName:'Control', 
            width: 150,
        },
        { 
            field: 'general_comments', 
            headerName:'Comentarios del trámite', 
            width: 220
        }
    ];

    const handleChange = (event) => {
        const value = event.target.value
        if(!value?.length){
            setRequestsSelected(requests)
            setSelectedEmpresas(value);
        }else{
            setSelectedEmpresas(value);
            const request_filters = requests.filter(filter => value.includes(filter.applicant_company_name));
            setRequestsSelected(request_filters)
        }
    };

    useEffect(() => {
        setRequestsSelected(requests)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} bgcolor={'#fdfdfd'}>
            <Box p={1} display={'flex'} flexDirection={'column'}>
                <Box>
                    <Button
                        type="button"
                        onClick={handleClickFilters}
                        sx={{
                            fontFamily: "Poppins-Regular",
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#323338",
                            textTransform: "inherit",
                            height: "46px",
                            m:1
                        }}
                        endIcon={
                            <img
                            src={ConfigIcon}
                            width="15px"
                            height="15px"
                            alt="ConfigIcon"
                            />
                        }
                    >
                        Filtrar por empresa(s)
                    </Button>
                </Box>       
                {showFilters && 
                    <Fade in={showFilters}>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} p={1.5}>
                            <FormControl fullWidth>
                                <InputLabel id="select-multiple-label">Selecciona empresas</InputLabel>
                                <Select
                                    labelId="select-multiple-label"
                                    id="select-multiple"
                                    multiple
                                    value={selectedEmpresas}
                                    onChange={handleChange}
                                    label="Selecciona empresas"
                                    >
                                    {companies.map((company) => (
                                        <MenuItem key={company.id} value={company.name}>
                                            {company.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Fade>
                }
            </Box>
            <Box
                sx={{
                    height: "80vh",
                    overflow: "auto",
                    width: "95%",
                    borderRadius: "7px 7px 0px 0px",
                    bgcolor: "#FFFFFF",
                    p: "0px 16px",
                    "& .options-className": {
                    borderLeft: "3px solid #E2E4E7",
                    },
                    "& .role-className": {
                    borderLeft: "1px solid #E2E4E7",
                    },
                }}
            >
                <TableListAdapter 
                    height={700}
                    isLoading={Boolean(!requestsSelected)}
                    columns={columns?.map( column => {
                        if(column?.field === 'physical_document' || column?.field === 'control_name' || column?.field === 'safeguarding_name'){
                            return column
                        }else {
                            return {
                            ...column,
                            valueFormatter: (params) => {
                                const value = params?.value;
                                return value === null || value === undefined ? 'NO APLICA' : value;
                            }}
                        }
                    })}
                    data={requestsSelected?.filter(x => x.status).filter((_, index) => index < countRows)}
                    props={{
                        slots: {
                          footer: () => (
                            <DataGridFooter
                              active={countRows === 10 && requestsSelected?.length > 10}
                              handleClick={() => {
                                setCountRows(requestsSelected?.length);
                              }}
                            />
                          ),
                        },
                    }}
                    handleRowClick={(row) => {}}
                    processRowUpdate={()=>{}}
                    handleCellClick={()=>{}}
                    search={false}
                />
            </Box>
        </Box>
    )
}