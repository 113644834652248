import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Hidden,
  Stack,
  TableContainer,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/icons/backIcon.svg";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { MUIModal } from "../../Common/MUIModal";
import { Input } from "../../Common/MUInput";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import FolderIcon from "@mui/icons-material/Folder";
import { InputFile } from "../../Common/MUIInputFile";
import { InputAutocompleteAdapter } from "../../Common/MUIAutoComplete";
import { ButtonGroup } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { readFile } from "../../../utils/AWS";
import { RenderPdf } from "../../Common/RenderPdf";
import { ImagePreview } from "../../Common/ImagePreview";
import ImageIcon from '@mui/icons-material/Image';
import dayjs from 'dayjs';

export const ContratosV = ({
  vars: {
    company_id,
    subpillar_id,
    isLoading,
    contratos,
    company,
    control,
    register,
    errors,
    setValueForm,
    reset,
    control2, //Crear File
    register2, //Crear File
    errors2, //Crear File
    setValueForm2, //Crear File
    reset2, //Crear File
    styles,
    users,
    actors,
    createFolder,
    editFolder,
    control3, //Editar Folder
    register3, //Editar Folder
    errors3, //Editar Folder
    setValueForm3, //Editar Folder
    reset3,
    createFile,
    editFile,
    companies,
    modalDocument, //Modal Document
    nameDoc,
    shareLink,
    clientsWithNoAplica,
    interCompaniesWithNoAplica,
    control4, //Editar File
    register4, //Editar File
    errors4, //Editar File
    setValueForm4, //Editar File
    reset4, //Editar File
    permisos,
    id,
    subPillar,
    folderName,
    expandedItems
  },
  funcs: {
    updateGetContratos,
    handleSubmit,
    OnSubmit,
    handleDeleteFolder,
    OnSubmitCreateFile,
    handleSubmit2,
    handleOpenFolder,
    handleCloseFolder,
    handleSubmit3,
    OnSubmitEditFolder,
    handleModalEditNameFolder,
    handleCloseEditNameFolder,
    handleModalEditFile,
    handleModarCloseEditFile,
    handleDeleteFile,
    setCreateFile,
    handleCloseFile,
    handleOpenModalDocument,
    handleCloseModalDocument,
    setNameDoc,
    OnSubmitEditFile,
    handleSubmit4,
    fetchShareLink,
    setId,
    setFolderName,
    setSubPillar,
    setExpandedItems
  },
}) => {
  const navigate = useNavigate();
  const [editNameFolder, setEditNameFolder] = React.useState(false); //Modal

  const [parentId, setParentId] = React.useState("");
  //const [id, setId] = React.useState("");
  //const [folderName, setFolderName] = React.useState("");
  //const [subPillar, setSubPillar] = React.useState("");

  useEffect(() => {
    updateGetContratos();
    setValueForm("parent_id", id);
    setValueForm2("parent_id", id);
    setValueForm2("subpillar_id", subPillar);
    const fetchShareLink = async () => {
      if (nameDoc) {
        contratos.map((x) => {
          if (x.id == id) {
            setFolderName(x.file_name);
          }
        });
      }
    };
    fetchShareLink();

    if (id) {
      contratos?.filter((item) => {
        if (item.id == id) {
          setSubPillar(item.subpillar_id);
        }
      });
    }
  }, [id, subPillar]);

  const FolderName = () => {
    return (
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
          color: "#323338",
          display: "inline",
        }}
      >
        {folderName}
      </Typography>
    );
  };

  const extractDigits = (str) => {
    const regex = /[0-9]/g;
    const digits = str.match(regex);
    return digits ? digits.join("") : "";
  };

  const fN = (id) => {
    const folder = contratos.filter((item) => {
      if (item.id == id) {
        setFolderName(item.file_name);
        setParentId(item.parent_id);
        setSubPillar(item.subpillar_id);
      }
    });
  };

  //const [expandedItems, setExpandedItems] = React.useState([]);
  const [lastSelectedItem, setLastSelectedItem] = React.useState(null);
  const handleItemSelectionToggle = (
    event,
    itemId,
    isSelected,
    label,
    node
  ) => {
    if (isSelected) {
      setLastSelectedItem(itemId);
      setId(extractDigits(itemId));
      fN(extractDigits(itemId));
      contratos.filter((item) => {
        if (item.id == id) {
          setSubPillar(item.subpillar_id);
        }
      });
    }
  };
  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const TreeView = ({ govermentActs }) => {
    const buildTree = (subpillarsChild2) => {
      subpillarsChild2 = Array.from(subpillarsChild2);
      const rootItems = [];
      const lookup = {};

      // Crear el lookup con los items
      subpillarsChild2.forEach((item) => {
        lookup[item.id] = { ...item, children: [] };
      });

      // Asignar los items a sus padres o a la raíz
      subpillarsChild2.forEach((item) => {
        if (item.parent_id && item.type !== "file") {
          lookup[item.parent_id].children.push(lookup[item.id]);
        } else if (!item.parent_id && item.type !== "file") {
          rootItems.push(lookup[item.id]);
        }
      });

      // Función para ordenar alfabéticamente por file_name
      const sortAlphabetically = (a, b) => {
        return a.file_name.localeCompare(b.file_name);
      };

      // Ordenar recursivamente los items y sus hijos
      const sortItems = (items) => {
        items.forEach((item) => {
          if (item.children.length > 0) {
            sortItems(item.children);
          }
        });
        items.sort(sortAlphabetically);
      };

      // Ordenar los items raíz y sus hijos
      sortItems(rootItems);

      return rootItems;
    };
    const treeData = buildTree(govermentActs);

    const buildTreeItems = (nodes) => {
      return nodes.map((node) => (
        <TreeItem
          key={node.type + node.id}
          sx={{ verticalAlign: "middle" }}
          itemId={node.type + node.id}
          label={
            <>
              {node.type === "folder" ? (
                <FolderIcon sx={{ verticalAlign: "middle" }} />
              ) : (
                <AttachFileIcon sx={{ verticalAlign: "middle" }} />
              )}
              <span> </span>
              {node.file_name}
            </>
          }
          {...node}
        >
          {node.children && node.children.length > 0
            ? buildTreeItems(node.children)
            : null}
        </TreeItem>
      ));
    };

    return (
      <div>
        <SimpleTreeView
          onItemSelectionToggle={handleItemSelectionToggle}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "14px",
            fontWeight: "400",
            color: "#323338",
          }}
        >
          {buildTreeItems(treeData)}
        </SimpleTreeView>
      </div>
    );
  };
  const handleDownloadFile = async (id) => {
    await fetchShareLink(id, false)
  };
  const downloadDocument = async (id) => {
    await fetchShareLink(id, false)
  };

  const TableData = ({
    items,
    contratos,
    users,
    actors,
    handleModalEditFile,
    handleDeleteFile,
    id,
    styles,
  }) => {
    const display = "none";
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState("");

    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
    const sortedRows = (rows, comparator) => {
      const stabilizedThis = rows.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    };
    const getComparator = (order, orderBy) => {
      return order === "desc"
        ? (a, b) => -descendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, orderBy);
    };

    const filteredData = contratos?.filter((row) => {
      const responsible = users?.find((x) => x.id === row.responsible_id);
      const client = actors?.find((x) => x.id === row.client);
      const intercompany = companies?.find(
        (x) => x.id === row.inter_company_id
      );
      const fileName = row.file_name ? row.file_name.toLowerCase() : "";
      const responsibleName = responsible
        ? `${responsible.names} ${responsible.last_names}`.toLowerCase()
        : "";
      const clientName = client
        ? `${client.name} - ${client.description}`.toLowerCase()
        : "";
      const intercompanyName = intercompany
        ? intercompany.name.toLowerCase()
        : "";
      const affair = row.affair ? row.affair.toLowerCase() : "";
      const observations = row.observations
        ? row.observations.toLowerCase()
        : "";
      const physical_file_location = row.physical_file_location
        ? row.physical_file_location.toLowerCase()
        : "";
      return (
        row.type === "file" &&
        row.parent_id == id &&
        (fileName.includes(filter.toLowerCase()) ||
          responsibleName.includes(filter.toLowerCase()) ||
          clientName.includes(filter.toLowerCase()) ||
          intercompanyName.includes(filter.toLowerCase()) ||
          observations.includes(filter.toLowerCase()) ||
          physical_file_location.includes(filter.toLowerCase()) ||
          affair.includes(filter.toLowerCase()))
      );
    });
    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };
    const sortedData = sortedRows(
      filteredData || [],
      getComparator(order, orderBy)
    );
    const renderSortIcon = (column) => {
      if (orderBy !== column) return null;
      return order === "desc" ? (
        <ArrowDownwardIcon fontSize="small" />
      ) : (
        <ArrowUpwardIcon fontSize="small" />
      );
    };
    const columnTitles = {
      inter_company_id: "Intercompañia",
      affair: "Objeto",
      start_date: "Inicio de vigencia",
      end_date: "Termino de vigencia",
      observations: "Observaciones",
      physical_file_location: "Ubicación física del documento",
      document_url: "Documento",
      client: "Cliente",
      responsible_id: "Responsable",
    };
    const permisoEditarCivil = permisos.some(permiso => permiso.section_id === 12 && permiso.edit === true);
    const permisoEditarMercantil = permisos.some(permiso => permiso.section_id === 13 && permiso.edit === true);
    const permisoEditarAdministrativo = permisos.some(permiso => permiso.section_id === 14 && permiso.edit === true);
    const permisoEditarLaboral = permisos.some(permiso => permiso.section_id === 15 && permiso.edit === true);

    const permisoEliminarCivil = permisos.some(permiso => permiso.section_id === 12 && permiso.delete === true);
    const permisoEliminarMercantil = permisos.some(permiso => permiso.section_id === 13 && permiso.delete === true);
    const permisoEliminarAdministrativo = permisos.some(permiso => permiso.section_id === 14 && permiso.delete === true);
    const permisoEliminarLaboral = permisos.some(permiso => permiso.section_id === 15 && permiso.delete === true);

    const permisoDescargarCivil = permisos.some(permiso => permiso.section_id === 12 && permiso.download === true);
    const permisoDescargarMercantil = permisos.some(permiso => permiso.section_id === 13 && permiso.download === true);
    const permisoDescargarAdministrativo = permisos.some(permiso => permiso.section_id === 14 && permiso.download === true);
    const permisoDescargarLaboral = permisos.some(permiso => permiso.section_id === 15 && permiso.download === true);

    return (
      <>
        <TextField
          label="Buscar..."
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setFilter(e.target.value)}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <>
                  {[
                    "inter_company_id",
                    "affair",
                    "start_date",
                    "end_date",
                    "observations",
                    "physical_file_location",
                    "document_url",
                    "client",
                    "responsible_id",
                  ].map((column) => (
                    <TableCell
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#323338",
                        ":hover": {
                          cursor: "pointer",
                          color: "#1AAFD0",
                        },
                        ":transition": "all 0.9s",
                      }}
                      key={column}
                      label={column}
                      align="center"
                      sortDirection={orderBy === column ? order : false}
                      onClick={() => handleRequestSort(column)}
                    >
                      {columnTitles[column]}
                      <span>{renderSortIcon(column)}</span>
                    </TableCell>
                  ))}
                </>
                <TableCell
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#323338",
                  }}
                  align="center"
                  colSpan={4}
                >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row) => (
                <TableRow key={row.id}>
                  <>
                    <TableCell align="center">
                      {interCompaniesWithNoAplica?.map((x) => {
                        if (x.id === row.inter_company_id) {
                          return x.name;
                        }
                      })}
                    </TableCell>
                    <TableCell align="center">{row.affair}</TableCell>
                    <TableCell align="center">{dayjs(row?.start_date).locale('es').format('dddd, D MMMM YYYY')}</TableCell>
                    <TableCell align="center">{dayjs(row?.end_date).locale('es').format('dddd, D MMMM YYYY')}</TableCell>
                    <TableCell align="center">{row.observations}</TableCell>
                    <TableCell align="center">
                      {row.physical_file_location}
                    </TableCell>
                    <TableCell align="center">{row.document_url}</TableCell>
                    <TableCell align="center">
                      {actors?.map((x) => {
                        if (x.id === row.client) {
                          return x.name + " - " + x.description;
                        }
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {users?.map((x) => {
                        if (x.id === row.responsible_id) {
                          return x.names + " " + x.last_names;
                        }
                      })}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Ver Documento">
                        <Button
                          onClick={() =>
                            handleOpenModalDocument(row.id, row.document_url)
                          }
                          variant="text"
                          sx={{
                            color: "#1AAFD0",
                            ":hover": {
                              color: "#323338",
                            },
                          }}
                        >
                          {row.document_url.toLowerCase().endsWith('.pdf') ? <PictureAsPdfIcon sx={{ fontSize: 35 }} /> : <ImageIcon sx={{ fontSize: 35 }} />}
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </>
                  <TableCell align="center">
                    <Tooltip title="Descargar Documento">
                      <Button
                        sx={{
                          color: "#1AAFD0",
                          ":hover": {
                            color: "#323338",
                          },
                        }}
                        onClick={() =>
                          handleDownloadFile(row.document_url)
                        }
                        disabled={
                          !(
                            (row.subpillar_id === 11 && permisoDescargarCivil) ||
                            (row.subpillar_id === 12 && permisoDescargarMercantil) ||
                            (row.subpillar_id === 13 && permisoDescargarAdministrativo) ||
                            (row.subpillar_id === 14 && permisoDescargarLaboral)
                          )
                        }
                      >
                        <DownloadIcon sx={{ fontSize: 35 }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Editar Documento">
                      <Button
                        sx={{
                          color: "#1AAFD0",
                          ":hover": {
                            color: "#323338",
                          },
                        }}
                        onClick={() =>
                          handleModalEditFile(row.subpillar_id, row.id)
                        }
                        disabled={
                          !(
                            (row.subpillar_id === 11 && permisoEditarCivil) ||
                            (row.subpillar_id === 12 && permisoEditarMercantil) ||
                            (row.subpillar_id === 13 && permisoEditarAdministrativo) ||
                            (row.subpillar_id === 14 && permisoEditarLaboral)
                          )
                        }
                      >
                        <EditIcon sx={{ fontSize: 35 }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Eliminar Documento">
                      <Button
                        sx={{
                          color: "#1AAFD0",
                          ":hover": {
                            color: "#323338",
                          },
                        }}
                        onClick={() => handleDeleteFile(row.subpillar_id, row.id)}
                        disabled={
                          !(
                            (row.subpillar_id === 11 && permisoEliminarCivil) ||
                            (row.subpillar_id === 12 && permisoEliminarMercantil) ||
                            (row.subpillar_id === 13 && permisoEliminarAdministrativo) ||
                            (row.subpillar_id === 14 && permisoEliminarLaboral)
                          )
                        }
                      >
                        <DeleteForeverIcon sx={{ fontSize: 35 }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const handleOpenFile = (id) => {
    setCreateFile(true);
    contratos.filter((item) => {
      if (item.id == id) {
        setSubPillar(item.subpillar_id);
      }
    });
  };

  const TopBarButtons = () => {
    const permisoEditarCivil = permisos.some(permiso => permiso.section_id === 12 && permiso.edit === true);
    const permisoEditarMercantil = permisos.some(permiso => permiso.section_id === 13 && permiso.edit === true);
    const permisoEditarAdministrativo = permisos.some(permiso => permiso.section_id === 14 && permiso.edit === true);
    const permisoEditarLaboral = permisos.some(permiso => permiso.section_id === 15 && permiso.edit === true);

    const permisoEliminarCivil = permisos.some(permiso => permiso.section_id === 12 && permiso.delete === true);
    const permisoEliminarMercantil = permisos.some(permiso => permiso.section_id === 13 && permiso.delete === true);
    const permisoEliminarAdministrativo = permisos.some(permiso => permiso.section_id === 14 && permiso.delete === true);
    const permisoEliminarLaboral = permisos.some(permiso => permiso.section_id === 15 && permiso.delete === true);

    const permisoAgregarCivil = permisos.some(permiso => permiso.section_id === 12 && permiso.add === true);
    const permisoAgregarMercantil = permisos.some(permiso => permiso.section_id === 13 && permiso.add === true);
    const permisoAgregarAdministrativo = permisos.some(permiso => permiso.section_id === 14 && permiso.add === true);
    const permisoAgregarLaboral = permisos.some(permiso => permiso.section_id === 15 && permiso.add === true);

    return (
      <>
        {subPillar === 11 ||
        subPillar === 12 ||
        subPillar === 13 ||
        subPillar === 14 ? (
          <>
            <Button onClick={handleOpenFolder}
              disabled={
                !(
                  (subPillar === 11 && permisoAgregarCivil) ||
                  (subPillar === 12 && permisoAgregarMercantil) ||
                  (subPillar === 13 && permisoAgregarAdministrativo) ||
                  (subPillar === 14 && permisoAgregarLaboral)
                )
              }
            >
              Crear Carpeta
              <CreateNewFolderIcon sx={{ marginLeft: 1 }} />{" "}
            </Button>
            <Button
              onClick={() => handleDeleteFolder(subPillar, id, company_id)}
              disabled={
                !(
                  (subPillar === 11 && permisoEliminarCivil) ||
                  (subPillar === 12 && permisoEliminarMercantil) ||
                  (subPillar === 13 && permisoEliminarAdministrativo) ||
                  (subPillar === 14 && permisoEliminarLaboral)
                )
              }
            >
              Eliminar Carpeta
              <FolderDeleteIcon sx={{ marginLeft: 1 }} />
            </Button>
            <Button onClick={handleOpenFile}
              disabled={
                !(
                  (subPillar === 11 && permisoAgregarCivil) ||
                  (subPillar === 12 && permisoAgregarMercantil) ||
                  (subPillar === 13 && permisoAgregarAdministrativo) ||
                  (subPillar === 14 && permisoAgregarLaboral)
                )
              }
            >
              Cargar Archivo
              <AttachFileIcon sx={{ marginLeft: 1 }} />{" "}
            </Button>
            <Button onClick={() => handleModalEditNameFolder(id)}
              disabled={
                !(
                  (subPillar === 11 && permisoEditarCivil) ||
                  (subPillar === 12 && permisoEditarMercantil) ||
                  (subPillar === 13 && permisoEditarAdministrativo) ||
                  (subPillar === 14 && permisoEditarLaboral)
                )
              }
            >
              Editar Carpeta <EditIcon />
            </Button>
          </>
        ) : (
          <Typography>Seleccione una carpeta</Typography>
        )}
      </>
    );
  };

  const ModalEditFolder = () => {
    return (
      <Stack>
        <MUIModal
          open={editFolder}
          handleClose={handleCloseEditNameFolder}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
          <Box sx={{}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Editar carpeta <FolderName />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography>Nombre de la carpeta</Typography>
              <form onSubmit={handleSubmit3(OnSubmitEditFolder)}>
                <input
                  type="hidden"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  {...register3("id", {})}
                />
                <Input
                  name={`folder_name`}
                  control={control3}
                  props={{
                    value: folderName,
                    size: "small",
                    fullWidth: true,
                    label: "Nombre de la carpeta",
                    autoComplete: "none",
                    required: true,
                  }}
                />
                <Button sx={{}} type="submit"
                 disabled={isLoading}>
                  Actualizar
                </Button>
              </form>
            </Typography>
          </Box>
        </MUIModal>
      </Stack>
    );
  };

  const ModalCreateFolder = () => {
    return (
      <Stack>
        <MUIModal
          open={createFolder}
          handleClose={handleCloseFolder}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
          <Box sx={{}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Crear carpeta en <FolderName />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography>Nombre de la carpeta</Typography>
              <form onSubmit={handleSubmit(OnSubmit)}>
                <input
                  type="hidden"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  {...register("parent_id", {})}
                />
                <Input
                  name={`folder_name`}
                  control={control}
                  props={{
                    size: "small",
                    fullWidth: true,
                    label: "Nombre de la carpeta",
                    autoComplete: "none",
                    required: true
                  }}
                />
                <Button
                  sx={{
                    marginTop: "20px",
                  }}
                  type="submit"
                  disabled={isLoading}
                >
                  Crear
                </Button>
              </form>
            </Typography>
          </Box>
        </MUIModal>
      </Stack>
    );
  };
  //Modal Edit File
  const ModalEditFile = () => {
    return (
      <>
        <Stack>
          <MUIModal
            open={editFile}
            handleClose={handleModarCloseEditFile}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            buttonTop={true}
          >
            <Box sx={{}}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Actualizar información
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <form onSubmit={handleSubmit4(OnSubmitEditFile)}>
                  <input
                    type="hidden"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    {...register4("file_id", {})}
                  />
                  <input
                    type="hidden"
                    value={subPillar}
                    onChange={(e) => setSubPillar(e.target.value)}
                    {...register4("subpillar_id", {})}
                  />

                  <>
                    <Typography sx={styles.label} pr={1}>
                      Intercompañia
                    </Typography>
                    <InputAutocompleteAdapter
                      disabled={false}
                      size={"small"}
                      mb={0}
                      fullWidth={true}
                      height={40}
                      label={""}
                      name="inter_company_id"
                      control={control4}
                      required={true}
                      options={
                        interCompaniesWithNoAplica?.map((x) => ({
                          value: x?.id,
                          label: `${x?.name}`,
                        })) || []
                      }
                    />
                  </>

                  <>
                    <Typography sx={styles.label} pr={1}>
                      Objeto
                    </Typography>
                    <Input
                      name={`affair`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Objeto",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>

                  <>
                    <Typography sx={styles.label} pr={1}>
                      Inicio de vigencia
                    </Typography>
                    <Input
                      name={`start_date`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "",
                        autoComplete: "none",
                        required: true,
                        type: "date",
                      }}
                    />
                  </>

                  <>
                    <Typography sx={styles.label} pr={1}>
                      Termino de vigencia
                    </Typography>
                    <Input
                      name={`end_date`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "",
                        autoComplete: "none",
                        required: true,
                        type: "date",
                      }}
                    />
                  </>

                  <>
                    <Typography sx={styles.label} pr={1}>
                      Observaciones
                    </Typography>
                    <Input
                      name={`observations`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Observaciones",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>

                  <>
                    <Typography sx={styles.label} pr={1}>
                      Ubicación física del documento
                    </Typography>
                    <Input
                      name={`physical_file_location`}
                      control={control4}
                      props={{
                        size: "small",
                        fullWidth: true,
                        label: "Ubicación física del documento",
                        autoComplete: "none",
                        required: true,
                      }}
                    />
                  </>
                  <Typography sx={styles.label} pr={1}>
                    Documento
                  </Typography>
                  <InputFile
                    name="document"
                    label={"Elegir Documento"}
                    control={control4}
                    props={{
                      required: false,
                      error: errors?.document ? true : false,
                      helperText: errors?.document?.message,
                    }}
                  />
                  <Typography sx={styles.label} pr={1}>
                    Actor relacionado
                  </Typography>
                  <InputAutocompleteAdapter
                    disabled={false}
                    size={"small"}
                    mb={0}
                    fullWidth={true}
                    height={40}
                    label={""}
                    name="client"
                    control={control4}
                    options={
                      clientsWithNoAplica?.map((x) => ({
                        value: x?.id,
                        label: `${x?.name} - ${x?.description}`,
                      })) || []
                    }
                  />
                  <Typography sx={styles.label} pr={1}>
                    Responsable
                  </Typography>
                  <InputAutocompleteAdapter
                    disabled={false}
                    size={"small"}
                    mb={0}
                    fullWidth={true}
                    height={40}
                    label={""}
                    name="responsible_id"
                    control={control4}
                    required={true}
                    options={
                      users?.map((x) => ({
                        value: x?.id,
                        label: `${x?.names} ${x?.last_names} - ${x?.charge}`,
                      })) || []
                    }
                  />
                  <Button sx={styles.buttonSave} type="submit">
                    Guardar
                  </Button>
                </form>
              </Typography>
            </Box>
          </MUIModal>
        </Stack>
      </>
    );
  };

  const ModalDocument = () => {
    return (
      <>
        <Stack>
          <MUIModal
            open={modalDocument.open}
            handleClose={handleCloseModalDocument}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            buttonTop={true}
          >
            <Box sx={{ height: '100%', width: '100%' }}>
              <Typography id="modal-modal-title2" variant="h6" component="h2">
                
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* Aqui se renderiza el archivo PDF */}
                {modalDocument.type === true ? (
                  <ImagePreview url={shareLink?.data?.url} />
                ) : (
                  <RenderPdf url={shareLink?.data?.url} />
                )}
                <Typography>{nameDoc}</Typography>
              </Typography>
            </Box>
          </MUIModal>
        </Stack>
      </>
    );
  };

  const ModalCreateFileInmueble = () => {
    return (
      <Stack>
        <MUIModal
          open={createFile}
          handleClose={handleCloseFile}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
          <Box sx={{}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Cargar archivo en <FolderName />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <form onSubmit={handleSubmit2(OnSubmitCreateFile)}>
                <input
                  type="hidden"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  {...register2("parent_id", {})}
                />
                <input
                  type="hidden"
                  value={subPillar}
                  onChange={(e) => setSubPillar(e.target.value)}
                  {...register2("subpillar_id", {})}
                />

                <>
                  <Typography sx={styles.label} pr={1}>
                    Intercompañia
                  </Typography>
                  <InputAutocompleteAdapter
                    disabled={false}
                    size={"small"}
                    mb={0}
                    fullWidth={true}
                    height={40}
                    label={""}
                    name="inter_company_id"
                    control={control2}
                    required={true}
                    options={
                      interCompaniesWithNoAplica?.map((x) => ({
                        value: x?.id,
                        label: `${x?.name}`,
                      })) || []
                    }
                  />
                </>

                <>
                  <Typography sx={styles.label} pr={1}>
                    Objeto
                  </Typography>
                  <Input
                    name={`affair`}
                    control={control2}
                    props={{
                      size: "small",
                      fullWidth: true,
                      label: "Objeto",
                      autoComplete: "none",
                      required: true,
                    }}
                  />
                </>

                <>
                  <Typography sx={styles.label} pr={1}>
                    Inicio de vigencia
                  </Typography>
                  <Input
                    name={`start_date`}
                    control={control2}
                    props={{
                      size: "small",
                      fullWidth: true,
                      label: "",
                      autoComplete: "none",
                      required: true,
                      type: "date",
                    }}
                  />
                </>

                <>
                  <Typography sx={styles.label} pr={1}>
                    Termino de vigencia
                  </Typography>
                  <Input
                    name={`end_date`}
                    control={control2}
                    props={{
                      size: "small",
                      fullWidth: true,
                      label: "",
                      autoComplete: "none",
                      required: true,
                      type: "date",
                    }}
                  />
                </>

                <>
                  <Typography sx={styles.label} pr={1}>
                    Observaciones
                  </Typography>
                  <Input
                    name={`observations`}
                    control={control2}
                    props={{
                      size: "small",
                      fullWidth: true,
                      label: "Observaciones",
                      autoComplete: "none",
                      required: true,
                    }}
                  />
                </>

                <>
                  <Typography sx={styles.label} pr={1}>
                    Ubicación física del documento
                  </Typography>
                  <Input
                    name={`physical_file_location`}
                    control={control2}
                    props={{
                      size: "small",
                      fullWidth: true,
                      label: "Ubicación física del documento",
                      autoComplete: "none",
                      required: true,
                    }}
                  />
                </>
                <Typography sx={styles.label} pr={1}>
                  Documento
                </Typography>
                <InputFile
                  name="document"
                  label={"Elegir Documento"}
                  control={control2}
                  props={{
                    required: false,
                    error: errors?.document ? true : false,
                    helperText: errors?.document?.message,
                    accept: ".pdf,.png,.jpg,image/jpeg"
                  }}
                />
                <Typography sx={styles.label} pr={1}>
                  Actor relacionado
                </Typography>
                <InputAutocompleteAdapter
                  disabled={false}
                  size={"small"}
                  mb={0}
                  fullWidth={true}
                  height={40}
                  label={""}
                  name="client"
                  control={control2}
                  options={
                    clientsWithNoAplica?.map((x) => ({
                      value: x?.id,
                      label: `${x?.name} - ${x?.description}`,
                    })) || []
                  }
                />
                <Typography sx={styles.label} pr={1}>
                  Responsable
                </Typography>
                <InputAutocompleteAdapter
                  disabled={false}
                  size={"small"}
                  mb={0}
                  fullWidth={true}
                  height={40}
                  label={""}
                  name="responsible_id"
                  control={control2}
                  required={true}
                  options={
                    users?.map((x) => ({
                      value: x?.id,
                      label: `${x?.names} ${x?.last_names} - ${x?.charge}`,
                    })) || []
                  }
                />
                <Button sx={styles.buttonSave} type="submit"
                 disabled={isLoading}>
                  Guardar
                </Button>
              </form>
            </Typography>
          </Box>
        </MUIModal>
      </Stack>
    );
  };

  return (
    <div>
      <Box bgcolor="#FFFFFF" p="0px 10px">
        {isLoading && <LinearProgress />}
        <Button
          type="button"
          onClick={() => { navigate(`/view-company/${company_id}?tab=2`) }}
          variant="text"
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "12px",
            fontWeight: "400",
            color: "#323338",
            width: "100px",
            textTransform: "capitalize",
          }}
          startIcon={<img src={BackIcon} alt="BackIcon" />}
        >
          Regresar
        </Button>
        <Stack
          flexDirection="column"
          gap={1}
          p={2}
          borderBottom="3px solid #DDDDDD"
        >
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "24px",
              fontWeight: "600",
              color: "#1AAFD0",
            }}
          >
            {company?.name}{" "}
            <span
              style={{
                color: "#000",
              }}
            >
              - Contratos
            </span>
          </Typography>
          <Typography>
            <TopBarButtons id={id} />
          </Typography>
        </Stack>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#323338",
                }}
              ></Typography>

              <TreeView govermentActs={contratos} vars={{}} />
            </Grid>
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#323338",
                }}
              >
                {folderName}
              </Typography>
              {contratos?.length > 0 ? (
                <TableContainer component={Paper}>
                  <TableData
                    items={contratos}
                    contratos={contratos}
                    users={users}
                    actors={clientsWithNoAplica}
                    handleDownloadFile={handleDownloadFile}
                    downloadDocument={downloadDocument}
                    handleModalEditFile={handleModalEditFile}
                    handleDeleteFile={handleDeleteFile}
                    id={id}
                    styles={styles}
                  />
                </TableContainer>
              ) : (
                <Typography
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#323338",
                  }}
                >
                  No hay datos
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ModalCreateFolder />
      <ModalCreateFileInmueble />
      <ModalEditFolder />
      <ModalEditFile />
      <ModalDocument />
    </div>
  );
};
