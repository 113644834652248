import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { encrypt_data } from "./../utils/JSImplements";
import { GET, POST, PUT } from "./../utils/AuthService";
import { SwalFire, SwalfireConfirm } from "./../utils";
import { readFile } from "./../utils/AWS";
import { logAction } from "../utils/logAction";
export const UseStoreCompany = create(
  persist(
    (set, get) => ({
      pillars_preview: [],
      companies: [],
      sort_companies: () => {
        set((state) => ({
          companies: [
            ...state.companies.sort((companyA, companyB) =>
              companyA.name.localeCompare(companyB.name)
            ),
          ],
        }));
      },
      get_companies: async (logo = false) => {
        const res = await GET("get-companies");
        if (res?.data?.length) {
          const arr = res?.data;
          if (logo) {
            for (let index = 0; index < arr.length; index++) {
              const element = arr[index];
              if (element?.url_logo) {
                const image = await readFile(
                  "/logos_company",
                  element?.url_logo
                );
                if (image?.statusCode === 200)
                  arr[index] = { ...element, logo: image?.data?.url };
              } else {
                arr[index] = { ...element, logo: "" };
              }
            }
          }

          set({ companies: arr });
        }
      },
      add_company: async (payload, isEdit) => {
        try {
          let company = {};
          let rollback = false;

          if (isEdit) company = await POST("update-company", payload?.company);
          else company = await POST("add-company", payload?.company);

          if (company?.statusCode === 200) {
            //Obtenemos el id  de la compañia a registrar
            const company_id = company?.data?.id;
            if (payload?.domicile_fiscal) {
              //Mandamos a guardar la informacion de direccion
              const address = await POST("add-domicile-fiscal", {
                company_id,
                ...payload?.domicile_fiscal,
              });
              //Validamos si se encuntra algun error al guardar la informacion de la empresa
              if (address?.statusCode !== 200) rollback = address;
            }

            if (payload?.company_administrations?.length) {
              //Se envia a guardar la informacion de los administradores
              const admins = await POST(
                "add-company-admins",
                payload?.company_administrations?.map((x) => ({
                  company_id,
                  ...x,
                }))
              );
              //Validamos si se encuntra algun error al guardar la informacion de la empresa
              if (admins?.statusCode !== 200) rollback = admins;
            }

            if (payload?.company_contacts) {
              //Se envia aguardar la informacion de contacto
              const contact = await POST("add-company-contact", {
                company_id,
                ...payload?.company_contacts,
              });
              //Validamos si se encuntra algun error al guardar la informacion de la empresa
              if (contact?.statusCode !== 200) rollback = contact;
            }

            //Validamos que se hayan asignados pilares
            if (payload?.pillar_selecteds?.length) {
              //Se envia a guarar la informacion de los pelares para la empresa
              const assing = await POST(
                "assign-pillar-company",
                payload?.pillar_selecteds?.map((x) => ({ company_id, ...x }))
              );
              //Validamos si se encuntra algun error al guardar la informacion de la empresa
              if (assing?.statusCode !== 200) rollback = assing;
            }
            if(!isEdit)
            {
              //Inicio de la creacion de la estructura de carpetas
              const folderStructure = [
                {
                  actosGubernamentales: [
                    {
                      subpillar_id: 5,
                      file_name: "Autoridades federales",
                      method: "get-government-acts",
                      children: [
                        {
                          subpillar_id: 5,
                          name: "(SAT) Servicio de Administración Tributaria",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                            {
                              subpillar_id: 5,
                              name: "Declaraciones anuales",
                            },
                            {
                              subpillar_id: 5,
                              name: "Declaraciones mensuales",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(INFONAVIT) Instituto del Fondo Nacional de la Vivienda para los Trabajadores",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(STPS) Secretaría del Trabajo y Previsión Social",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(SEMARNAT) Secretaría de Medio Ambiente y Recursos Naturales",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(FONACOT) Fondo de Fomento y Garantía para el Consumo de los Trabajadores",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(CRE) Comisión Reguladora de Energía",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(INAI) Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(CFE) Comisión Federal de Electricidad",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(INM) Instituto Nacional de Migración",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                        {
                          subpillar_id: 5,
                          name: "(IMSS) Instituto Mexicano del Seguro Social",
                          children: [
                            {
                              subpillar_id: 5,
                              name: "Documentos de identificación",
                            },
                          ],
                        },
                      ],
                    },
                    {
                      subpillar_id: 6,
                      file_name: "Autoridades estatales",
                      method: "get-government-acts",
                      children: [
                        { subpillar_id: 6, name: "Medio ambiente",
                          children: [
                            { subpillar_id: 6, name: "Documentos de identificación" },
                          ],
                        },
                        { subpillar_id: 6, name: "Protección Civil",
                          children: [
                            { subpillar_id: 6, name: "Documentos de identificación" },
                          ],
                        },
                        {
                          subpillar_id: 6,
                          name: "(ISSEM) Instituto de Seguridad Social del Estado de México",
                          children: [
                            { subpillar_id: 6, name: "Documentos de identificación" },
                          ],
                        },
                        {
                          subpillar_id: 6,
                          name: "Secretaría de Finanzas del Estado de México",
                        },
                      ],
                    },
                    {
                      subpillar_id: 7,
                      file_name: "Autoridades municipales",
                      method: "get-government-acts",
                      children: [
                        {
                          subpillar_id: 7,
                          name: "(OAPAS) Organismo Público Descentralizado para la Prestación de los Servicios de Agua Potable, Alcantarillado y Saneamiento",
                        },
                        { subpillar_id: 7, name: "Protección Civil",
                          children: [
                            { subpillar_id: 7, name: "Documentos de identificación" },
                          ],
                        },
                        {
                          subpillar_id: 7,
                          name: "Secretaría de Desarrollo Urbano",
                          children: [
                            { subpillar_id: 7, name: "Documentos de identificación" },
                          ],
                        },
                        { subpillar_id: 7, name: "Medio Ambiente",
                          children: [
                            { subpillar_id: 7, name: "Documentos de identificación" },
                          ],
                        },
                        {
                          subpillar_id: 7,
                          name: "Secretaría de Desarrollo Económico",
                          children: [
                            { subpillar_id: 7, name: "Documentos de identificación" },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  activos: [
                    {
                      subpillar_id: 8,
                      file_name: "Inmuebles",
                      method: "get-assets",
                      children: [],
                    },
                    {
                      subpillar_id: 9,
                      file_name: "Muebles",
                      method: "get-assets",
                      children: [],
                    },
                    {
                      subpillar_id: 10,
                      file_name: "Intangibles",
                      method: "get-assets",
                      children: [],
                    },
                  ],
                },
                {
                  contratos: [
                    {
                      subpillar_id: 11,
                      file_name: "Civil",
                      method: "get-contracts",
                      children: [],
                    },
                    {
                      subpillar_id: 12,
                      file_name: "Mercantil",
                      method: "get-contracts",
                      children: [],
                    },
                    {
                      subpillar_id: 13,
                      file_name: "Administrativo",
                      method: "get-contracts",
                      children: [],
                    },
                    {
                      subpillar_id: 14,
                      file_name: "Laboral",
                      method: "get-contracts",
                      children: [],
                    },
                  ],
                },
              ];
              async function createFolderStructure(structure, companyId, parentId = null, parentMethod = null) {
                for (const category of structure) {
                  for (const [categoryName, folders] of Object.entries(category)) {
                    for (const folder of folders) {
                      const folderData = {
                        subpillar_id: folder.subpillar_id,
                        type: "folder",
                        file_name: folder.file_name || folder.name,
                        company_id: companyId,
                        parent_id: parentId
                      };
              
                      const method = folder.method || parentMethod;
              
                      try {
                        //console.log(`Intentando crear carpeta: ${folderData.file_name}`);
                        const result = await POST(
                          `/${method}/${folder.subpillar_id}/folders`,
                          folderData,
                          "actosgubernamentales"
                        );
                        
                      // console.log(`Carpeta creada: ${folderData.file_name}`, result);
              
                        if (folder.children && folder.children.length > 0) {
                          await createFolderStructure(
                            [{ [categoryName]: folder.children }],
                            companyId,
                            result.data.id,
                            method // Propagamos el método actual a los hijos
                          );
                        }
                      } catch (error) {
                        console.error(`Error al crear la carpeta ${folderData.file_name}:`, error);
                        console.error(`URL intentada: /${method}/${folder.subpillar_id}/folders`);
                      }
                    }
                  }
                }
              }
              
              // Función principal para iniciar la creación de la estructura
              async function setupCompanyStructure(companyId) {
                try {
              //   console.log(`Iniciando configuración para la compañía con ID: ${companyId}`);
                  await createFolderStructure(folderStructure, companyId);
                //  console.log('Configuración de la estructura de carpetas completada');
                } catch (error) {
                //  console.error('Error durante la configuración de la estructura:', error);
                  throw error; // Re-lanza el error para manejarlo en el nivel superior
                }
              }
              //
              try {
                await setupCompanyStructure(company_id);
                //console.log('Estructura de la compañía configurada exitosamente');
              } catch (error) {
              // console.error('Error al configurar la estructura de la compañía:', error);
                rollback = { statusCode: 500, message: 'Error al configurar la estructura de carpetas' };
              }
              //Fin de la creacion de la estructura de carpetas
              //Inicio asignación de permisos a todos los usuarios ad ministradores o con role_id 1 
              const users = await GET(`get-users`);
              console.log(users);
              const section = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

              if (users?.statusCode === 200) {
                const usersAdmin = users?.data.filter((x) => x?.rol_id === 1);
                console.log("Users",users);
                console.log("Users Admin",usersAdmin);
                const payload = usersAdmin.flatMap(user => 
                  section.map(sectionId => ({
                    id: sectionId, // section_id
                    user_id: user.id,
                    companie_id: company_id,
                    module: "",
                    section: "",
                    view: true,
                    add: true,
                    edit: true,
                    delete: true,
                    download: true,
                  }))
                );
                const permissions = await POST("asign-permissions-user", payload);
                if (permissions?.statusCode !== 200) rollback = permissions;
              }
            }
            if (rollback){ SwalFire("Atencion!", rollback?.message, "warning");}
            else {
              SwalFire("Bien!", company?.message, "success");
            }
          } else {
            SwalFire("Atencion!", company?.message, "warning");
          }
          if(company?.statusCode === 200){
            if(isEdit){
              await logAction( "company", company?.data.id, "update", "Se actualizó la compañia ");
            }
            else{
              await logAction( "company", company?.data.id, "add", "Se agregó la compañia: ");
            }
          }
        } catch (error) {
          console.log(`ha ocurrido un error al guardar la empresa`);
        }
      },
      get_company: async (company_id) => {
        const company = await GET(`get-company/${company_id}`);

        if (company?.statusCode === 200) {
          return company?.data;
        } else {
          SwalFire("Atencion!", company?.message, "warning");
          return null;
        }
      },
      update_pillars_preview: async (data, active, arrPillars = []) => {
        const { pillars_preview } = get();

        if (data === null && arrPillars?.length > 0) {
          const new_pillars_preview = arrPillars.map((x, i) => {
            const subpillars = x?.sub_pillars;
            const new_sub_pillars = subpillars.map((y) => ({ active, ...y }));
            x.sub_pillars = new_sub_pillars;
            return x;
          });
          set({ pillars_preview: new_pillars_preview });
        } else {
          //Clonamos  para hacer inmutabilidad
          const new_pillars_preview = structuredClone(pillars_preview);

          const pillar = new_pillars_preview?.find(
            (x) => x?.id === data?.pillar_id
          );
          const pillarIndex = new_pillars_preview?.findIndex(
            (x) => x?.id === data?.pillar_id
          );

          //Consultamos si ya hemos modificado una fila para actualizar el valor y no agregar uno nuevo a la lista de asignaciones
          const subpillar = pillar?.sub_pillars?.find(
            (row) =>
              row?.pillar_id === pillar?.id && row?.id === data?.subpillar_id
          );
          const subpillarIndex = pillar?.sub_pillars?.findIndex(
            (row) => row?.id === data?.subpillar_id
          );

          if (subpillarIndex >= 0) {
            const newPillar = {
              ...pillar,
            };

            newPillar.sub_pillars[subpillarIndex] = {
              ...subpillar,
              active,
            };

            new_pillars_preview[pillarIndex] = newPillar;

            //Actualizamos el estado de Zustand
            set({ pillars_preview: new_pillars_preview });
          }
        }

        return pillars_preview;
      },
      delete_company: async (company_id) => {
        try {
          const resSwal = await SwalfireConfirm(
            "¿Está seguro que desea eliminar la compañia?"
          );
          if (resSwal) {
            const res = await PUT(`deactivate-company/${company_id}`);
            if (res?.statusCode === 200) {
              await logAction( "company", company_id, "delete", "Se eliminó la compañia: " + company_id);
              return true;
            } else return false;
          }
        } catch (error) {
          await logAction("company", company_id, "delete", "Ocurrio un error al eliminar la compañia");
          console.log(`Ocurrio un error al eliminar la compañia`);
        }
      },
    }),
    {
      name: encrypt_data("company_storage"),
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
