import { create } from "zustand";

export const useAlertStore = create((set) => ({
  open: false,
  message: "",
  options: { type: "default", autoClose: false },
  handleOpen: () => {
    set({ open: true });
  },
  handleClose: () => {
    set({ open: false });
  },
  alertTool: (message, options) => {
    set({ message, options, open: true });
  },
}));
