import * as React from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

export const MUIModal = ({
    children, 
    open,
    handleClose,
    title,
    maxWidth, 
    buttonTop,
    closeOnlyButton
}) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const styles = {
    typography: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "20",
      fontWeight: "600",
      color: "#323338",
      "@media (max-width: 790px)": {
        fontSize: "24px",
      },
      "@media (max-width: 590px)": {
        fontSize: "20px",
      },
    }
  }

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth={maxWidth || 'md'}
        open={open}
        onClose={closeOnlyButton ? handleClose : null}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={styles.typography}>
            {title}
            {buttonTop && 
              <Button autoFocus onClick={handleClose} startIcon={<CloseIcon />}>Cerrar</Button>
            }
          </Box>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions >
          {!buttonTop && <Button autoFocus onClick={handleClose} startIcon={<CloseIcon />}>
            Cerrar
          </Button>}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
