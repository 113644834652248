import React, { useEffect, useState } from "react";
import { Box, Button, Hidden, Stack, TextField,Grid, Table, TableBody, TableContainer, TableCell,TableHead,TableRow,Paper, Tooltip, LinearProgress, Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FolderIcon from "@mui/icons-material/Folder";
import { set } from "react-hook-form";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import { InputAutocompleteAdapter } from "../../Common/MUIAutoComplete";
import { Input } from "../../Common/MUInput";
import { MUIModal } from "../../Common/MUIModal";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../assets/icons/backIcon.svg";
import { InputFile } from "../../Common/MUIInputFile";
import { RenderPdf } from "../../Common/RenderPdf";
import { ImagePreview } from "../../Common/ImagePreview";
import { readFile } from "../../../utils/AWS";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { ButtonGroup } from "react-bootstrap";
import Swal from "sweetalert2";
import ImageIcon from '@mui/icons-material/Image';

export const PilaresV = ({
  vars: {
    company,
    pillars_in_company,
    isLoading,
    company_id,
    pillar,
    control,
    control2,
    register2,
    register,
    govermentActs,
    setValue,
    styles,
    users,
    errors,
    setValueForm,
    register3,
    control3,
    setValue3,
    setValue4,
    control4,
    register4,
    actors,
    openFolder,
    openFile,
    openEditFile,
    nameDocument,
    responsible,
    validity,
    physicalFileLocation,
    fileId,
    client,
    actorsWithNoAplica,
    clientsWithNoAplica,
    usersWithNoAplica,
    folderName,
    openEditFolder,
    subPillarId,
    id,
    permisos,
    expandedItems
  },
  funcs: {
    handleClickPreviewPillars,
    OnSubmit,
    handleSubmit,
    OnSubmit2,
    handleSubmit2,
    handleDeleteFolder,
    handleDeleteFile,
    handleSubmit3,
    OnSubmitUpdateFolder,
    OnSubmitUpdateFile,
    handleSubmit4,
    setSubPillar,
    handleOpenFolder,
    handleCloseFolder,
    handleOpenFile,
    handleCloseFile,
    handleModalEditFile,
    handleCloseEditFile,
    setResponsible,
    setFileId,
    setClient,
    setFolderName,
    handleCloseEditNameFolder,
    handleModalEditNameFolder,
    setSubPillarId,
    setId,
    setIsloading,
    setExpandedItems
  },
}) => {
  const navigate = useNavigate();
  const [govermentActs2, setGovermentActs2] = useState([]);
  const [parentId, setParentId] = React.useState("");
  const [lastSelectedItem, setLastSelectedItem] = React.useState(null);

  //const para la edición de archivos
  

  const [type, setType] = React.useState(null);
  
  const [nameDoc, setNameDoc] = React.useState("");
  const [shareLink, setShareLink] = React.useState("");

  useEffect(() => {
    if (id) {
      setParentId(id);
      setValue("parent_id", id); // Establecer el valor de parent_id en el formulario
      setValue("subpillar_id", subPillarId);
      setValueForm("parent_id", id); // Establecer el valor de parent_id en el formulario
      setValueForm("subpillar_id", subPillarId);
      setValue3("folder_id", id);

      setValue4("file_name", nameDocument);
      setValue4("responsible", responsible);
      setValue4("validity", validity);
      setValue4("physical_file_location", physicalFileLocation);
      setValue4("client", client);

      setGovermentActs2(govermentActs);
      const fetchShareLink = async () => {
        if (nameDoc) {
          const sha_link = await readFile("/Actos_gubernamentales", nameDoc);
          if (sha_link?.statusCode === 200) {
            setShareLink(sha_link);
            govermentActs.map((x) => {
              if (x.id == id) {
                setFolderName(x.file_name);
                setSubPillarId(x.subpillar_id);
                setValue3("subpillar_id", x.subpillar_id);
                setValue3("file_name", x.file_name);
                setValue4("subpillar_id", subPillarId);
                setSubPillar(x.subpillar_id);
              }
            });
          } else {
            console.log("Error al obtener el link del archivo", nameDoc);
          }
        }
      };
      fetchShareLink();
    }
  }, [
    id,
    setValue,
    govermentActs,
    parentId,
    setValueForm,
    nameDoc,
    subPillarId,
    setValue3,
    setValue4,
    nameDocument,
    responsible,
    validity,
    physicalFileLocation,
  ]);

  

  const ModalEditNameFolder = () => {
    return (
      <MUIModal
        open={openEditFolder}
        handleClose={handleCloseEditNameFolder}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        buttonTop={true}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" my={2}>
            Editar carpeta {folderName}
          </Typography>
          <form onSubmit={handleSubmit3(OnSubmitUpdateFolder)}>
            <input
              type="hidden"
              onChange={(e) => setId(e.target.value)}
              value={id}
              {...register3("folder_id", {})}
            />
            <input
              type="hidden"
              onChange={(e) => setSubPillarId(e.target.value)}
              value={subPillarId}
              {...register3("subpillar_id", {})}
            />
            <Input
              name={`file_name`}
              control={control3}
              props={{
                onChange: (e) => setFolderName(e.target.value),
                size: "small",
                fullWidth: true,
                label: "Nombre de la carpeta",
                autoComplete: "none",
                required: true,
                value: folderName,
              }}
            />

            <Button sx={styles.buttonSave} type="submit"
            disabled={isLoading}
            >
              {isLoading ? "Actualizando..." : "Actualizar"}
            </Button>
          </form>
        </Box>
      </MUIModal>
    );
  };

  const permisoEditarFederal = permisos.some(permiso => permiso.section_id === 6 && permiso?.edit);
  const permisoEditarEstatal = permisos.some(permiso => permiso.section_id === 7 && permiso?.edit);
  const permisoEditarMunicipal = permisos.some(permiso => permiso.section_id === 8 && permiso?.edit);

  const permisoEliminarFederal = permisos.some(permiso => permiso.section_id === 6 && permiso?.delete);
  const permisoEliminarEstatal = permisos.some(permiso => permiso.section_id === 7 && permiso?.delete);
  const permisoEliminarMunicipal = permisos.some(permiso => permiso.section_id === 8 && permiso?.delete);

  const permisoCrearFederal = permisos.some(permiso => permiso.section_id === 6 && permiso?.add);
  const permisoCrearEstatal = permisos.some(permiso => permiso.section_id === 7 && permiso?.add);
  const permisoCrearMunicipal = permisos.some(permiso => permiso.section_id === 8 && permiso?.add);

  const permisoDescargarFederal = permisos.some(permiso => permiso.section_id === 6 && permiso?.download);
  const permisoDescargarEstatal = permisos.some(permiso => permiso.section_id === 7 && permiso?.download);
  const permisoDescargarMunicipal = permisos.some(permiso => permiso.section_id === 8 && permiso?.download);

 
  const ModalEditDocument = () => {
    return (
      <MUIModal
        open={openEditFile}
        handleClose={handleCloseEditFile}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        buttonTop={true}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar archivo {nameDocument} {id}
          </Typography>
          <form onSubmit={handleSubmit4(OnSubmitUpdateFile)}>
            <Input
              name={`file_name`}
              control={control4}
              props={{
                size: "small",
                fullWidth: true,
                label: "Nombre del archivo",
                autoComplete: "none",
                required: true,
                value: nameDocument,
              }}
            />
            <Typography sx={styles.label} pr={1}>
              Responsable
            </Typography>
            <InputAutocompleteAdapter
              disabled={false}
              size={"small"}
              mb={0}
              fullWidth={true}
              height={40}
              label={""}
              name="responsible"
              control={control4}
              options={
                users?.map((x) => ({
                  value: x?.id,
                  label: `${x?.names} ${x?.last_names} - ${x?.charge}`,
                })) || []
              }
              value={responsible}
              onChange={(e) => setResponsible(e.target.value)}
            />
            <Typography sx={styles.label} pr={1}>
              Periodicidad / Vigencia
            </Typography>
            <Input
              name={`validity`}
              control={control4}
              props={{
                size: "small",
                fullWidth: true,
                label: "Periodicidad / Vigencia",
                autoComplete: "none",
                required: true,
                value: validity,
              }}
            />
            <Typography sx={styles.label} pr={1}>
              Ubicación física
            </Typography>
            <Input
              name={`physical_file_location`}
              control={control4}
              props={{
                size: "small",
                fullWidth: true,
                label: "Ubicación física",
                autoComplete: "none",
                required: true,
                value: physicalFileLocation,
              }}
            />
            <Typography sx={styles.label} pr={1}>
                Archivo
              </Typography>

              <InputFile
                name="file"
                label={"Elegir archivo"}
                control={control4}
                props={{
                  required: false,
                  error: errors?.file ? true : false,
                  helperText: errors?.file?.message,
                }}
              />
            <Typography sx={styles.label} pr={1}>
              Actor relacionado
            </Typography>
            <InputAutocompleteAdapter
              disabled={false}
              size={"small"}
              mb={0}
              fullWidth={true}
              height={40}
              label={"Actor relacionado"}
              name="client"
              control={control4}
              options={[
                ...(actorsWithNoAplica?.map((x) => ({
                  // agregar una oṕción No aplica con value 0
                  value: x?.id,
                  label: `${x?.name} - ${x?.description}`,
                })) || []),
              ]}
              value={client}
              onChange={(e) => setClient(e.target.value)}
            />
            <input
              type="hidden"
              onChange={(e) => setFileId(e.target.value)}
              value={fileId}
              {...register4("id", {})}
            />
            <input
              type="hidden"
              onChange={(e) => setSubPillarId(e.target.value)}
              value={subPillarId}
              {...register4("subpillar_id", {})}
            />
            <Button sx={styles.buttonSave} type="submit"
             disabled={isLoading}>
              Actualizar
            </Button>
          </form>
        </Box>
      </MUIModal>
    );
  };

  const [openFileView, setOpenFileView] = useState({open: false, type: false});
  const handleOpenFileView = async (file_id) => {
    setOpenFileView({open: true});
  };

  const handleCloseFileView = () => {
    setOpenFileView({open: false, type: false});
    //reset form values
    set({ folder_name: "", parent_id: id });
    setNameDoc("");
    setShareLink("");
  };

  const handleDownloadFile = async (file_id, download = false) => {
    setIsloading(true);
    let localNameDoc  = ""; 
    govermentActs2.map((x) => {
      if (x.id === file_id) {
        let isPDF = x.file_url.toLowerCase().endsWith('.pdf');
        setOpenFileView({open: true, type: !isPDF});
        setNameDoc(x.file_url);
        localNameDoc  = x.file_url;
      }
    });

    const fetchShareLink = async () => {
      if (localNameDoc !== "") {
        const sha_link = await readFile("/Actos_gubernamentales", localNameDoc);
        if (sha_link?.statusCode === 200) {
          setShareLink(sha_link);
          if (download) {
            window.open(sha_link?.data?.url, "_blank");
          } else {
            const isPDF = localNameDoc.toLowerCase().endsWith('.pdf');
            handleOpenFileView(file_id);
            setOpenFileView({open: true, type: !isPDF});
          }
        } else {
          Swal.fire({ icon: "error", title: "Error al obtener el documento, verifique su conexión de internet" });
          console.log("Error al obtener el link del archivo");
        }
      }
    };
    fetchShareLink();
    setIsloading(false);
  };

  const downloadDocument = async (file_id) => {
    govermentActs2.map((x) => {
      if (x.id === file_id) {
        setNameDoc(x.file_url);
      }
    });

    const file = govermentActs2.find((x) => x.id === file_id);
    if (file) {
      setNameDoc(file.file_url);
      const updatedNameDoc = file.file_url; // aqui que lo tome directamente
      const sha_link = await readFile("/Actos_gubernamentales", updatedNameDoc);
      if (sha_link?.statusCode === 200) {
        setShareLink(sha_link);
        window.open(sha_link?.data?.url, "_blank");
      } else {
        console.log("Error al obtener el link del archivo");
      }
    }
    handleDownloadFile(file_id, true);
  };

  const extractDigits = (str) => {
    const regex = /[0-9]/g;
    const digits = str.match(regex);
    return digits ? digits.join("") : "";
  };

  const handleItemSelectionToggle = (
    event,
    itemId,
    isSelected,
    label,
    className,
    node
  ) => {
    if (isSelected) {
      setLastSelectedItem(itemId, label, node);
      setType(itemId.substring(0, 2));
      setId(extractDigits(itemId));
      setParentId(extractDigits(itemId));
      setFolderName(parentId);
      govermentActs2.map((x) => {
        if (x.id == extractDigits(itemId)) {
          setFolderName(x.file_name);
          setId(x.id);
          setSubPillarId(x.subpillar_id);
        }
      });
    }
  };

  const style = {
    position: "relative",
    bgcolor: "background.paper",
    p: 2,
  };

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  // const buildTree = (subpillarsChild2) => {
  //   subpillarsChild2 = Array.from(subpillarsChild2);
  //   const rootItems = [];
  //   const lookup = {};

  //   subpillarsChild2.forEach((item) => {
  //     lookup[item.id] = { ...item, children: [] };
  //   });

  //   subpillarsChild2.forEach((item) => {
  //     if (item.parent_id && item.type !== "file") {
  //       lookup[item.parent_id].children.push(lookup[item.id]);
  //     } else if (!item.parent_id && item.type !== "file") {
  //       rootItems.push(lookup[item.id]);
  //     }
  //   });
  //   return rootItems;
  // };
  const buildTree = (subpillarsChild2) => {
    subpillarsChild2 = Array.from(subpillarsChild2);
    const rootItems = [];
    const lookup = {};

    // Crear el lookup con los items
    subpillarsChild2.forEach((item) => {
      lookup[item.id] = { ...item, children: [] };
    });

    // Asignar los items a sus padres o a la raíz
    subpillarsChild2.forEach((item) => {
      if (item.parent_id && item.type !== "file") {
        lookup[item.parent_id].children.push(lookup[item.id]);
      } else if (!item.parent_id && item.type !== "file") {
        rootItems.push(lookup[item.id]);
      }
    });

    // Función para ordenar alfabéticamente por file_name
    const sortAlphabetically = (a, b) => {
      return a.file_name.localeCompare(b.file_name);
    };

    // Ordenar recursivamente los items y sus hijos
    const sortItems = (items) => {
      items.forEach((item) => {
        if (item.children.length > 0) {
          sortItems(item.children);
        }
      });
      items.sort(sortAlphabetically);
    };

    // Ordenar los items raíz y sus hijos
    sortItems(rootItems);

    return rootItems;
  };

  const treeData = buildTree(govermentActs);

  const buildTreeItems = (nodes) => {
    return nodes.map((node) => (
      <TreeItem
        key={node.type + node.id}
        sx={{ verticalAlign: "middle" }}
        itemId={node.type + node.id}
        label={
          <>
            {node.type === "folder" ? (
              <FolderIcon sx={{ verticalAlign: "middle" }} />
            ) : (
              <AttachFileIcon sx={{ verticalAlign: "middle" }} />
            )}
            <span> </span>
            {node.file_name}
          </>
        }
        {...node}
      >
        {node.children && node.children.length > 0
          ? buildTreeItems(node.children)
          : null}
      </TreeItem>
    ));
  };

  const FolderName = () => {
    return (
      <Typography
        sx={{
          fontFamily: "Poppins-SemiBold",
          fontSize: "20px",
          fontWeight: "600",
          color: "#323338",
          display: "inline",
        }}
      >
        {folderName}
      </Typography>
    );
  };

  const MyTreeView = () => {
    return (
      <div>
        <SimpleTreeView
          onItemSelectionToggle={handleItemSelectionToggle}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "14px",
            fontWeight: "400",
            color: "#323338",
          }}
        >
          {buildTreeItems(treeData)}
        </SimpleTreeView>
      </div>
    );
  };
  const MyTable = ({
    govermentActs2,
    users,
    actors,
    handleDownloadFile,
    downloadDocument,
    handleModalEditFile,
    handleDeleteFile,
    id,
    styles,
  }) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState("");

    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const sortedRows = (rows, comparator) => {
      const stabilizedThis = rows.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order, orderBy) => {
      return order === "desc"
        ? (a, b) => -descendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };

    const filteredData = govermentActs2?.filter(
      (row) => {
       
      const responsible = users?.find((x) => x.id === row.responsible_id);
      const client = actors?.find((x) => x.id === row.client);
      const fileName = row.file_name ? row.file_name.toLowerCase() : "";
      const responsibleName = responsible
        ? `${responsible.names} ${responsible.last_names}`.toLowerCase()
        : "";
      const clientName = client
        ? `${client.name} - ${client.description}`.toLowerCase()
        : "";
      const validity = row.validity ? row.validity.toLowerCase() : "";
      const physicalFileLocation = row.physical_file_location
        ? row.physical_file_location.toLowerCase()
        : "";
        return (
          row.type === "file" &&
          row.parent_id == id &&
          (fileName.includes(filter.toLowerCase()) ||
            responsibleName.includes(filter.toLowerCase()) ||
            clientName.includes(filter.toLowerCase()) ||
            validity.includes(filter.toLowerCase()) ||
            physicalFileLocation.includes(filter.toLowerCase()))
        );
      }
    );

    const sortedData = sortedRows(filteredData || [], getComparator(order, orderBy));

    const renderSortIcon = (column) => {
      if (orderBy !== column) return null;
      return order === "desc" ? (
        <ArrowDownwardIcon fontSize="small" />
      ) : (
        <ArrowUpwardIcon fontSize="small" />
      );
    };
    const columnTitles = {
      file_name: "Archivo",
      responsible_id: "Responsable",
      validity: "Periodicidad",
      physical_file_location: "Ubicación Física",
      client: "Actor relacionado",
    };



    return (
      <>
        <TextField
        label="Buscar..."
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(e) => setFilter(e.target.value)}
      />
     
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {[
                "file_name",
                "responsible_id",
                "validity",
                "physical_file_location",
                "client",
              ].map((column) => (
                <TableCell
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#323338",
                    ":hover": {
                      cursor: "pointer",
                      color: "#1AAFD0",
                    },
                    ":transition": "all 0.9s",

                  }}
                  key={column}
                  label={column}
                  align="center"
                  sortDirection={orderBy === column ? order : false}
                  onClick={() => handleRequestSort(column)}
                >
                  {columnTitles[column]}
                  {renderSortIcon(column)}
                </TableCell>
              ))}
              <TableCell sx={styles.tableCell} align="center">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                <TableCell component="th" scope="row">
                  {row.file_name}
                </TableCell>
                <TableCell align="left">
                  {usersWithNoAplica?.find((x) => x.id === row.responsible_id)?.names +
                    " " +
                    usersWithNoAplica?.find((x) => x.id === row.responsible_id)
                      ?.last_names || ""}
                </TableCell>
                <TableCell align="left">{row.validity}</TableCell>
                <TableCell align="left">{row.physical_file_location}</TableCell>
                <TableCell align="left">
                  {actorsWithNoAplica?.find((x) => x.id === row.client)?.name +
                    " - " +
                    actorsWithNoAplica?.find((x) => x.id === row.client)?.description ||
                    "No Aplica"}
                </TableCell>
                <TableCell align="right">
                  <ButtonGroup variant="outlined">
                    <Tooltip title="Ver Documento">
                      <Button onClick={() => handleDownloadFile(row.id)}>
                        {row.file_url.toLowerCase().endsWith('.pdf') ? <PictureAsPdfIcon sx={{ fontSize: 35 }} /> : <ImageIcon sx={{ fontSize: 35 }} />}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Descargar Documento">
                      <Button onClick={() => downloadDocument(row.id)}
                        disabled = {
                          !(
                            (subPillarId === 5 && permisoDescargarFederal) ||
                            (subPillarId === 6 && permisoDescargarEstatal) ||
                            (subPillarId === 7 && permisoDescargarMunicipal)
                          )
                        }
                      >
                        <DownloadIcon sx={{ fontSize: 35 }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Editar Documento">
                      <Button
                        onClick={() =>
                          handleModalEditFile(row.subpillar_id, row.id)
                        }
                        disabled = {
                          !(
                            (subPillarId === 5 && permisoEditarFederal) ||
                            (subPillarId === 6 && permisoEditarEstatal) ||
                            (subPillarId === 7 && permisoEditarMunicipal)
                          )
                        }
                      >
                        <EditIcon sx={{ fontSize: 35 }} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Eliminar Documento">
                      <Button
                        onClick={() =>
                          handleDeleteFile(row.subpillar_id, row.id)
                        }
                        disabled = {
                          !(
                            (subPillarId === 5 && permisoEliminarFederal) ||
                            (subPillarId === 6 && permisoEliminarEstatal) ||
                            (subPillarId === 7 && permisoEliminarMunicipal)
                          )
                        }
                      >
                        <DeleteForeverIcon
                          sx={{ fontSize: 35 }}
                        />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </>
    );
  };

  return (
    <Box bgcolor="#FFFFFF" p="0px 10px">
      {isLoading && <LinearProgress />}
      <Button
        type="button"
        onClick={() => navigate(`/view-company/${company_id}?tab=2`)}
        variant="text"
        sx={{
          fontFamily: "Poppins-Regular",
          fontSize: "12px",
          fontWeight: "400",
          color: "#323338",
          width: "100px",
          textTransform: "capitalize",
        }}
        startIcon={<img src={BackIcon} alt="BackIcon" />}
      >
        Regresar
      </Button>
      <Stack
        flexDirection="column"
        gap={1}
        p={2}
        borderBottom="3px solid #DDDDDD"
      >
        <Typography
          sx={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            color: "#1AAFD0",
            textAlign: "left",
          }}
        >
          {company?.name} -{" "}
          <span
            style={{
              color: "#000",
            }}
          >
            Actos Gubernamentales
          </span>
        </Typography>
        <Typography>
          {(subPillarId === 5 ) || (subPillarId === 6  ) || (subPillarId === 7 ) ? (
            <>
              <Button onClick={handleOpenFolder}
                disabled = {
                  !(
                    (subPillarId === 5 && permisoCrearFederal) ||
                    (subPillarId === 6 && permisoCrearEstatal) ||
                    (subPillarId === 7 && permisoCrearMunicipal)
                  )
                }
              >
                Crear Carpeta
                <CreateNewFolderIcon sx={{ marginLeft: 1 }} />{" "}
              </Button>
              <Button
                onClick={() => handleDeleteFolder(subPillarId, id, company_id)}
                disabled = {
                  !(
                    (subPillarId === 5 && permisoEliminarFederal) ||
                    (subPillarId === 6 && permisoEliminarEstatal) ||
                    (subPillarId === 7 && permisoEliminarMunicipal)
                  )
                }
              >
                Eliminar Carpeta
                <FolderDeleteIcon sx={{ marginLeft: 1 }} />
              </Button>
              <Button onClick={handleOpenFile}
                disabled = {
                  !(
                    (subPillarId === 5 && permisoCrearFederal) ||
                    (subPillarId === 6 && permisoCrearEstatal) ||
                    (subPillarId === 7 && permisoCrearMunicipal)
                  )
                }
              >
                Cargar Archivo
                <AttachFileIcon sx={{ marginLeft: 1 }} />{" "}
              </Button>
              <Button onClick={() => handleModalEditNameFolder(id)}
                disabled = {
                  !(
                    (subPillarId === 5 && permisoEditarFederal) ||
                    (subPillarId === 6 && permisoEditarEstatal) ||
                    (subPillarId === 7 && permisoEditarMunicipal)
                  )
                }
              >
                Editar Carpeta <EditIcon />
              </Button>
            </>
          ) : type === "fo" ? (
            <>
              <Typography>
                Selecciona una carpeta para ver las opciones
              </Typography>
            </>
          ) : (
            <></>
          )}
        </Typography>
      </Stack>
      <Stack>
        <MUIModal
          open={openFolder}
          handleClose={handleCloseFolder}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Crear Carpeta en <FolderName />
            </Typography>
            <Typography>Nombre de la carpeta</Typography>
            <form onSubmit={handleSubmit2(OnSubmit2)}>
              <input
                type="hidden"
                onChange={(e) => setParentId(e.target.value)}
                value={parentId}
                {...register2("parent_id", {})}
              />
              <input
                type="hidden"
                onChange={(e) => setSubPillarId(e.target.value)}
                value={subPillarId}
                {...register2("subpillar_id", {})}
              />
              <Input
                name={`folder_name`}
                control={control2}
                props={{
                  size: "small",
                  fullWidth: true,
                  label: "Nombre de la carpeta",
                  autoComplete: "none",
                  required: true,
                }}
              />
              <Button sx={styles.buttonSave} type="submit"
               disabled={isLoading}>
                Crear
              </Button>
            </form>
          </Box>
        </MUIModal>
        <MUIModal
          open={openFile}
          handleClose={handleCloseFile}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          buttonTop={true}
        >
        
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h5"
              sx={{ margin: "10px 0" }}
            >
              Cargar Archivo en <FolderName />
            </Typography>

            <form onSubmit={handleSubmit(OnSubmit)}>
            <Typography sx={styles.label} pr={1}>
                Nombre del archivo
              </Typography>
              <Input
                name={`file_name`}
                control={control}
                props={{
                  size: "small",
                  fullWidth: true,
                  label: "Nombre del archivo",
                  autoComplete: "none",
                  required: true,
                }}
              />
              <Typography sx={styles.label} pr={1}>
                Responsable
              </Typography>
              <InputAutocompleteAdapter
                disabled={false}
                size={"small"}
                mb={0}
                fullWidth={true}
                height={40}
                label={""}
                name="responsible"
                control={control}
                options={
                  users?.map((x) => ({
                    value: x?.id,
                    label: `${x?.names} ${x?.last_names} - ${x?.charge}`,
                  })) || []
                }
              />
              <Typography sx={styles.label} pr={1}>
                Periodicidad / Vigencia
              </Typography>
              <Input
                name={`validity`}
                control={control}
                props={{
                  size: "small",
                  fullWidth: true,
                  label: "Periodicidad / Vigencia",
                  autoComplete: "none",
                  required: true,
                }}
              />
              <Typography sx={styles.label} pr={1}>
                Ubicación física
              </Typography>
              <Input
                name={`physical_file_location`}
                control={control}
                props={{
                  size: "small",
                  fullWidth: true,
                  label: "Ubicación física",
                  autoComplete: "none",
                  required: true,
                }}
              />
              <Typography sx={styles.label} pr={1}>
                Archivo
              </Typography>

              <InputFile
                name="file"
                label={"Elegir archivo"}
                control={control}
                props={{
                  error: errors?.shareholding_structure ? true : false,
                  helperText: errors?.shareholding_structure?.message,
                }}
              />
              <Typography sx={styles.label} pr={1}>
                Actor relacionado
              </Typography>
              <InputAutocompleteAdapter
                disabled={false}
                size={"small"}
                mb={0}
                fullWidth={true}
                height={40}
                label={""}
                name="client"
                control={control}
                options={[
                  ...(actorsWithNoAplica?.map((x) => ({
                    
                    // agregar una oṕción No aplica con value 0
                    value: x?.id,
                    label: `${x?.name} - ${x?.description}`,
                  })) || []),
                ]}
              />
              <input
                type="hidden"
                onChange={(e) => setParentId(e.target.value)}
                value={parentId}
                {...register("parent_id", {})}
              />
              <input type="hidden" onChange={(e) => setSubPillarId(e.target.value)} value={subPillarId} {...register("subpillar_id", {})} />                
              <Button sx={styles.buttonSave} type="submit"
               disabled={isLoading}>
                {isLoading ? "Cargando..." : "Cargar"}
              </Button>
            </form>
          </Box>
        </MUIModal>

        <MUIModal
          open={openFileView.open}
          handleClose={handleCloseFileView}
          buttonTop={true}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title2" variant="h6" component="h2">
              Ver Archivo
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/* Aqui se renderiza el archivo PDF */}
              {openFileView.type === true ? (
                <ImagePreview url={shareLink?.data?.url} />
              ) : (
                <RenderPdf url={shareLink?.data?.url} />
              )}
              <Typography>{nameDoc}</Typography>
            </Typography>
          </Box>
        </MUIModal>
      </Stack>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "20px",
                fontWeight: "600",
                color: "#323338",
              }}
            ></Typography>
            <Box>{govermentActs?.length > 0 && <MyTreeView />}</Box>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{
                fontFamily: "Poppins-SemiBold",
                fontSize: "20px",
                fontWeight: "600",
                color: "#323338",
              }}
            >
              <FolderName />
            </Typography>
            {/* Si id cambiar repintar la tabla  */}
            {govermentActs?.length > 0 ? (
              <MyTable
                govermentActs2={govermentActs}
                users={users}
                actors={actorsWithNoAplica}
                handleDownloadFile={handleDownloadFile}
                downloadDocument={downloadDocument}
                handleModalEditFile={handleModalEditFile}
                handleDeleteFile={handleDeleteFile}
                id={id}
                styles={styles}
              />
            ) : (
              <Typography>Seleccione una carpeta</Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      <>
        <ModalEditDocument />
        <ModalEditNameFolder />
      </>
    </Box>
  );
};
