import React, { useState } from "react";
import { Controller, useController } from "react-hook-form";
import { Button, TextField, Box, Typography } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import { SwalFire } from "./../../utils";

export const InputFile = ({
  label,
  onClick,
  name,
  control,
  props,
  value,
  activateColor = false,
}) => {
  const { field } = useController({
    name,
    control,
    rules: { required: false },
  });

  const [file, setFile] = useState(false);

  const validateFile = (file) => {
    const maxSizeMB = 35;
    if (file.size > maxSizeMB * 1024 * 1024) {
      SwalFire(
        "Advertencia",
        "El tamaño del archivo debe ser de 35 MB como máximo.",
        "warning"
      );
      return false;
    }

    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    if (!allowedTypes.includes(file.type)) {
      SwalFire(
        "Advertencia",
        "Solo se permiten archivos PDF, PNG y JPG.",
        "warning"
      );
      return false;
    }

    return true;
  };

  return (
    <Box>
      <Button
        component="label"
        variant="outlined"
        startIcon={
          field.value === "" ? (
            <CloudUploadOutlinedIcon />
          ) : file || value || field.value ? (
            <CloudDoneOutlinedIcon />
          ) : (
            <CloudUploadOutlinedIcon />
          )
        }
        onClick={onClick}
        sx={{
          color: "#323338",
          ...((file || value || field.value) && activateColor
            ? { background: "#1AAFD0", color: "#FFFF" }
            : {}),
          fontFamily: "Poppins-Regular",
          fontSize: "14px",
          fontWeight: "400",
          textTransform: "capitalize",
          border: "1px solid #666F88",
          ":hover": {
            background: "#1AAFD0",
            color: "#FFFF",
          },
        }}
      >
        {label}
        <Controller
          name={name}
          control={control}
          render={() => (
            <TextField
              sx={{
                clip: "rect(0 0 0 0)",
                clipPath: "inset(50%)",
                height: 1,
                overflow: "hidden",
                position: "absolute",
                bottom: 0,
                left: 0,
                whiteSpace: "nowrap",
                width: 1,
              }}
              inputRef={field.ref}
              onChange={(e) => {
                const selectedFile = e.target.files[0];

                if (selectedFile && validateFile(selectedFile)) {
                  field.onChange(selectedFile);
                  setFile(!file);
                } else {
                  e.target.value = null;
                }
              }}
              type="file"
              accept=".pdf,.png,.jpg,image/jpeg"
              required={props?.required}
              disabled={props?.disabled}
            />
          )}
        />
      </Button>
      {props?.error && (
        <Typography fontSize={11} color="error">
          {props?.helperText}
        </Typography>
      )}
    </Box>
  );
};
