import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { GET, POST, PUT, DELETE } from "../utils/AuthService";
import { SwalFire } from "../utils";
import { SwalfireConfirm } from "../utils";
import { logAction } from "../utils/logAction";
export const UseStoreActivos = create(
  persist(
    (set, get) => ({
      activos: [],
      get_activos: async (company_id, subpillar_id) => {
          const result = await GET(
            `/get-assets/${subpillar_id}/folders?company_id=${company_id}`,
            "actosgubernamentales"
          );
          if (result?.statusCode === 200) {
            //set({ goverment_acts: result?.data });
           // console.log(result?.data);
            return result?.data;
          } else {
           // SwalFire("Atencion!", result?.message, "warning");
            set({ goverment_acts: [] });
          }
      },
      add_activos: async (data) => {
        console.log(data);
        console.log(data?.subpillar_id);
        const result = await POST(
          `/get-assets/${data?.subpillar_id}/folders`,
          data,
          "actosgubernamentales"
        );
        if (result?.statusCode === 200) {
          logAction("asset_archives", result?.data?.id, "create", "Se agrego un nuevo activo: " + data?.file_name);
          //SwalFire("Exito!", result?.message, "success");
          //get().get_goverment_acts(data?.company_id ,data?.subpillar_id);
          return result;
        } else {
          console.log(result);
          //SwalFire("Atencion!", result?.message, "warning");
        }
      },
      delete_folder: async (subpillar_id, folder_id, company_id) => {
        // se tiene que verififcar que no tiene archivos o carpetas dentro, si tiene no se puede eliminar,
        // para determinarlo se tiene verificar si el folder tiene hijos con la propiedad type = folder y parent_id = folder_id
        const hasChildren = await GET(
          `/get-assets/${subpillar_id}/folders?company_id=${company_id}`,
          "actosgubernamentales"
        );
        if (hasChildren?.statusCode === 200 && hasChildren?.data?.length > 0) {
          const filter = hasChildren?.data.filter(
            (item) => item?.parent_id == `${folder_id}`
          );
          if (filter?.length > 0) {
            SwalFire(
              "Atencion!",
              "No se puede eliminar la carpeta porque tiene archivos o carpetas dentro",
              "warning"
            );
            return false;
          }
         // no se puede eliminar si es root para determinar esto se determinar si parend_id = null o parent_id = 0
          const isRoot = hasChildren?.data.find(
            (item) => (item?.parent_id == null || item?.parent_id == 0) && item?.id == folder_id
          );
          console.log(isRoot);
          if (isRoot) {
            SwalFire(     
              "Atencion!",
              "No se puede eliminar el carpeta raiz",
              "warning"
            );
            return false;
          }
        }
        try {
          const resSwal = await SwalfireConfirm(
            "¿Está seguro que desea eliminar la carpeta?"
          );
          if (resSwal) {
            const res = await DELETE(
              `/get-assets/${subpillar_id}/folders/${folder_id}`,
              {},
              "actosgubernamentales"
            );
            if (res?.statusCode === 200) {
              logAction("asset_archives", folder_id, "delete", "Se elimino una carpeta"); 
              return true;
            } else return false;
          }
        } catch (error) {
          logAction("asset_archives", folder_id, "error", "Error al eliminar un activo");
          console.log(`Ocurrio un error al eliminar la carpeta`);
        }
      },
      delete_file: async (subpillar_id, file_id) => {
        try {
          const resSwal = await SwalfireConfirm(
            "¿Está seguro que desea eliminar el archivo?"
          );
          if (resSwal) {
            const res = await DELETE(
              `/get-assets/${subpillar_id}/folders/${file_id}`,
              {},
              "actosgubernamentales"
            );
            if (res?.statusCode === 200) {
              logAction("asset_archives", file_id, "delete", "Se elimino un archivo de activos");
              return true;
            } else return false;
          }
        } catch (error) {
          console.log(`Ocurrio un error al eliminar el archivo`);
        }
      },
      update_folder: async (data) => {
        const result = await PUT(
          `/get-assets/${data?.subpillar_id}/folders/${data?.folder_id}`,
          data,
          "actosgubernamentales"
        );
        if (result?.statusCode === 200) {
          logAction("asset_archives", data?.folder_id, "update", "Se actualizo una carpeta: " + data?.file_name);
          SwalFire("Exito!", result?.message, "success");
          // get().get_goverment_acts(data?.subpillar_id);
        } else {
          SwalFire("Atencion!", result?.message, "warning");
        }
      },
      update_file: async (data) => {
        const result = await PUT(
          `/get-assets/${data?.subpillar_id}/folders/${data?.id}`,
          data,
          "actosgubernamentales"
        );
        if (result?.statusCode === 200) {
          const logMessage = `Se actualizó un archivo ${data?.file_name || JSON.stringify(data)}`;
          logAction("asset_archives", data?.id, "update", logMessage);
          //SwalFire("Exito!", result?.message, "success");
          // get().get_goverment_acts(data?.subpillar_id);
          return result;
        } else {
          SwalFire("Atencion!", result?.message, "warning");
        }
      }
    }),
    {
      name: "actos_gubernamentales",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
