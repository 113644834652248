import React from "react";
import './common/css/style.css'
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//Private Routes
import { PrivateRoute, RoutingConfig } from "./utils/PrivateRoute";

//MSAL
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./msal/authConfig";
import { AlertTool } from "./components/Common/AlertModal";
import { colors } from "./common/theme/colors";

//Components
import { App } from "./App";
import { Menu } from "./pages/Home";
import { Users } from "./pages/Users"
import { Roles } from "./pages/Roles"
import { Permissions } from "./pages/Permissions"
import { GroupCompanies, AddCompany, Pillars, MutiPillar } from './pages/Company'
import { Actor } from './pages/Actor'
import { ActorGroup } from './pages/Actor/ActorGroup'
import { PanelAgenda} from './pages/Agenda'
import { Notifications } from './pages/Notifications'
import { Palette } from './pages/Palette'
import { EmailController } from './pages/EmailController'
import { esES } from "@mui/material/locale";
import { Pilares, Activos, Contratos } from "./pages/Company/Pilares";

// Bootstrap components
// import "bootstrap/dcoist/css/bootstrap.min.css";

const msalInstance = new PublicClientApplication(msalConfig);
const theme = createTheme({
  palette: colors,
  // typography: {
  //   fontFamily: "Poppins",
  // },
}, esES);

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AlertTool/>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route exact path="/home" element={<Menu />} />
              <Route exact path="/GroupCompanies" element={<GroupCompanies />}/>
              <Route exact path="/panel-agenda-operational" element={<PanelAgenda />}/>
              <Route exact path="/actors-group" element={<ActorGroup />}/>
              <Route path="/view-company/:company_id"element={<AddCompany />}/>
              <Route path="/view-actor/:actor_id" element={<Actor />}/>
              <Route path="/pillars/:company_id" element={<Pillars />} />
              <Route path="/pillars/:company_name/:pillar" element={<MutiPillar />}/>
              <Route path="/subpillars/:company_name/:pillar/:subpillar" element={<MutiPillar />}/>
              <Route path="/pilares/act-gub/:company_id" element={<Pilares />} />
              <Route path="/pilares/activos/:company_id" element={<Activos />} />
              <Route path="/pilares/contratos/:company_id" element={<Contratos />} />
            </Route>
            <Route path="/home/config/" element={<RoutingConfig />}>
                <Route path="/home/config/" element={<Users/>}/>
                <Route path="users-list" element={<Users/>}/>
                <Route path="roles-list" element={<Roles />}/>
                <Route path="permissions-list" element={<Permissions />}/>
                <Route path="add-company" element={<AddCompany />}/>
                <Route path="add-actor" element={<Actor />}/>
                <Route path="notifications" element={<Notifications />}/>
                <Route path="color-palette" element={<Palette />}/>
                <Route path="email-controller" element={<EmailController />}/>
              </Route>
            <Route path="/login/" element={<App />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);
