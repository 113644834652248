import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material'
import { MUISkeleton } from '../Common/MUISkeleton'
import { Input } from '../Common/MUInput';
import SaveIcon from '@mui/icons-material/Save';

export const FormNotificationsV = ({
    vars: { control, isLoading },
    funcs: {handleSubmit, OnSubmit}
}) => {
    const styles = {
        label: {
          fontFamily: "Poppins-SemiBold",
          fontSize: "14px",
          fontWeight: "600",
        },
        buttonSave: {
            height: "49px",
            width: "100px",
            fontFamily: "Poppins-SemiBold",
            fontSize: "14px",
            fontWeight: "500",
            color: "#FFFF",
            textTransform: "capitalize",
            ":hover": {
              background: "#1AAFD0",
            },
        },
        typography: {
            fontFamily: "Poppins-SemiBold",
            fontSize: "30px",
            fontWeight: "600",
            color: "#323338",
            "@media (max-width: 790px)": {
              fontSize: "24px",
            },
            "@media (max-width: 590px)": {
              fontSize: "20px",
            },
        },
        grid_container: {
            display: "flex",
            alignItems: "center",
            flexGrow: 1, mt:1, p:1,
            height: "80vh",
            overflow: "auto",
            width: "100%",
            borderRadius: "7px 7px 0px 0px",
            bgcolor: "#FFFFFF",
        }
    };

    return (
        <Box width={'100%'} height={'100%'} bgcolor={'#fdfdfd'}>
            {isLoading ?
                <Box p={1}>
                    <MUISkeleton />
                </Box>
            :
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                    height: "100%",
                    background: "#f6f7fb",
                    pl: 1,
                }}
            >
                <Box
                    height="40px"
                    bgcolor="#ffffff"
                    p={2}
                    pr={8}
                    borderRadius="0px 0px 0px 7px"
                    justifyContent="space-between"
                    display="flex"
                    alignItems="center"
                    boxShadow="0px 3px 4px 0px #2222221A"
                >
                    <Typography
                    sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#323338",
                    }}
                    >
                        Notificaciones antes del vencimiento
                    </Typography>
                    <Button
                        startIcon={<SaveIcon />}
                        type="button"
                        onClick={handleSubmit(OnSubmit)}
                        sx={{
                            fontFamily: "Poppins-SemiBold",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            width: "119px",
                            height: "49px",
                            background: "#1AAFD0",
                            color: "#FFFFFF",
                            ":hover": {
                                background: "#1AAFD0",
                            },
                        }}
                    >
                        Guardar
                    </Button>
                </Box>
                <Grid container sx={styles.grid_container}>
                    <Grid container p={1} >
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography sx={styles.label}>1ra notificación/recordatorio</Typography>
                            <Input
                                name="before_first_notification"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    label: "",
                                    autoComplete:'none'
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2} 
                        >
                            <Typography sx={styles.label}>2da notificación/recordatorio</Typography>
                            <Input
                                name="before_second_notification"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    label: "",
                                    autoComplete:'none'
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography sx={styles.label}>3ra notificación/recordatorio</Typography>
                            <Input
                                name="before_third_notification"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    label: "",
                                    autoComplete:'none'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    height="40px"
                    bgcolor="#ffffff"
                    p={2}
                    pr={8}
                    borderRadius="0px 0px 0px 7px"
                    justifyContent="space-between"
                    display="flex"
                    alignItems="center"
                    boxShadow="0px 3px 4px 0px #2222221A"
                >
                    <Typography
                    sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#323338",
                    }}
                    >
                        Notificaciones después del vencimiento
                    </Typography>
                </Box>
                <Grid container
                    sx={styles.grid_container}
                >
                    <Grid container mt={5}>
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography sx={styles.label}>1ra notificación/recordatorio</Typography>
                            <Input
                                name="after_first_notification"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    label: "",
                                    autoComplete:'none'
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography sx={styles.label}>2da notificación/recordatorio</Typography>
                            <Input
                                name="after_second_notification"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    label: "",
                                    autoComplete:'none'
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} p={1}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Typography sx={styles.label}>3ra notificación/recordatorio</Typography>
                            <Input
                                name="after_third_notification"
                                control={control}
                                props={{
                                    size: 'small',
                                    fullWidth: true,
                                    label: "",
                                    autoComplete:'none'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            }
        </Box>
    )
}