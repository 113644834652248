/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { EmailControllerV } from '../../components/EmailController'
import { UseStoreEmailController } from '../../stores/EmailController'

export const EmailControllerC = () => {
    const emails = UseStoreEmailController(state => state.emails);
    const get_emails = UseStoreEmailController(state => state.get_emails);
    const update_emails_checked = UseStoreEmailController(state => state.update_emails_checked);
    const save_emails_controller = UseStoreEmailController(state => state.save_emails_controller);
    const [isLoading, setIsLoading] = useState()

    const get_data = async () => {
        setIsLoading(true)
        await get_emails()
        setIsLoading(false)
    }

    useEffect(() => {
        get_data()
    }, [])

    const handleChangeChecked = (emailId, field) => {
        update_emails_checked(emailId, field)
    }

    const handleSaveEmailsController = async () => {
        setIsLoading(true)
        await save_emails_controller()
        setIsLoading(false)
    }

    return (
        <EmailControllerV 
            vars={{
                emails,
                isLoading
            }}
            funcs={{
                handleChangeChecked,
                handleSaveEmailsController
            }}
        />
    )
}