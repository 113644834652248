import AddIcon from "@mui/icons-material/Add";
import BusinessIcon from "@mui/icons-material/Business";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import PaletteIcon from "@mui/icons-material/Palette";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { UseStoreTemplateView } from "../../../stores/TemplateView";

export const Aside = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const profilePhoto = UseStoreTemplateView((state) => state.img_user);

  const menuOptions = [
    { name: "Usuarios", icon: <PeopleAltIcon />, url: "users-list" },
    { name: "Roles", icon: <PermIdentityIcon />, url: "roles-list" },
    { name: "Permisos", icon: <ManageAccountsIcon />, url: "permissions-list" },
    { name: "Agregar empresa", icon: <BusinessIcon />, url: "add-company" },
    {
      name: "Actor relacionado",
      icon: <RecentActorsIcon />,
      url: "add-actor",
    },
    {
      name: "Notificaciones",
      icon: <EditNotificationsIcon />,
      url: "notifications",
    },
    { name: "Paleta de colores", icon: <PaletteIcon />, url: "color-palette" },
    { name: "Controlador de correos", icon: <MarkEmailReadIcon />, url: "email-controller" },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      mt={5}
      gap={3}
      alignItems="center"
    >
      <Avatar
        sx={{ width: 110, height: 110, mr: 5 }}
        alt="Adriana Rentería"
        src={profilePhoto}
      />
      <List>
        {menuOptions?.map((menuOption, key) => (
          <ListItem key={key} disablePadding>
            <ListItemButton
              sx={{
                borderRadius: "7px",
                background: location?.pathname?.includes(menuOption?.url)
                  ? "#E2E4E7"
                  : "#FFFFFF",
                ":hover": {
                  "& .add-company-btn": {
                    bgcolor: "#25a4c0",
                  },
                },
              }}
              onClick={() => navigate(menuOption?.url, { replace: true })}
            >
              <ListItemIcon>{menuOption?.icon}</ListItemIcon>
              <ListItemText
                sx={{
                  color: "#323338",
                  fontFamily: "Poppins-Regular",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
                primary={menuOption?.name}
              />
              {menuOption?.name === "Agregar empresa" && (
                <IconButton
                  className="add-company-btn"
                  sx={{
                    ml: 2,
                    borderRadius: "5px",
                    bgcolor: location?.pathname?.includes(menuOption?.url)
                      ? "#25a4c0"
                      : "#666F88",
                    color: "#fff",
                    ":hover": {
                      bgcolor: "#25a4c0",
                    },
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
