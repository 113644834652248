import React from 'react';

const Control = ({name_user, type_designation, control, id_activity, date, descripcion, date_end}) => {
    return (
        <div>
            <p>Estimado/a {name_user},</p>
            <p>Te informamos que la solicitud con ID {id_activity} en la que has sido registrado como {type_designation}, cambió su estatus en la columna Control como {control.toUpperCase()}.</p>
            <p>Detalles clave de la solicitud:</p>
            <ul>
                <li>ID de Actividad: {id_activity}</li>
                {date && <li>Fecha: {date}</li>}
                {descripcion && <li>Descripción de la solicitud: {descripcion}</li>}
                {date_end && <li>Fecha final: {date_end}</li>}
            </ul>
            <p>Si deseas revisar el detalle de la solicitud ingresa al siguiente enlace
                <a href={`${process.env.REACT_APP_URL}`}> aquí </a> 
            </p>
            <p>Apreciamos tu compromiso y dedicación.</p>
            <p>¡Gracias por tu colaboración!</p>
            <p>Saludos cordiales.</p>
        </div>
      );
}

export default Control