import axios from 'axios'
import { SwalFire } from './index'
import { msalConfig } from '../msal/authConfig'
import { UseStoreTemplateView } from '../stores/TemplateView'
import { PublicClientApplication } from "@azure/msal-browser";

//Variables de entorno backend
const urls = {
    buscador: process.env.REACT_APP_URL_BACK_BUSCADOR,
    agenda: process.env.REACT_APP_URL_BACK_AGENDA,
    actosgubernamentales: process.env.REACT_APP_URL_BACK_ACTOS_GUBERNAMENTALES
}
      
//Creamos una instacia de axios prefeconfigurada para hacer las peticiones ya con el token establecido
const client = axios.create({
    baseURL: process.env.REACT_APP_ENV !== 'DEV' ? process.env.REACT_APP_URL_BACK: process.env.REACT_APP_URL_BACK_DEV,
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": ' *',
        'Access-Control-Allow-Headers': 'X-Requested-With, content-type',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTIONS"
    }
})

client.interceptors.request.use((config) =>{
    config.headers.Authorization = `Bearer ${localStorage.getItem('authToken')}`
    return config;
});

client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newToken = await refreshToken();
        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return client(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

//const access_token = localStorage.getItem('authToken')
//Define el token de forma global para enviar las peticiones
//client.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

/* //Inicia la instancia de axios para poder tener acceso a sus propieddaes
const InicializateClient = () => {
    client.interceptors.response.use(response => response, async error => {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            await RefreshAccessToken()
            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
            return client(originalRequest)
        }
        return Promise.reject(error)
    })
}

InicializateClient()
 */

const closeSession = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload(true);
}

/* //Metodo que crea el login del proyecto
export const Authentication =  async (username, password) => {
    try {
        const {data} = await client.post(`${client?.defaults?.baseURL}auth/login`, {username, password})
        if(data){
            localStorage.setItem('signin', JSON.stringify(data))
            //InicializateClient()
            return data
        } 
    } catch (err) {
        SwalFire('Error',err?.response?.data?.message || err?.message, 'error')
    }
}

//Metodo para refrescar el token
export const RefreshAccessToken = async () => {
    try {
        const { data } = await client.post(`${client?.defaults?.baseURL}`, {
            refreshToken: ''
        })
        if(data) localStorage.setItem('signin', JSON.stringify(data))
    } catch (err) {
        SwalFire('Error',err?.response?.data?.message || err?.message, 'error')
    }
}
 */
let msalInstance = null;

const initializeMsal = () => {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
  }
  return msalInstance;
};

export const refreshToken = async () => {
    console.log('refresh token');
    
    try {
      const msalInstance = initializeMsal();
      await msalInstance.initialize();
      const accounts = msalInstance.getAllAccounts();
  
      if (accounts.length === 0) {
        throw new Error('No hay cuentas autenticadas');
      }
  
      const silentRequest = {
        scopes: ["User.Read", "Mail.Send", "offline_access"],
        account: accounts[0]
      };
  
      const response = await msalInstance.acquireTokenSilent(silentRequest);
  
      if (response.accessToken) {
        //console.log('Nuevo token obtenido:', response.accessToken);
        //console.log('response', response)
        //console.log('accounts', accounts)
        return response.accessToken;
      } else {
        throw new Error('No se pudo obtener un nuevo token de acceso');
      }
    } catch (err) {
      console.error('Error al refrescar el token:', err);
      if (err.name === "InteractionRequiredAuthError") {
        // El token ha expirado y se requiere interacción del usuario
        //console.log('Se requiere interacción del usuario para obtener un nuevo token');
        // Aquí podrías redirigir al usuario a la página de login o mostrar un modal de reautenticación
        return null;
      }
      // Otros errores
      return null;
    }
  };

export const setupTokenRefresh = (setTokenCallback) => {
  const refreshInterval = 5 * 60 * 1000; // 5 minutos en milisegundos

  const refreshTokenAndUpdate = async () => {
    const newToken = await refreshToken();
    if (newToken) {
      setTokenCallback(newToken);
    }
  };

  setInterval(refreshTokenAndUpdate, refreshInterval);

  // Ejecuta inmediatamente para asegurarse de que tenemos un token válido
  refreshTokenAndUpdate();
};

//Metodo global GET para hacer peticiones de listas
export const GET = async (path, module = false) => {
    try {
        //Creamos una validacion para identicar el modulo del back al que le queremos pegar
        let base_url = client?.defaults?.baseURL
        if(module){
            base_url = urls[module]
        }

        const {data} = await client.get(`${base_url}${path}`)
        if(data) return data
    } catch (err) {
        if(err?.response?.status === 401){
            SwalFire('Error',err?.response?.data?.detail, 'error')
            closeSession()
        }else{
            SwalFire('Error',err?.response?.data?.message || err?.message, 'error')
        }
    }
}

export const POST = async (path, payload, module = false) => {
    try {
        //Creamos una validacion para identicar el modulo del back al que le queremos pegar
        let base_url = client?.defaults?.baseURL
        if(module){
            base_url = urls[module]
        }

        const { data } = await client.post(`${base_url}${path}`, payload)
        if(data) return data
    } catch (err) {
        if(err?.response?.status === 401){
            SwalFire('Error',err?.response?.data?.detail, 'error')
            closeSession()
        }else{
            SwalFire('Error',err?.response?.data?.message || err?.message, 'error')
        }
    }
}

export const PUT = async (path, payload, module = false) => {
    try {
        //Creamos una validacion para identicar el modulo del back al que le queremos pegar
        let base_url = client?.defaults?.baseURL
        if(module){
            base_url = urls[module]
        }

        const {data} = await client.put(`${base_url}${path}`, payload)
        if(data) return data
    } catch (err) {
        if(err?.response?.status === 401){
            SwalFire('Error',err?.response?.data?.detail, 'error')
            closeSession()
        }else{
            SwalFire('Error',err?.response?.data?.message || err?.message, 'error')
        }
    }
}

export const PATCH = async () => {}

export const DELETE = async (path, payload, module = false) => {
    try {
        //Creamos una validacion para identicar el modulo del back al que le queremos pegar
        let base_url = client?.defaults?.baseURL
        if(module){
            base_url = urls[module]
        }

        const {data} = await client.delete(`${base_url}${path}`, payload)
        if(data) return data
    } catch (err) {
        if(err?.response?.status === 401){
            SwalFire('Error',err?.response?.data?.detail, 'error')
            closeSession()
        }else{
            SwalFire('Error',err?.response?.data?.message || err?.message, 'error')
        }
    }
}



