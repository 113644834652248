import Swal from 'sweetalert2'
import { useState, useEffect } from 'react';
import errorIcon from "../assets/icons/alert/errorIcon.svg";
import infoIcon from "../assets/icons/alert/infoIcon.svg";
import questionIcon from "../assets/icons/alert/questionIcon.svg";
import successIcon from "../assets/icons/alert/successIcon.svg";
import warningIcon from "../assets/icons/alert/warningIcon.svg";

// Hook personalizado para el debounce
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Establecer un temporizador para actualizar el valor después del retraso
    const timerId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Limpiar el temporizador en cada cambio de valor
    return () => {
      clearTimeout(timerId);
    };
  }, [value, delay]);

  return debouncedValue;
}

// Estilos personalizados
const customStyles = Swal.mixin({
    customClass: {
      icon: 'custom-swal-icon-container',
      popup: 'custom-swal-popup',
      title: 'custom-swal-title',
      htmlContainer: 'custom-swal-html'
    },
    styled: {
      '.custom-swal-icon-container': {
        border: 'none !important',
        background: 'none !important'
      },
      '.custom-swal-icon': {
        width: '5em',
        height: '5em',
        border: 'none !important',
        background: 'none !important'
      },
      '.swal2-icon': {
        border: 'none !important',
        background: 'none !important'
      },
      '.custom-swal-popup, .custom-swal-title, .custom-swal-html': {
      fontFamily: '"Poppins-Regular", sans-serif !important'
    }
    }
  });

const customIcons = {
    success: successIcon,
    error: errorIcon,
    warning: warningIcon,
    question: questionIcon,
  };

  export const SwalFire = (title, text, icon) => customStyles.fire({
    title,
    text,
    iconHtml: customIcons[icon] ? `<img src="${customIcons[icon]}" alt="${icon} icon" class="custom-swal-icon"/>` : null,
    icon: customIcons[icon] ? undefined : icon,
    showConfirmButton: false,
    timer: 2500
  });

export const SwalfireConfirm = async (title, textConfirm='Si', TextDeny='No') => {
    return await Swal.fire({
       iconHtml: customIcons['warning'] ? `<img src="${customIcons['warning']}" alt="${'warning'} icon" class="custom-swal-icon"/>` : null,
        title,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: textConfirm,
        denyButtonText: TextDeny,
    }).then((result) => {
        if (result.isConfirmed) {
            return true
        } else if (result.isDenied) {
            return false
        }
    })
}

export const SwalfireConfirmUnbold = async (title, textConfirm='Si', TextDeny='No') => {
  return await Swal.fire({
      iconHtml: customIcons['warning'] ? `<img src="${customIcons['warning']}" alt="${'warning'} icon" class="custom-swal-icon"/>` : null,
      html: `<span style="font-weight: normal;">${title}</span>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: textConfirm,
      denyButtonText: TextDeny,
  }).then((result) => {
      if (result.isConfirmed) {
          return true
      } else if (result.isDenied) {
          return false
      }
  })
}

export const validateName = (valor) => {
    const regex = /[a-zA-Z]+/;
    return regex.test(valor);
}

export const getFileExtension = (filename) => {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
}

const testMillers = (value) => {
    //declare an object RegExp
    let regex = new RegExp(/^-?\d*$/);
    //test the regexp
    return regex.test(value);
}

export const millers = (value) => {
    // eslint-disable-next-line no-extend-native
    Number.prototype.format = function(n, x, s, c) {
        let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
            num = this.toFixed(Math.max(0, ~~n));
        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    };

    let newValue = ''
    if(value !== ""){
        let str = value;
        let oldstr = str.substring(0, str.length - 1);
        let millares = ",";
        let decimales = ".";
        if (str !== millares) {
            str = str.split(millares).join("");
            if (testMillers(str)) {
                let numero = parseInt(str);
                newValue = numero.format(0, 3, millares, decimales);
            } else {
                newValue = oldstr;
            }
        } else {
            newValue = "";
        }
    }
    
    return newValue ? newValue : ''
}

export const ToPayerText = text => {
    // Obtener la primera letra de la cadena
    const primeraLetra = text.slice(0, 1);
    // Concatenar la primera letra con el resto de la cadena
    const cadenaConMayuscula = primeraLetra.toUpperCase() + text.slice(1).toLowerCase()
    return cadenaConMayuscula
}

export const verifyImageDimensions = async (file, width, height) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            if (img.width !== width && img.height !== height) {
                SwalFire('Atencion!',`La imagen debe ser de ${width} x ${height} píxeles.`, 'warning');
                resolve(false);
            }else{
                resolve(true);
            }
        };
    });
  };

export const validateFile = (file) => {
    const maxSizeMB = 35;
    if (file.size > maxSizeMB * 1024 * 1024) {
        SwalFire('Advertencia', 'El tamaño del archivo debe ser de 35 MB como máximo.', 'warning');
        return false;
    }

    return true;
};

export const capitalizeFirstLetter = (str) => {
    if (!str) return ''; // Verifica si la cadena está vacía
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}