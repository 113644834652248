import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { encrypt_data } from './../utils/JSImplements'
import { GET, POST } from './../utils/AuthService'
import { logAction } from '../utils/logAction'
export const UseStoreLogin = create(
    persist(
        (set, get) => ({
            data_user: {},
            isLogged: false,
            refreshUserInfo: async () => {
                const { data_user } = get()
                const res = await GET('get-users')
                if(res?.data?.length) {
                    const arr = res?.data
                    const result = arr?.find((user) => user?.id === data_user?.id)
                    set({ data_user: result })
                }
            }, 
            updateUserInfo: async (data_user) => {
                if (data_user) set({data_user, isLogged: true})
            },
            verifyToken: async () => {
                const { data_user } = get()
                const authToken =  localStorage.getItem('authToken')
            
                if(authToken && data_user){
                    const response = await POST("verify-token", {"authToken": authToken});
                    if (response?.statusCode === 200)
                    {
                        set({isLogged: true})
                    }
                    else{
                        set({isLogged: false})
                    } 
                }
                else{
                    set({isLogged: false})
                }
            }
        }),
        {
            name: encrypt_data('login_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)