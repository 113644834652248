/* eslint-disable no-unused-vars */
import TextField from "@mui/material/TextField";
import { Controller, useController } from "react-hook-form";
import { millers } from "./../../utils";

export const Input = ({ control, name, props }) => {
  const {
    field,
    fieldState: { error },
    formState: { defaultValues },
  } = useController({
    name,
    control,
  });

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <TextField
          {...props}
          onChange={({ target }) => {
            let value = target.value;
            if (props?.isDouble) {
              value = millers(value);
            }
            if (props?.getValue) {
              props?.getValueFunc(value);
            }
            field.onChange(value);
          }} // send value to hook form
          onBlur={({ target }) => {
            const value = target?.value;
            if (props?.customOnchange) {
              props?.onChange(value);
            } else {
              field.onBlur();
            }
          }} // notify when input is touched/blur
          value={field.value || props?.value} // input value
          name={field.name} // send down the input name
          inputRef={field.ref} // send input ref, so we can focus on input when error appear
          error={Boolean(error?.message)}
          helperText={error?.message || ""}
          defaultValue={field.value}
        />
      )}
    />
  );
};
