import {create} from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { encrypt_data } from './../utils/JSImplements'
import { GET, POST, PUT} from './../utils/AuthService'
import { SwalFire, SwalfireConfirm } from './../utils'
import { readFile, uploadFile } from './../utils/AWS'
import dayjs from 'dayjs'
import { logAction } from '../utils/logAction'

export const UseStoreAgenda = create(
    persist(
        (set, get) => ({
            id_operational_agenda: false,
            pdf_link: '',
            areas: [],
            requests: [],
            companies: [],
            get_areas: async () => {
                const { areas } = get()
                if(!areas?.length){
                    const area = await GET('get-areas', 'agenda')
                    if(area?.data.length) set({areas: area?.data})
                }
            },
            get_requests: async () => {
                const res = await GET('get-requests', 'agenda') 
                if(res?.data?.length){
                    const filterData = res?.data.filter(item => item?.control_id !==5)
                    const filterData2 = filterData.filter(item => item?.control_id !==4)
                    set({requests: filterData2})
                    return res?.data
                }

                return []
            },
            get_history_observations_by_requirement: async (id) => {
                const res = await GET(`get-history-observations-requirement/${id}`, 'agenda') 
                if(res?.data?.length){
                    const observations = res?.data
                    try{
                        for (let index = 0; index < observations.length; index++) {
                            const element = observations[index];
                            
                            const image_user = await readFile(`/imagen_usuarios`, element?.user?.image)
                            if(image_user?.statusCode === 200)
                                element.user_image = image_user?.data?.url
                            else
                                element.user_image = null
                     
                            observations[index] = element
                        }
                        return observations
                    }catch(e){
                        console.log(`Ha ocurrido un error al cargar la imagen del usuario`);
                        return []
                    }
                }
                return []
            },
            get_history_document_by_requirement: async (id) => {
                const res = await GET(`get-history-documents-requirement/${id}`, 'agenda') 
                if(res?.data?.length){
                    const documents = res?.data
                    try{
                        for (let index = 0; index < documents.length; index++) {
                            const element = documents[index];
                            
                            const image_user = await readFile(`/imagen_usuarios`, element?.user?.image)
                            if(image_user?.statusCode === 200)
                                element.user_image = image_user?.data?.url
                            else
                                element.user_image = null
                     
                            documents[index] = element
                        }
                        return documents
                    }catch(e){
                        console.log(`Ha ocurrido un error al cargar la imagen del usuario`);
                        return []
                    }
                }
                return []
            },
            get_requests_filter: async (payload) => {
                const res = await POST('get-requests-filters', payload, 'agenda') 
                if(res?.statusCode === 200){
                    const filterData = res?.data.filter(item => item?.control_id !==5)
                    set({requests: filterData})
                    return res?.data
                }else{
                    SwalFire('Atencion!', res?.message, 'warning')
                }
                return []
            },
            get_requests_filter_deleting: async (payload) => {
                const res = await POST('get-requests-filters', payload, 'agenda') 
                if(res?.statusCode === 200){
                    set({requests: res?.data})
                    return res?.data
                }else{
                    SwalFire('Atencion!', res?.message, 'warning')
                }
                return []
            },
            get_requests_filter_custodian: async (payload1, payload2) => {
                try {
                    const [res1, res2] = await Promise.all([
                        POST('get-requests-filters', payload1, 'agenda'),
                        POST('get-requests-filters', payload2, 'agenda')
                    ])
                    if(res1?.statusCode === 200 && res2?.statusCode === 200){
                        const combinedData = [...res1?.data, ...res2?.data]
                        const uniqueData = combinedData.reduce((acc, current) => {
                            const x = acc.find(item => item.id === current.id);
                            if (!x) {
                                return acc.concat([current]);
                            } else {
                                return acc;
                            }
                        }, []);
                        set({requests: uniqueData})
                        return uniqueData
                    }else{
                        SwalFire('Atencion!', res1?.message, 'warning')
                    }
                } catch (error) {
                    console.log(`Ha ocurrido un error al filtrar las solicitudes`);
                }
                return []
            },
            get_pdf_link: async (url) => {
                try{
                    const req = await readFile('/requisitos_tramite', url)
                    if(req?.statusCode === 200){
                        set({pdf_link: req?.data?.url})
                    }else{
                        SwalFire('Atencion!', req?.message, 'warning')
                    }
                }catch(e){
                    console.log(`Ha ocurrido al leer el archivo ${e}`);
                }
            },
            update_id_operational_agenda: (id) => {
                set({id_operational_agenda: id})
            },
            add_request: async (payload) => {
                try {
                    const { add_observation_requirement } = get()
                    let request = {}
                    let rollback = false

                    request = await POST('add-requests', payload?.request, 'agenda')
                    
                    if(request?.statusCode === 200){
                        await logAction('operational_agenda', request?.data?.id, 'create', 'Se ha creado una solicitud en la agenda operativa')
                        //Obtenemos el id de la solicitud
                        const request_id = request?.data?.id

                        if(payload?.requirements?.length > 0){
                            const filename = `ID ACTIVIDAD: ${request?.data?.id} FECHA SUBIDA: ${dayjs().locale('es').format('dddd, D MMMM YYYY')}`
                            const newRequirements = []
                            
                            for (let index = 0; index < payload?.requirements.length; index++) {
                                const element = payload?.requirements[index];
                                element.operational_id = request_id
                                element.delivery_date = dayjs(element?.delivery_date).format('YYYY-MM-DD')

                                if(element?.pdf_link){
                                    const upload_doc = await uploadFile(`/requisitos_tramite`, element?.pdf_link, filename, true)
                                    if(upload_doc?.statusCode === 200){
                                        await logAction('operational_agenda', request_id, 'create', 'Se ha subido un documento a la solicitud')
                                        element.pdf_link = upload_doc?.data?.urlFile
                                        newRequirements.push(element)
                                    }
                                }else{
                                    element.pdf_link = null
                                    newRequirements.push(element)
                                }
                            }
                            
                            if(newRequirements.length > 0){
                                const reqs = await POST('add-processing-requirements', {requirements: newRequirements}, 'agenda')
                                if(reqs?.statusCode === 200){
                                    await logAction('operational_agenda', request_id, 'create', 'Se han agregado requisitos a la solicitud')
                                    for (let index = 0; index < reqs?.data.length; index++) {
                                        const element = reqs?.data[index];
                                        const observation = {}
                                        observation.id_processing_requirements = element?.id
                                        observation.comment = element?.observation
                                        observation.comment_date = dayjs().format('YYYY-MM-DD HH:mm:ss')
                                        observation.user_id = payload?.user?.id
                                        await add_observation_requirement(observation)
                                    }
                                    console.log(`Se guardaron los requisitos del tramite`);
                                }else{
                                    rollback = reqs
                                }
                            }
                        }

                        if(rollback){
                            SwalFire('Atencion!', rollback?.message, 'warning')
                            return false
                        }
                        else{
                            SwalFire('Bien!', request?.message, 'success')
                            return request?.data
                        }
                    }else {
                        await logAction('operational_agenda', 0, 'create', 'Ha ocurrido un error al guardar la solicitud')
                        SwalFire('Atencion!', request?.message, 'warning')
                        return false
                    }
                } catch (error) {
                    await logAction('operational_agenda', 0, 'create', 'Ha ocurrido un error al guardar la solicitud')
                    console.log(`ha ocurrido un error al guardar la solicitud`);
                    return false
                }
            },
            add_general_comment:  async (payload) => {
                try {
                    const request = await POST(`add-general-comment`, payload ,'agenda')
                    if(request?.statusCode === 200){
                        return request; 
                    }else {
                        return false; 
                    }
                }catch (e) {
                    console.log('Ha ocurrido un error al insertar el comentario la solicitud')
                }
            },
            update_request: (neRows) => {
                set({requests: neRows})
            },
            update_status_requirements: async (request_id, new_requirement) => {
                const { requests } = get()

                //Clonamos para hacer inmutabilidad 
                const new_requests = structuredClone(requests)

                const request = new_requests?.find(x => x?.id === request_id)
                const requestIndex = new_requests?.findIndex(x => x?.id === request_id)
                
                //Consultamos el los requisitos del tramite
                const requirementsIndex = request?.requirements?.findIndex(row => row?.id === new_requirement?.id)
                
                const newRequest = {
                    ...request,
                    requirements: request.requirements?.map(r =>({...r, id_requirement: r?.id }))
                }

                if(requirementsIndex >= 0){
                    newRequest.requirements[requirementsIndex] = {
                        ...new_requirement,
                        id_requirement: new_requirement?.id
                    }
                    new_requests[requestIndex] = newRequest
                }
            
                return newRequest
            },
            update_requests:  async (payload) => {
                try {
                    const request = await PUT(`update-requests/${payload?.id}`,payload ,'agenda')
                    if(request?.statusCode === 200){
                        await logAction('operational_agenda', payload?.id, 'update', 'Se ha actualizado una solicitud en la agenda operativa')
                        SwalFire('Exito!', request?.message, 'success')
                        return request; 
                    }else {
                        SwalFire('Atencion!', request?.message, 'warning')
                    }
                }catch (e) {
                    console.log('Ha ocurrido un error al actualizar la solicitud')
                }
            },
            save_digital_document:  async (payload) => {
                try {
                    const request = await PUT(`save-digital-document/${payload?.id}`, payload ,'agenda')
                    if(request?.statusCode === 200){
                        console.log(`Se ha guardado un documento digital en la solicitud`, request);
                        await logAction('operational_agenda', payload?.id, 'update', 'Se ha guardado en la solicitud un documento digital ID: ' + request?.data.digital_document)
                        SwalFire('Exito!', request?.message, 'success')
                        return request; 
                    }else {
                        SwalFire('Atencion!', request?.message, 'warning')
                    }
                }catch (e) {
                    console.log('Ha ocurrido un error al actualizar la solicitud')
                }
            },
            update_processing_requirement: async (payload) =>{
                try {
                    const requirement = await PUT(`update-processing-requirements/${payload?.id}`, payload, 'agenda')
                    if(requirement?.statusCode === 200){
                        await logAction('operational_agenda', payload?.id, 'update', 'Se ha actualizado un requisito en la solicitud')
                        SwalFire('Exito!', requirement?.message, 'success')
                        return requirement; 
                    }else {
                        SwalFire('Atencion!', requirement?.message, 'warning')
                        return false
                    }
                }catch (e) {
                    await logAction('operational_agenda', payload?.id, 'update', 'Ha ocurrido un error al actualizar un requisito en la solicitud')
                    console.log(`Ha ocurrido un error al actualizar el requisito`);
                }
            },
            add_observation_requirement: async (payload) => {
                try {
                    const request = await POST(`add-observation-requirement`, payload ,'agenda')
                    if(request?.statusCode === 200){
                        await logAction('operational_agenda', payload?.id, 'create', 'Se ha agregado una observacion a un requisito')
                        return request; 
                    }else {
                        return false; 
                    }
                }catch (e) {
                    console.log('Ha ocurrido un error al insertar la observacion del requisito')
                }
            },
            add_document_requirement: async (payload) => {
                try {
                    const request = await POST(`add-document-requirement`, payload ,'agenda')
                    if(request?.statusCode === 200){
                        return request; 
                    }else {
                        return false; 
                    }
                }catch (e) {
                    console.log('Ha ocurrido un error al insertar la observacion del requisito')
                }
            },
            delete_request: async (request_id) => {
                try {
                    const resSwal = await SwalfireConfirm('¿Está seguro que desea eliminar la solicitud?')
                    if(resSwal){
                        const res = await PUT(`delete-requests/${request_id}`, {}, 'agenda')
                        if(res?.statusCode === 200){
                            return true
                        }else
                            return false
                    }
                } catch (error) {
                    console.log(`Ocurrio un error al eliminar la solictud`);
                }
            }
        }),
        {
            name: encrypt_data('agenda_storage'),
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)