import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Box, Typography}from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';

export const CheckboxIcon = ({name, control, disabled, indeterminate, value}) =>{
  return (
    <Controller 
      name={name}
      control={control}
      rules={{ required: true }}
      render={({field, fieldState}) => (
        <Box>
          <Checkbox
            {...field}
            value={value ? value : field.value}
            defaultValue={0}
            disabled={disabled}
            inputRef={field.ref}  
            icon={<CloseIcon/>}
            indeterminate={value === 2}
            indeterminateIcon={<BrowserNotSupportedIcon/>}
            checkedIcon={<CheckIcon/>}
            checked={value === 1}
            onChange={({target}) => {
                field.onChange(indeterminate ?
                  target.checked && field.value === 2 ? 1 : field.value === 1 && !target.checked ? 0 : (field.value === 0 || !field.value ) && target.checked ? 2 : 1
                  : target.checked ? 1 : 0 
                )
              }
            }
          >
            </Checkbox>
            {Boolean(fieldState.error?.message) && 
              <Typography fontSize={11} color="error">
                {fieldState.error?.message}
              </Typography>
            }
        </Box>
      )}
    />
  );
}