import React, {useEffect, useState} from 'react';
import { useNavigate} from 'react-router-dom'
import { ActorGroupV } from '../../components/Actor/ActorGroup'
import { UseStoreTemplateView } from './../../stores/TemplateView';

export const ActorGroupC = () => {
    const navigate = useNavigate()
    const actors = UseStoreTemplateView(state => state.actors);
    const get_actors = UseStoreTemplateView(state => state.get_actors);
    const sort_actors = UseStoreTemplateView(state => state.sort_actors);
    const updateLabelNav = UseStoreTemplateView((state) => state.updateLabelNav);
    const [isLoading, setIsLoading] = useState(false)

    const get_data = async () => {
        setIsLoading(true);
        await get_actors()
        await sort_actors()
        setIsLoading(false);
    }

    const handleNavigate = (id) => {
        navigate(`/view-actor/${id}`)
    }

    useEffect(() => {
        updateLabelNav("ACTORES");
        get_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <ActorGroupV 
        actors={actors}
        isLoading={isLoading}
        handleNavigate={handleNavigate}
    />
}