import React from 'react';
import { MUISkeleton } from '../Common/MUISkeleton'
import { Box, Button, Typography} from '@mui/material';
import { ListActors } from './ListActors';
import { FormActor } from './FormActor';
import AddIcon from '@mui/icons-material/Add';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

export const ActorV = ({ 
    vars: {
        actor_id,
        users,
        requests_actors,
        companies,
        contactsFields,
        companiesFields,
        obligationsFields,
        businessRelatedFields,
        isLoading,
        control,
        isView,
        type_user,
        actors,
        isEdit,
        viewForm
    },
    funcs: {
        handleSubmit,
        OnSubmit, 
        handleSetAppend,
        handleRemove,
        handleDeleteActor,
        handleEditActor,
        hadleViewActor,
        handleViewForm,
    }
}) => {

    return(
        <Box 
            width={'100%'} 
            height={actor_id ? '95%' :'100%'} 
            display={'flex'} 
            flexDirection={'column'} 
            bgcolor={'#fdfdfd'} 
            sx={{overflow: 'scroll'}} 
        >
            
            {isLoading ? <Box sx={{ width: '100%' }}>
                <MUISkeleton />
            </Box>
            :
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    width: "100%",
                    height: "100%",
                    background: "#f6f7fb",
                    pl: 1,
                  }}
                >
                    {!actor_id && 
                        <Box
                            height="40px"
                            bgcolor="#ffffff"
                            p={2}
                            pr={8}
                            borderRadius="0px 0px 0px 7px"
                            justifyContent="space-between"
                            display="flex"
                            alignItems="center"
                            boxShadow="0px 3px 4px 0px #2222221A"
                        >
                            <Typography
                            sx={{
                                fontFamily: "Poppins-SemiBold",
                                fontSize: "24px",
                                fontWeight: "600",
                                color: "#323338",
                            }}
                            >
                                {!viewForm ?  'Lista de actores' : 'Nuevo actor'}
                            </Typography>
                            <Button
                                startIcon={!viewForm ? <AddIcon /> : <FormatListNumberedIcon />}
                                type="button"
                                onClick={handleViewForm}
                                sx={{
                                    fontFamily: "Poppins-SemiBold",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                    width: "119px",
                                    height: "49px",
                                    background: "#1AAFD0",
                                    color: "#FFFFFF",
                                    ":hover": {
                                        background: "#1AAFD0",
                                    },
                                }}
                            >
                                {!viewForm ?  'Nuevo' : 'Lista'}
                            </Button>
                      </Box>
                    }
                    {viewForm ? 
                        <FormActor
                            requests_actors={requests_actors}
                            actor_id={actor_id}
                            users={users}
                            companies={companies}
                            type_user={type_user}
                            control={control}
                            isView={isView}
                            contactsFields={contactsFields}
                            companiesFields={companiesFields}
                            obligationsFields={obligationsFields}
                            businessRelatedFields={businessRelatedFields}
                            isEdit={isEdit}
                            handleSubmit={handleSubmit}
                            handleSetAppend={handleSetAppend}
                            handleRemove={handleRemove}
                            OnSubmit={OnSubmit}
                        />
                    :
                        <ListActors 
                            actors={actors} 
                            hadleViewActor={hadleViewActor} 
                            handleDeleteActor={handleDeleteActor}
                            handleEditActor={handleEditActor}
                        />
                    }
                </Box>
            }
            
        </Box>
    )
}