import React, { } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { TableListAdapter } from "../Common/DataGridAdapter";

export const ListActors = ({actors, handleDeleteActor, handleEditActor, hadleViewActor}) => {
    const columns = [
        { field: 'name', headerName:'Nombre', minWidth: 300},
        { field: 'description', headerName:'Descripción', minWidth: 300},
        { field: 'relationship_business', headerName:'Negocios de relación', minWidth: 300},
        {   
            field: "options",
            headerName: "Opciones",
            description: "Muestra las opciones que se le permiten al usuario",
            sortable: false,
            cellClassName: "options-className",
            minWidth: 150,
            renderCell: ({row}) => <Box>
                <Tooltip title="Ver detalles">
                    <IconButton 
                        onClick={() => hadleViewActor(row)}
                        sx={{
                            color: "#323338",
                            ":hover": {
                              color: "#1AAFD0",
                            },
                        }}
                    >
                        <RemoveRedEyeOutlinedIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Editar actor">
                    <IconButton 
                        onClick={() => handleEditActor(row)}
                        sx={{
                            color: "#323338",
                            ":hover": {
                              color: "#1AAFD0",
                            },
                        }}
                    >
                        <EditOutlinedIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar actor">
                    <IconButton 
                        onClick={() => handleDeleteActor(row?.id)}
                        sx={{
                            color: "#323338",
                            ":hover": {
                              color: "#1AAFD0",
                            }
                        }}
                    >
                        <DeleteOutlineOutlinedIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>
            </Box>
        }
    ]
    
    
    return (
        <Box
            sx={{
                height: "80vh",
                overflow: "auto",
                width: "95%",
                borderRadius: "7px 7px 0px 0px",
                bgcolor: "#FFFFFF",
                p: "0px 16px",
                "& .options-className": {
                borderLeft: "3px solid #E2E4E7",
                },
                "& .role-className": {
                borderLeft: "1px solid #E2E4E7",
                },
            }}
        >
            <TableListAdapter 
                height={"100%"}
                columns={columns}
                data={actors}
                countRows={7}
                isLoading={!Boolean(actors?.length)}
                handleRowClick={() => {}}
                handleCellClick={() => {}}
            />
        </Box>
    )
}