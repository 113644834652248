import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormNotificationsV } from '../../components/Notifications/FormNotifications'
import { NotificationsSchema } from '../../schemas/NotificationsSchemas';
import { UseStoreNotifications } from './../../stores/Notificactions';

export const FormNotificationsC = () => {
    const notification = UseStoreNotifications(state => state.notification);
    const get_notification = UseStoreNotifications(state => state.get_notification);
    const save_time_notification = UseStoreNotifications(state => state.save_time_notification);
    const [isLoading, setIsLoading] = useState(false)
    const { handleSubmit, control, reset } = useForm({resolver: yupResolver(NotificationsSchema)});

    const loadData = async () => {
        setIsLoading(true)
        const data = await get_notification()
        reset(data)
        setIsLoading(false)
    }

    const OnSubmit = async (data) => {
        const payload = {
            ...data
        }
        payload.id = notification?.id || null

        setIsLoading(true)
        await save_time_notification(payload)
        setIsLoading(false)
    }

    useEffect(() => {
        loadData();        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FormNotificationsV 
            vars={{ control, isLoading}}
            funcs={{OnSubmit, handleSubmit}}
        />
    )
}