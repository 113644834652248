import styled from "styled-components";
// import Fondo from '../../assets/b3.jpg'

export const ContentMain = styled.div``;

export const HeaderMain = styled.div`
  background-image: url();
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 47vh;
  width: 100%;
`;

export const BodyMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  height: auto;
  width: 100%;
`;
export const Upload = styled.div`
  width: 65%;
  height: 35vh;
  background-color: white;
  border-style: dashed;
  border-width: 2px;
  border-color: #67757c6c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Label = styled.h4`
  line-height: 22px;
  font-size: 18px;
  font-family: "Montserrat", "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  margin-right: 48.5%;
  height: 3vh;
  color: #5d686dc1;
`;
export const H5 = styled.h5`
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
  color: #3f4244c5;
  font-family: "Montserrat", "Helvetica Neue", "Arial", "sans-serif";
`;

export const ImgFile = styled.img`
  height: 10vh;
  margin: 20px;
`;
export const Input = styled.input`
  display: none;
`;
export const P = styled.h1`
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ color }) => (color ? color : "#455a64")};
  font-family: "Montserrat", "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400;
  margin: 7px;
`;
export const Button = styled.button`
  background-color: white;
  border: 1px solid #9ca9b1;
  border-radius: 3px;
  width: 45%;
  cursor: pointer;
  box-shadow: 0 14px 26px -12px rgb(169 169 169 / 42%),
    0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(169 169 169 / 20%);
`;
export const Box = styled.div`
  display: flex;
  justify-content: space-around;
  height: 3.5vh;
  width: 23%;
  margin: 15px;
`;
export const Span = styled.span`
  font-size: 10px;
  color: white;
  letter-spacing: 0.5px;
  color: ${({ color }) => (color ? color : "white")};
`;

export const ImgLogos = styled.img`
  width: 100%;
  height: 3vh;
`;
export const Box2 = styled.div`
  display: flex;
  justify-content: space-around;
  width: 15%;
  height: 3vh;
`;
export const ButtonCustom = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #54c8e8;
  border-radius: 3px;
  width: 38%;
  cursor: pointer;
  box-shadow: 0 14px 26px -12px rgb(169 169 169 / 42%),
    0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(169 169 169 / 20%);
`;
export const ButtonFile = styled.input`
  display: none;
`;
export const Text = styled.h1`
  line-height: 20px;
  margin: 25px auto;
  font-size: 18px;
  color: #666c6e;
  font-family: "Montserrat", "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 100;
  width: 97%;
`;
export const Div = styled.div`
  width: 97%;
  height: 10vh;
  display: flex;
  align-items: center;
  border-top: 1px solid #a39f9f;
  border-bottom: 1px solid #a39f9f;
  margin: auto;
  padding-left: 10px;
`;
export const Ancla = styled.a`
  width: 17%;
  height: 1.5vh;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
`;
export const BoxDown = styled.div`
  width: 15%;
  height: 7.5vh;
  margin: 10px;
  display: flex;
  flex-direction: column;
`;
export const ImgForm = styled.img`
  width: 3%;
  height: 80%;
  border: 1px solid #444040;
`;
export const ButtonOption = styled.button`
  background: transparent;
  border: none;
`;
