import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchListC } from "../../containers/Home/SearchList";
import { UseStoreLogin } from "../../stores/Login";
import { UseStoreSearch } from "../../stores/Search";
import { UseStoreTemplateView } from "../../stores/TemplateView";
import { stringAvatar } from "../../utils/JSImplements";
import { MUIModalAdapter } from "../Common/MUIModalAdapter";
import logo from "./../../assets/new_logo.svg";
import { Image } from "./styled";

export const Header = ({ Logout }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const profilePhoto = UseStoreTemplateView((state) => state.img_user);
  const current_data_search = UseStoreSearch((state) => state.current_data);
  const data_user = UseStoreLogin((state) => state.data_user);
  const type_user = data_user?.rol_id;

  //buscador
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const styles = {
    typography: {
      fontWeight: "400",
      fontSize: " 16px",
      fontFamily: "Poppins-Regular",
      cursor: "pointer",
      color: "#fff",
      [theme.breakpoints.only("xs")]: { display: "none" },
    },
    button: {
      color: "#666f87",
      bgcolor: "#fff",
      width: "127px",
      minWidth: "127px",
      paddingLeft: "25px",
      height: "35px",
      textTransform: "inherit",
      borderRadius: 5,
      ":hover": { bgcolor: "#fff" },
      "& .MuiButton-endIcon": {
        marginRight: "-10px",
        paddingLeft: "20px",
      },
    },
    appBar: {
      bgcolor: "#666f87",
      height: "70px",
      display: "flex",
      justifyContent: "center",
      boxShadow: "none",
    },
    buttonLogout: {
      height: "60px",
      textTransform: "inherit",
      width: "170px",
      minWidth: "170px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#323338",
    },
    menu: {
      mt: "50px",
      ".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      },
    },
  };

  return (
    <AppBar position="static" sx={styles.appBar}>
      <Toolbar>
        <Stack
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          gap={5}
        >
          <Stack flexDirection="row" alignItems="center" gap={5}>
            <IconButton
              type="button"
              onClick={() => navigate("/home/", { replace: true })}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <HomeIcon sx={{ color: "#fff" }} />
            </IconButton>
            <Image src={logo} alt="logo" />
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap={3}>
            <Button
              type="button"
              onClick={handleOpen}
              variant="contained"
              sx={styles.button}
              endIcon={
                <Box
                  borderRadius="50%"
                  width={25}
                  height={25}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#666f87"
                >
                  <SearchIcon sx={{ fontSize: 15, color: "#fff" }} />
                </Box>
              }
            >
              Buscar
            </Button>
            <Divider
              orientation="vertical"
              sx={{ height: "20px", width: "1px", background: "#8990A2" }}
              variant="middle"
            />
            <Stack flexDirection="row" alignItems="center">
              <IconButton type="button" onClick={handleOpenUserMenu}>
                {profilePhoto ? (
                  <Avatar alt="Avatar" src={profilePhoto} />
                ) : (
                  <Avatar
                    {...stringAvatar(
                      `${data_user?.names} ${data_user?.last_names}`
                    )}
                  />
                )}
              </IconButton>
              <Typography sx={styles.typography} onClick={handleOpenUserMenu}>
                {`${data_user?.names} ${data_user?.last_names}`}
              </Typography>
            </Stack>
            <IconButton
              color="inherit"
              type="button"
              disabled={type_user !== 1}
              onClick={() => {
                if (type_user === 1)
                  navigate("/home/config/", { replace: true });
              }}
            >
              <SettingsIcon sx={{ color: "#F7F7F7" }} />
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
      <MUIModalAdapter
        open={open}
        title={
          current_data_search?.length
            ? "Resultados de búsqueda"
            : "Inicia una búsqueda"
        }
        handleClose={handleClose}
      >
        <SearchListC onClose={handleClose} />
      </MUIModalAdapter>
      <Menu
        sx={styles.menu}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Button
          type="button"
          onClick={Logout}
          startIcon={<LogoutIcon sx={{ fontSize: "15px" }} />}
          sx={styles.buttonLogout}
        >
          Cerrar sesión
        </Button>
      </Menu>
    </AppBar>
  );
};
