import styled from "styled-components";
import Fondo from "./../../assets/new-background.svg";
//S -> Section

export const Image = styled.img`
  width: 145px;
  height: 54px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url(${Fondo});
  background-size: cover;
  min-width: 540px;
  background-repeat: no-repeat;
`;
