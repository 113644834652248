import React, { useState, useEffect } from 'react'
import { PermissionsV } from '../../components/Permissions'
import { UseStoreRoles } from './../../stores/Roles'
import { UseStoreUsers } from './../../stores/Users'
import { UseStoreCompany } from '../../stores/Company'
import { UseStorePermission } from './../../stores/Permissions'
import { PermissionList } from './../../components/Common/PermissionList'
import { SwalFire } from './../../utils'
export const PermissionsC = ({
    children, 
    option, 
    data: {
        label,
        stateView,
        isFilterUser,
        filters
    }}) => {
    const users = UseStoreUsers(state => state.users)
    const permissionTemp = UseStorePermission(state => state.permissionTemp)
    const getRolesEnableds = UseStoreRoles(state => state.getRolesEnableds)
    const rolesEnableds = UseStoreRoles(state => state.rolesEnableds)
    const getPermissions = UseStorePermission(state => state.getPermissions)
    const resetPermissions = UseStorePermission(state => state.resetPermissions)
    const disabledModuleZ = UseStorePermission(state => state.disabledModuleZ)
    const asignPermission = UseStorePermission(state => state.asignPermission)
    const setPermissionsTemp = UseStorePermission(state => state.setPermissionsTemp)
    const updatePermissionsTemp = UseStorePermission(state => state.updatePermissionsTemp)
    const companies = UseStoreCompany(state=> state.companies)
    const get_companies = UseStoreCompany(state=> state.get_companies)
    const [rowsAfected, setRowAfected] = useState([])
    const [enabledButtons, setEnabledButtons] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isBaordActive, setIsBaordActive] = useState(false)
    const [isLoadingData, setIsLoadingData] = useState(false)
    const [dataSelects, setDataSelects] = useState({
        rol_id: 0,
        companie_id: 0,
        user_id: 0,
        companie_user_id: 0
    });

    const fetchCompanies = async () => {
        setIsLoading(true)
        await get_companies()
        await getRolesEnableds()
        setIsLoading(false)
    }

    useEffect(() => {
        fetchCompanies()
        if((!dataSelects?.user_id && !dataSelects?.companie_user_id) || (!dataSelects?.rol_id && !dataSelects?.companie_id))
            setPermissionsTemp([])

        if(option === 2){
            if(isFilterUser)
                handleOnBlur(true)
            else
                handleOnBlur()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Metodo dinamico para filtrar los permisos segun su pantalla
    const handleOnBlur = async (filter_user=false, tab) => {
        //Variable para validar si encontramos algun error  y controlar la consulta
        let find = true
        //Validamos si se quiere hacer un filtro por usuario para cambiar el path
        let path = filter_user ? 'get-user-permissions' : 'get-role-permissions'

        //Iniciamos los estados de carga
        setIsLoading(true)
        setIsLoadingData(true)

        //Contendra las opciones de filtro segun el usso del metodo
        const payload = {}
        //Validamos la infortmacino capturada para armar el payload de la peticion 
        //Validamos si el tab es uno para saber que estoy en la pantalla de permisos y en la pestaña de rol
        if(tab === 1){
            if(!dataSelects?.companie_id && !dataSelects?.rol_id){
                SwalFire('Advertencia', 'Debe seleccionar un rol y una empresa.', 'warning')
                find=false
            }else if(!dataSelects?.companie_id){
                SwalFire('Advertencia', 'Debe seleccionar una empresa.', 'warning')
                find=false
            }else if(!dataSelects?.module_id){
                setPermissionsTemp([])
                find=false
            }else if(!dataSelects?.rol_id){
                SwalFire('Advertencia', 'Debe seleccionar un rol.', 'warning')
                find=false
            }
            else {
                payload['search_id'] = dataSelects.rol_id 
                payload['companie_id'] = dataSelects.companie_id 
                payload['module_id'] = dataSelects.module_id
            }
        //Validamos si el tab es uno para saber que estoy en la pantalla de permisos y en la pestaña de usuario
        }else if(tab === 2){
            payload['search_id'] = dataSelects?.user_id
            payload['companie_id'] = dataSelects?.companie_user_id
        //Validamos si el tab es uno para saber que estoy en la pantalla de roles y quiero editar la informacion del rol desde alli al darle ediatar al usuario
        }else if(filters?.role_id && !filter_user){
            payload['search_id'] = filters?.role_id
            payload['companie_id'] = null
            payload['module_id'] = null
        //Validamos si el tab es uno para saber que estoy en la pantalla de usuarios y quiero editar la informacion del permiso desde alli al darle ediatar al usuario
        }else if(filters?.user_id && filter_user){
            payload['search_id'] = filters?.user_id
        }

        if(find){
            //Enviamos a consuiltar la informacion segun los filtros
            const res = await getPermissions(path, payload)
            if(res?.length) {
                setPermissionsTemp(res)
            }
        }

        //Quitamos los estados de carga
        setIsLoadingData(false)
        setIsLoading(false)
        setRowAfected([])
    }
    

    //Esta pendiente de los cambios en los select para la pantalla de permisos
    const handleChangeSelect = (event) => {
        const { name, value} = event?.target
        setDataSelects({
            ...dataSelects,
            [name]: value
        });
    };

    //Se encarga de guardar los permisos
    const handleSave = async (assign_user=false) => {
        //Iniciamos el estado el estado de carga
        setIsLoading(true);
        //Creamos el path del endpoint dinamicamente segun donde se instancie el componente
        const path  = isFilterUser || assign_user ? 'asign-permissions-user' : 'asign-permissions-role'

        //Variable que tendra las variables para guardar la informacion de los permisos
        const payload = {}

        //validamos la inforamcion que estabamos estrayendo desde los select para saber que debo guardar
        if(dataSelects?.rol_id && dataSelects?.companie_id){
            console.log(1);
            payload['role_id'] = dataSelects?.rol_id
            payload['companie_id'] = dataSelects?.companie_id
        }else if(dataSelects?.user_id && dataSelects?.companie_user_id){
            console.log(2);
            payload['user_id'] = dataSelects?.user_id
            payload['companie_id'] = dataSelects?.companie_user_id
        //Validamos si el tab es uno para saber que estoy en la pantalla de roles y quiero editar la informacion del rol desde alli al darle ediatar al usuario
        }else if(filters?.role_id){
            console.log(3);
            payload['role_id'] = filters?.role_id
        //Validamos si el tab es uno para saber que estoy en la pantalla de usuarios y quiero editar la informacion del permiso desde alli al darle ediatar al usuario
        }else if(filters?.user_id){
            console.log(4);
            payload['user_id'] = filters?.user_id
        }
    
        //Creamos un array solo con los datos que se van a actualizar en la db para evitar reescribir informacion que no se neceestia
        const data = rowsAfected?.map(x => ({
            ...x,
            ...payload
        }))

        //Consumimos el servicio para validar la informacion si se guarddaron los cambiuos
        const res = await asignPermission(path, data, isFilterUser || assign_user )
        if(res || !res) setIsLoading(false)

        //Reiniciamos el estado de la tabla
        handleCancel()
        setRowAfected([])
    }

    //Actualiza el estado del permiso
    const handleChangePermission = async (event, data) => {
        setEnabledButtons(true)
        const { name, checked } = event?.target
        await updatePermissionsTemp({
            ...data,
            [name]: checked,
        })

        if(permissionTemp?.length)
            setRowAfected(permissionTemp)

        //Consultamos si ya hemos modificado una fila para actualizar el valor y no agregar uno nuevo a la lista de asignaciones
        const permissionTempIndex = rowsAfected?.findIndex(row => row?.id === data?.id)
        const per = {
            ...data,
            [name]: checked,
        }

        //Validamos que se ha encontrado un indice del valor para modificarlo o agreagarlo nuevo
        if(permissionTempIndex >= 0) {
            rowsAfected[permissionTempIndex] = {
                ...data,
                [name]: checked,
            }
            setRowAfected(rowsAfected)
        }else{
            setRowAfected([
                ...rowsAfected,
                per
            ])
        }
    };

    //Resetea los cambios de los permisos a su estado inicial
    const handleCancel = async() => {
        setEnabledButtons(false)
        await resetPermissions()
    }

    //Desactiva los permisos del modulo
    const disabledModule = async() => {
        setIsBaordActive(true)
        const res = await disabledModuleZ({
            role_id: dataSelects?.rol_id,
            companie_id: dataSelects?.companie_id
        })
        if (res || !res) setIsBaordActive(false)
    }

    return option === 2 ?
        (
            <PermissionList
                stateView={stateView}
                label={label}
                enabledButtons={enabledButtons}
                handleCancel={handleCancel}
                handleChangePermission={handleChangePermission}
                handleSave={handleSave}
                permissionTemp={permissionTemp}
                isLoadingData={isLoadingData}
                disabledModule={disabledModule}
            />
        )
        :
        (
            <PermissionsV
                data={{
                    vars:{
                        type_user: 1,
                        companies,
                        users,
                        permissionTemp,
                        enabledButtons,
                        dataSelects,
                        isLoading,
                        isLoadingData,
                        isBaordActive,
                        rolesEnableds
                    },
                    funcs: {
                        handleSave,
                        handleOnBlur,
                        handleChangePermission,
                        handleCancel,
                        handleChangeSelect,
                        getRolesEnableds,
                        setPermissionsTemp,
                        setDataSelects,
                        setEnabledButtons,
                        disabledModule
                    }
                }}
            >
                {children}
            </PermissionsV>
        )
                
}